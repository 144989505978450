import PropTypes from "prop-types";
import React from "react";
import createReactClass from "create-react-class";
import { ObjectSearch } from "../models/ObjectSearch";
import { Table } from "@timeedit/tecore-table";
import ContextMenu from "../lib/ContextMenu";
import ObjectSelectSettings from "./ObjectSelectSettings";
import ObjectSettingsConstants from "../lib/ObjectSettingsConstants";
import TemplateKind from "../models/TemplateKind";
import _ from "underscore";
import Popover from "./Popover";
import Language from "../lib/Language";
import API from "../lib/TimeEditAPI";
import LayerComponent from "../lib/LayerComponent";
import Log from "../lib/Log";
import FieldInput from "./FieldInput";
import { MillenniumTime } from "@timeedit/millennium-time";
import MultiSelect from "./MultiSelect";

import errorCorrectLoadedSettings from "../lib/ObjectSearchUtils";
import ActivityUtils from "../lib/ActivityUtils";
import { OPERATION_TYPES } from "./preferences/PrefsCoreAPI";
import { Macros } from "../models/Macros";
import { Selection } from "../models/Selection";
import { McFluffy } from "../models/McFluffy";

import Viewer from "../lib/Viewer";
import { TObjectsGetResult } from "../types/api/shared";

import * as Sentry from "@sentry/browser";
import { ValueOf } from "../types/utils";

const { getMatchAllIds } = ActivityUtils;

const TYPE_STATE_KEYS = ["objects", "objectSearch", "relationState", "availabilityState"];

const DEBOUNCE_LOAD_TIME = 200;
const DEBOUNCE_SETTINGS_TIME = 1000;
const ROW_HEIGHT = 20;

const MAX_OBJECTS_IN_SELECTION = 150;

const ObjectReservationKind = {
    STANDARD: 0,
    ABSTRACT: 1,
    VIRTUAL_ABSTRACT: 2,
    VIRTUAL_STANDARD: 3,
    TEMPLATE: 4,
} as const;

const ObjectSuggestionType = {
    NONE: "NONE",
    VIRTUAL_FILTER_OBJECT: "VIRTUAL_FILTER_OBJECT",
    VIRTUAL_FILTER_FIELD: "VIRTUAL_FILTER_FIELD",
    RELATED_OBJECT: "RELATED_OBJECT",
    RELATED_FIELD: "RELATED_FIELD",
    LAST_RESERVED: "LAST_RESERVED",
} as const;

export type TObjectSuggestionType = ValueOf<typeof ObjectSuggestionType>;

const KC_ENTER = 13;
const FOCUS_TIMEOUT = 200;

const toTypeClass = (id) => ({ class: "typeid", id });

const RelationFilter = {
    INACTIVE: { id: 0 },
    ACTIVE: { id: 1 },
} as const;

const AvailabilityFilter = {
    INACTIVE: { id: 0 },
    ACTIVE: { id: 1 },
} as const;

let _updateCounter = 0;

const getCategoryValues = (searchCriteria, fields, fieldDefs) => {
    const categoryValues = {};
    const removedValues: string[] = [];
    searchCriteria.categories.forEach((category) => {
        const field = fields.find((f) => f.extid === category.id);
        if (!field) {
            return;
        }
        const def = _.find(fieldDefs, (df) => df.id === field.id);
        if (category && Array.isArray(category.values)) {
            const finalValues = category.values
                .flat()
                .map((val) => val.trim())
                .filter((val) => {
                    const exists = def.categories ? def.categories.indexOf(val) !== -1 : false;
                    if (!exists) {
                        removedValues.push(val);
                        return false;
                    }
                    return true;
                });
            if (finalValues.length > 0) {
                if (!categoryValues[field.id]) {
                    categoryValues[field.id] = [finalValues];
                } else {
                    categoryValues[field.id].push(finalValues);
                }
            }
        }
    });
    if (removedValues.length > 0) {
        // TODO Localize
        Log.info(
            `${
                removedValues.length
            } category values do not exist and were ignored: ${removedValues.join(", ")}`
        );
    }
    const matchAllIds = getMatchAllIds(categoryValues, fieldDefs);

    return { categoryValues, matchAllIds };
};

type ObjectSelectState = {
    objects: [];
    columnWidths: [];
    objectSearch: typeof ObjectSearch;
    relationState: typeof RelationFilter.INACTIVE | typeof RelationFilter.ACTIVE;
    availabilityState: typeof AvailabilityFilter.INACTIVE | typeof AvailabilityFilter.ACTIVE;
    relatedObjects: [];
    hasStrictRelation: boolean;
    settingsChange: 0;
    preserveScroll: boolean;
    objectOccurances: [];
    namedSearches: [];
    selectedSearchName: "";
    enableRelatedToggle: boolean;
    isSelectFilterOperation: boolean;
    selectFilterCallback: any;
    showFieldSelection: boolean;
    noFieldsSelected: boolean;
    selectedIndexes: [];
    suggestionType: keyof typeof ObjectSuggestionType;
    manualToggle: boolean;
    updateCounter: 0;
};

const dataIrrelevantProps = [
    "allowMultiSelection",
    "hoverButton",
    "isLayerShown",
    "topLeftButton",
    "user",
];

// Only a subset of these should affect data loading!
const relevantProps = [
    "allowMultiSelection",
    "data",
    "hoverButton",
    "isLayerShown",
    "selectedFluffyItem",
    "selectedType",
    "subtypes",
    "topLeftButton",
    "user",
    "staticObjects",
    "reservationIds",
    "occuringObjects",
    "relatedObject",
];

const getChangedProps = (nextProps, currentProps, propList) => {
    const changedProps = [];
    propList.forEach((prop) => {
        if (!_.isEqual(nextProps[prop], currentProps[prop])) {
            changedProps.push(prop);
        }
    });
    return changedProps;
};

const ObjectSelect = createReactClass({
    displayName: "ObjectSelect",

    propTypes: {
        data: PropTypes.instanceOf(Selection),
    },

    contextTypes: {
        user: PropTypes.object,
        fireEvent: PropTypes.func,
        presentModal: PropTypes.func,
        registerMacro: PropTypes.func,
        deregisterMacro: PropTypes.func,
        primaryFieldManager: PropTypes.object,
        update: PropTypes.func,
    },

    getContext() {
        return this.props.context ? this.props.context : this.context;
    },

    getInitialState(): ObjectSelectState {
        return {
            objects: [],
            columnWidths: [],
            objectSearch:
                this.props.objectSearch ||
                new ObjectSearch({}, this.props.user.showExtraInfo).freeze(),
            relationState: this.props.data ? RelationFilter.ACTIVE : RelationFilter.INACTIVE,
            availabilityState: AvailabilityFilter.ACTIVE,
            relatedObjects: [],
            hasStrictRelation: false,
            settingsChange: 0,
            preserveScroll: false,
            objectOccurances: [],
            namedSearches: [],
            selectedSearchName: "",
            enableRelatedToggle: false,
            isSelectFilterOperation: false,
            selectFilterCallback: null,
            showFieldSelection: false,
            noFieldsSelected: false,
            selectedIndexes: [],
            suggestionType: ObjectSuggestionType.NONE,
            manualToggle: false,
            updateCounter: 0,
        };
    },

    componentDidMount() {
        this._typeSettingsCache = {};
        this._typeSettingsStorage = {};
        this._namedSearchesCache = {};
        this._columnWidthsCache = {};
        this._allowFallbackToAllObjects = true;
        this._loadCount = 0;
        this._externalCategories = {};
        this._externalSearchString = {};

        const selectionObjects = this.getSelectionListObjects();
        if (selectionObjects.length > 0) {
            const os = this.state.objectSearch.setSearchProperty(
                "excludeObjects",
                selectionObjects
            );
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({ objectSearch: os });
        }

        if (!this.props.isStatic) {
            this.loadSuggestedObjects(this.props);
        }

        const typeId = this.getTypeId();
        if (typeId) {
            API.getTypeDefsExtended([typeId], (defs) => {
                if (!this.props.objectSearch) {
                    this.loadUncachedTypeSettings(
                        this.props,
                        typeId,
                        typeId,
                        defs[0],
                        this.state,
                        (newState) => {
                            this.setState(newState);
                        }
                    );
                } else {
                    this.setState({ currentTypeDef: defs[0] });
                }
            });
        }

        this.props.setLayerContentProvider(this.getLayerContent);
        if (this.props.addReloadFunction) {
            this.props.addReloadFunction(() => {
                if (this._reloadRunning) {
                    // Ignore if already reloading
                } else {
                    this.loadObjects(0);
                }
            });
        }

        API.getPreferences("searchSettings", undefined, undefined, (value) => {
            if (!value) {
                return;
            }
            this._typeSettingsStorage = JSON.parse(value as any);
            if (typeId === 0 || !this._typeSettingsStorage[typeId]) {
                return;
            }

            if (!this.props.objectSearch) {
                this.onSearchSettingsChange(this._typeSettingsStorage[typeId].advancedSettings);
            }
            this.setState(_.pluck(this._typeSettingsStorage[typeId], "relationState"), () => {
                if (typeId !== 0) {
                    this.updateNamedSearches(typeId);
                }
            });
        });

        this.registerMacros();
    },

    shouldComponentUpdate(nextProps, nextState) {
        if (_.isEqual(nextProps, this.props) && _.isEqual(nextState, this.state)) {
            return false;
        }

        const changedProps = getChangedProps(nextProps, this.props, relevantProps);
        if (changedProps.length > 0) {
            console.log("*** OL props");
            changedProps.forEach((prop) => console.log(prop));
            console.log("*** OL props");
        }
        console.log("*** OL state");
        const changedState = getChangedProps(nextState, this.state, Object.keys(nextState));
        changedState.forEach((prop) => console.log(prop));
        console.log("*** OL state");

        return true;
    },

    componentDidUpdate(prevProps, prevState) {
        const currentType = this.getTypeId(prevProps);
        const nextType = this.getTypeId(this.props);
        const changedDataRelevantProps = getChangedProps(
            this.props,
            prevProps,
            relevantProps
        ).filter((prop) => dataIrrelevantProps.indexOf(prop) === -1);
        const changedState = getChangedProps(this.state, prevState, Object.keys(this.state));
        if (changedDataRelevantProps.length === 0 && changedState.length === 0) {
            console.log("OL no relevant changes for data loading");
            return;
        }
        console.log("OL", currentType, nextType, changedDataRelevantProps, changedState);
        if (nextType === 0) {
            return;
        }

        let newState = { ...this.state };
        _updateCounter++;
        newState.updateCounter = _updateCounter;
        // Harmless, never triggers the same time as anything else
        if (prevProps.user.showExtraInfo !== this.props.user.showExtraInfo) {
            this._typeSettingsCache = {};
            this.loadUncachedTypeSettings(
                this.props,
                nextType,
                currentType,
                newState.currentTypeDef,
                newState,
                (newState) => {
                    this.setState(newState, () => {});
                }
            );
        }

        if (
            !_.isEqual(this.props.reservationIds, prevProps.reservationIds) &&
            this.props.reservationIds &&
            !this.props.occuringObjects
        ) {
            API.getObjectsOnReservations(
                this.props.reservationIds,
                nextType,
                (objects, occurances) => {
                    this.updateType(prevProps, prevState, currentType, nextType, {
                        ...newState,
                        objectOccurances: objects.map((obj, index) => ({
                            id: obj.id,
                            occurances: occurances[index],
                        })),
                    });
                }
            );
        } else if (!_.isEqual(this.props.occuringObjects, prevProps.occuringObjects)) {
            this.updateType(prevProps, prevState, currentType, nextType, {
                ...newState,
                objectOccurances: this.props.occuringObjects || [],
            });
        } else {
            if (
                this.props.occuringObjects &&
                !_.isEqual(this.props.occuringObjects, newState.objectOccurances)
            ) {
                // console.log("OL Adding new occuring objects from props to state");
                this.updateType(prevProps, prevState, currentType, nextType, {
                    ...newState,
                    objectOccurances: this.props.occuringObjects || [],
                });
            } else {
                this.updateType(prevProps, prevState, currentType, nextType, newState);
            }
        }
    },

    componentWillUnmount() {
        this.getContext().deregisterMacro("objectList");
    },

    // END LIFECYCLE METHODS

    handleTypeChange(currentType, nextType, state, newObjectSearch, handleLoading) {
        console.log("OL Type change", currentType, nextType);
        // eslint-disable-next-line no-param-reassign
        state = { ...state, manualToggle: false };
        if (this.refs.search) {
            // Static object list has no search field
            setTimeout(() => {
                if (_.isInputNode(document.activeElement?.nodeName)) {
                    return;
                }
                if (this.refs.search) {
                    this.refs.search.focus();
                    this.refs.search.select();
                }
            }, FOCUS_TIMEOUT);
        }

        API.getTypeDefsExtended([nextType], (defs) => {
            if (!this._typeSettingsCache[nextType]) {
                this.loadUncachedTypeSettings(
                    this.props,
                    nextType,
                    currentType,
                    defs[0],
                    state,
                    handleLoading
                );
            } else {
                if (nextType !== this.getTypeId(this.props)) {
                    console.log(
                        "OL handleTypeChange, type mismatch",
                        nextType,
                        this.getTypeId(this.props)
                    );
                    return;
                }
                _updateCounter++;
                const newState = {
                    preserveScroll: false,
                    enableRelatedToggle: false,
                    currentTypeDef: defs[0],
                    updateCounter: _updateCounter,
                };
                const combinedState = _.extend(
                    {},
                    state,
                    this._typeSettingsCache[nextType],
                    newState
                );
                if (newObjectSearch) {
                    combinedState.objectSearch = combinedState.objectSearch.immutableSet({
                        excludeObjects: newObjectSearch.excludeObjects,
                    });
                }
                const subtypes = nextType !== 0 ? this.getSubtypes(this.props) : [];
                this.applyTypeSettings(
                    nextType,
                    subtypes,
                    combinedState,
                    this._columnWidthsCache[nextType],
                    handleLoading
                );
            }
        });
    },

    handleNewObjectSearch(currentType, nextType, state, newObjectSearch, handleLoading) {
        console.log("OL New object search", currentType, nextType);
        const combinedState = { state, ...this.getSettingsForType(currentType, nextType) };
        combinedState.objectSearch = combinedState.objectSearch.immutableSet({
            excludeObjects: newObjectSearch.excludeObjects,
        });
        if (nextType !== this.getTypeId()) {
            console.log("OL handleNewObjectSearch, type mismatch", nextType, this.getTypeId());
            return;
        }
        if (this._externalCategories[nextType]) {
            this.applyTypeSettings(
                nextType,
                this.getSubtypes(),
                combinedState,
                this._columnWidthsCache[nextType],
                handleLoading
            );
        } else {
            handleLoading(state);
        }
    },

    handleExternalCategories(currentType, nextType, state, handleLoading) {
        console.log("OL External categories", currentType, nextType);
        if (nextType !== this.getTypeId()) {
            console.log("OL handleExternalCategories, type mismatch", nextType, this.getTypeId());
            return;
        }
        this.applyTypeSettings(
            nextType,
            this.getSubtypes(),
            { ...state, ...this.getSettingsForType(currentType, nextType) },
            this._columnWidthsCache[nextType],
            handleLoading
        );
    },

    handleTimeLimitChange(state, handleLoading) {
        console.log("OL Time limit change");
        // The state to use unless a strict relation is in effect.
        const regularState =
            this.props.data.availableObjects === false
                ? RelationFilter.INACTIVE
                : state.relationState;
        const relationState = state.hasStrictRelation ? RelationFilter.ACTIVE : regularState;
        handleLoading({ ...state, availabilityState: AvailabilityFilter.ACTIVE, relationState });
    },

    handleFinalElse(currentProps, state, prevProps, prevState, handleLoading) {
        // Catch anything else which needs to cause a load
        console.log("OL Final else");
        if (
            state.relationState !== prevState.relationState ||
            state.availabilityState !== prevState.availabilityState ||
            state.selectedSearchName !== prevState.selectedSearchName ||
            state.relatedObjects !== prevState.relatedObjects ||
            !_.isEqual(state.objectOccurances, prevState.objectOccurances) ||
            !_.isEqual(state.objectSearch, prevState.objectSearch) ||
            !_.isEqual(currentProps.selectedFluffyItem, prevProps.selectedFluffyItem) ||
            !_.isEqual(currentProps.relatedObject, prevProps.relatedObject) ||
            !_.isEqual(currentProps.staticObjects, prevProps.staticObjects)
        ) {
            console.log("OL Running final else");
            handleLoading(state);
        } else {
            console.log("OL Doing nothing");
        }
    },

    updateType(prevProps, prevState, currentType, nextType, state) {
        const selectionListObjects = this.getSelectionListObjects(this.props);
        const prevSelectionListObjects = this.getSelectionListObjects(prevProps);

        let newObjectSearch: false | typeof ObjectSearch = false;
        if (
            !_.isEqual(selectionListObjects, prevSelectionListObjects) &&
            !_.isEqual(selectionListObjects, state.objectSearch.excludeObjects || [])
        ) {
            newObjectSearch = state.objectSearch.immutableSet({
                excludeObjects: selectionListObjects,
            });
            this._allowFallbackToAllObjects = true;
        }
        const currentProps = this.props;

        const handleLoading = (currentState) => {
            console.log("OL Handle loading");
            if (currentState.objectSearch.type === null || currentState.objectSearch.type === 0) {
                console.log("OL No type, not loading objects");
                return;
            }
            if (
                this.shouldLoadSuggestedObjects(
                    currentProps,
                    prevProps,
                    currentState,
                    prevState,
                    selectionListObjects,
                    prevSelectionListObjects
                )
            ) {
                this.loadSuggestedObjects(currentProps, currentState);
            } else if (this.shouldLoadObjects(currentProps, currentState, prevProps, prevState)) {
                console.log("OL Loading objects");
                this.loadObjects(0, currentState);
            }
        };

        if (currentType !== nextType) {
            this.handleTypeChange(currentType, nextType, state, newObjectSearch, handleLoading);
            return;
        }
        if (currentType === nextType && this.state.objectSearch.type !== nextType) {
            console.log("OL Object search not up to date, adjusting");
            this.handleTypeChange(currentType, nextType, state, newObjectSearch, handleLoading);
            return;
        }

        if (newObjectSearch) {
            this.handleNewObjectSearch(
                currentType,
                nextType,
                state,
                newObjectSearch,
                handleLoading
            );
            return;
        }

        if (this._externalCategories[nextType]) {
            this.handleExternalCategories(currentType, nextType, state, handleLoading);
            return;
        }

        if (prevProps.data && !_.isEqual(this.props.data.timeLimit, prevProps.data.timeLimit)) {
            this.handleTimeLimitChange(state, handleLoading);
        } else {
            this.handleFinalElse(currentProps, state, prevProps, prevState, handleLoading);
        }
    },

    registerMacros() {
        if (this.props.useMacros === false) {
            return;
        }

        this.getContext().registerMacro("objectList", {
            events: [Macros.Event.SELECTED_TYPE_CHANGED],
            actions: [
                {
                    key: Macros.Action.SELECTED_TYPE_CHANGED,
                    action: (typeId) => {
                        this.onTypeChange(typeId);
                    },
                },
            ],
        });
        this.getContext().registerMacro("objectList", {
            events: [Macros.Event.SHOW_SEARCH_SETTINGS],
            actions: [
                {
                    key: Macros.Action.SHOW_SEARCH_SETTINGS,
                    action: () => {
                        setTimeout(() => {
                            this.showSettings();
                        }, 1000);
                    },
                },
            ],
        });
        this.getContext().registerMacro("objectList", {
            events: [Macros.Event.SET_EXTERNAL_OBJECT_SEARCH_CRITERIA],
            actions: [
                {
                    key: Macros.Action.SET_EXTERNAL_OBJECT_SEARCH_CRITERIA,
                    action: ({ searchCriteria }, callback) => {
                        const setForOtherType = (typeId, filters, searchString) => {
                            this._externalCategories[typeId] = filters;
                            this._externalSearchString[typeId] = searchString || "";
                        };

                        if (Array.isArray(searchCriteria) && searchCriteria.length === 1) {
                            // eslint-disable-next-line no-param-reassign
                            searchCriteria = searchCriteria[0];
                        }

                        // Setting search criteria for many types at once
                        if (Array.isArray(searchCriteria)) {
                            const extIds = searchCriteria.reduce(
                                (result, criteria) =>
                                    result.concat(
                                        criteria.categories.map((category) => category.id)
                                    ),
                                []
                            );
                            /*
                                Multiple category values in one item becomes a match any group
                                Separate values become individual match any groups
                                (Which could validly be joined into one match all group)
                            */
                            API.getFieldsByExtid(extIds, (fields) => {
                                API.getFieldDefs(
                                    fields.map((field) => field.id),
                                    undefined,
                                    (defs) => {
                                        const typedFilters = {};
                                        searchCriteria.forEach((criteria) => {
                                            const { categoryValues, matchAllIds } =
                                                getCategoryValues(criteria, fields, defs);
                                            typedFilters[criteria.type.id] = {
                                                categoryValues,
                                                matchAllIds,
                                                searchString: criteria.searchString,
                                            };

                                            setForOtherType(
                                                criteria.type.id,
                                                categoryValues,
                                                criteria.searchString
                                            );
                                        });
                                        callback();
                                    }
                                );
                            });
                            return;
                        }

                        // Setting search criteria for just one type
                        let finish = (categoryValues) => {
                            setForOtherType(
                                searchCriteria.type.id,
                                categoryValues.values,
                                searchCriteria.searchString
                            );
                            callback();
                        };

                        if (searchCriteria.categories) {
                            // Set the advanced setting selectedCategories, an object where the field ID is the key and the array of values is the value
                            API.getFieldsByExtid(
                                searchCriteria.categories.map((category) => category.id),
                                (fields) => {
                                    API.getFieldDefs(
                                        fields.map((field) => field.id),
                                        undefined,
                                        (defs) => {
                                            const { categoryValues, matchAllIds } =
                                                getCategoryValues(searchCriteria, fields, defs);
                                            finish({
                                                values: categoryValues,
                                                matchAll: matchAllIds,
                                            });
                                        }
                                    );
                                }
                            );
                        } else {
                            finish({});
                        }
                    },
                },
            ],
        });
        this.getContext().registerMacro("objectList", {
            events: [Macros.Event.REQUEST_OPERATION],
            actions: [
                {
                    key: Macros.Action.REQUEST_OPERATION,
                    action: (options) => {
                        if (options.operationType === OPERATION_TYPES.SELECT_FILTER) {
                            this.setState(
                                {
                                    isSelectFilterOperation: true,
                                    selectFilterCallback: options.callback,
                                },
                                () => {
                                    options.data.next();
                                }
                            );
                        }
                    },
                },
            ],
        });
    },

    getNamedSearchesKey(typeId) {
        return `objectSearch.type${typeId}.namedSearches`;
    },

    // @ts-ignore:next-line
    updateNamedSearches(
        typeId = this.getTypeId(),
        objectSearch = this.state.objectSearch,
        name = undefined,
        reload = false,
        callback
    ) {
        if (typeId === 0) {
            return;
        }
        if (!callback) {
            callback = (newState) => {
                this.setState(newState);
            };
        }
        const finish = (foundSearches) => {
            const matchingSearch = _.find(foundSearches, (search) =>
                _.isEqual(search.settings, objectSearch.advancedSettings)
            );
            if (!name && matchingSearch) {
                // eslint-disable-next-line no-param-reassign
                name = matchingSearch.name;
            }
            if (name) {
                callback({
                    namedSearches: foundSearches,
                    selectedSearchName: name,
                });
            } else {
                callback({ namedSearches: foundSearches, selectedSearchName: "" });
            }
        };
        if (!reload && this._namedSearchesCache[typeId]) {
            // eslint-disable-next-line consistent-return
            return finish(this._namedSearchesCache[typeId]);
        }
        API.getPreferences(this.getNamedSearchesKey(typeId), undefined, undefined, (dataString) => {
            if (!dataString) {
                const namedSearches = [];
                this._namedSearchesCache[typeId] = namedSearches;
                callback({ namedSearches });
                return;
            }
            try {
                const namedSearches = errorCorrectLoadedSettings(JSON.parse(dataString as any));
                this._namedSearchesCache[typeId] = namedSearches;
                finish(namedSearches);
            } catch (ignore) {
                // Yes, ignore
            }
        });
    },

    // @ts-ignore:next-line
    getTypeId(props = this.props) {
        if (props.selectedType) {
            return props.selectedType.id;
        }
        return 0;
    },

    // @ts-ignore:next-line
    getSubtypes(props = this.props) {
        if (props.subtypes) {
            return props.subtypes;
        }
        return [];
    },

    shouldLoadObjects(props, state, prevProps, prevState) {
        const result =
            state.objectSearch.type !== prevState.objectSearch.type ||
            state.objectSearch.searchString !== prevState.objectSearch.searchString ||
            !_.isEqual(state.relatedObjects, prevState.relatedObjects) ||
            state.hasStrictRelation !== prevState.hasStrictRelation ||
            !_.isEqual(state.objectSearch.columns, prevState.objectSearch.columns) ||
            !_.isEqual(state.objectSearch.defaultColumns, prevState.objectSearch.defaultColumns) ||
            !_.isEqual(
                state.objectSearch.userSearchFields,
                prevState.objectSearch.userSearchFields
            ) ||
            !_.isEqual(state.objectSearch.allFields, prevState.objectSearch.allFields) ||
            !_.isEqual(
                state.objectSearch.advancedSettings,
                prevState.objectSearch.advancedSettings
            ) ||
            !_.isEqual(
                this.getSelectionListObjects(props),
                state.objectSearch.excludeObjects || []
            ) ||
            !_.isEqual(state.relationState, prevState.relationState) ||
            state.availabilityState !== prevState.availabilityState ||
            (props.data && props.data.editedEntry !== prevProps.data.editedEntry) ||
            !_.isEqual(props.selectedType, prevProps.selectedType) ||
            (props.data && !_.isEqual(props.data.timeLimit, prevProps.data.timeLimit)) ||
            !_.isEqual(
                state.objectSearch.selectedCategories,
                prevState.objectSearch.selectedCategories
            ) ||
            state.settingsChange !== prevState.settingsChange ||
            (props.data && props.data.availableObjects !== prevProps.data.availableObjects) ||
            !_.isEqual(this.props.staticObjects, prevProps.staticObjects) ||
            !_.isEqual(this.props.occuringObjects, prevProps.occuringObjects) ||
            !_.isEqual(props.selectedFluffyItem, prevProps.selectedFluffyItem);
        console.log("OL Should load objects", result);
        return result;
    },

    shouldLoadSuggestedObjects(
        props,
        prevProps,
        state,
        prevState,
        selectionListObjects,
        prevSelectionListObjects
    ) {
        if (!props.selectedFluffyItem && !props.data && !props.relatedObject) {
            console.log("OL Should load suggested objects 1", false);
            return false;
        }
        if (state.relationState === RelationFilter.INACTIVE && state.manualToggle) {
            console.log("OL Should load suggested objects 2", false);
            return false;
        }
        if (state.relationState !== prevState.relationState) {
            console.log("OL Should load suggested objects 3", true);
            return true;
        }
        const result =
            !_.isEqual(selectionListObjects, prevSelectionListObjects) ||
            !_.isEqual(props.selectedType, prevProps.selectedType) ||
            !_.isEqual(props.selectedFluffyItem, prevProps.selectedFluffyItem) ||
            !_.isEqual(props.relatedObject, prevProps.relatedObject);
        console.log("OL Should load suggested objects 4", result);
        return result;
    },

    // @ts-ignore:next-line
    loadSuggestedObjects(props = this.props, state = this.state) {
        console.log("OL Loading suggested objects");
        if (!props.selectedFluffyItem || !props.data) {
            if (!props.relatedObject) {
                console.log("OL Loading suggested objects, early return");
                return;
            }
        }
        const suggestionTypeId = this.getTypeId(props);
        const callback = (result) => {
            if (suggestionTypeId !== this.getTypeId()) {
                return;
            }

            let suggestionType = result[4] || ObjectSuggestionType.NONE;
            let relationState: typeof RelationFilter.INACTIVE | typeof RelationFilter.ACTIVE =
                RelationFilter.ACTIVE; //result[1] ? RelationFilter.ACTIVE : state.relationState;
            if (result[0].length === 0 && !state.manualToggle && !state.hasStrictRelation) {
                //console.log("OL No suggested objects, disabling relation filter");
                relationState = RelationFilter.INACTIVE;
            }
            if (suggestionType === ObjectSuggestionType.LAST_RESERVED) {
                const recentChecked = this.props.user.isRecentlyUsedChecked(suggestionTypeId);
                if (recentChecked === false && !state.hasStrictRelation) {
                    //console.log("OL Recently used not checked, disabling relation filter");
                    relationState = RelationFilter.INACTIVE;
                }
                if (
                    relationState === RelationFilter.ACTIVE &&
                    !state.manualToggle &&
                    !state.hasStrictRelation &&
                    this.hasSearchSettingsOrString(
                        state.objectSearch.advancedSettings,
                        state.objectSearch.searchString
                    )
                ) {
                    //console.log("OL Has search settings, disabling relation filter");
                    relationState = RelationFilter.INACTIVE;
                }
            }
            const enableRelatedToggle = result[3] || false;

            if (state.updateCounter < this.state.updateCounter) {
                console.log(
                    "OL loadSuggestedObjects, state update mismatch",
                    state.updateCounter,
                    this.state.updateCounter
                );
                return;
            }

            this.loadObjects(0, {
                ...state,
                relatedObjects: result[0].map((object, index) => ({
                    id: object.id,
                    deletable: result[2] ? result[2][index] : false,
                })),
                hasStrictRelation: result[1],
                relationState,
                enableRelatedToggle,
                suggestionType,
            });
        };
        if (props.relatedObject) {
            API.getSuggestedObjectsSimple(
                props.relatedObject,
                undefined,
                undefined,
                undefined,
                undefined,
                callback
            );
        } else {
            const toObjectType = (object) => ({
                id: object.object.id,
                type: object.type.id,
                subtypes: _.pluck(object.subtypes || [], "id"),
            });
            const selected = toObjectType(props.selectedFluffyItem);
            const others = props.data.fluffy.objectItems
                .map(toObjectType)
                .filter((object) => object.id !== selected.id);
            const isReservationMode = TemplateKind.equals(
                TemplateKind.RESERVATION,
                props.data.fluffy.templateKind
            );
            const calculateSkipLastReserved = (typeDef) => {
                let skipLastReserved = typeDef.skipLastReserved || false;
                return skipLastReserved;
            };
            if (state.currentTypeDef?.id !== suggestionTypeId) {
                API.getTypeDefsExtended([suggestionTypeId], (defs) => {
                    API.getSuggestedObjectsAdvanced(
                        selected,
                        others,
                        isReservationMode,
                        props.data.fluffy.templateGroupId,
                        0,
                        0,
                        calculateSkipLastReserved(defs[0]),
                        callback
                    );
                });
            } else {
                API.getSuggestedObjectsAdvanced(
                    selected,
                    others,
                    isReservationMode,
                    props.data.fluffy.templateGroupId,
                    0,
                    0,
                    calculateSkipLastReserved(state.currentTypeDef),
                    callback
                );
            }
        }
    },

    // @ts-ignore:next-line
    isPhysicalType(props = this.props) {
        const physicalKinds = [
            McFluffy.RESERVATION_KIND.PHYSICAL,
            McFluffy.RESERVATION_KIND.PHYSICAL_DOUBLE,
            McFluffy.RESERVATION_KIND.OPTIONAL,
            McFluffy.RESERVATION_KIND.OPTIONAL_DOUBLE,
        ];
        return (
            props.selectedFluffyItem &&
            _.contains(physicalKinds, props.selectedFluffyItem.typePhysical)
        );
    },

    // @ts-ignore:next-line
    isAvailabilityFilterAvailable(props = this.props) {
        return props.data && props.data.hasTimeLimit() && this.isPhysicalType(props);
    },

    loadUncachedTypeSettings(props, nextType, prevType, currentTypeDef, state, callback = _.noop) {
        // If nextType ever stops matching what's in props, shouldn't we always bail?
        if (nextType !== this.getTypeId(props)) {
            console.log(
                "OL loadUncachedTypeSettings, type mismatch",
                nextType,
                this.getTypeId(props)
            );
            return;
        }
        const subtypes = nextType !== 0 ? this.getSubtypes(props) : [];
        const newState = { ...state, ...this.getDefaultTypeSettings(prevType, nextType) };
        if (currentTypeDef) {
            newState.currentTypeDef = currentTypeDef;
        }
        newState.objectSearch = newState.objectSearch.immutableSet({
            excludeObjects: this.getSelectionListObjects(props),
        });
        if (this.isAvailabilityFilterAvailable(props)) {
            newState.availabilityState = AvailabilityFilter.ACTIVE;
        }
        this.loadSettingsForType(nextType, newState, (settings, widths) =>
            this.applyTypeSettings(nextType, subtypes, settings, widths, callback)
        );
    },

    loadSettingsForType(typeId, typeSettings, callback) {
        const onComplete = (settings, widths?) => {
            if (this._typeSettingsStorage[typeId]) {
                // eslint-disable-next-line no-param-reassign
                settings = _.extend(settings, {
                    objectSearch: settings.objectSearch.setSearchProperty(
                        "advancedSettings",
                        this._typeSettingsStorage[typeId].advancedSettings
                    ),
                    relationState: this._typeSettingsStorage[typeId].relationState,
                });
            }

            this._typeSettingsCache[typeId] = settings;
            if (widths) {
                this._columnWidthsCache[typeId] = widths;
            }
            callback(settings, widths);
        };

        if (typeId === 0 || typeId === null || typeId === undefined) {
            onComplete(typeSettings);
            return;
        }

        this.loadColumnsAndWidths(typeId, typeSettings, onComplete);
    },

    loadColumnsAndWidths(typeId, typeSettings, onComplete) {
        const typeClass = toTypeClass(typeId);
        API.getPreferences("objectListColumns", [typeClass], undefined, (result) => {
            if (!result) {
                onComplete(typeSettings);
                return;
            }
            const ts = JSON.parse(result[0]);

            const forTyped = _.find(ts.defaultColumns, (col) => col.forType !== undefined);
            if (forTyped && forTyped.forType !== typeId) {
                Sentry.captureMessage(
                    `Loaded columns for type ${typeId}, but columns are for type ${forTyped.forType}. Ignoring.`,
                    { extra: { id: typeId, otherTypeId: forTyped.forType } }
                );
                onComplete(typeSettings);
                return;
            }
            if (!ts.defaultColumns || !_.some(ts.defaultColumns, (col) => col.primary === true)) {
                onComplete(typeSettings);
                return;
            }
            API.getFieldDefs(
                ts.defaultColumns.filter((col) => col !== null).map((col) => col.id),
                undefined,
                (defs) => {
                    ts.defaultColumns = ts.defaultColumns
                        .filter((col) => col !== null)
                        .map((col) => {
                            const def = defs.find((df) => df.id === col.id);
                            if (!def) {
                                return null;
                            }
                            // eslint-disable-next-line no-param-reassign
                            col.name = this.props.user.showExtraInfo
                                ? `${def.name} (${def.extid})`
                                : def.name;
                            return col;
                        });
                    // eslint-disable-next-line no-param-reassign
                    typeSettings.objectSearch = typeSettings.objectSearch.setDefaultColumns(
                        ts.defaultColumns
                    );

                    API.getPreferences(
                        "objectColumnWidths",
                        [typeClass],
                        undefined,
                        (widthResult) => {
                            if (!widthResult) {
                                onComplete(typeSettings);
                                return;
                            }

                            let widths = JSON.parse(widthResult[0]);
                            if (typeSettings.objectSearch.defaultColumns.length !== widths.length) {
                                widths = undefined;
                            }

                            onComplete(typeSettings, widths);
                        }
                    );
                }
            );
        });
    },

    // @ts-ignore:next-line
    applyTypeSettings(
        type,
        subtypes,
        settings,
        // @ts-ignore:next-line
        columnWidths = this._columnWidthsCache[type],
        callback = _.noop
    ) {
        this._allowFallbackToAllObjects = true;
        const delta = {
            reserveMode: settings.reserveMode !== undefined ? settings.reserveMode : true,
            type,
            subtypes,
        };

        if (
            this.props.data &&
            !this.hasSearchSettings(settings.objectSearch.advancedSettings) &&
            !settings.objectSearch.searchString &&
            !this._externalSearchString[type]
        ) {
            // eslint-disable-next-line no-param-reassign
            settings.relationState = RelationFilter.ACTIVE;
            // eslint-disable-next-line no-param-reassign
            settings.availabilityState = AvailabilityFilter.ACTIVE;
        }
        let newSearchObject = settings.objectSearch.applySettings(delta);
        let categoriesSet = false;

        if (this._externalCategories[type]) {
            console.log("OL Applying external categories", this._externalCategories[type]);
            const newCategories = this._externalCategories[type] || {};

            const matchAllIds = getMatchAllIds(newCategories);

            const matchAll = _.uniq([
                ...(newSearchObject.advancedSettings.matchAll || []),
                ...matchAllIds,
            ]);
            newSearchObject = newSearchObject.setSearchProperty("advancedSettings", {
                selectedCategories: newCategories,
                matchAll,
            });
            this._externalCategories[type] = null;
            categoriesSet = true;
        }
        if (this._externalSearchString[type]) {
            newSearchObject = newSearchObject.setSearchProperty(
                "searchString",
                this._externalSearchString[type]
            );
            this._externalSearchString[type] = null;
        }
        newSearchObject.loadSearchFields((newOs) => {
            let newSettings = _.extend({}, settings, {
                objectSearch: newOs,
                columnWidths: columnWidths || [],
                selectedIndexes: [],
            });

            this.clearSelection();
            this.updateNamedSearches(type, newOs, undefined, false, (newNamedSearchesState) => {
                newSettings = _.extend({}, newSettings, newNamedSearchesState);
                if (this.refs.search) {
                    this.refs.search.value = newOs.searchString || "";
                }
                if (categoriesSet) {
                    this.onSearchSettingsChange(
                        newOs.advancedSettings,
                        newSettings,
                        (finalState) => {
                            callback(finalState);
                        }
                    );
                } else {
                    callback(newSettings);
                }
            });
        });
    },

    hasSearchSettingsOrString(advancedSettings, searchString) {
        return searchString !== "" || this.hasSearchSettings(advancedSettings);
    },

    hasSearchSettings(advancedSettings) {
        // Have we made any non-default search settings at all?
        const isDefault = (settings) =>
            Object.keys(ObjectSettingsConstants.DEFAULTS)
                .filter((key) => !_.isNullish(settings[key]))
                .every((key) => _.isEqual(ObjectSettingsConstants.DEFAULTS[key], settings[key]));
        const hasAssociatedObjects =
            advancedSettings.associatedObjects && advancedSettings.associatedObjects.length > 0;
        const hasSelectedCategory =
            Object.keys(advancedSettings.selectedCategories || {}).length > 0;
        return hasAssociatedObjects || hasSelectedCategory || !isDefault(advancedSettings);
    },

    onTableColumnsChange(columns) {
        const newFirstPrimary = _.find(columns, (column) => column.primary);
        const oldFirstPrimary = _.find(
            this.state.objectSearch.defaultColumns,
            (column) => column.primary
        );
        if (newFirstPrimary && oldFirstPrimary && oldFirstPrimary.id !== newFirstPrimary.id) {
            this.setPrimaryField(newFirstPrimary.id);
        }
        const typeId = this.getTypeId();
        const forTyped = _.find(columns, (col) => col.forType !== undefined);
        if (forTyped && forTyped.forType !== typeId) {
            Sentry.captureMessage(
                `Loaded columns for type ${typeId}, but columns are for type ${forTyped.forType}. Ignoring.`,
                { extra: { id: typeId, otherTypeId: forTyped.forType } }
            );
            return;
        }
        API.setPreferences(
            "objectListColumns",
            [toTypeClass(typeId)],
            [JSON.stringify({ defaultColumns: columns })],
            _.noop
        );
        const objectSearch = this.state.objectSearch.setDefaultColumns(columns);
        this._typeSettingsCache[typeId].objectSearch = objectSearch;
        this.setState({ objectSearch }, () => {
            this.loadMoreRows({
                startIndex: this._loadMoreRowsStartIndex,
                stopIndex: this._loadMoreRowsStopIndex,
            });
        });
    },

    setPrimaryField(fieldId) {
        const type = this.getTypeId();
        this.getContext().primaryFieldManager.setPrimaryField(type, fieldId);
        const objects = this.state.objects.map((object) => {
            const field = _.find(object.fields, (item) => item.id === fieldId);
            return _.extend({}, object, {
                name: field ? field.values[0] : object.name,
            });
        });
        this.setState({ objects });
    },

    onTableColumnsWidthChange(columnWidths) {
        if (columnWidths.length === 0) {
            return;
        }
        const typeId = this.getTypeId();
        if (typeId > 0) {
            API.setPreferences(
                "objectColumnWidths",
                [toTypeClass(typeId)],
                [JSON.stringify(columnWidths)],
                _.noop
            );
        }

        this._columnWidthsCache[typeId] = columnWidths;
        this.setState({ columnWidths });
    },

    saveSettingsForType(typeId, settings) {
        console.log("OL saveSettingsForType", typeId, settings);
        if (typeId === 0) {
            return;
        }
        if (typeId !== settings.objectSearch.type) {
            // Really, shouldn't we be able to find a way not to end up here?
            // One case is when there is no type yet set in the objectSearch. That might be okay.
            // The other is that the objectSearch has a different type, which means not returning will save
            // incorrect columns for the type. See DEV-5151.
            console.log("OL", typeId, settings.objectSearch.type, "Not matching, ignoring");
            return;
        }

        this._typeSettingsCache[typeId] = settings;

        const searchSettings = Object.keys(this._typeSettingsCache).reduce(
            (allSettings, id) =>
                _.extend(allSettings, {
                    [id]: {
                        relationState: this._typeSettingsCache[id].relationState,
                        advancedSettings: this._typeSettingsCache[id].objectSearch.advancedSettings,
                    },
                }),
            {}
        );
        API.setPreferences("searchSettings", [JSON.stringify(searchSettings)], _.noop);
    },

    getSettingsForType(prevType, typeId) {
        const settings = this._typeSettingsCache[typeId];
        if (typeId === 0 || !settings) {
            return this.getDefaultTypeSettings(prevType, typeId);
        }

        return settings;
    },

    getDefaultTypeSettings(prevType, nextType) {
        const defaults = _.pick(this.getInitialState(), TYPE_STATE_KEYS);
        if (prevType !== undefined && prevType !== nextType) {
            if (nextType !== defaults.objectSearch.type) {
                defaults.objectSearch = new ObjectSearch(
                    {},
                    this.props.user.showExtraInfo
                ).freeze(); // getInitialState returns the current object search from props, not what we want when switching to a new type
            }
        }
        return defaults;
    },

    onSearchKeyPress(event) {
        if (event.charCode === KC_ENTER && this.refs.search) {
            this.refs.search.blur();
        }
    },

    onSearchStringChange(event) {
        this.debounceSearchStringChange(event.target.value);
    },

    debounceSearchStringChange: _.debounce(function (value) {
        // @ts-ignore:next-line
        this.setSearchString(value);
    }, DEBOUNCE_LOAD_TIME),

    clearSearchString() {
        if (this.refs.search) {
            this.refs.search.value = "";
            this.refs.search.focus();
            this.setSearchString("");
        }
    },

    toggleSettings(event) {
        if (event) {
            event.stopPropagation();
        }
        this.setState({ showFieldSelection: false }, () => {
            this.props.toggleLayer();
        });
    },

    showSettings(callback = _.noop) {
        this.setState({ showFieldSelection: false }, () => {
            this.props.showLayer();
            callback();
        });
    },

    showFieldSelection(event) {
        if (event) {
            event.stopPropagation();
        }
        this.setState({ showFieldSelection: true }, () => {
            this.props.toggleLayer();
        });
    },

    hideFieldSelection(event) {
        if (event) {
            event.stopPropagation();
        }
        this.setState({ showFieldSelection: false, noFieldsSelected: false }, () => {
            this.props.hideLayer();
        });
    },

    // Invoked when the user changes settings in the search settings popover
    onSearchSettingsChange(settings, state = this.state, callback) {
        const newState: Partial<ObjectSelectState> = {
            objectSearch: state.objectSearch.setSearchProperty("advancedSettings", settings),
            settingsChange: state.settingsChange + 1,
            selectedIndexes: [],
        };

        const typeId = this.getTypeId();
        const typeSettings = _.pick(_.extend({}, state, newState), TYPE_STATE_KEYS);
        this._typeSettingsCache[typeId] = typeSettings;

        clearTimeout(this._settingsSaveTimeout);
        this._settingsSaveTimeout = setTimeout(() => {
            this.saveSettingsForType(typeId, typeSettings);
        }, DEBOUNCE_SETTINGS_TIME);

        const matchingSearch = _.find(state.namedSearches, (search) =>
            _.isEqual(search.settings, settings)
        );
        if (matchingSearch) {
            newState.selectedSearchName = matchingSearch.name;
        } else {
            newState.selectedSearchName = "";
        }

        if (
            this.hasSearchSettingsOrString(
                newState.objectSearch.advancedSettings,
                newState.objectSearch.searchString
            )
        ) {
            if (
                state.suggestionType === ObjectSuggestionType.LAST_RESERVED &&
                !state.hasStrictRelation
            ) {
                newState.relationState = RelationFilter.INACTIVE;
            }
        }
        this.clearSelection();
        if (callback) {
            callback({ ...state, ...newState });
        } else {
            this.setState(newState);
        }
    },

    // @ts-ignore:next-line
    setSearchString(value, objectSearch = this.state.objectSearch, callback = _.noop) {
        let relationState = this.state.relationState;
        const isRecentlyUsed = this.state.suggestionType === ObjectSuggestionType.LAST_RESERVED;
        if (isRecentlyUsed && value !== "" && !this.state.hasStrictRelation) {
            relationState = RelationFilter.INACTIVE;
        }
        const newState: Partial<ObjectSelectState> = {
            objectSearch: objectSearch.setSearchProperty("searchString", value),
            relationState,
        };

        const typeId = this.getTypeId();
        if (!this._typeSettingsCache[typeId]) {
            this._typeSettingsCache[typeId] = _.pick(
                _.extend({}, this.state, newState),
                TYPE_STATE_KEYS
            );
        }
        this._typeSettingsCache[typeId].objectSearch = newState.objectSearch;
        this._typeSettingsCache[typeId].relationState = this.state.relationState; //The relationState of newState is temporary, we do not want it persisted in this situation
        this.setState(newState, callback);
    },

    // @ts-ignore:next-line
    getSelectionListObjects(props = this.props) {
        if (!props.data || !props.data.fluffy) {
            if (props.excludeObjects) {
                return props.excludeObjects;
            }
            return [];
        }
        return props.data.fluffy.getObjectIds();
    },

    getUpdatedObjectStore(newObjects, firstIndex, totalNumber, state = this.state) {
        const total = firstIndex === 0 ? totalNumber : state.objects.length;
        const objectStore: object[] = state.objects ? [].concat(state.objects) : [];
        objectStore.length = total;

        for (let i = 0; i < newObjects.length; i++) {
            objectStore[firstIndex + i] = newObjects[i];
        }

        return objectStore;
    },

    getUpdatedSettings(firstIndex, advancedSettings, state, cb) {
        let reserveMode = null;
        if (
            this.isAvailabilityFilterAvailable() &&
            state.availabilityState === AvailabilityFilter.ACTIVE
        ) {
            reserveMode = this.props.data.availableObjects;
        }

        const settings = {
            type: this.getTypeId(),
            subtypes: this.getSubtypes(),
            searchString: state.objectSearch.searchString,
            reserveMode,
            startRow: firstIndex,
            beginTime: 0,
            endTime: 0,
            numberOfRows: 0,
            excludeObjects: this.getSelectionListObjects(),
            otherObjectsMode: ObjectSettingsConstants.OTHER_OBJECTS.EXCLUDE,
            searchObjects: this.props.staticObjects || [],
            excludeReservations: [],
        };
        let fallbackToAllObjects = false;

        if (this.props.data && this.props.data.editedEntry) {
            settings.excludeReservations = this.props.data.editedEntry.reservationids;
        }

        // Availability
        if (this.props.data && this.props.data.hasTimeLimit()) {
            settings.beginTime = this.props.data.timeLimit.begin;
            settings.endTime = this.props.data.timeLimit.end;
        }

        // Related objects
        const suggestedObjectIds = _.pluck(state.relatedObjects, "id");
        if (_.isEqual(state.relationState, RelationFilter.ACTIVE)) {
            if (
                suggestedObjectIds.length === 0 &&
                this._allowFallbackToAllObjects &&
                this.isRelationFilterToggleable()
            ) {
                fallbackToAllObjects = true;
            } else {
                settings.searchObjects = suggestedObjectIds;
                settings.otherObjectsMode = ObjectSettingsConstants.OTHER_OBJECTS.INCLUDE;
            }
        }

        // Associated objects
        if (advancedSettings.associatedObjects && advancedSettings.associatedObjects.length > 0) {
            settings.otherObjectsMode = ObjectSettingsConstants.OTHER_OBJECTS.INCLUDE;
            API.getAssociatedObjects(
                advancedSettings.associatedObjects,
                this.getTypeId(),
                (objects) => {
                    const associatedObjects = objects.map((item) => item.id);
                    settings.searchObjects = associatedObjects;
                    if (_.isEqual(state.relationState, RelationFilter.ACTIVE)) {
                        settings.searchObjects = _.intersection(
                            associatedObjects,
                            suggestedObjectIds
                        );
                    }
                    cb(settings, fallbackToAllObjects);
                }
            );
            return;
        }

        cb(settings, fallbackToAllObjects);
    },

    loadObjects(firstIndex, state = this.state) {
        console.log("OL Loading objects");
        if (
            !this.props.isStatic &&
            this.props.selectedFlufffyItem &&
            state.objectSearch?.type !== this.props.selectedFluffyItem?.type.id
        ) {
            console.log("OL Loading objects, early return");
            return;
        }
        const advancedSettings = _.clone(state.objectSearch.advancedSettings);
        this.getUpdatedSettings(
            firstIndex,
            advancedSettings,
            state,
            (searchSettings, fallbackToAllObjects = false) => {
                let os = state.objectSearch.setSearchProperty("advancedSettings", advancedSettings);
                os = os.applySettings(searchSettings);

                this._loadCount++;
                const currentCount = this._loadCount;
                this._reloadRunning = true;

                os.search(
                    firstIndex,
                    (newObjects, total) => {
                        if (this._loadCount > currentCount) {
                            this._reloadRunning = false;
                            return;
                        }
                        if (state.updateCounter < this.state.updateCounter) {
                            console.log(
                                "OL loadObjects, state update mismatch",
                                state.updateCounter,
                                this.state.updateCounter
                            );
                            this._reloadRunning = false;
                        }
                        if (this.props.onObjectSearcherChange) {
                            this.props.onObjectSearcherChange(os);
                        }

                        const objects = this.getUpdatedObjectStore(
                            newObjects,
                            firstIndex,
                            total,
                            state
                        );

                        // If the type in the current search object is different from that in state,
                        // the loaded objects are of the wrong type and may as well be discarded
                        if (state.objectSearch.type !== os.type) {
                            this._reloadRunning = false;
                            return;
                        }

                        let updatedState = {
                            ...state,
                            objects,
                            objectSearch: os,
                        };

                        if (
                            fallbackToAllObjects ||
                            (this._allowFallbackToAllObjects &&
                                newObjects.length === 0 &&
                                total === 0 &&
                                this.isRelationFilterToggleable() &&
                                !state.manualToggle &&
                                state.relationState !== RelationFilter.INACTIVE)
                        ) {
                            if (!state.hasStrictRelation) {
                                this._allowFallbackToAllObjects = false;
                                console.log(
                                    "OL loadObjects disabling relation filter",
                                    fallbackToAllObjects
                                );
                                updatedState = _.extend(updatedState, {
                                    relationState: RelationFilter.INACTIVE,
                                    selectedIndexes: [],
                                    manualToggle: true,
                                });
                            }
                        }
                        this.clearSelection();
                        this.setState(updatedState);
                        this._reloadRunning = false;
                    },
                    this.props.isStatic
                );
            }
        );
    },

    getFieldDef(fieldId, state = this.state) {
        return _.find(state.objectSearch.allFields, (field) => field.id === fieldId) || null;
    },

    isBoolean(fieldId) {
        const def = this.getFieldDef(fieldId);
        if (!def) {
            return false;
        }
        return def.kind === FieldInput.fieldKind.BOOLEAN;
    },

    isLength(fieldId) {
        const def = this.getFieldDef(fieldId);
        if (!def) {
            return false;
        }
        return def.kind === FieldInput.fieldKind.LENGTH;
    },

    getOccurances(objectId, state = this.state) {
        if (!state.objectOccurances || state.objectOccurances.length === 0) {
            return 0;
        }
        const result = _.find(state.objectOccurances, (occurance) => occurance.id === objectId);
        if (result) {
            return result.occurances;
        }
        return 0;
    },

    getTableRow({ index }) {
        const object = this.state.objects ? this.state.objects[index] : null;
        const columns = this.state.objectSearch.defaultColumns;
        const fillRow = (obj: TObjectsGetResult) => {
            const row: any = {};
            if (obj) {
                const isVirtual = _.contains(
                    [
                        ObjectReservationKind.VIRTUAL_ABSTRACT,
                        ObjectReservationKind.VIRTUAL_STANDARD,
                    ],
                    obj.reservationKind
                );
                const isAbstract = _.contains(
                    [ObjectReservationKind.ABSTRACT, ObjectReservationKind.VIRTUAL_ABSTRACT],
                    obj.reservationKind
                );
                row.italicize = isVirtual;
                columns.forEach((column, columnIndex) => {
                    const fieldItem = this.state.objectSearch.getFieldById(column.id);
                    if (!fieldItem) {
                        Log.debug(
                            "Tried to get value before having correct fields. Have incorrect columns been used for the object type?"
                        );
                        row[column.name] = "";
                        return;
                    }
                    const field = _.find(
                        obj.fields,
                        (objectField) => objectField.id === fieldItem.id
                    );

                    if (!field || !field.values) {
                        row[column.name] = "";
                        return;
                    }

                    let label = field.values.join(", ");

                    if (this.isBoolean(field.id)) {
                        label = label === "1" ? Language.get("dynamic_object_list_yes") : "";
                    }

                    if (this.isLength(field.id)) {
                        label =
                            label === ""
                                ? label
                                : new MillenniumTime(parseInt(label, 10)).format("HH:mm");
                    }

                    const occurances = this.getOccurances(obj.id);
                    if (occurances > 0) {
                        label = `${label} (${occurances}/${this.props.reservationIds.length})`;
                    }

                    if (isAbstract && columnIndex === 0) {
                        row[column.name] = `*${label}`;
                        return;
                    }

                    row[column.name] = label;
                });
            } else {
                columns.forEach((column) => {
                    row[column.name] = "";
                });
            }
            return row;
        };
        if (object) {
            return fillRow(object);
        }
        const row = {};
        columns.forEach((column) => {
            row[column.name] = "";
        });
        return row;
    },

    isRowLoaded(index) {
        return Boolean(this.state.objects[index]);
    },

    loadMoreRows({ startIndex, stopIndex }) {
        // All rows already loaded if list is static
        // Prevents a bug with out of sync objects and type
        if (this.props.isStatic === true) {
            return;
        }
        if (this._reloadRunning === true) {
            return;
        }
        if (!startIndex || (startIndex === 0 && stopIndex === 0)) {
            return;
        }

        this._loadMoreRowsStartIndex = startIndex;
        this._loadMoreRowsStopIndex = stopIndex;
        const advancedSettings = _.clone(this.state.objectSearch.advancedSettings);
        this.getUpdatedSettings(
            startIndex,
            advancedSettings,
            this.state,
            // eslint-disable-next-line no-unused-vars
            (searchSettings) => {
                let os = this.state.objectSearch.setSearchProperty(
                    "advancedSettings",
                    advancedSettings
                );
                os = os.applySettings(searchSettings);

                this._loadCount++;
                const currentCount = this._loadCount;

                os.search(
                    startIndex,
                    (objects, total) => {
                        if (this._loadCount > currentCount) {
                            /*console.log(
                                "OL Waiting for later call, ignoring.",
                                currentCount,
                                this._loadCount
                            );*/
                            return;
                        }

                        const objectStore = this.getUpdatedObjectStore(objects, startIndex, total);
                        this.setState({
                            objects: objectStore,
                            // Since objectSearch is updated the same way here as in loadObjects, it should be safe not to set it, right?
                        });
                    },
                    this.props.isStatic
                );
            }
        );
    },

    objectForIndex(index, columns, sortColumnId, sortOrder, cb) {
        const object = this.state.objects ? this.state.objects[index] : null;

        const activeSortColumn = _.isNullish(sortColumnId)
            ? this.state.objectSearch.cacheSortColumn
            : sortColumnId;
        const activeSortOrder = _.isNullish(sortOrder)
            ? this.state.objectSearch.cacheSortOrder
            : sortOrder;

        const sortingChanged =
            this.state.objectSearch.cacheSortColumn !== activeSortColumn ||
            this.state.objectSearch.cacheSortOrder !== activeSortOrder;
        const objectFieldIds = object ? object.fields.map((field) => field.id) : [];
        const columnFieldIds = columns
            ? _.compact(columns, (column) => this.getFieldById(column.id)).map((field) => field.id)
            : [];
        const hasColumns =
            object &&
            (!columns ||
                _.intersection(objectFieldIds, columnFieldIds).length === columnFieldIds.length);

        if (!sortingChanged && hasColumns) {
            if (cb) {
                cb(object);
            }
            return object;
        }

        if (!cb) {
            return null;
        }

        const firstRequestedRow = Math.max(0, index - ROW_HEIGHT);
        if (!this._rowLoadTimeout) {
            this._rowLoadCallbacks = [];
        }
        this._rowLoadCallbacks.push({ index, callback: cb });
        clearTimeout(this._rowLoadTimeout);
        this._rowLoadTimeout = setTimeout(() => {
            let os = this.state.objectSearch;
            if (sortingChanged) {
                os = os.applySettings({
                    cacheSortColumn: activeSortColumn,
                    cacheSortOrder: activeSortOrder,
                });
            }
            if (this._reloadRunning === true) {
                if (!cb) {
                    return;
                }
                cb(object);
                return;
            }

            this._loadCount++;
            const currentCount = this._loadCount;

            os.search(
                firstRequestedRow,
                (objects, total) => {
                    if (this._loadCount > currentCount) {
                        /*console.log(
                            "OL Waiting for later call, ignoring.",
                            currentCount,
                            this._loadCount
                        );*/
                        return;
                    }
                    const objectStore = this.getUpdatedObjectStore(
                        objects,
                        firstRequestedRow,
                        total
                    );
                    this.setState(
                        {
                            objects: objectStore,
                            objectSearch: this.state.objectSearch.applySettings({
                                cacheSortColumn: activeSortColumn,
                                cacheSortOrder: activeSortOrder,
                            }),
                        },
                        () => {
                            this._rowLoadTimeout = null;
                            this._rowLoadCallbacks.forEach((rowLoadCallback) => {
                                rowLoadCallback.callback(this.state.objects[rowLoadCallback.index]);
                            });
                        }
                    );
                },
                this.props.isStatic
            );
        }, DEBOUNCE_LOAD_TIME);

        return null;
    },

    onSelect(index, selectedIndexes, event) {
        if (!this.props.onClick) {
            return;
        }

        this.objectForIndex(index, null, null, null, (object) => {
            if (!object) {
                if (this.props.onMultipleSelected) {
                    this.setState({ selectedIndexes });
                }
                return;
            }

            const newState: any = {
                preserveScroll: true,
                selectedIndexes: this.props.onMultipleSelected ? selectedIndexes : undefined,
            };
            const isModKey = _.isModKey(event);
            this.props.onClick(
                object,
                !isModKey,
                selectedIndexes,
                selectedIndexes.map((index) => this.state.objects[index]) // Should we do this in some more elegant way?
            );
            this.setState(newState);
            this.refs.table.reloadVisibleRows();
        });
    },

    onRowHover(index) {
        if (this.props.onRowHover) {
            this.objectForIndex(index, null, null, null, (object) => {
                this.props.onRowHover(object);
            });
        }
    },

    onDragStart(index, event) {
        this.objectForIndex(index, null, null, null, (data) => {
            _.setDragData(event, "application/x-timeedit-object", JSON.stringify(data));
        });
    },

    onAddSelectedObjects(allObjects = false) {
        let objects: object[] = [];
        if (allObjects) {
            if (this.state.objects.length > MAX_OBJECTS_IN_SELECTION) {
                Log.info(
                    Language.get(
                        "nc_a_reservation_may_not_contain_more_than_x_objects",
                        MAX_OBJECTS_IN_SELECTION
                    )
                );
                return;
            }
            _.runSync(
                [...this.state.objects].map((object, index) => (done) => {
                    if (!_.isNullish(object)) {
                        objects[index] = object;
                        done();
                    } else {
                        this.objectForIndex(index, null, null, null, (loadedObject) => {
                            objects[index] = loadedObject;
                            done();
                        });
                    }
                }),
                () => {
                    if (objects.length === 0) {
                        return;
                    }
                    this.props.onMultipleSelected(objects, this.state.selectedIndexes);
                }
            );
        } else {
            if (this.state.selectedIndexes.length > MAX_OBJECTS_IN_SELECTION) {
                Log.info(
                    Language.get(
                        "nc_a_reservation_may_not_contain_more_than_x_objects",
                        MAX_OBJECTS_IN_SELECTION
                    )
                );
                return;
            }
            objects = this.state.selectedIndexes.map((index) => this.state.objects[index]);
            if (objects.length === 0) {
                return;
            }
            this.props.onMultipleSelected(objects, this.state.selectedIndexes);
        }
    },

    selectAllSearchFields() {
        const allFields = this.state.objectSearch
            .getAllPossibleUserSearchFields()
            .map((field) => field.id)
            .slice(0, ObjectSearch.MAX_SEARCH_FIELDS);
        this.onSearchFieldsChanged(allFields);
    },

    selectNoSearchFields() {
        this.onSearchFieldsChanged([]);
    },

    onSearchFieldsChanged(values) {
        // Don't allow checking too many fields, but do allow unchecking if we should somehow end up with too many already selected
        if (
            values.length > ObjectSearch.MAX_SEARCH_FIELDS &&
            values.length > this.state.objectSearch.userSearchFields.length
        ) {
            return;
        }
        this.setState(
            {
                objectSearch: this.state.objectSearch.setUserSearchFields(values),
                noFieldsSelected: values.length === 0,
            },
            () => {
                this.props.forceLayerUpdate();
            }
        );
    },

    getLayerContent() {
        if (this.state.showFieldSelection) {
            const clientRect = this.refs.searchFieldButton.getBoundingClientRect();
            const target = {
                top: clientRect.top,
                left: clientRect.left,
                width: clientRect.width,
                height: clientRect.height,
            };
            const selectedFields = this.state.noFieldsSelected
                ? []
                : this.state.objectSearch.getUserSearchFields();
            const options = this.state.objectSearch
                .getAllPossibleUserSearchFields()
                .map((field) => ({
                    value: field.id,
                    label: field.name,
                    selected: selectedFields.indexOf(field.id) !== -1,
                }));
            this.props.setLayerCloseHandler(this.hideFieldSelection);
            return (
                <Popover
                    key="searchFieldPopover"
                    style={{
                        width: "200px",
                        padding: "5px",
                    }}
                    target={target}
                    onClose={this.hideFieldSelection}
                >
                    <h3 style={{ marginTop: "0px" }}>
                        {Language.get("nc_object_list_search_fields")}
                    </h3>
                    <div className="btnGroup vertical">
                        <button className="default" onClick={this.selectAllSearchFields}>
                            {Language.get("nc_button_title_select_all")}
                        </button>
                        <button className="default" onClick={this.selectNoSearchFields}>
                            {Language.get("nc_button_title_select_none")}
                        </button>
                    </div>
                    <hr />
                    <MultiSelect options={options} onValueChanged={this.onSearchFieldsChanged} />
                    <p>{Language.get("nc_object_list_search_fields_help")}</p>
                </Popover>
            );
        }
        const offset = _.nodeOffset(this.refs.objectSelectSettingsButton.offsetParent);
        const target = {
            top: offset.top,
            left: offset.left,
            width: 32,
            height: 32,
        };

        return (
            <Popover
                key="objectSelectPopover"
                style={{
                    width: "50%",
                    minWidth: "400px",
                    maxWidth: "800px",
                }}
                target={target}
                onClose={this.props.hideLayer}
            >
                <ObjectSelectSettings
                    categories={this.state.objectSearch?.categories || []}
                    selectedCategories={this.state.objectSearch?.selectedCategories || {}}
                    onChange={this.onSearchSettingsChange}
                    onClose={this.toggleSettings}
                    defaultSettings={this.state.objectSearch?.advancedSettings || {}}
                    type={this.getTypeId()}
                    onSavedSettingsChanged={(name) => {
                        this.updateNamedSearches(
                            this.getTypeId(),
                            this.state.objectSearch,
                            name,
                            true
                        );
                    }}
                />
            </Popover>
        );
    },

    getRowMenuItems(objectIndex) {
        type RowItem = {
            label?: string;
            action?: () => void;
            isSeparator?: boolean;
            isDisabled?: () => boolean;
        };
        let items: RowItem[] = [];

        if (this.props.onMultipleSelected) {
            items.push({
                label: Language.get("nc_add_selected_objects"),
                action: () => {
                    this.onAddSelectedObjects(false);
                },
            });
        }

        if (this.props.onObjectInfo) {
            items.push({
                label: Language.get("nc_selection_list_show_object_information"),
                action: () => {
                    this.objectForIndex(objectIndex, null, null, null, (object) => {
                        if (object) {
                            this.props.onObjectInfo(
                                object.id,
                                false,
                                true,
                                object.name || Language.get("cal_res_side_tab_object_info")
                            );
                        }
                    });
                },
            });

            // It would be nice to check for permissions before displaying these, but API is lacking
            items.push({ isSeparator: true });

            items.push({
                label: Language.get("cal_selected_get_object_info"),
                action: () => {
                    this.objectForIndex(objectIndex, null, null, null, (object) => {
                        this.props.onObjectInfo(
                            object.id,
                            false,
                            false,
                            `${Language.get("cal_selected_get_object_info")} ${object.name}`
                        );
                    });
                },
            });

            items.push({
                label: Language.get("cal_selected_copy_object"),
                action: () => {
                    this.objectForIndex(objectIndex, null, null, null, (object) => {
                        this.props.onObjectInfo(
                            object.id,
                            true,
                            false,
                            Language.get("cal_selected_copy_object")
                        );
                    });
                },
            });
        }

        if (Viewer.isActive(this.props.user)) {
            items.push({ isSeparator: true });
            items.push({
                label: Language.get("nc_open_in_te_viewer"),
                action: () => {
                    let all = this.state.selectedIndexes.map((index) => this.state.objects[index]);
                    if (_.isEmpty(all)) {
                        const base = this.objectForIndex(objectIndex, null, null, null);
                        all = [base];
                    }
                    const objects = all
                        .map((object) => `&o=${object.id}.${object.typeId}`)
                        .join("");
                    Viewer.open(objects, this.props.user);
                },
            });
        }

        if (_.isEqual(this.state.relationState, RelationFilter.ACTIVE)) {
            const getSuggestedObject = (object) =>
                _.find(this.state.relatedObjects, (item) => item.id === object.id) || {};
            items = items.concat([
                { isSeparator: true },
                {
                    label: Language.get("nc_object_select_hide_object"),
                    isDisabled: () => {
                        const object = this.objectForIndex(objectIndex, null, null, null);
                        const suggestedObject = getSuggestedObject(object);
                        return !suggestedObject.deletable;
                    },
                    action: () => {
                        this.objectForIndex(objectIndex, null, null, null, (object) => {
                            const suggestedObject = getSuggestedObject(object);
                            if (suggestedObject.deletable) {
                                API.deleteFromSuggestedObjects(suggestedObject.id, () => {
                                    this.loadSuggestedObjects();
                                });
                            }
                        });
                    },
                },
            ]);
        }

        return items;
    },

    isRelationFilterToggleable() {
        return !this.state.hasStrictRelation && this.getTypeId() !== 0;
    },

    toggleRelationState() {
        if (!this.isRelationFilterToggleable()) {
            return;
        }

        const newState = _.isEqual(this.state.relationState, RelationFilter.INACTIVE)
            ? RelationFilter.ACTIVE
            : RelationFilter.INACTIVE;
        const isRecentlyUsed = this.state.suggestionType === ObjectSuggestionType.LAST_RESERVED;
        if (isRecentlyUsed) {
            this.getContext().update(
                this.props.user,
                this.props.user.setRecentlyUsedChecked(
                    this.getTypeId(),
                    newState === RelationFilter.ACTIVE
                )
            );
        }

        this._allowFallbackToAllObjects = false;
        this.setState({
            relationState: newState,
            preserveScroll: false,
            manualToggle: true,
        });
    },

    toggleAvailabilityFilter() {
        this.setState({
            availabilityState:
                this.state.availabilityState !== AvailabilityFilter.INACTIVE
                    ? AvailabilityFilter.INACTIVE
                    : AvailabilityFilter.ACTIVE,
            preserveScroll: false,
        });
    },

    onSortingChanged(sortData) {
        if (!this.isSortable(sortData.sortBy)) {
            return;
        }
        const os = this.state.objectSearch.applySettings({
            cacheSortColumn: sortData.sortBy,
            cacheSortOrder: sortData.sortDirection === "ASC" ? 0 : 1,
        });
        if (this._reloadRunning === true) {
            // This will lose the sorting we just tried to set. But a reload is running anyway, so that should be reasonable and rare, right?
            return;
        }
        this._loadCount++;
        const currentCount = this._loadCount;

        os.search(
            0,
            (objects, total) => {
                if (this._loadCount > currentCount) {
                    //console.log("OL Waiting for later call, ignoring.", currentCount, this._loadCount);
                    return;
                }
                const objectStore = this.getUpdatedObjectStore(objects, 0, total);
                const updatedSearch = this.state.objectSearch.applySettings({
                    cacheSortColumn: sortData.sortBy,
                    cacheSortOrder: sortData.sortDirection === "ASC" ? 0 : 1,
                });

                const newState = {
                    objects: objectStore,
                    objectSearch: updatedSearch,
                    selectedIndexes: [],
                };

                this.clearSelection();

                const typeId = this.getTypeId();
                const typeSettings = _.pick(_.extend({}, this.state, newState), TYPE_STATE_KEYS);
                this._typeSettingsCache[typeId] = typeSettings;

                this.setState(newState);
                if (this.props.onObjectSearcherChange) {
                    this.props.onObjectSearcherChange(updatedSearch);
                }
                this.refs.table.reloadVisibleRows();
            },
            this.props.isStatic
        );
    },

    isSortable(sortColumn) {
        const column = _.find(
            this.state.objectSearch.defaultColumns,
            (col) => col.name === sortColumn
        );
        if (column) {
            return column.sortable;
        }
        return false;
    },

    clearSelection() {
        if (this._clearSelection) {
            this._clearSelection();
        }
    },

    _setClearSelection(clearFunction) {
        this._clearSelection = clearFunction;

        if (this.props.setClearSelection) {
            this.props.setClearSelection(this._clearSelection);
        }
    },

    onNamedSearchSelected(event) {
        const searchName = event.target.value;
        const selectedSettings = _.find(
            this.state.namedSearches,
            (setting) => setting.name === searchName
        ) || { settings: {} };
        this.onSearchSettingsChange(selectedSettings.settings);
    },

    onCancelSelectFilterOperation() {
        this.state.selectFilterCallback(null);
        this.setState({ isSelectFilterOperation: false, selectFilterCallback: null });
    },

    onSelectFilterOperation() {
        type ResultType = {
            searchString: string;
            selectedCategories?: object;
            type?: string;
        };
        const result: ResultType = {
            searchString: this.state.objectSearch.searchString,
        };

        const selectedCategories = {};
        Object.keys(this.state.objectSearch.advancedSettings.selectedCategories).forEach(
            (fieldId) => {
                const intId = parseInt(fieldId, 10);
                const extId = _.find(
                    this.state.objectSearch.allFields,
                    (field) => field.id === intId
                ).extid;
                let values =
                    this.state.objectSearch.advancedSettings.selectedCategories[fieldId].flat();
                const isMatchAll =
                    this.state.objectSearch.advancedSettings.matchAll.indexOf(intId) !== -1;
                if (isMatchAll) {
                    values = values.map((value) => [value]);
                }
                selectedCategories[extId] = values;
            }
        );
        result.selectedCategories = selectedCategories;
        API.getTypes([this.getTypeId()], false, (types) => {
            result.type = types[0].extid;
            this.state.selectFilterCallback(result);
            this.setState({ isSelectFilterOperation: false, selectFilterCallback: null });
        });
    },

    getAllObjects(cb, doSet) {
        const objects: object[] = [];
        _.runSync(
            [...this.state.objects].map((object, index) => (done) => {
                if (!_.isNullish(object)) {
                    objects[index] = object;
                    done();
                } else {
                    this.objectForIndex(index, null, null, null, (loadedObject) => {
                        objects[index] = loadedObject;
                        done();
                    });
                }
            }),
            () => {
                if (objects.length === 0) {
                    return;
                }
                cb(objects, doSet);
            }
        );
    },

    getSelectedObjects(cb, doSet) {
        if (
            this.state.selectedIndexes &&
            this.state.selectedIndexes.length > 0 &&
            this.state.selectedIndexes.length < MAX_OBJECTS_IN_SELECTION
        ) {
            const objects = this.state.selectedIndexes.map((index) => this.state.objects[index]);
            cb(objects, doSet);
        } else {
            this.getAllObjects(cb, doSet);
        }
    },

    render() {
        const typeId = this.getTypeId();
        const hasType = typeId !== 0;
        const buttonClasses = ["settings"];
        const hasSearchSettings = this.hasSearchSettings(this.state.objectSearch.advancedSettings);
        if (hasSearchSettings) {
            buttonClasses.push("active");
        }

        const clearSettingsButton = hasSearchSettings ? (
            <button
                title={Language.get("nc_dynamic_object_list_clear_search_settings")}
                className="clearObjectSearch"
                onClick={() => {
                    this.onSearchSettingsChange({});
                }}
            />
        ) : null;

        const toggleClasses = {
            listModeToggle: true,
            disabled: !hasType,
        };

        const isRecentlyUsed = this.state.suggestionType === ObjectSuggestionType.LAST_RESERVED;
        let isChecked = _.isEqual(this.state.relationState, RelationFilter.ACTIVE);

        let stateButtons = [
            <label className={_.classSet(toggleClasses)} key="relationStateButton">
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={this.toggleRelationState}
                    disabled={!this.isRelationFilterToggleable()}
                />
                {isRecentlyUsed
                    ? Language.get("nc_object_list_recently_used")
                    : Language.get("dynamic_object_list_footer_title_related")}
            </label>,
        ];
        if (!this.props.data) {
            stateButtons = [];
            if (this.state.enableRelatedToggle) {
                stateButtons = [
                    <label className={_.classSet(toggleClasses)} key="relationStateButton">
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={this.toggleRelationState}
                            disabled={false}
                        />
                        {isRecentlyUsed
                            ? Language.get("nc_object_list_recently_used")
                            : Language.get("dynamic_object_list_footer_title_related")}
                    </label>,
                ];
            }
        }
        if (this.isAvailabilityFilterAvailable()) {
            const label = this.props.data.availableObjects
                ? Language.get("dynamic_object_list_footer_title_free")
                : Language.get("dynamic_object_list_footer_title_occupied");
            stateButtons = stateButtons.concat(
                <label className={_.classSet(toggleClasses)} key="availabilityStateButton">
                    <input
                        type="checkbox"
                        checked={this.state.availabilityState !== AvailabilityFilter.INACTIVE}
                        onChange={this.toggleAvailabilityFilter}
                        disabled={!hasType}
                    />
                    {label}
                </label>
            );
        }

        const savedSettings =
            this.state.namedSearches.length > 0 ? (
                <select value={this.state.selectedSearchName} onChange={this.onNamedSearchSelected}>
                    <option key="noNamedSearchSelected" value="">
                        -
                    </option>
                    {this.state.namedSearches.map((namedSearch) => (
                        <option key={namedSearch.name} value={namedSearch.name}>
                            {namedSearch.name}
                        </option>
                    ))}
                </select>
            ) : null;

        const sendToObjectHeaderButton = this.props.onSendToObjectHeader ? (
            <button
                title={Language.get("nc_set_objects_in_object_header")}
                className="sendObjects"
                onClick={(event) => {
                    ContextMenu.displayMenu(
                        [
                            {
                                key: "setObjects",
                                label: Language.get("nc_set_objects_in_object_header"),
                                action: () =>
                                    this.getSelectedObjects(this.props.onSendToObjectHeader, true),
                            },
                            {
                                key: "addObjects",
                                label: Language.get("nc_add_objects_to_object_header"),
                                action: () =>
                                    this.getSelectedObjects(this.props.onSendToObjectHeader, false),
                            },
                        ],
                        event
                    );
                }}
            />
        ) : null;

        const sendToObjectManagerButton = this.props.onSendToObjectManager ? (
            <button
                title={Language.get("nc_send_to_object_manager")}
                className="sendObjects"
                onClick={() => {
                    this.getSelectedObjects(this.props.onSendToObjectManager);
                }}
            />
        ) : null;

        const sendToSelectionListButton = this.props.allowMultiSelection ? (
            <button
                title={Language.get("nc_add_to_selection")}
                className="sendObjectsSelection"
                onClick={(event) => {
                    ContextMenu.displayMenu(
                        [
                            {
                                key: "setObjects",
                                label: Language.get("nc_add_selected_objects"),
                                action: () => this.onAddSelectedObjects(false),
                            },
                            {
                                key: "addObjects",
                                label: Language.get("nc_add_all_objects"),
                                action: () => this.onAddSelectedObjects(true),
                            },
                        ],
                        event
                    );
                }}
            />
        ) : null;

        const isDefaultFields =
            this.state.objectSearch.getUserSearchFields().join("") ===
            this.state.objectSearch.getDefaultUserSearchFields().join("");
        const className = isDefaultFields ? "searchFieldButton defaultFields" : "searchFieldButton";
        const topLine =
            this.props.isStatic === true ? null : (
                <div className="listTopLine">
                    {this.props.topLeftButton}
                    <button
                        ref="objectSelectSettingsButton"
                        onClick={this.toggleSettings}
                        className={buttonClasses.join(" ")}
                        disabled={!hasType}
                    />
                    {clearSettingsButton}
                    {savedSettings}
                    <div className="searchContainer">
                        <input
                            ref="search"
                            type="text"
                            className="search"
                            disabled={!hasType}
                            onKeyPress={this.onSearchKeyPress}
                            onChange={this.onSearchStringChange}
                        />
                        {this.state.objectSearch.getAllPossibleUserSearchFields().length > 0 ? (
                            <button
                                ref="searchFieldButton"
                                className={className}
                                onClick={this.showFieldSelection}
                                title={Language.get("nc_object_search_fields")}
                            />
                        ) : null}
                        <button className="clearSearch" onClick={this.clearSearchString} />
                    </div>
                    {sendToObjectHeaderButton}
                    {sendToObjectManagerButton}
                    {sendToSelectionListButton}
                </div>
            );

        let highlight = this.props.highlightSelection
            ? Table.HIGHLIGHT.SINGLE
            : Table.HIGHLIGHT.NONE;

        if (this.props.allowMultiSelection) {
            highlight = Table.HIGHLIGHT.MULTI;
        }

        const selectFilterButton = this.state.isSelectFilterOperation ? (
            <div style={{ display: "flex" }}>
                <button
                    className="prefsOperationButton cancel"
                    onClick={this.onCancelSelectFilterOperation}
                >
                    {Language.get("dialog_cancel")}
                </button>
                <button className="prefsOperationButton" onClick={this.onSelectFilterOperation}>
                    {Language.get("nc_am_update_filter")}
                </button>
            </div>
        ) : null;

        const missingTypeMessage = hasType ? null : (
            <div className="missingType">{Language.get("nc_object_select_no_type_help")}</div>
        );

        return (
            <div style={{ width: this.props.width }}>
                {topLine}
                {missingTypeMessage}
                <Table
                    ref="table"
                    highlight={highlight}
                    language={Language}
                    log={Log}
                    presentModal={this.getContext().presentModal}
                    contextMenu={ContextMenu}
                    isRowLoaded={this.isRowLoaded}
                    loadMoreRows={this.loadMoreRows}
                    rowCount={hasType ? this.state.objects.length : 0}
                    modalKey={this.props.modalKey}
                    allColumns={this.state.objectSearch.columns}
                    columns={this.state.objectSearch.defaultColumns || []}
                    columnWidths={this.state.columnWidths || []}
                    onColumnChange={this.onTableColumnsChange}
                    onColumnWidthChange={this.onTableColumnsWidthChange}
                    setClearSelection={this._setClearSelection}
                    columnButtonClick={this.columnButtonClick}
                    getTableRow={this.getTableRow}
                    getRowMenuItems={this.getRowMenuItems}
                    onRowHover={this.onRowHover}
                    hoverButton={this.props.hoverButton}
                    width={this.props.width}
                    onSelect={this.onSelect}
                    onSortingChanged={this.onSortingChanged}
                    isSortable={this.isSortable}
                    onDragStart={this.onDragStart}
                    scrollToIndex={this.state.preserveScroll ? undefined : 0}
                    sortOrder={this.state.objectSearch.cacheSortOrder}
                    sortColumn={this.state.objectSearch.cacheSortColumn}
                    maxColumns={10}
                />
                <div className="toggleButtons">{stateButtons}</div>
                {selectFilterButton}
            </div>
        );
    },
});

export default LayerComponent.wrap(ObjectSelect);
