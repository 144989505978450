import { Calendar } from "./Calendar";
import { CancellationSearch } from "./CancellationSearch";
import { ConflictSearch } from "./ConflictSearch";
import { ExamData } from "./ExamData";
import { McFluffy } from "./McFluffy";
import { MemberData } from "./MemberData";
import { Model } from "./Model";
import { OrderSearch } from "./OrderSearch";
import { PrefsData } from "./PrefsData";
import { RequestSearch } from "./RequestSearch";
import { ReservationSearch } from "./ReservationSearch";
import { Selection } from "./Selection";
import { StaticReservations } from "./StaticReservations";
import { WaitingListSearch } from "./WaitingListSearch";

type TFirstAndSecondTypes =
    | Calendar
    | ReservationSearch
    | CancellationSearch
    | WaitingListSearch
    | OrderSearch
    | RequestSearch
    | StaticReservations
    | PrefsData
    | ExamData
    | ConflictSearch
    | MemberData;
export class Section extends Model {
    first: TFirstAndSecondTypes;
    second: TFirstAndSecondTypes | null;
    weight: any;
    hasVerticalLayout: any;
    previous?: any;
    area: any;

    constructor(
        first: TFirstAndSecondTypes,
        second?: TFirstAndSecondTypes | null,
        weight?: number,
        hasVerticalLayout?: boolean
    ) {
        super("Section");
        this.first = first;
        this.second = second || null;
        this.weight = weight || 1.0;
        this.hasVerticalLayout = hasVerticalLayout || false;
    }

    hasCalendar() {
        return this.first instanceof Calendar;
    }

    setComponent(calendar) {
        return this.immutableSet({
            first: calendar,
            second: null,
            weight: 1.0,
            previous: this.first,
        });
    }

    hasReservationList() {
        return this.first instanceof ReservationSearch;
    }

    hasCancellationList() {
        return this.first instanceof CancellationSearch;
    }

    hasWaitingList() {
        return this.first instanceof WaitingListSearch;
    }

    hasOrderList() {
        return this.first instanceof OrderSearch;
    }

    hasRequestList() {
        return this.first instanceof RequestSearch;
    }

    hasStaticReservations() {
        return this.first instanceof StaticReservations;
    }

    hasPrefsComponent() {
        return this.first instanceof PrefsData;
    }

    hasExamComponent() {
        return this.first instanceof ExamData;
    }

    hasConflictList() {
        return this.first instanceof ConflictSearch;
    }

    hasMemberCreator() {
        return this.first instanceof MemberData;
    }

    hasChild() {
        return (
            this.hasCalendar() ||
            this.hasReservationList() ||
            this.hasCancellationList() ||
            this.hasOrderList() ||
            this.hasWaitingList() ||
            this.hasRequestList() ||
            this.hasStaticReservations() ||
            this.hasPrefsComponent() ||
            this.hasExamComponent() ||
            this.hasConflictList() ||
            this.hasMemberCreator()
        );
    }

    splitToComponent(splitHorizontally = false, component) {
        const self = this;
        return this.immutableSet({
            hasVerticalLayout: splitHorizontally,
            weight: 0.5,
            first: new Section(self.first, null, null, null),
            second: new Section(component, null, null, null),
        });
    }

    split(splitHorizontally = false, splitWithReservationList = false) {
        const self = this;
        return this.immutableSet({
            hasVerticalLayout: splitHorizontally,
            weight: 0.5,
            first: new Section(self.first, null, null, null),
            second: new Section(
                splitWithReservationList
                    ? new ReservationSearch(this.first.limits)
                    : Calendar.create(this.first.limits),
                null,
                null,
                null
            ),
        });
    }

    toList(selection) {
        if (this.previous && this.previous instanceof ReservationSearch) {
            return this.setComponent(this.previous);
        }
        const newSelection = new Selection().freeze();
        return this.setComponent(
            new ReservationSearch(
                this.first.limits,
                newSelection.immutableSet({
                    fluffy: McFluffy.create(selection.fluffy.toJson(), selection.fluffy.labels),
                })
            )
        );
    }

    toCancellationList(selection) {
        if (this.previous && this.previous instanceof CancellationSearch) {
            return this.setComponent(this.previous);
        }
        return this.setComponent(new CancellationSearch(this.first.limits));
    }

    toOrderList(selection) {
        if (this.previous && this.previous instanceof OrderSearch) {
            return this.setComponent(this.previous);
        }
        return this.setComponent(new OrderSearch(this.first.limits));
    }

    toWaitingList(selection) {
        if (this.previous && this.previous instanceof WaitingListSearch) {
            return this.setComponent(this.previous);
        }
        return this.setComponent(new WaitingListSearch(this.first.limits));
    }

    toRequestList(selection) {
        if (this.previous && this.previous instanceof RequestSearch) {
            return this.setComponent(this.previous);
        }
        return this.setComponent(new RequestSearch(this.first.limits));
    }

    toConflictList(selection) {
        if (this.previous && this.previous instanceof ConflictSearch) {
            return this.setComponent(this.previous);
        }
        return this.setComponent(new ConflictSearch(this.first.limits));
    }

    toCalendar(selection) {
        if (this.previous && this.previous instanceof Calendar) {
            return this.setComponent(this.previous);
        }
        return this.setComponent(Calendar.create(this.first.limits));
    }

    toPrefsComponent(selection) {
        if (this.previous && this.previous instanceof PrefsData) {
            return this.setComponent(this.previous);
        }
        return this.setComponent(new PrefsData(this.first.limits, selection));
    }

    toExamComponent(selection) {
        if (this.previous && this.previous instanceof ExamData) {
            return this.setComponent(this.previous);
        }
        return this.setComponent(new ExamData(this.first.limits, selection));
    }

    toMemberCreator(selection) {
        if (this.previous && this.previous instanceof MemberData) {
            return this.setComponent(this.previous);
        }
        return this.setComponent(new MemberData(this.first.limits, selection));
    }

    close(first) {
        const otherSection = first ? "second" : "first";

        return this.immutableSet((section) => {
            const layout = section[otherSection].hasVerticalLayout;
            const fst = section[otherSection].first;
            const second = section[otherSection].second;

            // eslint-disable-next-line no-param-reassign
            section.first = fst;
            // eslint-disable-next-line no-param-reassign
            section.second = second;
            // eslint-disable-next-line no-param-reassign
            section.hasVerticalLayout = layout;
        });
    }

    getAllChildren() {
        if (this.hasChild()) {
            return [this.first];
        }
        return []
            .concat(this.first.getAllChildren() ?? [])
            .concat(this.second?.getAllChildren() ?? []);
    }

    getCalendarSection(calendar) {
        const stack = [this];
        let section;

        while (stack.length > 0) {
            section = stack.pop();
            if (section.first === calendar) {
                return section;
            } else if (!section.hasChild()) {
                stack.unshift(section.first);
                stack.unshift(section.second);
            }
        }

        return null;
    }

    toJSON(x = 0, y = 0, width = 1.0, height = 1.0) {
        if (this.hasChild()) {
            const calendar = this.first.toJSON();
            calendar.orientation = {
                x,
                y,
                width,
                height,
            };
            return [calendar];
        }

        if (this.hasVerticalLayout) {
            return this.first
                .toJSON(x, y, width, height * this.weight)
                .concat(
                    this.second?.toJSON(
                        x,
                        y + height * this.weight,
                        width,
                        height * (1 - this.weight)
                    )
                );
        }

        return this.first
            .toJSON(x, y, width * this.weight, height)
            .concat(
                this.second.toJSON(x + width * this.weight, y, width * (1 - this.weight), height)
            );
    }
}
