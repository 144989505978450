import { useMemo } from "react";
import MessageBar from "./MessageBar";
import NotificationLogListener from "./Notification";
import { useFlags } from "launchdarkly-react-client-sdk";

type TTempLogListenerSelectorProps = {
    messageSize: {
        width: any;
        marginLeft: number;
    };
};

const TempLogListenerSelector = ({ messageSize }: TTempLogListenerSelectorProps) => {
    const ldFlags = useFlags();
    const shouldUseNewLogListener = ldFlags?.coreUseNewNotificationLogListener ?? false;

    return useMemo(
        () =>
            shouldUseNewLogListener ? (
                <NotificationLogListener />
            ) : (
                <MessageBar style={messageSize} />
            ),
        [shouldUseNewLogListener]
    );
};

export default TempLogListenerSelector;
