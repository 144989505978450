import React from "react";
import PropTypes from "prop-types";
import API from "../lib/TimeEditAPI";
import ContextMenu from "../lib/ContextMenu";
import { Table } from "@timeedit/tecore-table";
import _ from "underscore";
import Language from "../lib/Language";
import { Macros } from "../models/Macros";
import { MillenniumDateTime, SimpleDateFormat } from "@timeedit/millennium-time";
import Log from "../lib/Log";
import RC from "../lib/ReservationConstants";
import ColumnConstants from "../lib/ColumnConstants";
import { Reservation } from "../models/Reservation";
import { Entry } from "../models/Entry";
import { TWithId } from "../types/models/TWithId";

const { TYPE_COLUMN_OFFSET } = ColumnConstants;

const SETTINGS_TO_SAVE = ["columnWidths", "selectedColumns"];

const COL_IDS = {
    ID: 0,
    TYPE: 1,
    TIME: 2,
    DATE: 3,
};

const MIN_CONTENT_LENGTH = 2;

const getColumns = (useNewReservationGroups = false) => {
    const result = [
        { id: COL_IDS.ID, name: Language.get("cal_reservation_list_column_id") },
        { id: COL_IDS.TIME, name: Language.get("cal_reservation_list_column_time") },
        { id: COL_IDS.TYPE, name: Language.get("cal_reservation_list_column_type") },
    ];
    if (useNewReservationGroups) {
        result.push({ id: COL_IDS.DATE, name: Language.get("cal_reservation_list_column_date") });
    }
    return result;
};

const MAX_RESERVATIONS_MODIFY = 100;

type TGroupInformationPaneProps = {
    id: number;
    listId: number;
    reservations: number[];
    toggleAddMode: (entries: Entry[]) => void;
    isGroupMode: boolean;
    onInfoOpen: (
        entry: Entry | Entry[] | number[],
        showPanel?: boolean,
        editMode?: boolean
    ) => void;
    disableEntryEditing: boolean;
    onEditGroupReservation: (reservations: TWithId | TWithId[]) => void;
    groupIds: number[];
    unlockedReservations: number[];
    onFinishMoveButtonClicked: () => void;
};
class GroupInformationPane extends React.Component<TGroupInformationPaneProps> {
    private _isMounted = false;
    constructor(props, context) {
        super(props, context);
        this.state = {
            entries: [],
            columnWidths: [],
            selectedColumns: [],
            types: [],
            hoverIndex: null,
            reservations: [],
        };
    }

    static contextTypes = {
        update: PropTypes.func,
        user: PropTypes.object,
        fireEvent: PropTypes.func,
        presentModal: PropTypes.func,
        registerMacro: PropTypes.func,
        deregisterMacro: PropTypes.func,
        useNewReservationGroups: PropTypes.bool,
    };

    componentDidMount() {
        this._isMounted = true;
        this.loadTypes();
        this.loadGroupReservations();
        this.loadSettings((newSettings) => {
            if (this._isMounted) {
                this.setState(newSettings);
            }
        });
        this.context.registerMacro(`groupReservations${this.props.id}`, {
            events: [Macros.Event.RESERVATION_MADE_OR_MODIFIED],
            actions: [
                {
                    key: Macros.Action.REFRESH,
                    action: this.refresh.bind(this),
                },
            ],
        });
        this.context.registerMacro(`groupReservations${this.props.id}`, {
            events: [Macros.Event.SELECT_RESERVATION],
            actions: [
                {
                    key: Macros.Action.SET_RESERVATION,
                    action: this.selectReservation.bind(this),
                },
            ],
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const shouldLoadGroupReservations =
            !_.isEqual(prevProps.reservations, this.props.reservations) ||
            this.props.isGroupMode !== prevProps.isGroupMode ||
            !_.isEqual(prevState.selectedColumns, this.state.selectedColumns) ||
            !_.isEqual(prevState.types, this.state.types);

        if (shouldLoadGroupReservations) {
            this.loadGroupReservations();
        }
        if (!_.isEqual(prevState.entries, this.state.entries)) {
            this.refs.groupTable.clearSelection();
        }
        if (!_.isEqual(prevState.reservations, this.state.reservations)) {
            this.refs.groupTable.clearSelection();
        }
        if (!_.isEqual(prevState.columnWidths, this.state.columnWidths)) {
            this.saveSettings();
        }
    }

    componentWillUnmount() {
        this.context.deregisterMacro(`groupReservations${this.props.id}`);
        this._isMounted = false;
    }

    refresh() {
        this.loadGroupReservations();
    }

    selectReservation(reservation) {
        if (this._isMounted) {
            if (this.context.useNewReservationGroups) {
                const index = this.state.reservations.map((res) => res.id).indexOf(reservation.id);
                this.setSelected(index);
            } else {
                const index = Math.max(
                    this.state.entries.indexOf(this.getEntryWithReservation(reservation.id)),
                    0
                );
                this.setSelected(index);
            }
        }
    }

    loadTypes() {
        API.updateMcFluffy(null, (res) => {
            const reservationTypes = res.parameters[1].types;
            API.findTypes((types) => {
                const activeTypes = reservationTypes.map((reservationType) =>
                    _.find(types, (type) => type.id === reservationType.id)
                );
                if (this._isMounted) {
                    this.setState({ types: _.filter(activeTypes, (tp) => tp !== undefined) });
                }
            });
        });
    }

    loadGroupReservations() {
        const idsToGet = this.props.reservations;
        const calls = [];
        const newState = {};
        if (this.context.useNewReservationGroups) {
            calls.push((done) => {
                API.getReservationGroup(this.props.groupIds[0], (group) => {
                    newState.group = group;
                    newState.newGroupName = group.name;
                    done();
                });
            });
            calls.push((done) => {
                API.findAllReservationsList(
                    {
                        allReservations: true,
                        reservationStatus: [
                            RC.STATUS.COMPLETE,
                            RC.STATUS.INCOMPLETE,
                            RC.STATUS.CONFIRMED,
                            RC.STATUS.PLANNED,
                            RC.STATUS.PRELIMINARY,
                            RC.STATUS.REJECTED,
                            RC.STATUS.REQUESTED,
                        ],
                        groupIds: [this.props.groupIds[0]],
                        useModifyPermission: false,
                        numberOfRows: 1000,
                    },
                    (reservations) => {
                        newState.reservations = reservations;
                        done();
                    }
                );
            });
        }
        if (idsToGet.length > 0) {
            const typeFields = this.state.selectedColumns
                .filter((id) => id > TYPE_COLUMN_OFFSET)
                .map((id) => id - TYPE_COLUMN_OFFSET);

            calls.push((done) => {
                API.getGroupedEntries(
                    this.props.clusterKind,
                    idsToGet,
                    { typeFields },
                    (entries) => {
                        if (entries.length > 0) {
                            entries.forEach((entry) => {
                                // eslint-disable-next-line no-param-reassign
                                entry.labels = (entry.text || "").split("\n");
                                // eslint-disable-next-line no-param-reassign
                                entry.types = entry.types || [];
                            });
                            newState.entries = entries;
                            done();
                        }
                    }
                );
            });
        }

        _.runSync(calls, () => {
            if (idsToGet.length === 0) {
                newState.entries = [];
            }
            if (this._isMounted) {
                this.setState(newState);
            }
        });
    }

    getTime(time, isEnd) {
        return SimpleDateFormat.format(
            time,
            isEnd
                ? Language.getDateFormat("date_f_hh_mm_end")
                : Language.getDateFormat("date_f_hh_mm")
        );
    }

    getTableRow({ index }) {
        const row = {};
        const newGroups = this.context.useNewReservationGroups;
        const entry = newGroups ? this.state.reservations[index] : this.state.entries[index];
        this.getColumns().forEach((col) => {
            if (col.id === 0) {
                row[col.name] = newGroups ? entry.id : entry.reservationids.join(", ");
            } else if (col.id === COL_IDS.TYPE) {
                row[col.name] = newGroups
                    ? entry.objects
                          .map((object) => object.fields.map((field) => field.values.join(", ")))
                          .join(", ")
                    : entry.text || "";
            } else if (col.id === COL_IDS.TIME) {
                const startTime = entry.begins
                    ? new MillenniumDateTime(entry.begins[0])
                    : entry.begin
                    ? new MillenniumDateTime(entry.begin)
                    : null;
                const endTime = entry.ends
                    ? new MillenniumDateTime(entry.ends[0])
                    : entry.end
                    ? new MillenniumDateTime(entry.end)
                    : null;
                row[col.name] = `${this.getTime(startTime, false)}-${this.getTime(endTime, true)}`;
            } else if (col.id > TYPE_COLUMN_OFFSET) {
                const type = col.id - TYPE_COLUMN_OFFSET;
                if (newGroups) {
                    row[col.name] = entry.objects
                        .filter((object) => object.type.id === type)
                        .map((object) => object.fields.map((field) => field.values.join(", ")));
                } else {
                    const labels = entry.types
                        .map((tp, idx) => {
                            if (tp === type) {
                                return entry.labels[idx];
                            }
                            return null;
                        })
                        .filter((lb) => lb !== null);
                    row[col.name] = _.uniq(labels).join(", ");
                }
            } else if (
                col.id === COL_IDS.DATE &&
                ((entry.begins && entry.ends) || (entry.begin && entry.end))
            ) {
                let format;
                const startTime = entry.begins
                    ? new MillenniumDateTime(entry.begins[0])
                    : new MillenniumDateTime(entry.begin);
                const endTime = entry.ends
                    ? new MillenniumDateTime(entry.ends[0])
                    : new MillenniumDateTime(entry.end);
                // eslint-disable-next-line no-magic-numbers
                const between = startTime && endTime ? startTime.daysBetween(endTime) : 2;
                const isSameDay = between === 0 || (between === 1 && endTime.isMidnight());

                if (!isSameDay) {
                    // Different start and end date
                    format = Language.getDateFormat("date_f_m_d");
                    const start = SimpleDateFormat.format(startTime, format);
                    const end = SimpleDateFormat.format(
                        endTime,
                        Language.getDateFormat("date_f_m_d_end")
                    );
                    if (start !== end) {
                        row[col.name] = `${start}-${end}`;
                    }
                    row[col.name] = start;
                } else {
                    format = Language.getDateFormat("date_f_yy_mm_dd");
                    row[col.name] = SimpleDateFormat.format(startTime, format);
                }
            } else {
                row[col.name] = "";
            }
        });
        return row;
    }

    onGroupReservationSelected(index) {
        if (this.context.useNewReservationGroups) {
            this.props.onGroupReservationSelected(this.state.reservations[index]);
        } else {
            this.props.onGroupReservationSelected(this.state.entries[index]);
        }
    }

    onTableColumnsWidthChange(columnWidths) {
        this.setState({ columnWidths });
    }

    onTableColumnsChange(columns) {
        this.setState({
            selectedColumns: columns.map((column) => column.id),
        });
    }

    onDelete(reservationIds, callback) {
        API.okToCancelReservations({ reservationIds }, (res) => {
            const canCancelAll = _.every(res.parameters[0], (status) => !status.details);
            if (!canCancelAll) {
                this.context.presentModal(
                    <div>
                        {<p>{Language.get("cal_res_below_reservations_can_not_be_cancelled")}</p>}
                    </div>,
                    null,
                    Language.get("cal_res_below_cancel_reservations")
                );
                return;
            }

            const yesButton = {
                title: Language.get("dialog_yes"),
                cb: () => {
                    const doCancel = (rIds) => {
                        Reservation.cancel(
                            rIds,
                            () => {
                                this.context.fireEvent(
                                    `reservationList${this.props.id}`,
                                    Macros.Event.RESERVATION_MADE_OR_MODIFIED,
                                    []
                                );
                                if (this._clearSelection) {
                                    this._clearSelection();
                                }
                                this.refresh();
                                callback();
                            },
                            this.context.useNewReservationGroups
                        );
                    };
                    if (this.context.useNewReservationGroups) {
                        doCancel(reservationIds);
                    } else {
                        API.removeReservationsFromGroups(reservationIds, (result) => {
                            if (result === false) {
                                Log.error(
                                    Language.get("nc_could_not_remove_from_reservation_group")
                                );
                            } else {
                                doCancel(reservationIds);
                            }
                        });
                    }
                },
            };
            const noButton = { title: Language.get("dialog_no") };
            const buttons = [yesButton, noButton];
            this.context.presentModal(
                <div>
                    {
                        <p>
                            {Language.get(
                                "cal_res_below_do_you_wish_to_cancel_all",
                                reservationIds.length
                            )}
                        </p>
                    }
                </div>,
                null,
                Language.get("cal_res_below_cancel_reservations"),
                buttons
            );
        });
    }

    getColumns() {
        if (this.state.types.length === 0) {
            return [];
        }
        if (this.state.selectedColumns.length > 0) {
            return this.state.selectedColumns
                .map((id) => _.find(this.getAllColumns(), (column) => column.id === id))
                .filter((column) => column !== undefined);
        }
        const cols = getColumns(this.context.useNewReservationGroups);
        return [cols[1], cols[2]];
    }

    getAllColumns() {
        if (this.state.types.length === 0) {
            return [];
        }

        return getColumns(this.context.useNewReservationGroups).concat(
            this.state.types.map((type) => ({
                id: TYPE_COLUMN_OFFSET + type.id,
                name: type.name,
            }))
        );
    }

    getColumnWidths() {
        return this.state.columnWidths;
    }

    getSaveState(state = this.state) {
        // Return the state which should be persisted
        return _.pick(_.extend({}, state), SETTINGS_TO_SAVE);
    }

    loadSettings(callback) {
        API.getPreferences("listSettings", [this.props.listId], undefined, (result) => {
            if (!result || result.length === 0) {
                callback({});
                return;
            }

            const newSettings = JSON.parse(result[0]);

            callback(newSettings);
        });
    }

    saveSettings(state = this.state, props = this.props) {
        const TIMEOUT = 1000;
        clearTimeout(this._saveSearchTimeout);
        this._saveSearchTimeout = setTimeout(() => {
            API.setPreferences(
                "listSettings",
                [props.listId],
                [JSON.stringify(this.getSaveState(state, props))],
                _.noop
            );
        }, TIMEOUT);
    }

    getEntryWithReservation(reservationIds) {
        const ids = _.asArray(reservationIds);
        return _.find(this.state.entries, (entry) =>
            _.some(entry.reservationids, (id) => ids.indexOf(id) !== -1)
        );
    }

    getEntryReservationIds(reservationIds) {
        if (this.context.useNewReservationGroups) {
            return reservationIds;
        }
        return this.getEntryWithReservation(reservationIds).reservationids;
    }

    getRowMenuItems(selectedIndexes, callback) {
        const selectedReservationIds = this.context.useNewReservationGroups
            ? selectedIndexes.map((index) => this.state.reservations[index].id)
            : _.flatten(selectedIndexes.map((index) => this.state.entries[index].reservationids));
        type TMenuRowItem = {
            key?: string;
            label: string;
            isDisabled?: boolean;
            action: () => void;
            classNames: string[];
        };
        const items: Array<TMenuRowItem[]> = [];
        // TODO Correct lock check
        const isLocked = this.state.reservations.some(
            (reservation) => reservation.lock && reservation.lock === "soft"
        );
        items.push([
            {
                label: Language.get("menu_view_info"),
                isDisabled: selectedReservationIds.length > MAX_RESERVATIONS_MODIFY,
                action: () => {
                    this.props.onInfoOpen(selectedReservationIds, true);
                },
                classNames: ["icon", "showInfo"],
            },
            {
                key: "entry.changeObject",
                label: Language.get("cal_func_res_change_object"),
                isDisabled: this.props.disableEntryEditing || isLocked,
                action: () => {
                    this.props.onEditGroupReservation(
                        this.getEntryReservationIds(selectedReservationIds).map((rId) => ({
                            id: rId,
                        }))
                    );
                },
                classNames: ["icon", "changeObjects"],
            },
        ]);

        if (!isLocked) {
            items.push([
                {
                    label: Language.get("nc_cal_func_res_edit_fields"),
                    isDisabled: selectedReservationIds.length > MAX_RESERVATIONS_MODIFY,
                    action: () => {
                        this.props.onInfoOpen(selectedReservationIds, true, true);
                    },
                    classNames: ["icon", "editFields"],
                },
                {
                    key: "table_change_time",
                    label: Language.get("nc_menu_change_time"),
                    isDisabled: this.props.disableEntryEditing,
                    action: () => {
                        this.props.onChangeTime(
                            selectedReservationIds,
                            selectedIndexes.map((index) => {
                                if (this.context.useNewReservationGroups) {
                                    return this.state.reservations[index];
                                }
                                return this.state.entries[index];
                            }),
                            true
                        );
                    },
                    classNames: ["icon", "changeTime"],
                },
                {
                    key: "table_remove_from_group",
                    label: Language.get("nc_remove_from_reservation_group"),
                    action: () => {
                        if (this.context.useNewReservationGroups) {
                            if (!this.state.group) {
                                // eslint-disable-next-line no-console
                                console.log(
                                    "Attempted to remove reservations from a group without a group being loaded"
                                );
                                return;
                            }
                            API.removeReservationsFromReservationGroup(
                                this.state.group.id,
                                selectedReservationIds,
                                (result) => {
                                    if (result === false) {
                                        Log.error(
                                            Language.get(
                                                "nc_could_not_remove_from_reservation_group"
                                            )
                                        );
                                    } else {
                                        this.refresh();
                                        this.context.fireEvent(
                                            `groupReservations${this.props.id}`,
                                            Macros.Event.RESERVATION_MADE_OR_MODIFIED,
                                            selectedReservationIds
                                        );
                                        callback();
                                    }
                                }
                            );
                        } else {
                            API.removeReservationsFromGroups(selectedReservationIds, (result) => {
                                if (result === false) {
                                    Log.error(
                                        Language.get("nc_could_not_remove_from_reservation_group")
                                    );
                                } else {
                                    this.refresh();
                                    this.context.fireEvent(
                                        `groupReservations${this.props.id}`,
                                        Macros.Event.RESERVATION_MADE_OR_MODIFIED,
                                        selectedReservationIds
                                    );
                                    callback();
                                }
                            });
                        }
                    },
                    classNames: ["icon", "removeFromGroup"],
                },
                {
                    key: "entry.cancel",
                    label: Language.get("cal_func_res_delete"),
                    action: () => this.onDelete(selectedReservationIds, callback),
                    classNames: ["icon", "remove"],
                },
            ]);
        }
        return items;
    }

    onRowHover(index) {
        this.setState({ hoverIndex: index });
    }

    setSelected(index) {
        if (this._setSelected) {
            this._setSelected(index);
        }
    }

    registerSetSelected(fn) {
        this._setSelected = fn;
    }

    saveGroupName() {
        if (!this.state.group || this.state.newGroupName === this.state.group.name) {
            return;
        }

        API.updateReservationGroup(
            this.state.group.id,
            this.state.group.extid,
            this.state.newGroupName,
            (result) => {
                if (!result.message) {
                    this.setState({
                        group: { ...this.state.group, name: this.state.newGroupName },
                    });
                } else {
                    Log.error(result.details);
                }
            }
        );
    }

    onGroupNameChange(event) {
        this.setState({ newGroupName: event.target.value });
    }

    render() {
        const content = this.context.useNewReservationGroups
            ? this.state.reservations
            : this.state.entries;
        let groupButtons = (
            <button
                style={{ position: "absolute", right: "0px" }}
                className="editGroupButton save"
                onClick={() => {
                    this.props.toggleAddMode(content);
                }}
            >
                {Language.get("menu_edit")}
            </button>
        );
        if (this.props.unlockedReservations) {
            groupButtons = (
                <button
                    style={{ position: "absolute", right: "0px" }}
                    className="editGroupButton save"
                    onClick={this.props.onFinishMoveButtonClicked}
                >
                    {Language.get("nc_dialog_done")}
                </button>
            );
        } else if (this.props.isGroupMode) {
            groupButtons = (
                <button
                    style={{ position: "absolute", right: "0px" }}
                    className="editGroupButton save"
                    onClick={() => {
                        this.saveGroupName();
                        this.props.toggleAddMode(content);
                    }}
                >
                    {content.length < MIN_CONTENT_LENGTH
                        ? Language.get("dialog_cancel")
                        : Language.get("nc_dialog_done")}
                </button>
            );
        }
        return (
            <div>
                <div className="listTopLine">
                    {this.props.isGroupMode && this.state.group ? (
                        <input
                            type="text"
                            onChange={this.onGroupNameChange.bind(this)}
                            value={this.state.newGroupName}
                        />
                    ) : (
                        <h1>
                            {this.state.group
                                ? this.state.group.name || this.state.group.extId
                                : Language.get("nc_reservation_group_list_title")}
                        </h1>
                    )}
                    {groupButtons}
                </div>
                <Table
                    language={Language}
                    log={Log}
                    presentModal={this.context.presentModal}
                    contextMenu={ContextMenu}
                    ref="groupTable"
                    isRowLoaded={() => true}
                    allColumns={this.getAllColumns()}
                    registerSetSelected={this.registerSetSelected.bind(this)}
                    columns={this.getColumns()}
                    getTableRow={this.getTableRow.bind(this)}
                    rowCount={content.length}
                    getRowMenuItems={this.getRowMenuItems.bind(this)}
                    onRowHover={this.onRowHover.bind(this)}
                    hoverIndex={this.state.hoverIndex}
                    onSelect={this.onGroupReservationSelected.bind(this)}
                    columnWidths={this.getColumnWidths()}
                    width={"100%"}
                    highlight={Table.HIGHLIGHT.SINGLE}
                    onColumnChange={this.onTableColumnsChange.bind(this)}
                    onColumnWidthChange={this.onTableColumnsWidthChange.bind(this)}
                />
            </div>
        );
    }
}

export default GroupInformationPane;
