import React from "react";
import Language from "../lib/Language";
import ObjectSettingsConstants from "../lib/ObjectSettingsConstants";
import { ObjectSearch } from "../models/ObjectSearch";
import API from "../lib/TimeEditAPI";
import { Table } from "@timeedit/tecore-table";
import ContextMenu from "../lib/ContextMenu";
import Log from "../lib/Log";
import _ from "underscore";
import { TimeEdit } from "../lib/TimeEdit";
import PropTypes from "prop-types";
import FieldInput from "./FieldInput";
import ObjectSelectSettings from "./ObjectSelectSettings";
import ArrayInput from "./ArrayInput";
import { MillenniumDateTime, SimpleDateFormat } from "@timeedit/millennium-time";
import DateRange from "./DateRange";
import getMembershipColumn from "../lib/MembershipConstants";
import ScratchpadList from "./ScratchpadList";
import { SourceType, ScratchpadObject } from "../models/Scratchpad";

const DUMMY_VALUE = "DUMMY";

const SET_STATE_TIMEOUT = 500;

type TMultiObjectSelectProps = {
    hasPeriodObjects: boolean;
    onPinboardItemAdded: (item: ScratchpadObject) => void;
    parentId: number;
};

class MultiObjectSelect extends React.Component<TMultiObjectSelectProps> {
    state = {
        selectedOption: DUMMY_VALUE,
        availableValues: [],
        selectedValues: [],
        filteredValues: [],
        defaultColumns: [],
        searchObject: new ObjectSearch({}, this.context.user.showExtraInfo).freeze(),
        pinboardSearch: new ObjectSearch(
            { excludeObjects: [], otherObjectsMode: ObjectSettingsConstants.OTHER_OBJECTS.INCLUDE },
            this.context.user.showExtraInfo
        ).freeze(),
        allColumns: [],
        selectedColumns: {},
        categoryValues: {},
        totalNumberOfObjects: 0,
        totalSelectedObjects: 0,
        leftColumnWidths: [],
        rightColumnWidths: [],
        scratchpadObjects: this.context.scratchpad.getObjects(),
    };

    static contextTypes = {
        presentModal: PropTypes.func,
        user: PropTypes.object,
        scratchpad: PropTypes.object,
    };
    private _isMounted = false;
    isMoveClick?: boolean;

    componentDidMount() {
        this._isMounted = true;
        this._columnWidthsCache = {};
        const checkSelection = () => {
            if (this.props.options && this.props.options.length > 0) {
                const options = this.getOptions(this.props.options);
                let optionValue = null;
                if (options[0].props.value) {
                    optionValue = String(options[0].props.value);
                } else if (
                    options[0].props.children &&
                    options[0].props.children.length > 0 &&
                    options[0].props.children[0].props &&
                    options[0].props.children[0].props.value
                ) {
                    optionValue = options[0].props.children[0].props.value;
                }
                if (optionValue) {
                    this.onOptionSelected({ target: { value: optionValue } });
                }
            }
        };
        const finishMount = () => {
            this.state.pinboardSearch.setType(TimeEdit.rootType, (updatedSearch) => {
                this.setState({ pinboardSearch: updatedSearch }, () => {
                    API.getPreferences("multiObjectSelectColumns", [], undefined, (result) => {
                        if (!result || result.length === 0) {
                            this.updateState(this.props, checkSelection);
                            return;
                        }

                        const newSettings = JSON.parse(result[0]);
                        this.setState({ selectedColumns: newSettings }, () => {
                            this.updateState(this.props, checkSelection);
                        });
                    });
                });
            });
        };

        finishMount();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.shouldUpdate(prevProps, this.props)) {
            this.updateState(this.props);
            return;
        }
        if (
            !_.isEqual(
                this.state.categoryValues,
                this.state.searchObject.advancedSettings.selectedCategories
            )
        ) {
            const advancedSettings = _.clone(this.state.searchObject.advancedSettings);
            advancedSettings.selectedCategories = this.state.categoryValues;
            const searchObject = this.state.searchObject.setSearchProperty(
                "advancedSettings",
                advancedSettings
            );
            this.search(
                0,
                0,
                searchObject,
                this.state.selectedValues.map((obj) => obj.id)
            );
        } else if (
            !_.isEqual(
                this.state.searchObject.advancedSettings,
                prevState.searchObject.advancedSettings
            )
        ) {
            this.search(
                0,
                0,
                this.state.searchObject,
                this.state.selectedValues.map((obj) => obj.id)
            );
        }
    }

    componentWillUnmount() {
        this._isMounted = true;
    }

    shouldUpdate(props, nextProps) {
        return (
            !_.isEqual(props.selectedValues, nextProps.selectedValues) ||
            !_.isEqual(props.options, nextProps.options)
        );
    }

    updateState = (props, callback = _.noop) => {
        let selected = props.selectedValues || [];
        if (this.state.selectedValues && this.state.selectedValues.length > 0) {
            selected = this.state.selectedValues;
        }
        const mapToIds = function (object) {
            return object.id ? object.id : object;
        };
        const mapToNameAndId = function (object) {
            let name = object.id;
            const original = _.find(selected, (obj) => obj.id === object.id);
            if (object.extid) {
                name = object.extid;
            }
            if (object.fields && object.fields[0] && object.fields[0].values) {
                name = object.fields[0].values.join(", ");
            }
            if (name === "") {
                name = object.extid ? object.extid : object.id;
            }
            return {
                class: "objectid",
                id: object.id,
                name,
                begin: original.begin,
                end: original.end,
                parentValue: original.parentValue,
            };
        };
        API.getObjects(selected.map(mapToIds), (updatedSelected) => {
            const newSelected = updatedSelected.map(mapToNameAndId);
            if (this._isMounted) {
                this.setState(
                    {
                        selectedValues: newSelected,
                    },
                    callback
                );
            } else {
                setTimeout(() => {
                    if (this._isMounted) {
                        this.setState(
                            {
                                selectedValues: newSelected,
                            },
                            callback
                        );
                    }
                }, SET_STATE_TIMEOUT);
            }
        });
    };

    onSearchChanged = (event) => {
        let search = this.state.searchObject;
        const otherObjects = this.state.selectedValues.map((value) => value.id);
        if (otherObjects) {
            search = search.immutableSet({
                excludeObjects: otherObjects,
                otherObjectsMode: ObjectSettingsConstants.OTHER_OBJECTS.EXCLUDE,
            });
        }
        search = search.setSearchProperty("searchString", event.target.value);
        this.search(
            0,
            0,
            search,
            this.state.selectedValues.map((obj) => obj.id)
        );
    };

    getValue = (objectId) => {
        let result = null;
        this.state.availableValues.forEach((value) => {
            if (value.id === objectId) {
                result = value;
            }
        });
        this.state.selectedValues.forEach((value) => {
            if (value.id === objectId) {
                result = value;
            }
        });
        this.state.filteredValues.forEach((value) => {
            if (value.id === objectId) {
                result = value;
            }
        });
        this.state.searchObject.selectedOfType.forEach((value) => {
            if (value.id === objectId) {
                result = value;
            }
        });
        return result;
    };

    getEventValue = (evt) => {
        let value = evt.target.value;
        let parentValue; // = this.state.searchObject.parentValue;
        if (value && typeof value === "string" && value.indexOf(":") !== -1) {
            value = value.split(":");
            parentValue = parseInt(value[0], 10);
            value = parseInt(value[1], 10);
        } else {
            value = parseInt(value, 10);
        }
        return { value, parentValue };
    };

    searchSelected = (startIndex, sO, selectedObjects) => {
        const searchObject = sO.immutableSet({
            searchObjects: selectedObjects,
            excludeObjects: [],
            otherObjectsMode: ObjectSettingsConstants.OTHER_OBJECTS.INCLUDE,
        });

        searchObject.search(startIndex, (objects, total) => {
            let values, totalNumber;
            if (startIndex === 0) {
                values = [].concat(objects);
                totalNumber = total;
            } else {
                values = [].concat(this.state.filteredValues);
                objects.forEach((object, index) => {
                    values[startIndex + index] = object;
                });
                totalNumber = this.state.totalSelectedObjects;
            }

            const newState = {
                searchObject,
                filteredValues: values,
                totalSelectedObjects: totalNumber,
            };

            this.setState(newState);
        });
    };

    searchAvailable = (startIndex, sO, selectedObjects) => {
        const searchObject = sO.immutableSet({
            searchObjects: [],
            excludeObjects: selectedObjects,
            otherObjectsMode: ObjectSettingsConstants.OTHER_OBJECTS.EXCLUDE,
        });

        searchObject.search(startIndex, (availableObjects, total) => {
            let values, totalNumber;
            if (startIndex === 0) {
                values = [].concat(availableObjects) || [];
                totalNumber = total;
            } else {
                values = [].concat(this.state.values);
                availableObjects.forEach((object, index) => {
                    values[startIndex + index] = object;
                });
                totalNumber = this.state.totalNumberOfObjects;
            }
            const newState = {
                searchObject,
                availableValues: values,
                totalNumberOfObjects: totalNumber,
            };

            this.setState(newState);
        });
    };

    search = (startIndexAvailable, startIndexSelected, searchObject, selectedObjects) => {
        const sO = searchObject.immutableSet({
            searchObjects: selectedObjects,
            excludeObjects: [],
            otherObjectsMode: ObjectSettingsConstants.OTHER_OBJECTS.INCLUDE,
        });

        this._latestLoadIndex = startIndexAvailable;
        sO.search(startIndexSelected, (objs, totalSel) => {
            const objects = sO.parentValue
                ? objs.map((o) => ({ ...o, parentValue: sO.parentValue }))
                : objs;
            let selectedValues, totalSelected;
            if (startIndexSelected === 0) {
                selectedValues = [].concat(objects);
                totalSelected = totalSel;
            } else {
                selectedValues = [].concat(this.state.filteredValues);
                objects.forEach((object, index) => {
                    selectedValues[startIndexSelected + index] = object;
                });
                totalSelected = this.state.totalSelectedObjects;
            }
            const availableSearcher = sO.immutableSet({
                selectedOfType: selectedValues,
                excludeObjects: selectedObjects,
                searchObjects: [],
                otherObjectsMode: ObjectSettingsConstants.OTHER_OBJECTS.EXCLUDE,
            });

            availableSearcher.search(startIndexAvailable, (availableObjects, total) => {
                let availableValues, totalNumber;
                if (startIndexAvailable === 0) {
                    availableValues = [].concat(availableObjects) || [];
                    totalNumber = total;
                } else {
                    availableValues = [].concat(this.state.availableValues);
                    availableObjects.forEach((object, index) => {
                        availableValues[startIndexAvailable + index] = object;
                    });
                    totalNumber = this.state.totalNumberOfObjects;
                }
                const newState = {
                    searchObject: availableSearcher,
                    availableValues,
                    filteredValues: selectedValues,
                    totalNumberOfObjects: totalNumber,
                    totalSelectedObjects: totalSelected,
                };
                this.setState(newState);
            });
        });
    };

    onSelection = (selectedObjects) => {
        this.search(this._latestLoadIndex, 0, this.state.searchObject, selectedObjects); // TODO Search available from first visible index or something, list clears because search goes from 0
    };

    addValue = (index) => {
        const vl = this.state.availableValues[index];
        const self = this;
        this.modifyValue(vl, (selectedValues, so, value) => {
            const newSelectedValues = [].concat(selectedValues);
            const newValue = {
                class: "objectid",
                id: value.id,
                name: value.name,
                parentValue: so.parentValue ? so.parentValue : undefined,
            };
            newSelectedValues.push(newValue);
            if (so.parentValue) {
                self.setState({ selectedValues: newSelectedValues });
            }
            return newSelectedValues;
        });
    };

    removeValue = (index) => {
        const vl = this.state.filteredValues[index];
        if (this.isMoveClick) {
            this.isMoveClick = false;
            this.moveToScratchpad(vl, index);
        } else {
            const self = this;
            this.modifyValue(vl, (selectedValues, so, value) => {
                const valueIds = selectedValues.map((val) => val.id);
                const newSelectedValues = [].concat(selectedValues);
                newSelectedValues.splice(valueIds.indexOf(value.id), 1);
                if (so.parentValue) {
                    self.setState({ selectedValues: newSelectedValues });
                }
                return newSelectedValues;
            });
        }
    };

    modifyValue = (value, modifyFunction) => {
        if (!value) {
            return;
        }
        let selectedValues = [].concat(this.state.selectedValues);
        const so = this.state.searchObject;

        selectedValues = modifyFunction(selectedValues, so, value);

        if (!so.parentValue) {
            this.setState({ selectedValues });
            this.onSelection(selectedValues.map((val) => val.id));
        } else {
            this.onSelection(
                selectedValues
                    .filter((val) => val.parentValue === so.parentValue)
                    .map((val) => val.id)
            );
        }
    };

    optionSelected = (event) => {
        this.onOptionSelected(event);
    };

    onOptionSelected = (evt) => {
        let selectedObjects = this.state.selectedValues.map((value) => value.id);
        let searchObject = this.state.searchObject;
        let pinboardSearch = this.state.pinboardSearch;

        if (evt.target.value === DUMMY_VALUE) {
            return;
        }
        this.setState({ selectedOption: evt.target.value });
        const { value, parentValue } = this.getEventValue(evt);
        if (parentValue) {
            selectedObjects = this.state.selectedValues
                .filter((val) => val.parentValue === parentValue)
                .map((o) => o.id);
            searchObject = searchObject.immutableSet({ parentValue });
        }
        const allColumns = _.clone(this.state.selectedColumns);
        allColumns[searchObject.type] = searchObject.defaultColumns;
        API.setPreferences("multiObjectSelectColumns", [], [JSON.stringify(allColumns)], _.noop);
        API.findCategories(value, (result) => {
            this.setState({ categories: result, selectedColumns: allColumns });
            if (allColumns[value]) {
                searchObject = searchObject.setDefaultColumns(allColumns[value]);
                pinboardSearch = pinboardSearch.setDefaultColumns(allColumns[value]);
            }
            searchObject.setType(value, (newSearchObject) => {
                this.search(0, 0, newSearchObject, selectedObjects);
                pinboardSearch.setType(value, (newPinboardSearch) => {
                    this.setState({ pinboardSearch: newPinboardSearch });
                });
            });
        });
    };

    onSave = (event) => {
        if (this.props.onSave) {
            this.props.onSave(this.state.selectedValues);
        }
        return event.preventDefault();
    };

    onCancel = (event) => {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
        return event.preventDefault();
    };

    onTableColumnsChange = (columns) => {
        const searchObject = this.state.searchObject.setDefaultColumns(columns);
        this.setState({ searchObject }, () => {
            this.search(
                0,
                0,
                searchObject,
                this.state.selectedValues.map((obj) => obj.id)
            );
        });
        const allColumns = _.clone(this.state.selectedColumns);
        allColumns[searchObject.type] = searchObject.defaultColumns;
        API.setPreferences("multiObjectSelectColumns", [], [JSON.stringify(allColumns)], _.noop);
        this.setState({ selectedColumns: allColumns });
    };

    onLeftTableColumnsWidthChange = (columnWidths) => {
        this.onTableColumnsWidthChange(columnWidths, true);
    };

    onRightTableColumnsWidthChange = (columnWidths) => {
        this.onTableColumnsWidthChange(columnWidths, false);
    };

    _columnWidthsCache = {};

    onTableColumnsWidthChange = (columnWidths, isLeftTable) => {
        if (columnWidths.length === 0) {
            return;
        }
        const typeId = this.state.searchObject.type;
        this._columnWidthsCache[typeId] = columnWidths;
        if (isLeftTable) {
            this.setState({ leftColumnWidths: columnWidths });
        } else {
            this.setState({ rightColumnWidths: columnWidths });
        }
    };

    getTypeName = (typeId, types, rootType) => {
        if (typeId === 0 || typeId === rootType.id) {
            return rootType.name;
        }
        return _.find(types, (type) => type.id === typeId).name;
    };

    getCategoryValue = (category) => {
        if (this.state.categoryValues && this.state.categoryValues[category.id]) {
            return this.state.categoryValues[category.id][0] || "";
        }
        return "";
    };

    setCategoryValue = (category, value) => {
        const categoryValues = _.clone(this.state.categoryValues);
        if (value === "") {
            categoryValues[category.id] = [];
        } else {
            categoryValues[category.id] = [value];
        }
        this.setState({ categoryValues });
    };

    isRowLoaded = (isAvailable, index) => {
        if (isAvailable) {
            return this.state.availableValues[index] !== undefined;
        }
        return this.state.filteredValues[index] !== undefined;
    };

    _latestLoadIndex = 0;

    // eslint-disable-next-line no-unused-vars
    loadMoreRows = (isAvailable, { startIndex, stopIndex }) => {
        if (!isAvailable) {
            this.searchSelected(
                startIndex,
                this.state.searchObject,
                this.state.searchObject.selectedOfType
                    ? this.state.searchObject.selectedOfType.map((obj) => obj.id)
                    : this.state.selectedValues.map((obj) => obj.id)
            );
            return;
        }
        this._latestLoadIndex = startIndex;
        this.searchAvailable(
            startIndex,
            this.state.searchObject,
            this.state.searchObject.selectedOfType
                ? this.state.searchObject.selectedOfType.map((obj) => obj.id)
                : this.state.selectedValues.map((obj) => obj.id)
        );
    };

    presentMembershipPeriod(object) {
        const timedObject = _.find(this.state.selectedValues, (obj) => obj.id === object.id);
        if (timedObject) {
            const begin =
                timedObject.begin && timedObject.begin.mts > 0
                    ? new MillenniumDateTime(timedObject.begin)
                    : null;
            const end =
                timedObject.end && timedObject.end.mts > 0
                    ? new MillenniumDateTime(timedObject.end)
                    : null;
            const format = (time, isEnd = false) => {
                if (!isEnd) {
                    const formatString = Language.getDateFormat("date_f_yy_mm_dd");
                    return SimpleDateFormat.format(time, formatString);
                }
                const formatString = Language.getDateFormat("date_f_yy_mm_dd_end");
                return SimpleDateFormat.format(time, formatString);
            };
            if (!begin && !end) {
                return null;
            }
            if (begin && !end) {
                return `${format(begin)} - `;
            }
            if (!begin && end) {
                return ` - ${format(end, true)}`;
            }

            return `${format(begin)} - ${format(end, true)}`;
        }
        return null;
    }

    getTableRow = (isAvailable, { index }) => {
        const object = isAvailable
            ? this.state.availableValues[index]
            : this.state.filteredValues[index];
        const result = {};
        this.state.searchObject.defaultColumns.forEach((column) => {
            if (!object) {
                result[column.name] = "-";
            } else {
                const values = _.find(object.fields, (field) => field.id === column.id);
                if (!values || column.id === "_admin_color_") {
                    if (column.id === "type") {
                        result[column.name] = this.getTypeName(
                            object.typeId,
                            this.props.options,
                            TimeEdit.rootType
                        );
                    } else if (column.id === "_admin_color_") {
                        result[column.name] = object.color || "-";
                    } else if (
                        column.id === getMembershipColumn().id &&
                        this.props.hasPeriodObjects
                    ) {
                        result[column.name] = this.presentMembershipPeriod(object);
                    } else if (column.id === "placeholder") {
                        let value = object.fields[0].values.join(", ");
                        if (!value) {
                            value = object.name || object.extid || object.id;
                        }
                        result[column.name] = value;
                    } else {
                        result[column.name] = "";
                    }
                } else {
                    result[column.name] = values.values ? values.values.join(", ") : "";
                }
            }
        });
        return result;
    };

    getPropertySectionName(key) {
        if (key === "reserve") {
            return Language.get("dynamic_object_list_status");
        }
        return Language.get(`dynamic_object_list_${key}_item`);
    }

    onPropertyChanged(propertyKey, value) {
        let search = this.state.searchObject;
        const advancedSettings = _.clone(search.advancedSettings);
        advancedSettings[propertyKey] = value;
        search = search.setSearchProperty("advancedSettings", advancedSettings);
        this.setState({ searchObject: search });
    }

    removeMembershipPeriod(object) {
        const timedObject = _.find(this.state.selectedValues, (member) => member.id === object.id);
        timedObject.begin = undefined;
        timedObject.end = undefined;
        const selectedValues = []
            .concat(
                this.state.selectedValues.filter(
                    (mbr) => mbr.id !== object.id || (mbr.object && mbr.object.id !== object.id)
                )
            )
            .concat(timedObject);
        this.setState({ selectedValues });
    }

    editMembership(object) {
        const onChange = (selectedRange) => {
            this._selectedRange = selectedRange;
        };
        const onDone = () => {
            const selectedRange = this._selectedRange;
            let begin = null;
            if (selectedRange.startDate) {
                begin = MillenniumDateTime.fromDate(selectedRange.startDate);
            }
            let end = null;
            if (selectedRange.endDate) {
                end = MillenniumDateTime.fromDate(selectedRange.endDate.addDays(1));
            }
            const periodItem = _.clone(object);
            periodItem.begin = begin;
            periodItem.end = end;
            const selectedValues = []
                .concat(
                    this.state.selectedValues.filter(
                        (mbr) => mbr.id !== object.id || (mbr.object && mbr.object.id !== object.id)
                    )
                )
                .concat(periodItem);
            this.setState({ selectedValues });
        };
        const timedObject = _.find(this.state.selectedValues, (member) => member.id === object.id);
        const buttons = [
            {
                title: Language.get("dialog_cancel"),
            },
            {
                title: Language.get("nc_dialog_done"),
                cb: onDone,
            },
        ];
        const content = (
            <DateRange
                startDate={
                    timedObject && timedObject.begin ? timedObject.begin.getMillenniumDate() : null
                }
                endDate={
                    timedObject && timedObject.end
                        ? timedObject.end.getMillenniumDate().addDays(-1)
                        : null
                }
                onChange={onChange}
            />
        );
        this.context.presentModal(content, null, Language.get("nc_member_period"), buttons);
    }

    getRowMenuItems(scratchpadActive, selectedIndexes) {
        const result: {
            label: string;
            action: () => void;
        }[] = [];
        const item = this.state.filteredValues[selectedIndexes[0]];
        if (this.props.hasPeriodObjects) {
            result.push({
                label: Language.get("nc_edit_membership_period"),
                action: () => {
                    this.editMembership(item);
                },
            });
            result.push({
                label: Language.get("nc_delete_membership_period"),
                action: () => {
                    this.removeMembershipPeriod(item);
                },
            });
        }
        if (scratchpadActive) {
            result.push({
                label: Language.get("nc_move_to_pinboard"),
                action: () => {
                    this.moveToScratchpad(item, selectedIndexes[0]);
                },
            });
        }
        return result;
    }

    moveToScratchpad(item, index) {
        const sourceType = SourceType.Membership;
        const pinboardItem = new ScratchpadObject(
            item.id,
            this.props.parentId || 0,
            sourceType,
            0,
            item.name,
            this.state.searchObject.type
        );
        this.context.scratchpad.addObject(pinboardItem);
        if (this.props.onPinboardItemAdded) {
            this.props.onPinboardItemAdded(pinboardItem);
        }
        this.setState({ scratchpadObjects: this.context.scratchpad.getObjects() }, () => {
            this.removeValue(index);
        });
    }

    getOptions(optionsFromProps) {
        const options = optionsFromProps.map((item) => {
            if (item.options) {
                return (
                    <optgroup key={item.name} label={item.name}>
                        {item.options.map((subOption) => (
                            <option key={subOption.id} value={`${item.id}:${subOption.id}`}>
                                {subOption.name || subOption.extid}
                            </option>
                        ))}
                    </optgroup>
                );
            }
            return (
                <option key={item.id} value={item.id}>
                    {item.name || item.extid}
                </option>
            );
        }, this);
        if (optionsFromProps.headlineValue) {
            options.splice(
                0,
                0,
                <option key={-1} value={DUMMY_VALUE}>
                    {optionsFromProps.headlineValue}
                </option>
            );
        }
        return options;
    }

    render() {
        const scratchpadActive = this.props.isPinboardEnabled || false;

        let optionDropdown = null;
        if (this.props.options && this.props.options.length > 0) {
            const options = this.getOptions(this.props.options);

            optionDropdown = (
                <select
                    className="definitionList"
                    value={this.state.selectedOption}
                    onChange={this.optionSelected}
                >
                    <option key={Language.get("nc_option_select")} value={DUMMY_VALUE}>
                        {Language.get("nc_option_select")}
                    </option>
                    {options}
                </select>
            );
        }
        let categories = null;
        if (this.state.categories) {
            categories = this.state.categories.map((category) => {
                // eslint-disable-next-line no-param-reassign
                category.editable = false;
                // eslint-disable-next-line no-param-reassign
                category.mandatory = false;
                return (
                    <div key={category.id}>
                        {category.name}
                        <br />
                        <FieldInput
                            value={this.getCategoryValue(category)}
                            editable={true}
                            field={category}
                            definition={category}
                            onChange={this.setCategoryValue.bind(this, category)}
                        />
                    </div>
                );
            });
        }
        const propSettings = this.state.searchObject.advancedSettings;
        const allProps = ObjectSelectSettings.getProperties();
        const properties = Object.keys(allProps).map((key) => {
            const options = allProps[key].options;
            options.forEach((option) => {
                if (propSettings[key] !== undefined) {
                    // eslint-disable-next-line no-param-reassign
                    option.selected = option.value === propSettings[key];
                } else {
                    // eslint-disable-next-line no-param-reassign
                    option.selected = option.value === option.isDefault;
                }
            });
            return (
                <div key={key}>
                    {this.getPropertySectionName(key)}
                    <br />
                    <ArrayInput
                        size={1}
                        limit={1}
                        onUpdate={this.onPropertyChanged.bind(this, key)}
                        defaultValue={options}
                    />
                </div>
            );
        });

        const allColumns = this.state.searchObject.columns.concat(getMembershipColumn());
        const allColIds = allColumns.map((column) => column.id);
        const columns = (this.state.searchObject.defaultColumns || []).filter(
            (column) => allColIds.indexOf(column.id) !== -1
        );

        const removeFromScratchpadButton = scratchpadActive ? (
            <button
                className="unpinButton"
                onClick={() => {
                    this.isRemoveClick = true;
                }}
                title={Language.get("nc_remove_from_pinboard")}
            />
        ) : null;

        const scratchpad = scratchpadActive ? (
            <ScratchpadList
                objects={this.state.scratchpadObjects}
                searchObject={this.state.pinboardSearch}
                updateSearchObject={(property, newSearch) => {
                    this.setState({ pinboardSearch: newSearch });
                }}
                onObjectSelected={(object) => {
                    const vl = this.context.scratchpad
                        .getObjects()
                        .find((obj) => obj.objectId === object.id);
                    const removedItems = this.context.scratchpad.removeObjects([object.id]);
                    if (this.props.onPinboardItemRemoved) {
                        this.props.onPinboardItemRemoved(removedItems[0]);
                    }
                    if (this.isRemoveClick) {
                        this.isRemoveClick = false;
                        this.setState({
                            scratchpadObjects: this.context.scratchpad.getObjects(),
                        });
                        return;
                    }
                    const self = this;
                    this.modifyValue(vl, (selectedValues, so, value) => {
                        const newSelectedValues = [].concat(selectedValues);
                        const newValue = {
                            class: "objectid",
                            id: value.objectId,
                            name: value.label,
                            parentValue: so.parentValue ? so.parentValue : undefined,
                        };
                        newSelectedValues.push(newValue);
                        if (so.parentValue) {
                            self.setState({
                                selectedValues: newSelectedValues,
                                scratchpadObjects: this.context.scratchpad.getObjects(),
                            });
                        } else {
                            self.setState({
                                scratchpadObjects: this.context.scratchpad.getObjects(),
                            });
                        }
                        return newSelectedValues;
                    });
                }}
                hoverButton={removeFromScratchpadButton}
            />
        ) : null;

        const moveToScratchpadButton = scratchpadActive ? (
            <button
                className="pinboardButton"
                onClick={() => {
                    this.isMoveClick = true;
                }}
                title={Language.get("nc_move_to_pinboard")}
            />
        ) : null;

        return (
            <div className="multiObjectSelect objectDefinitionPane">
                <div style={{ display: "flex", height: "600px" }}>
                    <div className="sidePanel">
                        <h2>{Language.get("dynamic_object_list_search")}</h2>
                        {optionDropdown}
                        <br />
                        <input className="" type="text" onChange={this.onSearchChanged} />
                        <h2>{Language.get("nc_object_select_settings_object_categories")}</h2>
                        {categories}
                        <h2>{Language.get("nc_object_select_settings_properties")}</h2>
                        {properties}
                    </div>
                    <div style={{ display: "flex", flexGrow: "1", flexDirection: "column" }}>
                        <h3>{Language.get("cal_res_side_reservation_objects")}</h3>
                        <Table
                            language={Language}
                            log={Log}
                            columns={columns}
                            allColumns={allColumns}
                            isRowLoaded={this.isRowLoaded.bind(this, true)}
                            loadMoreRows={this.loadMoreRows.bind(this, true)}
                            presentModal={this.context.presentModal}
                            contextMenu={ContextMenu}
                            rowCount={this.state.totalNumberOfObjects}
                            onSelect={this.addValue}
                            getTableRow={this.getTableRow.bind(this, true)}
                            columnWidths={this.state.leftColumnWidths || []}
                            onColumnChange={this.onTableColumnsChange}
                            onColumnWidthChange={this.onLeftTableColumnsWidthChange}
                        />
                        {scratchpad ? <h3>{Language.get("nc_pinboard")}</h3> : null}
                        {scratchpad}
                    </div>
                    <div style={{ display: "flex", flexGrow: "1", flexDirection: "column" }}>
                        <h3>{Language.get("nc_object_select_settings_headline_selected")}</h3>
                        <Table
                            language={Language}
                            log={Log}
                            columns={columns}
                            allColumns={allColumns}
                            isRowLoaded={this.isRowLoaded.bind(this, false)}
                            loadMoreRows={this.loadMoreRows.bind(this, false)}
                            presentModal={this.context.presentModal}
                            contextMenu={ContextMenu}
                            rowCount={this.state.totalSelectedObjects}
                            onSelect={this.removeValue}
                            getTableRow={this.getTableRow.bind(this, false)}
                            hoverButton={moveToScratchpadButton}
                            getRowMenuItems={this.getRowMenuItems.bind(this, scratchpadActive)}
                            columnWidths={this.state.rightColumnWidths || []}
                            onColumnChange={this.onTableColumnsChange}
                            onColumnWidthChange={this.onRightTableColumnsWidthChange}
                        />
                    </div>
                </div>
                <form className="bottomButtons btnGroup horizontal" onSubmit={this.onSave}>
                    <button className="cancel" onClick={this.onCancel}>
                        {Language.get("dialog_cancel")}
                    </button>{" "}
                    <button type="submit" className="save">
                        {Language.get("nc_dialog_done")}
                    </button>
                </form>
            </div>
        );
    }
}

export default MultiObjectSelect;
