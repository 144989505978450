const EXAM_MODE = {
    CREATE: "create",
    CREATE_MULTI: "create_multi",
    EDIT: "edit",
    MOVE: "move",
};

const EXAM_NOT_AUTHORIZED = 401;
const EXAM_NO_ORG_ACCESS = 403;
const EXAM_NOT_FOUND = 404;

export default { EXAM_MODE, EXAM_NOT_AUTHORIZED, EXAM_NO_ORG_ACCESS, EXAM_NOT_FOUND };
