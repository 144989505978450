import { Model } from "./Model";
import _ from "underscore";

export class Macros extends Model {
    items: any[];

    constructor() {
        super("Macros");
        this.items = [];
    }

    static DataType = {
        DATE: 0,
        TIME: 1,
        DATETIME: 2,
        OBJECT: 3,
        RESERVATION: 4,
        ORDER: 5,
        TYPE: 6,
        REQUEST: 7,
        GROUP_IDENTIFIER: 8,
    };
    static Event = {
        SET_DATE: "SET_DATE",
        SCROLL_DATE: "SCROLL_DATE",
        SCROLL_TIME: "SCROLL_TIME",
        SELECT_RESERVATION: "SELECT_RESERVATION",
        SELECT_ORDER: "SELECT_ORDER",
        SELECT_REQUEST: "SELECT_REQUEST",
        RESERVATION_MADE_OR_MODIFIED: "RESERVATION_MADE_OR_MODIFIED",
        RESERVATION_RESTORED: "RESERVATION_RESTORED",
        SELECTED_TYPE_CHANGED: "SELECTED_TYPE_CHANGED",
        PRIMARY_FIELD_CHANGED: "PRIMARY_FIELD_CHANGED",
        CHANGE_RESERVATION_OBJECTS: "CHANGE_RESERVATION_OBJECTS",
        CHANGE_RESERVATION_TIME: "CHANGE_RESERVATION_TIME",
        PROVIDER_UPDATE: "PROVIDER_UPDATE",
        SELECT_EXTERNAL_OBJECT: "SELECT_EXTERNAL_OBJECT",
        SELECT_EXTERNAL_TYPE: "SELECT_EXTERNAL_TYPE",
        SET_EXTERNAL_OBJECT_SEARCH_CRITERIA: "SET_EXTERNAL_OBJECT_SEARCH_CRITERIA",
        OBJECT_MODIFIED: "OBJECT_MODIFIED",
        SET_EXTERNAL_SELECTION: "SET_EXTERNAL_SELECTION",
        REQUEST_OPERATION: "REQUEST_OPERATION",
        SET_EXTERNAL_TEMPLATE_GROUP: "SET_EXTERNAL_TEMPLATE_GROUP",
        SET_EXTERNAL_TEMPLATE_KIND: "SET_EXTERNAL_TEMPLATE_KIND",
        SET_EXTERNAL_DATE: "SET_EXTERNAL_DATE",
        EXAM_REQUEST_UPDATE: "EXAM_REQUEST_UPDATE",
        CLEAR_SELECTION: "CLEAR_SELECTION",
        AM_CLOSED: "AM_CLOSED",
        LIST_RESERVATION_GROUP: "LIST_RESERVATION_GROUP",
        SHOW_SEARCH_SETTINGS: "SHOW_SEARCH_SETTINGS",
        EXTERNAL_SELECTION_SET: "EXTERNAL_SELECTION_SET",
        EXTERNAL_SCHEDULING_CANCELLED: "EXTERNAL_SCHEDULING_CANCELLED",
    };
    static Action = {
        SET_DATE: "SET_DATE",
        SCROLL_DATE: "SCROLL_DATE",
        SET_TIME: "SET_TIME",
        SCROLL_TIME: "SCROLL_TIME",
        SET_RESERVATION: "SET_RESERVATION",
        SET_ORDER: "SET_ORDER",
        REFRESH: "REFRESH",
        SET_OBJECT: "SET_OBJECT",
        SELECTED_TYPE_CHANGED: "SELECTED_TYPE_CHANGED",
        CHANGE_RESERVATION_OBJECTS: "CHANGE_RESERVATION_OBJECTS",
        CHANGE_RESERVATION_TIME: "CHANGE_RESERVATION_TIME",
        UPDATE_PROVIDER: "UPDATE_PROVIDER",
        SELECT_EXTERNAL_OBJECT: "SELECT_EXTERNAL_OBJECT",
        SELECT_EXTERNAL_TYPE: "SELECT_EXTERNAL_TYPE",
        SET_EXTERNAL_OBJECT_SEARCH_CRITERIA: "SET_EXTERNAL_OBJECT_SEARCH_CRITERIA",
        OBJECT_MODIFIED: "OBJECT_MODIFIED",
        SET_EXTERNAL_SELECTION: "SET_EXTERNAL_SELECTION",
        REQUEST_OPERATION: "REQUEST_OPERATION",
        SET_EXTERNAL_TEMPLATE_GROUP: "SET_EXTERNAL_TEMPLATE_GROUP",
        SET_EXTERNAL_TEMPLATE_KIND: "SET_EXTERNAL_TEMPLATE_KIND",
        SET_EXTERNAL_DATE: "SET_EXTERNAL_DATE",
        UPDATE_EXAM_REQUEST: "UPDATE_EXAM_REQUEST",
        CLEAR_SELECTION: "CLEAR_SELECTION",
        AM_CLOSED: "AM_CLOSED",
        LIST_RESERVATION_GROUP: "LIST_RESERVATION_GROUP",
        SHOW_SEARCH_SETTINGS: "SHOW_SEARCH_SETTINGS",
        SHOW_EXTERNAL_ENTRY: "SHOW_EXTERNAL_ENTRY",
        EXTERNAL_SCHEDULING_CANCELLED: "EXTERNAL_SCHEDULING_CANCELLED",
    };

    register(id, data) {
        const items = this.items.concat(_.extend({}, data, { id }));
        // eslint-disable-next-line no-console
        //console.log("register", items.length);
        return this.immutableSet({ items });
    }

    deregister(id) {
        const items = this.items.filter((item) => item.id !== id);
        // eslint-disable-next-line no-console
        //console.log("deregister", items.length);
        return this.immutableSet({ items });
    }

    getMatchingActions(source, event) {
        const hasMatchingDataTypes = (eventType, actionType) =>
            !actionDataTypes[actionType] ||
            eventDataTypes[eventType] === actionDataTypes[actionType];
        const actions = _.flatten(
            this.items
                .filter((item) => item.id !== source && _.contains(_.asArray(item.events), event))
                .map((item) => item.actions)
        );
        actions.sort((a, b) => {
            if (a.fireLast && !b.fireLast) {
                return 1;
            }
            if (!a.fireLast && b.fireLast) {
                return -1;
            }
            return 0;
        });
        return actions
            .filter((item) => hasMatchingDataTypes(event, item.key))
            .map((item) => item.action);
    }
}

const eventDataTypes = {
    SCROLL_DATE: Macros.DataType.DATE,
    SET_DATE: Macros.DataType.DATE,
    SCROLL_TIME: Macros.DataType.TIME,
    SELECT_RESERVATION: Macros.DataType.RESERVATION,
    SELECT_REQUEST: Macros.DataType.REQUEST,
    RESERVATION_RESTORED: Macros.DataType.RESERVATION,
    SELECT_ORDER: Macros.DataType.ORDER,
    PRIMARY_FIELD_CHANGED: Macros.DataType.TYPE,
    CHANGE_RESERVATION_OBJECTS: Macros.DataType.RESERVATION,
    CHANGE_RESERVATION_TIME: Macros.DataType.RESERVATION,
    SELECT_EXTERNAL_OBJECT: Macros.DataType.OBJECT,
    SELECT_EXTERNAL_TYPE: Macros.DataType.TYPE,
    SET_EXTERNAL_OBJECT_SEARCH_CRITERIA: Macros.DataType.OBJECT,
    OBJECT_MODIFIED: Macros.DataType.OBJECT,
    SET_EXTERNAL_SELECTION: Macros.DataType.OBJECT,
    REQUEST_OPERATION: Macros.DataType.OBJECT,
    LIST_RESERVATION_GROUP: Macros.DataType.GROUP_IDENTIFIER,
};

const actionDataTypes = {
    SET_DATE: Macros.DataType.DATE,
    SCROLL_DATE: Macros.DataType.DATE,
    SET_TIME: Macros.DataType.TIME,
    SCROLL_TIME: Macros.DataType.TIME,
    SET_RESERVATION: Macros.DataType.RESERVATION,
    SET_REQUEST: Macros.DataType.REQUEST,
    SET_ORDER: Macros.DataType.ORDER,
    SET_OBJECT: Macros.DataType.OBJECT,
    CHANGE_RESERVATION_OBJECTS: Macros.DataType.RESERVATION,
    CHANGE_RESERVATION_TIME: Macros.DataType.RESERVATION,
    SELECT_EXTERNAL_OBJECT: Macros.DataType.OBJECT,
    SELECT_EXTERNAL_TYPE: Macros.DataType.TYPE,
    SET_EXTERNAL_OBJECT_SEARCH_CRITERIA: Macros.DataType.OBJECT,
    OBJECT_MODIFIED: Macros.DataType.OBJECT,
    SET_EXTERNAL_SELECTION: Macros.DataType.OBJECT,
    REQUEST_OPERATION: Macros.DataType.OBJECT,
    LIST_RESERVATION_GROUP: Macros.DataType.GROUP_IDENTIFIER,
};
