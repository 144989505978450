import API from "../lib/TimeEditAPI";
import React from "react";
import Language from "../lib/Language";
import FieldTableRow from "./FieldTableRow";
import ColorCell from "./ColorCell";
import { Macros } from "../models/Macros";
import _ from "underscore";
import PropTypes from "prop-types";
import { TFieldDefClass, TTypeNameClass } from "../types/api/APIClassTypes";
import { TObject } from "../types/api/shared";

const caseInsensitiveSort = (a, b) => {
    const aLower = a.toLowerCase();
    const bLower = b.toLowerCase();
    if (aLower < bLower) {
        return -1;
    }
    if (aLower > bLower) {
        return 1;
    }
    return 0;
};

type TObjectInfoState = {
    types?: TTypeNameClass[];
    objects: TObject[];
    fieldDefs: TFieldDefClass[];
};

class ObjectInfo extends React.Component<{}, TObjectInfoState> {
    static displayName = "ObjectInfo";

    static contextTypes = {
        colorDefs: PropTypes.array,
        registerMacro: PropTypes.func,
        deregisterMacro: PropTypes.func,
    };

    state = {
        objects: [],
        fields: [],
        fieldDefs: [],
        ignoreFields: [],
        canEditObject: false,
        namedObjects: [],
    };
    private _isMounted?: boolean;

    componentDidMount() {
        this._isMounted = true;
        API.findFields((fields) => {
            API.getOrganizations({}, (orgs) => {
                this.setState({ organizations: orgs.parameters[0], fields });
            });
        });

        this.updateObject(this.props);
        this.loadIgnoreFields(this.props.typeId);
        this.registerMacros();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.objectId !== this.props.objectId) {
            this.updateObject(this.props);
        }
        if (prevProps.typeId !== this.props.typeId) {
            this.loadIgnoreFields(this.props.typeId);
        }
    }

    componentWillUnmount() {
        this.context.deregisterMacro("objectInfo");
        this._isMounted = false;
    }

    registerMacros = () => {
        this.context.registerMacro("objectInfo", {
            events: [Macros.Event.OBJECT_MODIFIED],
            actions: [
                {
                    key: Macros.Action.OBJECT_MODIFIED,
                    action: (objects) => {
                        if (objects.map((o) => o.id).indexOf(this.props.objectId) !== -1) {
                            this.updateObject(this.props);
                        }
                    },
                },
            ],
        });
    };

    handleResetIgnoreFields = () => {
        this.saveIgnoreFiels(this.props.typeId, []);
        this.setState({ ignoreFields: [] });
    };

    getFieldDef = (fieldId) => {
        return _.find(this.state.fieldDefs, (def) => def.id === fieldId) || null;
    };

    loadIgnoreFields = (typeId) => {
        if (
            typeId === null ||
            typeId === undefined ||
            typeId === 0 ||
            this.props.noIgnore === true
        ) {
            return;
        }
        API.getPreferences(
            "ignoreFields",
            [{ class: "typeid", id: typeId }],
            undefined,
            (result) => {
                if (result) {
                    const loadedIgnores = JSON.parse(result[0]);
                    this.setState({ ignoreFields: loadedIgnores });
                }
            }
        );
    };

    saveIgnoreFiels = (typeId, ignoreFields) => {
        if (typeId === 0) {
            return;
        }
        API.setPreferences(
            "ignoreFields",
            [{ class: "typeid", id: typeId }],
            [JSON.stringify(ignoreFields)],
            _.noop
        );
    };

    updateObject = (props) => {
        if (!props.objectId) {
            this.setState({ objects: [], fieldDefs: [] });
            return;
        }

        API.exportObjects([props.objectId], (objects) => {
            if (objects.length === 0) {
                throw new Error(
                    Language.get("nc_error_no_object_found_with_id", this.props.objectId)
                );
            }
            const toName = [];
            ["members", "related", "optional_related", "availability"].forEach((prop) => {
                if (!objects[0][prop]) {
                    return;
                }
                objects[0][prop].forEach((object) => {
                    if (_.isArray(object)) {
                        object.forEach((obj) => toName.push(obj.id));
                    } else {
                        if (object.object) {
                            toName.push(object.object.id);
                        } else if (object.objects) {
                            object.objects.forEach((ob) => {
                                toName.push(ob.id);
                            });
                        } else {
                            toName.push(object.id);
                        }
                    }
                });
            });
            API.getObjectNames(toName, true, (nameResult) => {
                const namedObjects = nameResult.map((namedObject) => ({
                    id: namedObject.id,
                    name: namedObject.fields
                        .map((field) => (field.values ? field.values.join(", ") : ""))
                        .join(", "),
                }));
                API.getFieldDefs(
                    objects[0].fields.map((field) => field.id),
                    undefined,
                    (fieldDefs) => {
                        API.okToModifyObjects(props.objectId, (okToModify) => {
                            API.findTypes((types) => {
                                if (this._isMounted) {
                                    this.setState({
                                        objects,
                                        fieldDefs,
                                        namedObjects,
                                        canEditObject: okToModify[0],
                                        types,
                                    });
                                }
                            });
                        });
                    }
                );
            });
        });
    };

    ignoreField = (fieldId) => {
        const toIgnore = this.state.ignoreFields;
        toIgnore.push(fieldId);
        this.saveIgnoreFiels(this.props.typeId, toIgnore);
        this.setState({ ignoreFields: toIgnore });
    };

    getObjectName = (objectId) => {
        // Returns null if object was not found, which should mean it's inactive
        const found = _.find(this.state.namedObjects, (namedObject) => namedObject.id === objectId);
        if (found && found.name !== "") {
            return found.name;
        }
        return null;
    };

    getOrgName = (inOrg, orgs) => {
        const found = _.find(orgs, (org) => org.id === inOrg.id);
        if (found) {
            return found.extid;
        }
        return inOrg.extid;
    };

    getTypeName = (typeId, allTypes) => {
        const item = _.find(allTypes, (itm) => itm.id === typeId);
        if (item && this.props.user.showExtraInfo) {
            return `${item.name} (${typeId})`;
        }
        return item ? item.name : String(typeId);
    };

    presentTypes = (types, allTypes) => {
        return types.map((tp) => this.getTypeName(tp, allTypes)).join(", ");
    };

    render() {
        if (_.isNullish(this.props.objectId) || this.props.objectId < 1) {
            return null;
        }
        const list = (title, values) => (
            <tbody>
                <tr>
                    <th colSpan="2">{title}</th>
                </tr>
                <tr>
                    <td colSpan="2">
                        <select size="5">
                            {values.map((value, index) => (
                                <option key={`${index}:${value}`}>{value}</option>
                            ))}
                        </select>
                    </td>
                </tr>
            </tbody>
        );
        let objectInfo = [];
        let fieldInfo;
        let memberInfo;
        let relatedInfo;
        let optionalRelatedInfo;
        let availabilityInfo;
        let colorInfo;
        let orgInfo;
        let editButton;
        if (this.state.objects) {
            objectInfo = this.state.objects.map(function (object) {
                colorInfo = null;
                if (object.color) {
                    const colorDef = _.find(
                        this.context.colorDefs,
                        (def) => def.id === object.color
                    );
                    if (colorDef) {
                        colorInfo = (
                            <tbody>
                                <tr>
                                    <th className="fieldLabel">{Language.get("admin_color")}</th>
                                    <td>
                                        <ColorCell
                                            color={colorDef.baseColor}
                                            pattern={colorDef.basePattern}
                                        />
                                    </td>
                                    <td />
                                </tr>
                            </tbody>
                        );
                    }
                }
                memberInfo = null;
                relatedInfo = null;
                optionalRelatedInfo = null;
                availabilityInfo = null;
                orgInfo = null;
                if (this.props.embedded === true) {
                    if (object.members) {
                        memberInfo = list(
                            Language.get("dynamic_object_info_members"),
                            object.members
                                .map((member) =>
                                    this.getObjectName(member.object ? member.object.id : member.id)
                                )
                                .filter((obj) => obj !== null)
                                .sort(caseInsensitiveSort)
                        );
                    }
                    if (object.related) {
                        relatedInfo = list(
                            Language.get("dynamic_object_info_relations"),
                            object.related
                                .map((related) => {
                                    if (related instanceof Array) {
                                        return related
                                            .map((obj) => this.getObjectName(obj.id))
                                            .filter((obj) => obj !== null)
                                            .join(", ");
                                    }
                                    if (related instanceof Object) {
                                        return related.objects
                                            .map((obj) => this.getObjectName(obj.id))
                                            .filter((obj) => obj !== null)
                                            .join(", ");
                                    }
                                    return this.getObjectName(related.id);
                                })
                                .filter((obj) => obj !== null && obj !== "")
                                .sort(caseInsensitiveSort)
                        );
                    }
                    if (object.availability) {
                        availabilityInfo = list(
                            Language.get("dynamic_object_info_availrel_title"),
                            object.availability
                                .map((member) => this.getObjectName(member.id))
                                .filter((obj) => obj !== null)
                                .sort(caseInsensitiveSort)
                        );
                    }
                    if (object.optional_related) {
                        optionalRelatedInfo = list(
                            Language.get("dynamic_object_info_optrel_title"),
                            object.optional_related
                                .map((related) => this.getObjectName(related.id))
                                .filter((obj) => obj !== null)
                                .sort(caseInsensitiveSort)
                        );
                    }
                    if (object.orgs) {
                        orgInfo = list(
                            Language.get("dynamic_object_info_orgs"),
                            object.orgs.map((org) => this.getOrgName(org, this.state.organizations))
                        );
                    }
                }
                fieldInfo = object.fields.map(function (field) {
                    if (this.state.ignoreFields.indexOf(field.id) !== -1) {
                        return null;
                    }
                    if (!field.values || field.values.length === 0) {
                        return null;
                    }
                    const definition = this.getFieldDef(field.id);
                    if (definition === null) {
                        return (
                            <tr key={`${object.id}.${field.id}`}>
                                <th>{field.id}</th>
                                <td colSpan="2">{field.values.join(", ")}</td>
                            </tr>
                        );
                    }
                    definition.field = field;
                    return (
                        <FieldTableRow
                            key={`${object.id}.${field.id}`}
                            editable={false}
                            field={field}
                            definition={definition}
                            deleteButton={
                                this.props.noIgnore ? null : this.ignoreField.bind(this, field.id)
                            }
                        />
                    );
                }, this);

                const activeInfo = this.props.user.showExtraInfo ? (
                    <tbody>
                        <tr key={`${object.id}.active`}>
                            <th>{Language.get("dynamic_object_info_is_active")}</th>
                            <td colSpan="2">
                                {object.active
                                    ? Language.get("dynamic_object_list_yes")
                                    : Language.get("dynamic_object_list_no")}
                            </td>
                        </tr>
                    </tbody>
                ) : null;

                const typeInfo = this.props.user.showExtraInfo ? (
                    <tbody>
                        <tr key={`${object.id}.types`}>
                            <th>{Language.get("dynamic_object_info_type")}</th>
                            <td colSpan="2">{this.presentTypes(object.types, this.state.types)}</td>
                        </tr>
                    </tbody>
                ) : null;

                if (this.props.onObjectInfo) {
                    const label = Language.get("cal_selected_get_object_info");
                    editButton = (
                        <div className="btnGroup horizontal">
                            <button
                                className="ok"
                                onClick={() => {
                                    this.props.onObjectInfo(
                                        object.id,
                                        false,
                                        false,
                                        `${label} ${object.extid}`
                                    );
                                }}
                            >
                                {label}
                            </button>
                        </div>
                    );
                }

                const idInfo = this.props.user.showExtraInfo ? (
                    <tbody>
                        <tr key={`${object.id}.id`}>
                            <th>{Language.get("dynamic_object_info_id")}</th>
                            <td colSpan="2">{object.id}</td>
                        </tr>
                        <tr key={`${object.id}.extid`}>
                            <th>{Language.get("dynamic_object_info_ext_id")}</th>
                            <td colSpan="2">{object.extid}</td>
                        </tr>
                    </tbody>
                ) : null;

                return (
                    <div key={object.id}>
                        <table>
                            {idInfo}
                            {colorInfo}
                            {fieldInfo}
                            {activeInfo}
                            {typeInfo}
                            {memberInfo}
                            {relatedInfo}
                            {optionalRelatedInfo}
                            {availabilityInfo}
                            {orgInfo}
                        </table>
                        {editButton}
                    </div>
                );
            }, this);
        }

        let showAllFieldsButton = null;
        if (this.state.ignoreFields.length > 0) {
            showAllFieldsButton = (
                <button className="default showAllFields" onClick={this.handleResetIgnoreFields}>
                    {Language.get("nc_object_info_button_show_all_fields")}
                </button>
            );
        }

        const isEmbedded = this.props.embedded;

        if (objectInfo.length === 0 && !isEmbedded) {
            objectInfo = (
                <div className="objectInfoHelp">{Language.get("nc_object_info_help_text.")}</div>
            );
        }

        const closeButton = isEmbedded ? (
            <div className="dismiss" onClick={this.props.onClose} />
        ) : (
            <div className="closeButton" onClick={this.props.onClose} />
        );

        let headline = null;
        if (isEmbedded) {
            headline = <h2>{Language.get("cal_res_side_tab_object_info")}</h2>;
        }

        return (
            <div>
                {closeButton}
                {headline}
                {objectInfo}
                {showAllFieldsButton}
            </div>
        );
    }
}

export default ObjectInfo;
