import API from "../lib/TimeEditAPI";
import { Model } from "./Model";
import { MillenniumDateTime } from "@timeedit/millennium-time";
import ReservationConstants from "../lib/ReservationConstants";
import Log from "../lib/Log";
import Language from "../lib/Language";
import TemplateKind from "./TemplateKind";
import _ from "underscore";
import { TFluffyObjectItem } from "../types/models/TMcFluffy";
import { TMcFluffyRules, TMcFluffyActivityData } from "../types/api/shared";
import { IUpdateMcFluffyResponse } from "../types/api/APIResponseTypes";

const { RESERVATION_KIND } = ReservationConstants;

// Private constructor, other objects use McFluffy.create
export class McFluffy extends Model {
    labels: any;
    objectItems: TFluffyObjectItem[];
    fieldItems: {
        field: { id: number; values: any };
        max: number;
        min: number;
    }[];
    templateGroupId: null | number;
    templateKind: any;
    hasMandatoryFields: boolean;
    allowIncomplete: boolean;
    availabilityOverlap: boolean;
    layer: number;
    length: number;
    size: null | number;
    capacityReservationId: number;
    rules?: TMcFluffyRules;
    beginTime: any;
    endTime: any;
    status: any;
    reservation: any;
    activityInfo: TMcFluffyActivityData[];

    static OLD_RESERVATION_KIND = {
        UNDEFINED: 0,
        ABSTRACT: 1,
        PHYSICAL: 2,
        VIRTUAL_ABSTRACT: 3,
        DOUBLE: 4,
    };
    static RESERVATION_KIND = {
        UNDEFINED: 0,
        PHYSICAL: 1,
        PHYSICAL_DOUBLE: 2,
        OPTIONAL: 3,
        OPTIONAL_DOUBLE: 4,
        ABSTRACT: 5,
        ABSTRACT_VIRTUAL: 6,
    };

    constructor(inJsonData?, labels?) {
        super("McFluffy");
        this.labels = labels || [];
        this.objectItems = [];
        this.fieldItems = [];
        this.templateGroupId = null;
        this.templateKind = TemplateKind.RESERVATION;
        this.hasMandatoryFields = false;
        this.allowIncomplete = false;
        this.availabilityOverlap = false;
        this.layer = 0;
        this.length = 0;
        this.size = null; // Size is nothing if not a size template, 0 for a size template which does not yet have a size
        this.capacityReservationId = 0;
        this.activityInfo = [];

        if (!inJsonData) {
            return;
        }
        let jsonData: IUpdateMcFluffyResponse["parameters"];
        if (inJsonData.parameters) {
            jsonData = inJsonData.parameters;
        } else {
            jsonData = [inJsonData.status, inJsonData, inJsonData.rules];
        }
        if (jsonData[0] && jsonData[0].details) {
            Log.warning(jsonData[0].details);
        }
        const EXPECTED_DATA_LENGTH = 3;
        if (jsonData.length < EXPECTED_DATA_LENGTH) {
            return;
        }
        const fluffyData = jsonData[1];
        this.rules = jsonData[2];
        this.length = fluffyData.length || 0;
        if (fluffyData.layer) {
            this.layer = fluffyData.layer;
        }

        const toMillenniumDateTime = (time) =>
            new MillenniumDateTime(_.isObject(time) ? time.datetime : time);
        if (fluffyData.begin_time) {
            this.beginTime = Array.isArray(fluffyData.begin_time)
                ? fluffyData.begin_time.map(toMillenniumDateTime)
                : toMillenniumDateTime(fluffyData.begin_time);
        }
        if (fluffyData.end_time) {
            this.endTime = Array.isArray(fluffyData.end_time)
                ? fluffyData.end_time.map(toMillenniumDateTime)
                : toMillenniumDateTime(fluffyData.end_time);
        }
        if (fluffyData.template_group) {
            this.templateGroupId = fluffyData.template_group;
        }

        if (fluffyData.template_kind) {
            this.templateKind = TemplateKind.get(fluffyData.template_kind);
        }

        if (fluffyData.incomplete) {
            this.allowIncomplete = fluffyData.incomplete;
        }

        if (fluffyData.availabilityOverlap) {
            this.availabilityOverlap = fluffyData.availability_overlap;
        }

        if (fluffyData.status) {
            this.status = fluffyData.status;
        }

        if (fluffyData.reservation) {
            this.reservation = fluffyData.reservation.id;
        }

        let fields = fluffyData.fields;
        if (!fields) {
            fields = [];
        }
        const maxFields = fluffyData.max_fields || [];
        const minFields = fluffyData.min_fields || [];

        for (let i = 0; i < fields.length; i++) {
            if (minFields[i] > 0) {
                this.hasMandatoryFields = true;
            }
            this.fieldItems.push({
                field: { id: fields[i].id, values: fields[i].values },
                max: maxFields[i],
                min: minFields[i],
            });
        }

        const upgradeKinds = (oldKinds) => {
            if (oldKinds) {
                return oldKinds.map((oldKind) => {
                    switch (oldKind) {
                        case McFluffy.OLD_RESERVATION_KIND.UNDEFINED:
                            return McFluffy.RESERVATION_KIND.UNDEFINED;
                        case McFluffy.OLD_RESERVATION_KIND.ABSTRACT:
                            return McFluffy.RESERVATION_KIND.ABSTRACT;
                        case McFluffy.OLD_RESERVATION_KIND.PHYSICAL:
                            return McFluffy.RESERVATION_KIND.PHYSICAL;
                        case McFluffy.OLD_RESERVATION_KIND.VIRTUAL_ABSTRACT:
                            return McFluffy.RESERVATION_KIND.ABSTRACT_VIRTUAL;
                        case McFluffy.OLD_RESERVATION_KIND.DOUBLE:
                            return McFluffy.RESERVATION_KIND.PHYSICAL_DOUBLE;
                        default:
                            return RESERVATION_KIND.UNDEFINED;
                    }
                });
            }
            return [];
        };

        // Size is left out or null if not in a size template,
        // 0 or another number if in a size template
        if (fluffyData.size) {
            this.size = fluffyData.size;
        } else if (fluffyData.size === 0) {
            this.size = 0;
        }
        this.capacityReservationId = fluffyData.capacityReservationId || 0;

        if (fluffyData.activityInfo) {
            this.activityInfo = fluffyData.activityInfo;
        }

        const objects = fluffyData.objects || [];
        const autoObjects = fluffyData.auto_objects || [];
        const preferredDoubles = fluffyData.preferred_doubles || [];
        const virtualObjects = fluffyData.virtual_objects || [];
        let typePhysicals = upgradeKinds(fluffyData.kind_types);
        if (fluffyData.kind_for_types) {
            typePhysicals = fluffyData.kind_for_types;
        }
        let objectPhysicals = upgradeKinds(fluffyData.physical_objects);
        if (fluffyData.kind_for_objects) {
            objectPhysicals = fluffyData.kind_for_objects;
        }
        const ignoreAbstractExceptions = fluffyData.ignore_abstract_exceptions || [];

        const sizeReservationTypes = fluffyData.size_reservation_types || [];

        const objectLines: TFluffyObjectItem[] = [];
        const placeholderLines: TFluffyObjectItem[] = [];

        for (let i = 0; i < objects.length; i++) {
            const item = {
                object: objects[i],
                objectText: objects[i].id,
                max: fluffyData.max_objects[i],
                min: fluffyData.min_objects[i],
                physical: objectPhysicals[i],
                selected: fluffyData.selected_objects[i],
                optional: fluffyData.optional_objects[i],
                type: fluffyData.types[i],
                typePhysical: typePhysicals[i],
                subtypes: fluffyData.subtypes[i],
                auto: autoObjects[i] ? autoObjects[i] : false,
                double: preferredDoubles[i] || false,
                virtual: virtualObjects[i] || false,
                ignoreAbstractException: ignoreAbstractExceptions[i] || false,
                sizeReservationType: sizeReservationTypes[i] || false,
            };

            for (let j = 0; j < this.labels.length; j++) {
                const label = this.labels[j];
                if (label.id === item.object.id) {
                    item.objectText = label.label;
                }
            }
            if (item.object.id > 0) {
                objectLines.push(item);
            } else {
                placeholderLines.push(item);
            }
        }
        this.objectItems = objectLines.concat(placeholderLines);
    }

    static create = function (jsonData?, labelObjects?) {
        return new McFluffy(jsonData, toLabels(labelObjects));
    };

    labelIndexOfObjectId(objectId) {
        for (let i = 0; i < this.labels.length; i++) {
            if (this.labels[i].id === objectId) {
                return i;
            }
        }
        return -1;
    }

    objectItemsForType(typeId) {
        return this.objectItems.filter((item) => item.type.id === typeId);
    }

    replaceObject(objectItem, newObject, callback, clearFields = true, isEditMode = false) {
        if (!objectItem.selected) {
            return;
        }
        let valueId = objectItem.object.id;
        let isValueTypeId = false;
        if (valueId === 0) {
            valueId = objectItem.type.id;
            isValueTypeId = true;
        }

        const self = this;
        const json = clearFields ? this.clearFields().toJson() : this.toJson();
        if (!isEditMode && json.reservation) {
            delete json.reservation;
            json.activityInfo = [];
        }
        API.replaceInMcFluffy(
            json,
            valueId,
            isValueTypeId,
            newObject.typeId,
            newObject.id,
            (result) => {
                const newLabels = self.labels.concat(toLabel(newObject));
                newLabels.splice(self.labelIndexOfObjectId(objectItem.object.id), 1);
                const resultFluffy = new McFluffy(result, newLabels);
                callback(resultFluffy);
            }
        );
    }

    addObjects(objects, callback, clearFields = true, isEditMode = false) {
        const objectIds = objects.map((object) => (object.object ? object.object.id : object.id));
        const typeId = objects[0].type ? objects[0].type.id : objects[0].typeId;

        const typeItems = this.objectItems.filter((item) => item.type.id === typeId) || [];
        const objectItem = typeItems[0] || {};

        if (objectItem.max < objectIds.length) {
            return Log.warning(
                Language.get("nc_selection_list_type_is_full", toLabel(objects[0]).label)
            );
        }

        const json = clearFields ? this.clearFields().toJson() : this.toJson();
        if (!isEditMode && json.reservation) {
            delete json.reservation;
            json.activityInfo = [];
        }
        API.addToMcFluffyMulti(json, typeId, objectIds, (result) => {
            if (result.parameters[0].class === "status" && result.parameters[0].result < 0) {
                Log.warning(result.parameters[0].details, result.parameters[0].result);
                return callback(this, result.parameters[0]);
            }

            const resultFluffy = new McFluffy(result, this.labels.concat(objects.map(toLabel)));
            return callback(resultFluffy, result.parameters[0]);
        });
        return null;
    }

    addObject(object, callback, clearFields = true, isEditMode = false) {
        const objectId = object.object ? object.object.id : object.id;
        const typeId = object.type ? object.type.id : object.typeId;
        const typeItems = this.objectItems.filter((item) => item.type.id === object.typeId) || [];
        if (_.any(typeItems, (te) => te.object.id === objectId && te.type.id === typeId)) {
            return null;
        }
        const objectItem = typeItems[0] || {};

        // When adding an object to a type which allows exactly 1 object and the type is full,
        // replace the previous object and inform the user of what happened
        if (objectItem.max === 0 && typeItems.length === 1) {
            Log.info(
                Language.get(
                    "nc_selection_list_type_is_full_replace_object",
                    objectItem.objectText,
                    object.name
                )
            );
            return this.replaceObject(objectItem, object, callback, clearFields);
        }

        // If type is full and it allows more than one object, warn the user of that the object cannot be added and return
        if (objectItem.max === 0 && typeItems.length > 1) {
            return Log.warning(
                Language.get("nc_selection_list_type_is_full", toLabel(object).label)
            );
        }

        const json = clearFields ? this.clearFields().toJson() : this.toJson();
        if (!isEditMode && json.reservation) {
            delete json.reservation;
            json.activityInfo = [];
        }
        API.addToMcFluffy(json, typeId, objectId, (result) => {
            if (result.parameters[0].class === "status" && result.parameters[0].result < 0) {
                Log.warning(result.parameters[0].details, result.parameters[0].result);
                return callback(this, result.parameters[0]);
            }

            const resultFluffy = new McFluffy(result, this.labels.concat(toLabel(object)));
            return callback(resultFluffy, result.parameters[0]);
        });
        return null;
    }

    setObjects(objects, presevePreferDouble, callback) {
        API.setToMcFluffy(this.toJson(), objects, presevePreferDouble, (result) => {
            const resultFluffy = new McFluffy(result, this.labels.concat(toLabels(objects)));
            callback(resultFluffy);
        });
    }

    removeObject(object, callback, clearFields = true, isEditMode = false) {
        if (!object.selected) {
            return;
        }
        let valueId = object.object.id;
        let isValueTypeId = false;
        if (valueId === 0) {
            valueId = object.type.id;
            isValueTypeId = true;
        }
        const self = this;
        const json = clearFields ? this.clearFields().toJson() : this.toJson();
        if (!isEditMode && json.reservation) {
            delete json.reservation;
            json.activityInfo = [];
        }
        API.removeFromMcFluffy(json, valueId, isValueTypeId, (result) => {
            const newLabels = [].concat(self.labels);
            if (object.object.id !== 0) {
                newLabels.splice(self.labelIndexOfObjectId(object.object.id), 1);
            }
            const resultFluffy = new McFluffy(result, newLabels);
            callback(resultFluffy);
        });
    }

    setTemplateGroup(templateId, callback) {
        const newFluffy = new McFluffy();
        newFluffy.templateKind = this.templateKind;
        newFluffy.templateGroupId = templateId;
        newFluffy.layer = this.layer;

        API.setToMcFluffy(newFluffy.toJson(), this.getObjects(), false, (result) => {
            const resultFluffy = new McFluffy(result, this.labels);
            callback(resultFluffy);
        });
    }

    setLength(length = 0) {
        if (length === this.length) {
            return this;
        }
        const newFluffy = new McFluffy(this.toJson(), this.labels);
        newFluffy.length = length;
        return newFluffy;
    }

    setLayer(layerId) {
        const newFluffy = new McFluffy(this.toJson(), this.labels);
        newFluffy.layer = layerId;
        return newFluffy;
    }

    setSize(newSize) {
        const newFluffy = new McFluffy(this.toJson(), this.labels);
        newFluffy.size = newSize;
        return newFluffy;
    }

    setCapacityReservationId(reservationId) {
        const newFluffy = new McFluffy(this.toJson(), this.labels);
        newFluffy.capacityReservationId = reservationId;
        return newFluffy;
    }

    setTemplateKind(templateKind, callback) {
        const newFluffy = new McFluffy();
        newFluffy.templateKind = templateKind;
        newFluffy.templateGroupId = 0;
        API.updateMcFluffy(newFluffy.toJson(), (result) => {
            const resultFluffy = new McFluffy(result, newFluffy.labels);
            callback(resultFluffy);
        });
    }

    setObjectDoubleBookingForType(typeId, isDoubleBookingAllowed, callback) {
        const newFluffy = new McFluffy(this.toJson(), this.labels);
        newFluffy.objectItems.forEach((item) => {
            if (item.type.id === typeId && item.object.id !== 0) {
                // eslint-disable-next-line no-param-reassign
                item.double = isDoubleBookingAllowed;
            }
        });

        API.updateMcFluffy(newFluffy.toJson(), (result) => {
            callback(new McFluffy(result, newFluffy.labels));
        });
    }

    setObjectDoubleBooking(object, isDoubleBookingAllowed, callback) {
        const newFluffy = new McFluffy(this.toJson(), this.labels);

        newFluffy.objectItems.forEach((item) => {
            if (object.object.id !== item.object.id) {
                return;
            }

            // eslint-disable-next-line no-param-reassign
            item.double = isDoubleBookingAllowed;
        });

        API.updateMcFluffy(newFluffy.toJson(), (result) => {
            callback(new McFluffy(result, newFluffy.labels));
        });
    }

    setObjectAbstractException(object, ignoreAbstractException, callback) {
        const newFluffy = new McFluffy(this.toJson(), this.labels);

        newFluffy.objectItems.forEach((item) => {
            if (object.object.id !== item.object.id) {
                return;
            }
            // eslint-disable-next-line no-param-reassign
            item.ignoreAbstractException = ignoreAbstractException;
        });

        API.updateMcFluffy(newFluffy.toJson(), (result) => {
            callback(new McFluffy(result, newFluffy.labels));
        });
    }

    getFirstObjectOfType(typeId) {
        let result: any = null;
        this.objectItems.forEach((item) => {
            if (item.type.id === typeId) {
                if (item.object && item.object.id > 0 && result === null) {
                    result = item.object;
                }
            }
        });
        return result;
    }

    hasTimeValues() {
        return (
            (this.beginTime && this.beginTime.length > 0) ||
            (this.endTime && this.endTime.length > 0)
        );
    }

    clearTimeValues() {
        if (!this.hasTimeValues()) {
            return this;
        }
        const result = new McFluffy(this.toJson(), this.labels);
        result.beginTime = [];
        result.endTime = [];
        return result;
    }

    hasFields() {
        return this.fieldItems.length > 0;
    }

    hasFieldValues() {
        return !this.allFieldsEmpty();
    }

    allFieldsEmpty() {
        return this.fieldItems.every(
            (fieldItem) =>
                !fieldItem.field.values ||
                fieldItem.field.values.length === 0 ||
                (fieldItem.field.values.length === 1 && fieldItem.field.values[0] === "")
        );
    }

    clearFields() {
        if (this.allFieldsEmpty()) {
            return this;
        }
        const result = new McFluffy(this.toJson(), this.labels);
        result.fieldItems.forEach((fieldItem) => {
            // eslint-disable-next-line no-param-reassign
            fieldItem.field.values = [];
        });
        return result;
    }

    setFields(newFields, concatenateValuesIfNeeded = false) {
        const result = new McFluffy(this.toJson(), this.labels);

        const valuesFor = function (fieldId) {
            for (let i = 0; i < newFields.length; i++) {
                if (newFields[i].id === fieldId) {
                    return newFields[i].values || [];
                }
            }
            return [];
        };

        result.fieldItems.forEach((fieldItem) => {
            const values = valuesFor(fieldItem.field.id);
            if (values.length > 0) {
                if (
                    concatenateValuesIfNeeded &&
                    values.length > fieldItem.max + fieldItem.field.values.length
                ) {
                    // eslint-disable-next-line no-param-reassign
                    fieldItem.field.values = [values.join(", ")];
                } else {
                    // eslint-disable-next-line no-param-reassign
                    fieldItem.field.values = values;
                }
            }
        });
        return result;
    }

    getLineForType(typeId) {
        let typeLine = null;
        this.objectItems.forEach((objectItem) => {
            if (objectItem.object.id === 0 && objectItem.type.id === typeId) {
                typeLine = objectItem;
            }
        });
        return typeLine;
    }

    getObjects() {
        const result: any[] = [];
        this.objectItems.forEach((objectItem) => {
            if (objectItem.object.id > 0) {
                result.push({
                    id: objectItem.object.id,
                    typeId: objectItem.type.id,
                    name: objectItem.objectText,
                });
            }
        });
        return result;
    }

    getTypeIds() {
        return _.uniq(this.objectItems.map((item) => item.type.id));
    }

    getObjectIds(typeId) {
        const result: any[] = [];
        this.objectItems.forEach((objectItem) => {
            if (typeId !== undefined && objectItem.type.id !== typeId) {
                return;
            }
            if (objectItem.object.id > 0) {
                result.push(objectItem.object.id);
            }
        });
        return result;
    }

    allowsIncomplete() {
        return _.some(
            this.objectItems.filter((item) => item.object.id !== 0),
            (object) => object.virtual === true
        );
    }

    updateLabels(callback) {
        API.getObjectNames(
            this.objectItems
                .filter((item) => item.object.id !== 0)
                .map((oI) => ({
                    id: oI.object.id,
                    type: oI.type.id,
                })),
            false,
            (namedObjects) => {
                callback(McFluffy.create(this.toJson(), namedObjects));
            }
        );
    }

    sortByTypes(objects) {
        const typeOrder = this.objectItems.map((oI) => oI.type.id);
        return [...objects].sort((o1, o2) => {
            const o1Idx = typeOrder.indexOf(o1.typeId);
            const o2Idx = typeOrder.indexOf(o2.typeId);
            if (o1Idx < o2Idx) {
                return -1;
            }
            if (o1Idx > o2Idx) {
                return 1;
            }
            return 0;
        });
    }

    hasActivityInfo() {
        return this.activityInfo.length > 0;
    }

    setActivityInfo(newInfo) {
        const result = new McFluffy(this.toJson(), this.labels);
        result.activityInfo = [newInfo];
        return result;
    }

    clearActivityInfo() {
        if (this.activityInfo.length === 0) {
            return this;
        }
        const result = new McFluffy(this.toJson(), this.labels);
        result.activityInfo = [];
        return result;
    }

    hasSize() {
        // Interesting question: do we want to be in size mode or not if the size is 0?
        // No real point in making zero-size reservations, right?
        return this.size !== null;
    }

    toJson() {
        const result: any = { length: this.length };

        const fields: any[] = [];
        const maxFields: any[] = [];
        const minFields: any[] = [];

        const numFields = this.fieldItems ? this.fieldItems.length : 0;
        for (let i = 0; i < numFields; i++) {
            const field = this.fieldItems[i];
            fields.push({
                id: field.field.id,
                values: [].concat(field.field.values || []),
            });
            maxFields.push(field.max);
            minFields.push(field.min);
        }
        result.fields = fields;
        result.max_fields = maxFields;
        result.min_fields = minFields;

        result.rules = this.rules;

        if (this.templateGroupId && this.templateGroupId > 0) {
            result.template_group = this.templateGroupId;
        } else {
            result.template_group = 0;
        }

        if (this.templateKind && this.templateKind !== TemplateKind.RESERVATION) {
            result.template_kind = this.templateKind.number;
        }
        if (this.beginTime) {
            result.begin_time = _.asArray(this.beginTime).map((time) => time.getMts());
        }
        if (this.endTime !== undefined && this.endTime !== null) {
            result.end_time = _.asArray(this.endTime).map((time) => time.getMts());
        }

        const objects: any[] = [];
        const maxObjects: any[] = [];
        const minObjects: any[] = [];
        const physicalObjects: any[] = [];
        const selectedObjects: any[] = [];
        const optionalObjects: any[] = [];
        const autoObjects: any[] = [];
        const preferredDoubles: any[] = [];
        const virtualObjects: any[] = [];
        const types: any[] = [];
        const typePhysicals: any[] = [];
        const subtypes: any[] = [];
        const ignoreAbstractExceptions: any[] = [];
        const sizeReservationTypes: any[] = [];
        for (let i = 0; i < this.objectItems.length; i++) {
            const object = this.objectItems[i];
            objects.push(object.object);
            maxObjects.push(object.max);
            minObjects.push(object.min);
            physicalObjects.push(object.physical);
            selectedObjects.push(object.selected);
            optionalObjects.push(object.optional);
            types.push(object.type);
            typePhysicals.push(object.typePhysical);
            subtypes.push(object.subtypes);
            autoObjects.push(object.auto || false);
            preferredDoubles.push(object.double || false);
            virtualObjects.push(object.virtual || false);
            ignoreAbstractExceptions.push(object.ignoreAbstractException || false);
            sizeReservationTypes.push(object.sizeReservationType || false);
        }
        result.objects = objects;
        result.max_objects = maxObjects;
        result.min_objects = minObjects;
        result.kind_for_objects = physicalObjects;
        result.physical_objects = downgradeKinds(physicalObjects);
        result.selected_objects = selectedObjects;
        result.optional_objects = optionalObjects;
        result.auto_objects = autoObjects;
        result.preferred_doubles = preferredDoubles;
        result.virtual_objects = virtualObjects;
        result.types = types;
        result.kind_for_types = typePhysicals;
        result.kind_types = downgradeKinds(typePhysicals);
        result.ignore_abstract_exceptions = ignoreAbstractExceptions;
        result.size_reservation_types = sizeReservationTypes;
        result.subtypes = subtypes;
        result.incomplete = this.allowIncomplete;
        result.availability_overlap = this.availabilityOverlap;
        result.layer = this.layer || 0;
        result.status = !this.status
            ? []
            : this.status.map((status) => ({ class: "reservationstatus", status }));
        if (this.reservation) {
            result.reservation = this.reservation;
        }

        if (this.size) {
            result.size = this.size;
        } else if (this.size === 0) {
            result.size = 0;
        }
        result.capacityReservationId = this.capacityReservationId;
        result.activityInfo = this.activityInfo;
        return result;
    }
}

const downgradeKinds = (newKinds) => {
    if (newKinds) {
        return newKinds.map((newKind) => {
            switch (newKind) {
                case McFluffy.RESERVATION_KIND.UNDEFINED:
                    return McFluffy.OLD_RESERVATION_KIND.UNDEFINED;
                case McFluffy.RESERVATION_KIND.PHYSICAL:
                    return McFluffy.OLD_RESERVATION_KIND.PHYSICAL;
                case McFluffy.RESERVATION_KIND.PHYSICAL_DOUBLE:
                    return McFluffy.OLD_RESERVATION_KIND.DOUBLE;
                case McFluffy.RESERVATION_KIND.OPTIONAL:
                    return McFluffy.OLD_RESERVATION_KIND.PHYSICAL;
                case McFluffy.RESERVATION_KIND.OPTIONAL_DOUBLE:
                    return McFluffy.OLD_RESERVATION_KIND.ABSTRACT;
                case McFluffy.RESERVATION_KIND.ABSTRACT:
                    return McFluffy.OLD_RESERVATION_KIND.ABSTRACT;
                case McFluffy.RESERVATION_KIND.ABSTRACT_VIRTUAL:
                    return McFluffy.OLD_RESERVATION_KIND.VIRTUAL_ABSTRACT;
                default:
                    return McFluffy.OLD_RESERVATION_KIND.UNDEFINED;
            }
        });
    }
    return [];
};

const toLabel = function (object) {
    const objectId = object.object ? object.object.id : object.id;
    if (object.id && object.label) {
        return { id: objectId, label: object.label };
    }
    const firstValueField = _.find(
        object.fields,
        (field) => field.values && field.values.length > 0
    );
    const label =
        object.name ||
        object.objectText ||
        object.label ||
        (firstValueField ? firstValueField.values.join(", ") : "-");
    return { id: objectId, label };
};

const toLabels = function (objects) {
    if (objects === undefined || objects === null) {
        return [];
    }
    return objects.map((object) => toLabel(object));
};
