import React from "react";

// Migration to class component with 'react-codemod' failed, explained here:
// https://github.com/reactjs/react-codemod/blob/master/LEGACY.md#explanation-of-the-new-es2015-class-transform-with-property-initializers
import createReactClass from "create-react-class";

import ReactDOM from "react-dom";
import Language from "../lib/Language";
import ObjectSettingsConstants from "../lib/ObjectSettingsConstants";
import ListSettingsStore from "./ListSettingsStore";
import _ from "underscore";
import API from "../lib/TimeEditAPI";
import Log from "../lib/Log";

import errorCorrectLoadedSettings from "../lib/ObjectSearchUtils";
import { TTypeNameClass } from "../types/api/APIClassTypes";

const first = (valueOrArray) => (Array.isArray(valueOrArray) ? valueOrArray[0] : valueOrArray);
const Keyboard = { LEFT: 37, UP: 38, RIGHT: 39, DOWN: 40, ENTER: 13 };

const { ACTIVE, VIRTUAL, ABSTRACT, RESERVE } = ObjectSettingsConstants;

const TYPE_PREFIX = "type-";

const SectionType = {
    CATEGORY: -1,
    PROPERTY: -2,
    ASSOCIATION: -3,
};

const CATEGORY_BOOLEAN_KIND = 7;

const getAssociatedObjectsFromSettings = (settings) => {
    return settings.selection
        .filter((item) => item.section.type === SectionType.ASSOCIATION)
        .map((item) => item.value);
};

const addAssociatedObjectLabels = (settings, callback) => {
    const objectIds = getAssociatedObjectsFromSettings(settings);
    API.getObjects(objectIds, (objects) => {
        const result = { ...settings };
        result.selection = settings.selection.map((item) => {
            if (item.section.type !== SectionType.ASSOCIATION) {
                return item;
            }
            const labelObject = _.find(objects, (object) => object.id === item.value);
            if (!labelObject) {
                return item;
            }

            return _.extend({}, item, {
                label: labelObject.fields[0].values[0],
            });
        });
        callback(result);
    });
};

const getProperties = () => ({
    virtual: {
        id: -1,
        options: [
            { value: VIRTUAL.VIRTUAL, label: Language.get("dynamic_object_list_virtual") },
            { value: VIRTUAL.NONVIRTUAL, label: Language.get("dynamic_object_list_not_virtual") },
            {
                value: VIRTUAL.ALL,
                label: Language.get("dynamic_object_list_virtual_all"),
                isDefault: true,
                hidden: true,
            },
        ],
    },
    abstract: {
        id: -2,
        options: [
            { value: ABSTRACT.ABSTRACT, label: Language.get("dynamic_object_list_abstract") },
            {
                value: ABSTRACT.NONABSTRACT,
                label: Language.get("dynamic_object_list_not_abstract"),
            },
            {
                value: ABSTRACT.ALL,
                label: Language.get("dynamic_object_list_abstract_all"),
                isDefault: true,
                hidden: true,
            },
        ],
    },
    reserve: {
        id: -3,
        options: [
            {
                value: RESERVE.RESERVE,
                label: Language.get("permission_reserve"),
                isDefault: true,
                hidden: true,
            },
            { value: RESERVE.READ, label: Language.get("permission_read") },
            { value: RESERVE.PLAN, label: Language.get("permission_plan") },
        ],
    },
    active: {
        id: -4,
        options: [
            {
                value: ACTIVE.ACTIVE,
                label: Language.get("dynamic_object_list_active"),
                isDefault: true,
                hidden: true,
            },
            { value: ACTIVE.INACTIVE, label: Language.get("dynamic_object_list_not_active") },
            { value: ACTIVE.ALL, label: Language.get("dynamic_object_list_active_all") },
        ],
    },
});

const ObjectSelectSettings = createReactClass({
    displayName: "ObjectSelectSettings",

    getInitialState(props = this.props) {
        const DEFAULT_STATE = {
            selection: {},
            activeSection: null,
            activeOption: undefined,
            activeSelected: undefined,
            availableOptions: [],
            associatedTypes: [],
            associatedTypeFields: {},
            searchString: "",
            hasMoreOptions: false,
        };

        if (!_.isObject(props.defaultSettings)) {
            return _.extend({}, DEFAULT_STATE);
        }

        const state = _.extend({}, DEFAULT_STATE, this.getStateFromSettings(props.defaultSettings));
        state.activeSection = this.getAllSectionDefs(state)[0];

        return state;
    },

    componentDidMount() {
        let allTypes: TTypeNameClass[] = [];
        let associatedTypes = [];
        _.runAsync(
            [
                (done) =>
                    API.getAssociatedTypes(this.props.type, (res) => {
                        associatedTypes = res;
                        done();
                    }),
                (done) =>
                    API.findTypes((res) => {
                        allTypes = res;
                        done();
                    }),
            ],
            () => {
                const activeTypeIds = _.pluck(allTypes, "id");
                associatedTypes = associatedTypes
                    .filter((associatedType) => _.contains(activeTypeIds, associatedType.id))
                    .map((type) => ({
                        id: type.id,
                        name: _.find(allTypes, (typeDefinition) => typeDefinition.id === type.id)
                            .name,
                    }));
                this.setState({ associatedTypes });

                const associatedTypeFields = {};
                _.runAsync(
                    associatedTypes.map(
                        (type) => (done) =>
                            API.getFieldDefsForType(type.id, true, (fieldDefs) => {
                                const MAX_SEARCHABLE_FIELDS = 10;
                                const searchableFields = fieldDefs.filter((def) => def.searchable);
                                associatedTypeFields[type.id] = _.head(
                                    _.sortBy(searchableFields, (def) => (def.primary ? 0 : 1)),
                                    MAX_SEARCHABLE_FIELDS
                                );
                                done();
                            })
                    ),
                    () => this.setState({ associatedTypeFields })
                );
            }
        );

        if (this.state.activeSection) {
            this.getOptions(
                this.state.activeSection,
                this.state.searchString,
                (availableOptions, isComplete) => {
                    this.setState({
                        availableOptions,
                        hasMoreOptions: !isComplete,
                    });
                }
            );
        }

        if (this.props.defaultSettings.associatedObjects) {
            this.updateAssociatedObjectsWithLabels(
                this.props.defaultSettings.associatedObjects,
                (newState) => this.setState(newState)
            );
        }

        ReactDOM.findDOMNode(this.refs.category).focus();
    },

    updateAssociatedObjectsWithLabels(associatedObjects, callback) {
        API.getObjects(
            associatedObjects.map((object) => object.id),
            (objects) => {
                const updatedSelection = this.state.selection.map((item) => {
                    if (item.section.type !== SectionType.ASSOCIATION) {
                        return item;
                    }
                    const labelObject = _.find(objects, (object) => object.id === item.value);
                    if (!labelObject) {
                        return item;
                    }

                    return _.extend({}, item, {
                        label: labelObject.fields[0].values[0],
                    });
                });
                callback({ selection: updatedSelection });
            }
        );
    },

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.state.activeSection, prevState.activeSection)) {
            this.getOptions(this.state.activeSection, "", (availableOptions, isComplete) => {
                this.setState({
                    availableOptions,
                    searchString: "",
                    hasMoreOptions: !isComplete,
                });
            });
        }
        if (!_.isEqual(prevProps.defaultSettings, this.props.defaultSettings)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(this.getInitialState(this.props));
        }

        const prevSettingsObject = this.getSettingsObject(prevState);
        const currentSettingsObject = this.getSettingsObject(this.state);

        if (!_.isEqual(prevSettingsObject, currentSettingsObject)) {
            this.props.onChange(currentSettingsObject);
        }
    },

    getSettingsObject(state = this.state) {
        const allProps = getProperties();
        // Returns a data object with selected categories as an object and separate properties for other settings.
        const selectedCategories = state.selection
            .filter((item) => item.section.type === SectionType.CATEGORY)
            .reduce((categories, item) => {
                const categoryId = item.section.id;
                let val = !Array.isArray(item.value) ? [item.value] : item.value;
                let cats = categories[categoryId] || [];
                cats.push(val);
                return _.extend(categories, {
                    [categoryId]: cats,
                });
            }, {});

        const getValue = (key) => {
            const item =
                _.find(
                    state.selection,
                    (selectItem) =>
                        selectItem.section.type === SectionType.PROPERTY &&
                        selectItem.section.id === allProps[key].id
                ) || {};
            return !_.isNullish(item.value)
                ? first(item.value)
                : ObjectSettingsConstants.DEFAULTS[key];
        };
        const getCategoryValuesObject = (categories, key) =>
            _.extend(categories, { [key]: getValue(key) });
        const properties = Object.keys(allProps).reduce(getCategoryValuesObject, {});

        properties.matchAll = [...state.matchAll];

        const associatedObjects = state.selection
            .filter((item) => item.section.type === SectionType.ASSOCIATION)
            .map((item) => ({
                type: item.section.id,
                id: item.value,
            }));

        return _.extend({}, { selectedCategories }, { associatedObjects }, properties);
    },

    getStateFromSettings(settings) {
        const allProps = getProperties();
        const state = {};

        state.selection = _.flatten(
            Object.keys(settings.selectedCategories || {}).map((categoryId) => {
                const section = this.getSectionDef(categoryId);
                const values = settings.selectedCategories[categoryId] || [];
                if (values.section && values.value && values.label) {
                    return values;
                }
                const def = this.getSectionDefinition(categoryId);
                return values.map((value) => ({
                    section,
                    value,
                    label: def.kind === CATEGORY_BOOLEAN_KIND ? this.getBooleanLabel(value) : value,
                }));
            })
        );
        const selectedProperties = Object.keys(allProps)
            .filter(
                (key) =>
                    settings.hasOwnProperty(key) &&
                    !this.isDefaultSetting(allProps[key].id, [settings[key]])
            )
            .map((key) => ({
                section: this.getSectionDef(allProps[key].id),
                value: settings[key],
                label: _.find(allProps[key].options, (option) => option.value === settings[key])
                    .label,
            }));
        state.selection = state.selection.concat(selectedProperties);
        state.selection = state.selection.concat(
            (settings.associatedObjects || []).map((object) => ({
                section: {
                    type: SectionType.ASSOCIATION,
                    id: object.type,
                },
                value: object.id,
            }))
        );
        state.matchAll = settings.matchAll ? [...settings.matchAll] : [];
        return state;
    },

    getSelectedValues(key) {
        const sectionDef = _.isObject(key) ? key : this.getSectionDef(key);
        return this.state.selection
            .filter((item) => _.isEqual(item.section, sectionDef))
            .map((item) => item.value)
            .flat();
    },

    getSectionDef(section) {
        if (String(section).indexOf(TYPE_PREFIX) === 0) {
            return {
                type: SectionType.ASSOCIATION,
                id: parseInt(String(section).replace(TYPE_PREFIX, ""), 10),
            };
        }
        if (parseInt(section, 10) < 0) {
            return {
                type: SectionType.PROPERTY,
                id: parseInt(section, 10),
            };
        }

        return {
            type: SectionType.CATEGORY,
            id: parseInt(section, 10),
        };
    },

    getAllSectionDefs(state = this.state) {
        const allProps = getProperties();
        return this.props.categories
            .map((item) => this.getSectionDef(item.id))
            .concat(
                state.associatedTypes.map((associatedType) => ({
                    type: SectionType.ASSOCIATION,
                    id: associatedType.id,
                }))
            )
            .concat(Object.keys(allProps).map((prop) => this.getSectionDef(allProps[prop].id)));
    },

    getOptions(section, searchString, callback) {
        if (section.type === SectionType.ASSOCIATION) {
            const query = {
                type: section.id,
                generalSearchFields: this.state.associatedTypeFields[section.id],
                generalSearchString: searchString,
            };
            API.findObjects(query, (objects, totalNumber) => {
                const isComplete = objects.length === totalNumber;
                callback(
                    objects.map((item) => ({
                        value: item.id,
                        label: item.name,
                    })),
                    isComplete
                );
            });
            return;
        }

        if (section.type === SectionType.CATEGORY) {
            const category = _.find(this.props.categories, (item) => item.id === section.id);
            const categoryOptions = category.categories || [];
            const matchesSearchString = (item) =>
                !searchString || item.label.toLowerCase().indexOf(searchString.toLowerCase()) > -1;

            callback(
                categoryOptions
                    .map((item) => ({
                        value: item,
                        label:
                            category.kind === CATEGORY_BOOLEAN_KIND
                                ? this.getBooleanLabel(item)
                                : item,
                    }))
                    .filter(matchesSearchString),
                true
            );
            return;
        }

        const allProps = getProperties();
        const key = _.find(
            Object.keys(allProps),
            (category) => allProps[category].id === section.id
        );
        callback(
            allProps[key].options
                .filter((item) => !item.hidden)
                .map((item) => ({
                    value: item.value,
                    label: item.label,
                })),
            true
        );
    },

    isSearchAvailable() {
        if (!this.state.activeSection) {
            return false;
        }

        if (this.state.activeSection.type === SectionType.ASSOCIATION) {
            const fields = this.state.associatedTypeFields[this.state.activeSection.id];
            return Array.isArray(fields) && fields.length > 0;
        }

        return this.state.activeSection.type === SectionType.CATEGORY;
    },

    getBooleanLabel(value) {
        if (value === "1") {
            return Language.get("dynamic_object_list_yes");
        }
        if (value === "0") {
            return Language.get("dynamic_object_list_no");
        }
        return value;
    },

    getVisibleOptions() {
        const sectionDef = this.state.activeSection;
        const isOptionSelected = (item) =>
            _.contains(this.getSelectedValues(sectionDef), item.value);

        const allProps = getProperties();
        return this.state.availableOptions
            .filter(_.negate(isOptionSelected))
            .filter((categoryValue) => {
                if (sectionDef.type !== SectionType.PROPERTY) {
                    return true;
                }

                const selectedValue = first(this.getSelectedValues(sectionDef.id));
                const isDefaultActive =
                    selectedValue === undefined ||
                    this.isDefaultSetting(sectionDef.id, [selectedValue]);
                if (!isDefaultActive) {
                    const key = _.find(
                        Object.keys(allProps),
                        (item) => allProps[item].id === sectionDef.id
                    );
                    const availableItems = allProps[key].options.filter(
                        (item) => item.value !== selectedValue && !item.hidden
                    );
                    if (categoryValue.value !== availableItems[0].value) {
                        return false;
                    }
                }
                return true;
            });
    },

    handleClear() {
        ReactDOM.findDOMNode(this.refs.category).focus();
        this.setState({ selection: [] });
    },

    handleSectionKeyDown(event) {
        const allSections = this.getAllSectionDefs();
        let index = allSections.findIndex((section) =>
            _.isEqual(this.state.activeSection, section)
        );

        if (event.keyCode === Keyboard.UP) {
            index = index - 1;
            if (index < 0) {
                index = allSections.length - 1;
            }

            this.setState({ activeSection: allSections[index] });
        }

        if (event.keyCode === Keyboard.DOWN) {
            index = (index + 1) % allSections.length;
            this.setState({ activeSection: allSections[index] });
        }

        if (event.keyCode === Keyboard.LEFT) {
            ReactDOM.findDOMNode(this.refs.chosen).focus();
        }

        if (event.keyCode === Keyboard.RIGHT) {
            ReactDOM.findDOMNode(this.refs.option).focus();
        }

        if (event.keyCode === Keyboard.ENTER) {
            this.handleSectionChange(event);
        }

        if (
            _.contains(
                Object.keys(Keyboard).map((key) => Keyboard[key]),
                event.keyCode
            )
        ) {
            event.preventDefault();
        }
    },

    handleSectionChange(event) {
        this.setState({ activeSection: JSON.parse(event.target.value) }, () => {
            ReactDOM.findDOMNode(this.refs.option).focus();
        });
    },

    handleOptionKeyDown(event) {
        let value = event.target.value;

        if (this.state.activeSection.type === SectionType.PROPERTY) {
            value = this.getPropertyValue(this.state.activeSection, value);
        }
        if (this.state.activeSection.type === SectionType.ASSOCIATION) {
            value = parseInt(value, 10);
        }
        const list = _.difference(
            this.state.availableOptions.map((item) => item.value),
            this.getSelectedValues(this.state.activeSection)
        );
        const current = list.findIndex((item) => item === value);

        if (event.keyCode === Keyboard.UP) {
            let index = current - 1;
            if (index < 0) {
                index = list.length - 1;
            }
            this.setState({ activeOption: list[index] });
        }

        if (event.keyCode === Keyboard.DOWN) {
            const index = (current + 1) % list.length;
            this.setState({ activeOption: list[index] });
        }

        if (event.keyCode === Keyboard.LEFT) {
            ReactDOM.findDOMNode(this.refs.category).focus();
        }

        if (event.keyCode === Keyboard.RIGHT) {
            ReactDOM.findDOMNode(this.refs.chosen).focus();
        }

        if (event.keyCode === Keyboard.ENTER) {
            if (!event.target.value) {
                ReactDOM.findDOMNode(this.refs.chosen).focus();
                return;
            }

            this.handleOptionChange(event, true);
        }

        if (
            _.contains(
                Object.keys(Keyboard).map((key) => Keyboard[key]),
                event.keyCode
            )
        ) {
            event.preventDefault();
        }
    },

    handleOptionChange(event, selectNextOption = false) {
        // We only want and need to handle clicks events right on the selected option
        if (event.type === "click" && event.target.nodeName === "SELECT") {
            return;
        }
        let value = event.target.value;

        if (this.state.activeSection.type === SectionType.PROPERTY) {
            value = this.getPropertyValue(this.state.activeSection, value);
        }
        if (this.state.activeSection.type === SectionType.ASSOCIATION) {
            value = parseInt(value, 10);
        }

        const list = _.difference(
            this.state.availableOptions.map((item) => item.value),
            this.getSelectedValues(this.state.activeSection)
        );
        const current = list.findIndex((item) => item === value);

        let activeOption = undefined;
        if (selectNextOption) {
            activeOption = list[current + 1];
            if (current === list.length - 1) {
                activeOption = list[current - 1];
            }

            if (!activeOption) {
                activeOption = list[0];
            }
        }

        // When clicking, the highlighted line, the event target will be an option element.
        // Otherwise, it'll be the select element.
        const label =
            event.target.nodeName === "OPTION"
                ? event.target.label
                : event.target.options[event.target.selectedIndex].label;
        const sectionDef = _.clone(this.state.activeSection);

        let selection = _.clone(this.state.selection);
        let section = [...selection]
            .reverse()
            .find(
                (sec) => sec.section.id === sectionDef.id && sec.section.type === sectionDef.type
            );

        if (!section || this.state.activeSection.type !== SectionType.CATEGORY) {
            section = { section: sectionDef, value, label };
            selection.push(section);
        } else {
            let replaceIndex = -1;
            selection = selection.filter((sec, index) => {
                if (sec === section) {
                    replaceIndex = index;
                    return false;
                }
                return true;
            });
            section = _.clone(section);
            if (Array.isArray(section.value)) {
                if (section.value.indexOf(value) === -1) {
                    section.value = [...section.value, value];
                }
            } else {
                // If we want to not change grouping
                // Move replaceIndex up into the array if
                // Change this section to set the value
                // And then add the new section instead of replacing one?
                // Doesn't work, modern addition also starts with a single non-array value
                // So, would need to check if there are many sections for the same field
                // And they all have a single non-array value
                if (section.value !== value) {
                    section.value = [section.value, value];
                }
            }
            if (replaceIndex > -1) {
                selection.splice(replaceIndex, 0, section);
            }
        }

        const isCheckbox =
            sectionDef.type === SectionType.CATEGORY &&
            this.getSectionDefinition(sectionDef.id).kind === CATEGORY_BOOLEAN_KIND;
        // If option is a property or category checkbox, remove the previously selected value if one was selected
        if (sectionDef.type === SectionType.PROPERTY || isCheckbox) {
            selection = selection.filter(
                (item) => !(_.isEqual(item.section, sectionDef) && item.value !== value)
            );
        }

        selection = _.sortBy(selection, (item) => item.section.type);

        if (
            selection.filter((item) => _.isEqual(item.section, sectionDef)).length >=
            this.state.availableOptions.length
        ) {
            ReactDOM.findDOMNode(this.refs.chosen).focus();
        }

        this.setState({ selection, activeOption });
    },

    handleSelectedKeyDown(event) {
        const selection = this.state.selection;

        if (_.contains([Keyboard.DOWN, Keyboard.UP], event.keyCode)) {
            let index = -1;
            if (event.target.value) {
                const current = JSON.parse(event.target.value);
                index = selection.findIndex(
                    (item) =>
                        _.isEqual(item.section, current.section) && item.value === current.value
                );
            }

            if (event.keyCode === Keyboard.UP) {
                index = index - 1;
                if (index < 0) {
                    index = selection.length - 1;
                }
            } else if (event.keyCode === Keyboard.DOWN) {
                index = (index + 1) % selection.length;
            }

            this.setState({
                activeSelected: JSON.stringify(_.pick(selection[index], ["section", "value"])),
            });
        }

        if (event.keyCode === Keyboard.LEFT) {
            ReactDOM.findDOMNode(this.refs.option).focus();
        }

        if (event.keyCode === Keyboard.RIGHT) {
            ReactDOM.findDOMNode(this.refs.category).focus();
        }

        if (event.keyCode === Keyboard.ENTER) {
            if (!event.target.value) {
                ReactDOM.findDOMNode(this.refs.option).focus();
                return;
            }

            this.handleSelectedChange(event, true);
        }

        if (
            _.contains(
                Object.keys(Keyboard).map((key) => Keyboard[key]),
                event.keyCode
            )
        ) {
            event.preventDefault();
        }
    },

    handleSelectedChange(event, selectNextOption = false) {
        if (
            !event.target.getAttribute("data-value") &&
            (event.target.value === undefined || event.target.value === "")
        ) {
            // A "headline" was clicked, do nothing
            return;
        }
        // Handle clicking of match all/one headlines
        const dataValue = event.target.getAttribute("data-value");
        if (dataValue) {
            if (dataValue.indexOf("MATCH_ONE:") === 0) {
                const id = parseInt(dataValue.split(":")[1], 10);
                const matchAllFields = this.state.matchAll ? [...this.state.matchAll] : [];
                this.setState({ matchAll: matchAllFields.filter((field) => field !== id) });
                return;
            }
            if (dataValue.indexOf("MATCH_ALL:") === 0) {
                const id = parseInt(dataValue.split(":")[1], 10);
                const matchAllFields = this.state.matchAll ? [...this.state.matchAll] : [];
                if (matchAllFields.indexOf(id) === -1) {
                    matchAllFields.push(id);
                }
                this.setState({ matchAll: matchAllFields });
                return;
            }
        }
        try {
            const { value, section } = JSON.parse(event.target.value);
            const hasValue = (arrayOrItem, value) => {
                if (!Array.isArray(arrayOrItem)) {
                    return arrayOrItem === value;
                }
                return arrayOrItem.indexOf(value) !== -1;
            };
            const index = this.state.selection.findIndex(
                (item) => _.isEqual(item.section, section) && hasValue(item.value, value)
            );
            const firstInNextSectionIndex = this.state.selection.findIndex(
                (item) => _.isEqual(item.section, section) && !hasValue(item.value, value)
            );

            let activeSelected = undefined;
            if (selectNextOption) {
                let nextActive = {};
                if (index > 0) {
                    nextActive = this.state.selection[Math.max(index - 1, firstInNextSectionIndex)];
                } else {
                    nextActive = this.state.selection[0];
                }

                activeSelected = JSON.stringify(_.pick(nextActive, ["section", "value"]));
            }

            // Handle a section with multiple values as well as single ones
            const selection = this.state.selection
                .map((item) => {
                    if (!_.isEqual(item.section, section)) {
                        return item;
                    }
                    if (!hasValue(item.value, value)) {
                        return item;
                    }
                    if (item.value === value) {
                        return null;
                    }
                    return {
                        section: item.section,
                        value: item.value.filter((val) => val !== value),
                        label: item.label,
                    };
                })
                .filter((item) => {
                    if (item === null) {
                        return false;
                    }
                    if (Array.isArray(item.value) && item.value.length === 0) {
                        return false;
                    }
                    return true;
                });

            if (selection.length === 0) {
                ReactDOM.findDOMNode(this.refs.option).focus();
            }

            this.setState({ activeSelected, selection });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    },

    handleSearchStringChange(event) {
        this.setState({ searchString: event.target.value });
        this.getOptions(
            this.state.activeSection,
            event.target.value,
            (availableOptions, isComplete) => {
                this.setState({
                    availableOptions,
                    hasMoreOptions: !isComplete,
                });
            }
        );
    },

    getPropertySectionName(key) {
        if (key === "reserve") {
            return Language.get("dynamic_object_list_status");
        }
        return Language.get(`dynamic_object_list_${key}_item`);
    },

    getSectionDefinition(categoryId) {
        const id = parseInt(categoryId, 10);

        if (isNaN(id)) {
            const typeId = parseInt(categoryId.replace(TYPE_PREFIX, ""), 10);
            const typeDefinition =
                _.find(this.state.associatedTypes, (type) => type.id === typeId) || {};
            return {
                id: categoryId,
                name: typeDefinition.name || "",
                kind: SectionType.ASSOCIATION,
                multiple: false,
            };
        }

        if (id >= 0) {
            const categories = this.props.categories.map((category) => ({
                id: category.id,
                name: category.name,
                kind: category.kind,
                multiple: category.multiple || false,
            }));
            const result = _.find(categories, (category) => category.id === id);
            if (result) {
                return result;
            }
        }
        const allProps = getProperties();
        const key = _.find(Object.keys(allProps), (category) => allProps[category].id === id);
        const name = this.getPropertySectionName(key);
        return { id, name, kind: SectionType.PROPERTY };
    },

    getPropertyValue(section, value) {
        const allProps = getProperties();
        const key = _.find(
            Object.keys(allProps),
            (category) => allProps[category].id === section.id
        );
        const items = allProps[key].options.filter((item) => !item.hidden);
        const property = _.find(
            items,
            (item) => item.value === value || item.value === parseInt(value, 10)
        );
        return property ? property.value : undefined;
    },

    isDefaultSetting(key, values) {
        if (values.length === 0) {
            return true;
        }

        const sectionDef = _.isObject(key) ? key : this.getSectionDef(key);
        if (sectionDef.type !== SectionType.PROPERTY) {
            return false;
        }

        const allProps = getProperties();
        const categoryName = _.find(
            Object.keys(allProps),
            (category) => allProps[category].id === sectionDef.id
        );
        const defaultValue = _.find(allProps[categoryName].options, (item) => item.isDefault).value;

        return values.length === 1 && values[0] === defaultValue;
    },

    onChange(settings) {
        addAssociatedObjectLabels(this.getStateFromSettings(settings), (result) => {
            this.setState(result);
        });
    },

    getNamedSearchesKey() {
        return `objectSearch.type${this.props.type}.namedSearches`;
    },

    saveSettingsWithName(searchName = undefined, callback = _.noop) {
        const cb = _.isFunction(searchName) ? searchName : callback;
        let name = _.isFunction(searchName) ? undefined : searchName;
        // eslint-disable-next-line no-alert
        name = name || window.prompt(Language.get("nc_reserv_list_search_name"));

        if (!name) {
            cb(false);
            return;
        }

        this.getSavedSettings((currentData) => {
            const newData = currentData
                .filter((search) => search.name !== name)
                .concat({ name, settings: this.getSettingsObject() });
            newData.sort((a, b) => a.name.localeCompare(b.name));
            API.setPreferences(this.getNamedSearchesKey(), [JSON.stringify(newData)], (savedOk) => {
                if (!savedOk) {
                    Log.error("nc_list_search_settings_could_not_be_saved_as", name);
                    cb(savedOk);
                }
                Log.info(Language.get("nc_list_search_settings_saved_as", name));
                cb(savedOk);
                if (this.props.onSavedSettingsChanged) {
                    this.props.onSavedSettingsChanged(name);
                }
            });
        });
    },

    removeSettings(name, callback = _.noop) {
        if (!name) {
            return;
        }

        this.getSavedSettings((currentData) => {
            const newData = currentData.filter((search) => search.name !== name);
            API.setPreferences(this.getNamedSearchesKey(), [JSON.stringify(newData)], (savedOk) => {
                if (!savedOk) {
                    //debugger;
                }
                callback(savedOk);
                if (this.props.onSavedSettingsChanged) {
                    this.props.onSavedSettingsChanged(name);
                }
            });
        });
    },

    getSavedSettings(callback) {
        API.getPreferences(this.getNamedSearchesKey(), undefined, undefined, (dataString) => {
            if (!dataString) {
                callback([]);
                return;
            }
            try {
                callback(errorCorrectLoadedSettings(JSON.parse(dataString as any)));
            } catch (ignore) {
                callback([]);
            }
        });
    },

    isMatchAllActive(group) {
        return (
            group.length === 1 ||
            !group.some((section) => Array.isArray(section.value) && section.value.length > 1)
        );
    },

    render() {
        const categorySelector = null;
        const availableSections = this._renderAvailableSections();
        const availableOptions = this._renderAvailableOptions();
        const selectedOptions = this._renderSelectedOptions();

        if (availableSections.length === 0) {
            return (
                <div className="settingsContainer">
                    <div className="closeButton" onClick={this.props.onClose} />
                    {categorySelector}
                </div>
            );
        }

        let hasMoreOptionsIndicator = null;
        if (this.state.hasMoreOptions) {
            hasMoreOptionsIndicator = (
                <option disabled={true}>
                    {Language.get("nc_object_select_settings_search_for_more")}
                </option>
            );
        }
        const sectionString = JSON.stringify(this.state.activeSection || {});
        return (
            <div className="objectSelectSettings">
                <ListSettingsStore
                    searchSettings={this.getSettingsObject()}
                    onChange={this.onChange}
                    getSavedSettings={this.getSavedSettings}
                    saveSettings={this.saveSettingsWithName}
                    removeSettings={this.removeSettings}
                />

                <div className="objectSelectSettingsContainer">
                    <div className="settingsColumn">
                        <h3>{Language.get("nc_object_select_settings_headline_categories")}</h3>
                        <select
                            size="20"
                            ref="category"
                            onKeyDown={this.handleSectionKeyDown}
                            onChange={this.handleSectionChange}
                            value={sectionString}
                        >
                            {availableSections}
                        </select>
                    </div>

                    <div className="settingsColumn" style={{ flexGrow: 1.25 }}>
                        <h3>{Language.get("nc_object_select_settings_headline_options")}</h3>
                        <input
                            type="text"
                            value={this.state.searchString}
                            key={sectionString}
                            onChange={this.handleSearchStringChange}
                            disabled={!this.isSearchAvailable()}
                        />
                        <select
                            size="20"
                            ref="option"
                            onKeyDown={this.handleOptionKeyDown}
                            onChange={this.handleOptionChange}
                            onClick={this.handleOptionChange}
                            value={this.state.activeOption}
                        >
                            {availableOptions}
                            {hasMoreOptionsIndicator}
                        </select>
                    </div>

                    <div className="settingsColumn" style={{ flexGrow: 1.25 }}>
                        <h3>{Language.get("nc_object_select_settings_headline_selected")}</h3>
                        <select
                            size="20"
                            ref="chosen"
                            onKeyDown={this.handleSelectedKeyDown}
                            onChange={this.handleSelectedChange}
                            onClick={this.handleSelectedChange}
                            value={this.state.activeSelected}
                        >
                            {selectedOptions}
                        </select>
                    </div>
                </div>

                <div className="objectSelectSettingsButtons">
                    <button
                        className="default"
                        onClick={this.handleClear}
                        disabled={this.state.selection.length === 0}
                    >
                        {Language.get("nc_dynamic_object_list_clear_search_settings")}
                    </button>

                    <button className="default" onClick={this.props.onClose}>
                        {Language.get("menu_close")}
                    </button>
                </div>
            </div>
        );
    },

    _renderAvailableSections() {
        // TODO: User getAllSectionDefs() and map names using e.g. getSectionName(sectionDef)
        const toDef = (id) => JSON.stringify(this.getSectionDef(id));
        const availableSections = [];
        if (this.props.categories && this.props.categories.length > 0) {
            availableSections.push(
                <optgroup
                    title={Language.get("nc_object_select_settings_object_categories")}
                    key="objectCategories"
                    label={Language.get("nc_object_select_settings_object_categories")}
                >
                    {this.props.categories.map((item) => (
                        <option title={item.name} key={item.id} value={toDef(item.id)}>
                            {item.name}
                        </option>
                    ))}
                </optgroup>
            );
        }

        if (this.state.associatedTypes.length > 0) {
            availableSections.push(
                <optgroup
                    title={Language.get("nc_object_select_settings_membership")}
                    key="associations"
                    label={Language.get("nc_object_select_settings_membership")}
                >
                    {this.state.associatedTypes.map((item) => (
                        <option
                            title={item.name}
                            key={`${TYPE_PREFIX}${item.id}`}
                            value={toDef(`${TYPE_PREFIX}${item.id}`)}
                        >
                            {item.name}
                        </option>
                    ))}
                </optgroup>
            );
        }

        const allProps = getProperties();
        availableSections.push(
            <optgroup
                title={Language.get("nc_object_select_settings_properties")}
                key="properties"
                label={Language.get("nc_object_select_settings_properties")}
            >
                {Object.keys(allProps).map((key) => (
                    <option
                        title={this.getPropertySectionName(key)}
                        key={key}
                        value={toDef(allProps[key].id)}
                    >
                        {this.getPropertySectionName(key)}
                    </option>
                ))}
            </optgroup>
        );

        return availableSections;
    },

    _renderAvailableOptions() {
        return this.getVisibleOptions().map((categoryValue) => (
            <option
                title={categoryValue.label}
                key={`${this.state.activeSection.type}-${this.state.activeSection.id}-${categoryValue.value}`}
                value={categoryValue.value}
            >
                {categoryValue.label}
            </option>
        ));
    },

    _renderSelectedOptions() {
        const groups = _.groupBy(
            this.state.selection,
            (item) => `${item.section.type}:${item.section.id}`
        );
        return Object.keys(groups).map((key) => {
            let [type, id] = key.split(":");
            type = parseInt(type, 10);
            if (type === SectionType.ASSOCIATION) {
                id = `${TYPE_PREFIX}${id}`;
            }
            const categoryObject = this.getSectionDefinition(id);
            let categoryLabel = categoryObject.name;
            const sectionDef = first(groups[key]).section;

            let matchValue;
            if (this.isMatchAllActive(groups[key]) && categoryObject.multiple) {
                if ((this.state.matchAll || []).indexOf(categoryObject.id) === -1) {
                    categoryLabel = `${categoryObject.name} (${Language.get(
                        "nc_category_match_one"
                    )})`;
                    matchValue = `MATCH_ALL:${categoryObject.id}`;
                } else {
                    categoryLabel = `${categoryObject.name} (${Language.get(
                        "nc_category_match_all"
                    )})`;
                    matchValue = `MATCH_ONE:${categoryObject.id}`;
                }
            }
            let categoryTitle = categoryLabel;
            if (matchValue) {
                categoryTitle = `${categoryLabel} (${Language.get(
                    "nc_category_match_click_to_toggle"
                )})`;
            }

            const result = [];
            result.push(
                groups[key]
                    .filter((item) => _.isEqual(item.section, sectionDef))
                    .filter((item) => Array.isArray(item.value))
                    .map((item, index) => (
                        <optgroup
                            title={categoryTitle}
                            key={`${key}+${index}sel`}
                            data-value={matchValue ? matchValue : null}
                            label={categoryLabel}
                            className={matchValue ? "interactive" : null}
                        >
                            {item.value.map((value) => (
                                <option
                                    title={item.label}
                                    key={`${key + value}sel`}
                                    value={JSON.stringify({
                                        section: sectionDef,
                                        value,
                                    })}
                                >
                                    {value}
                                </option>
                            ))}
                        </optgroup>
                    ))
            );
            const remaining = groups[key]
                .filter((item) => _.isEqual(item.section, sectionDef))
                .filter((item) => !Array.isArray(item.value));
            if (remaining.length > 0) {
                result.push(
                    <optgroup
                        title={categoryTitle}
                        key={key}
                        data-value={matchValue ? matchValue : null}
                        label={categoryLabel}
                        className={matchValue ? "interactive" : null}
                    >
                        {remaining.map((item) => (
                            <option
                                title={item.label}
                                key={`${key + item.value}sel`}
                                value={JSON.stringify({
                                    section: sectionDef,
                                    value: item.value,
                                })}
                            >
                                {item.label}
                            </option>
                        ))}
                    </optgroup>
                );
            }
            return result;
        });
    },
});

ObjectSelectSettings.getProperties = getProperties;

export default ObjectSelectSettings;
