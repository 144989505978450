import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import _ from "underscore";

const POPOVER_STYLE = {
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 12,
    display: "block",
};

const wrap = function (Component) {
    return class extends React.Component {
        _isPopoverMounted?: boolean;
        _getLayerContent: any;
        _onClose?: () => void;
        _parent?: HTMLElement;
        _popover?: React.ReactInstance | null;

        static displayName = Component.displayName;

        static contextTypes = {
            user: PropTypes.object,
        };

        state = {
            showLayer: false,
        };

        componentDidUpdate(prevProps, prevState) {
            if (!prevState.showLayer && this.state.showLayer) {
                this._renderLayer();
            } else if (this.state.showLayer && !this._isPopoverMounted) {
                this._renderLayer();
            }

            if (prevState.showLayer && !this.state.showLayer) {
                this._unrenderLayer();
            } else if (!this.state.showLayer && this._isPopoverMounted) {
                this._unrenderLayer();
            }
        }

        componentWillUnmount() {
            this._unrenderLayer();
        }

        showLayer = () => {
            if (this.state.showLayer === true) {
                return;
            }
            this.setState({
                showLayer: true,
            });
        };

        hideLayer = () => {
            if (this.state.showLayer === false) {
                return;
            }
            this.setState({
                showLayer: false,
            });
        };

        toggleLayer = () => {
            this.setState({
                showLayer: !this.state.showLayer,
            });
        };

        setLayerContentProvider = (provider) => {
            this._getLayerContent = provider;
        };

        setCloseHandler = (handler: (...args) => void) => {
            this._onClose = handler;
        };

        _windowCloseHandler = () => {
            this.setState({ showLayer: false });
            if (this._onClose) {
                this._onClose();
            }
        };

        _windowClickHandler = (event: MouseEvent | TouchEvent) => {
            if (event.ctrlKey) {
                return;
            }

            let target: HTMLElement | null = event.target as HTMLElement;
            const thisNode = ReactDOM.findDOMNode(this);
            const popNode = ReactDOM.findDOMNode(this._popover);
            while (target !== null) {
                if (target === thisNode || (this._isPopoverMounted && target === popNode)) {
                    return;
                }
                if (target === this._parent) {
                    this._windowCloseHandler();
                }
                target = (target as HTMLElement).parentNode as HTMLElement;
            }
            //this._windowCloseHandler();
        };

        _renderLayer = () => {
            const popover: React.ReactElement<{ noClickToClose: boolean }> = React.cloneElement(
                this._getLayerContent(),
                { ref: this.setPopover }
            ) as any;
            this._parent = document.createElement("div");
            if (!popover.props.noClickToClose) {
                this._parent.removeEventListener("mousedown", this._windowClickHandler, true);
                this._parent.removeEventListener("touchend", this._windowClickHandler, true);
                this._parent.addEventListener("mousedown", this._windowClickHandler, true);
                this._parent.addEventListener("touchend", this._windowClickHandler, true);
            }
            Object.keys(POPOVER_STYLE).forEach((prop) => {
                if (this._parent) {
                    this._parent.style[prop] = POPOVER_STYLE[prop];
                }
            });
            document.body.appendChild(this._parent);
            ReactDOM.render(popover, this._parent);
            this._isPopoverMounted = true;
        };

        forceLayerUpdate = () => {
            if (!this._isPopoverMounted) {
                return;
            }
            const popover = React.cloneElement(this._getLayerContent(), { ref: this.setPopover });
            ReactDOM.render(popover, this._parent);
        };

        _unrenderLayer = () => {
            window.removeEventListener("mousedown", this._windowClickHandler, true);
            window.removeEventListener("touchend", this._windowClickHandler, true);

            if (this._parent) {
                this._isPopoverMounted = false;
                ReactDOM.unmountComponentAtNode(this._parent);
                document.body.removeChild(this._parent);
                this._parent = undefined;
            }
        };

        setPopover = (popover: React.ReactInstance | null) => {
            this._popover = popover;
        };

        render() {
            return (
                <Component
                    {...this.props}
                    showLayer={this.showLayer}
                    hideLayer={this.hideLayer}
                    toggleLayer={this.toggleLayer}
                    isLayerShown={this.state.showLayer}
                    setLayerContentProvider={this.setLayerContentProvider}
                    setLayerCloseHandler={this.setCloseHandler}
                    forceLayerUpdate={this.forceLayerUpdate}
                />
            );
        }

        shouldComponentUpdate(nextProps, nextState, nextContext) {
            return (
                !_.isEqual(this.props, nextProps) ||
                !_.isEqual(this.context, nextContext) ||
                this.state.showLayer !== nextState.showLayer
            );
        }
    };
};

export default { wrap };
