import { Menu } from "./Menu";
import { Model } from "./Model";
import { Selection } from "./Selection";
import { User } from "./User";
import { View } from "./View";
import { ITEToken } from "@timeedit/types/lib";

export class App extends Model {
    menu: Menu | null;
    view: View | null;
    user: User | null;
    selection: Selection;
    _transient: string[];
    token?: ITEToken;
    logoutAuth?: boolean;

    constructor(menu?: Menu, view?: View | null, user?: User) {
        super("App");
        this.menu = menu || null;
        this.view = view || null;
        this.user = user || null;
        this.selection = new Selection();
        this._transient = ["menu"];
    }

    setView(view: any) {
        return this.immutableSet({ view });
    }

    asProps() {
        // eslint-disable-next-line no-unused-vars
        const { _transient, _typeName, _modelId, ...props } = this; // jshint ignore:line
        return props;
    }
}
