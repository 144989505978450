import React from "react";

// Migration to class component with 'react-codemod' failed, explained here:
// https://github.com/reactjs/react-codemod/blob/master/LEGACY.md#explanation-of-the-new-es2015-class-transform-with-property-initializers
import createReactClass from "create-react-class";

import PropTypes from "prop-types";
import Log from "../lib/Log";
import { MillenniumDateTime, SimpleDateFormat } from "@timeedit/millennium-time";
import _ from "underscore";
import RC from "../lib/ReservationConstants";
import { TimeConstants as TC } from "../lib/TimeConstants";
import ReservationStatus from "../lib/ReservationStatus";
import Language from "../lib/Language";
import { Header } from "../models/Header";
const Size = Header.Label;
import TimeEditAPI from "../lib/TimeEditAPI";
import { Table } from "@timeedit/tecore-table";
import ErrorBoundary from "./ErrorBoundary";
import ContextMenu from "../lib/ContextMenu";
import ColumnConstants from "../lib/ColumnConstants";

const {
    TYPE_COLUMN_OFFSET,
    getCollisionColumn,
    RESERVATION_SORT_ORDERS,
    INFO_COLLISION,
    collisionCellRenderer,
} = ColumnConstants;

const DEFAULT_LIST_ID = 6;

const LABEL_SIZE_CUTOFFS = {
    MINIMUM: 10,
    XS: 30,
    S: 40,
    M: 60,
    L: 110,
};

const sortBy = (sortOrder, a, b) => {
    if (sortOrder === RESERVATION_SORT_ORDERS.ID_ASC) {
        return b.id - a.id;
    }
    if (sortOrder === RESERVATION_SORT_ORDERS.ID_DESC) {
        return a.id - b.id;
    }
    if (sortOrder === RESERVATION_SORT_ORDERS.TIME_ASC) {
        return b.begin - a.begin;
    }
    if (sortOrder === RESERVATION_SORT_ORDERS.TIME_DESC) {
        return a.begin - b.begin;
    }
    if (sortOrder === RESERVATION_SORT_ORDERS.CREATED_ASC) {
        return b.created - a.created;
    }
    if (sortOrder === RESERVATION_SORT_ORDERS.CREATED_DESC) {
        return a.created - b.created;
    }
    if (sortOrder === RESERVATION_SORT_ORDERS.MODIFIED_ASC) {
        return b.modified - a.modified;
    }
    if (sortOrder === RESERVATION_SORT_ORDERS.MODIFIED_DESC) {
        return a.modified - b.modified;
    }
    return 0;
};

const MAX_RESERVATIONS_PER_CALL = 1000;

const StaticReservationList = createReactClass({
    displayName: "StaticReservationList",

    contextTypes: {
        user: PropTypes.object,
        fireEvent: PropTypes.func,
        update: PropTypes.func,
        presentModal: PropTypes.func,
        customWeekNames: PropTypes.array,
    },

    getInitialState() {
        return {
            columnWidths: [],
            selectedColumns: this.props.defaultSelectedColumns,
            loadedReservations: [],
        };
    },

    componentDidMount() {
        this._isMounted = true;
        TimeEditAPI.findTypes((types) => this.setState({ types }));
        //this.props.setLayerContentProvider(this.getLayerContent);

        this.loadSettings(DEFAULT_LIST_ID, (newSettings) => {
            const allColumns = this.getAvailableColumns().map((column) => column.id);
            let selectedColumns = this.getInitialState().selectedColumns;
            if (newSettings.selectedColumns && (!selectedColumns || selectedColumns.length === 0)) {
                selectedColumns = newSettings.selectedColumns.filter(
                    (columnId) =>
                        columnId > TYPE_COLUMN_OFFSET || allColumns.indexOf(columnId) !== -1
                );
            }
            if (selectedColumns.length === 0) {
                selectedColumns = this.getInitialState().selectedColumns;
            }
            if (
                !newSettings.columnWidths ||
                selectedColumns.length !== newSettings.columnWidths.length
            ) {
                const width = 1 / selectedColumns.length;
                // eslint-disable-next-line no-param-reassign
                newSettings.columnWidths = _.fill(_.clone(selectedColumns), width);
            }
            // eslint-disable-next-line no-param-reassign
            newSettings.selectedColumns = selectedColumns;
            this.setState(newSettings);
            this.props.onSearchSettingsChange(
                _.omit(newSettings, ["selectedColumns", "columnWidths"])
            );
        });

        if (this.props.setRefreshFunction) {
            this.props.setRefreshFunction(this.find);
        }

        if (this.props.setRefreshFunction) {
            this.props.setRefreshFunction(this.find);
        }

        this.findReservations(
            this.state.sortOrder,
            this.state.sortColumn,
            this.onReservationsLoaded
        );
    },

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.reservations, this.props.reservations)) {
            this.findReservations(
                this.state.sortOrder,
                this.state.sortColumn,
                this.onReservationsLoaded
            );
        }
    },

    componentWillUnmount() {
        this._isMounted = false;
    },

    getSavedSettings(callback) {
        TimeEditAPI.getPreferences(
            this.getNamedSearchesKey(),
            undefined,
            undefined,
            (dataString) => {
                if (!dataString) {
                    callback([]);
                    return;
                }
                try {
                    callback(JSON.parse(dataString as any));
                } catch (ignore) {
                    callback([]);
                }
            }
        );
    },

    loadSettings(listId, callback) {
        TimeEditAPI.getPreferences("listSettings", [listId], undefined, (result) => {
            if (!result || result.length === 0) {
                callback({});
                return;
            }

            const newSettings = JSON.parse(result[0]);

            callback(_.omit(newSettings, ["beginTime", "endTime"]));
        });
    },

    onReservationsLoaded(reservations, numFound) {
        this.setState({ loadedReservations: reservations, totalNumber: numFound });
    },

    findReservations(sortOrder, sortColumn, cb) {
        let reservationSortOrder = this.getSortOrder(sortOrder, sortColumn);
        if (
            reservationSortOrder === RESERVATION_SORT_ORDERS.CREATED_ASC ||
            reservationSortOrder === RESERVATION_SORT_ORDERS.CREATED_DESC
        ) {
            // eslint-disable-next-line no-magic-numbers
            reservationSortOrder -= 4;
        }

        // What about reservationSortOrder and sorting over all?

        const onComplete = (result) => {
            if (!this._isMounted) {
                return;
            }

            const numFound = result.parameters ? result.parameters[2] : result.length;
            const newData = result.parameters ? result.parameters[0] : result;

            if (this.props.activeLayer !== 0) {
                TimeEditAPI.getReservationCollisions(this.props.activeLayer, (collisions) => {
                    this.setState({ collisions });
                });
            }

            cb(newData.sort(sortBy.bind(reservationSortOrder)), numFound);
        };

        let reservations = [];
        const calls = _.splitArray(
            this.props.reservations.map((res) => res.id),
            MAX_RESERVATIONS_PER_CALL
        ).map((batch) => (done) => {
            TimeEditAPI.getReservations(batch, (res) => {
                reservations = reservations.concat(res);
                done();
            });
        });
        _.runSync(calls, () => {
            onComplete(reservations);
        });
    },

    getSortOrder(sortOrder, sortColumn) {
        const ASC = 0;

        // 0 RES_SORT_ID_ASC,
        // 1 RES_SORT_ID_DESC,
        if (sortColumn === Language.get("cal_reservation_list_column_id")) {
            // ID
            return sortOrder;
        }
        // 2 RES_SORT_TIME_ASC,
        // 3 RES_SORT_TIME_DESC,
        if (sortColumn === Language.get("cal_reservation_list_column_date")) {
            // Date
            return sortOrder === ASC
                ? RESERVATION_SORT_ORDERS.TIME_ASC
                : RESERVATION_SORT_ORDERS.TIME_DESC;
        }
        if (
            this.props.searchOptions &&
            this.props.searchOptions.reservationVariant === RC.VARIANT.CANCELLED
        ) {
            if (sortColumn !== Language.get("cal_reservation_list_column_cancelled")) {
                // Cancelled column has ID 5
                return RESERVATION_SORT_ORDERS.ID_ASC;
            }
            return sortOrder === ASC
                ? RESERVATION_SORT_ORDERS.MODIFIED_ASC
                : RESERVATION_SORT_ORDERS.MODIFIED_DESC;
        }
        // 4 RES_SORT_MODIFIED_ASC,
        // 5 RES_SORT_MODIFIED_DESC
        if (sortColumn === Language.get("cal_reservation_list_column_modified")) {
            // Modified
            return sortOrder === ASC
                ? RESERVATION_SORT_ORDERS.MODIFIED_ASC
                : RESERVATION_SORT_ORDERS.MODIFIED_DESC;
        }
        // Made up values for created, transfer to 4 or 5 and set extra parameter useCreatedInsteadOfModified
        if (sortColumn === Language.get("cal_reservation_list_column_created")) {
            // Created
            return sortOrder === ASC
                ? RESERVATION_SORT_ORDERS.CREATED_ASC
                : RESERVATION_SORT_ORDERS.CREATED_DESC;
        }
        return RESERVATION_SORT_ORDERS.ID_ASC;
    },

    isSortable(sortColumn) {
        return (
            sortColumn === Language.get("cal_reservation_list_column_id") ||
            sortColumn === Language.get("cal_reservation_list_column_date") ||
            sortColumn === Language.get("cal_reservation_list_column_cancelled") ||
            (sortColumn === Language.get("cal_reservation_list_column_modified") &&
                this.props.searchOptions.reservationVariant === RC.VARIANT.CANCELLED) ||
            sortColumn === Language.get("cal_reservation_list_column_modified") ||
            sortColumn === Language.get("cal_reservation_list_column_created")
        );
    },

    getLabelSize(size) {
        if (size <= LABEL_SIZE_CUTOFFS.MINIMUM) {
            return null;
        }
        if (size < LABEL_SIZE_CUTOFFS.XS) {
            return Size.XS;
        }
        if (size < LABEL_SIZE_CUTOFFS.S) {
            return Size.S;
        }
        if (size < LABEL_SIZE_CUTOFFS.M) {
            return Size.M;
        }
        if (size < LABEL_SIZE_CUTOFFS.L) {
            return Size.L;
        }
        return Size.XL;
    },

    getTime(time, size, isEnd) {
        switch (this.getLabelSize(size)) {
            case Size.M:
                return SimpleDateFormat.format(
                    time,
                    isEnd
                        ? Language.getDateFormat("date_f_hh_end")
                        : Language.getDateFormat("date_f_hh")
                );
            default:
                return SimpleDateFormat.format(
                    time,
                    isEnd
                        ? Language.getDateFormat("date_f_hh_mm_end")
                        : Language.getDateFormat("date_f_hh_mm")
                );
        }
    },

    getLongDate(date, size) {
        switch (this.getLabelSize(size)) {
            case Size.XS:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_d"));
            case Size.S:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_d"));
            case Size.M:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_m_d"));
            case Size.L:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_ee_m_d"));
            default:
                return SimpleDateFormat.format(
                    date,
                    Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm")
                );
        }
    },

    getCollisionsFor(reservationId) {
        if (!this.state || !this.state.collisions) {
            return false;
        }
        return _.find(this.state.collisions, (cln) => cln.id === reservationId);
    },

    // eslint-disable-next-line no-unused-vars
    getReservationMenuItems(selectedReservationIds, getDataAtIndex, callback, onMassReplace) {
        const items = [];
        // Removed everything in ReservationTypeList
        return items;
    },

    getColumnWidths() {
        return this.state.columnWidths;
    },

    getSelectedColumnIds() {
        const collisionColumn = getCollisionColumn();
        let selectedIds = this.state.selectedColumns;
        if (this.props.activeLayer !== 0 && !_.contains(selectedIds, collisionColumn.id)) {
            selectedIds = [].concat([collisionColumn.id]).concat(selectedIds);
        }
        return selectedIds;
        /*if (
            TemplateKind.equals(
                TemplateKind.INFO_RESERVATION,
                this.props.searchOptions.templateKind
            )
        ) {
            selectedIds = this.state.selectedInfoColumns;
        }*/
    },

    getSelectedColumns() {
        const columns = this.getAvailableColumns();
        return _.compact(
            this.getSelectedColumnIds().map((id) => _.find(columns, (column) => column.id === id))
        );
    },

    getActiveFluffy() {
        // Keep a Fluffy, or no need?
        return null;
    },

    getTypeName(typeId) {
        const type = _.find(this.state.types, (tp) => tp.id === typeId);
        return type ? type.name : String(typeId);
    },

    getAvailableColumns() {
        let columns = this.props.columns.map((column) => _.extend({ primary: true }, column));
        if (this.props.activeLayer > 0) {
            columns.push(getCollisionColumn());
        }

        const fluffy = this.getActiveFluffy();
        if (fluffy && this.props.addTypeColumns) {
            const types = _.uniq(fluffy.objectItems.map((item) => item.type.id));
            columns = columns.concat(
                types.map((type) => ({
                    id: TYPE_COLUMN_OFFSET + type,
                    name: this.getTypeName(type),
                }))
            );
        }

        // Add any selected type columns that does not exist in the fluffy
        const selectedIds = this.getSelectedColumnIds();
        const missingTypeColumns = selectedIds
            .filter((id) => id > TYPE_COLUMN_OFFSET)
            .filter((id) => !_.find(columns, (column) => column.id === id))
            .map((id) => ({
                id,
                name: this.getTypeName(id - TYPE_COLUMN_OFFSET),
            }));

        return columns.concat(missingTypeColumns);
    },

    getTableRow({ index }) {
        const reservation = this.state.loadedReservations[index];
        const columns = this.getSelectedColumns();
        const fillRow = (res) => {
            const row = {};
            if (res) {
                const labelData = this.getCellLabels(res, columns, this.getColumnWidths());
                row.color = labelData.color;
                row.bold = labelData.bold;
                row.textColor = labelData.textColor;
                row.italicize = labelData.italicize;
                columns.forEach((column, idx) => {
                    row[column.name] = labelData.labels[idx];
                });
            } else {
                columns.forEach((column) => {
                    row[column.name] = "";
                });
            }
            return row;
        };
        if (reservation) {
            return fillRow(reservation);
        }
        const row = {};
        columns.forEach((column) => {
            row[column.name] = "";
        });
        return row;
    },

    onTableColumnsWidthChange(columnWidths) {
        if (columnWidths.length === 0) {
            return;
        }

        this.setState({ columnWidths });
    },

    onTableColumnsChange(columns) {
        const columnIds = columns.map((column) => column.id);
        this.setState({ selectedColumns: columnIds });
    },

    getCellLabels(reservation, columns, columnWidths, columnTypeIds = []) {
        let color;
        let textColor;
        let bold;

        const labels = columns.map((column, i) => {
            if (!reservation) {
                return "";
            }
            if (column.name === Language.get("cal_reservation_list_column_id")) {
                return reservation.id;
            }
            const width = columnWidths[i];
            if (column.name === Language.get("cal_reservation_list_column_type_all")) {
                if (!reservation.objects) {
                    return "";
                }
                return reservation.objects
                    .filter((object) => !_.contains(columnTypeIds, object.type.id))
                    .map((object) => {
                        if (object.fields && object.fields[0].values) {
                            return object.fields[0].values[0];
                        }
                        return "-";
                    })
                    .join(", ");
            }
            if (column.id > TYPE_COLUMN_OFFSET) {
                if (!reservation.objects) {
                    return "";
                }
                const typeId = column.id - TYPE_COLUMN_OFFSET;
                return reservation.objects
                    .filter((object) => object.type.id === typeId)
                    .map((object) => {
                        if (object.fields && object.fields[0].values) {
                            return object.fields[0].values[0];
                        }
                        return "-";
                    })
                    .join(", ");
            }
            if (column.name === Language.get("cal_reservation_list_column_field_all")) {
                if (!reservation.fields) {
                    return "";
                }
                return reservation.fields
                    .map((field) => (field.values ? field.values.join(", ") : null))
                    .filter((val) => val !== "")
                    .join(", ");
            }
            let format;
            const startTime = reservation.begin ? new MillenniumDateTime(reservation.begin) : null;
            const endTime = reservation.end ? new MillenniumDateTime(reservation.end) : null;
            if (
                column.name === Language.get("cal_reservation_list_column_date") &&
                reservation.begin
            ) {
                if (startTime.daysBetween(endTime) > 0) {
                    // Different start and end date
                    format = Language.getDateFormat("date_f_m_d");
                    const start = SimpleDateFormat.format(startTime, format);
                    const end = SimpleDateFormat.format(
                        endTime,
                        Language.getDateFormat("date_f_m_d_end")
                    );
                    if (start !== end) {
                        return `${start}-${end}`;
                    }
                    return start;
                }
                format = Language.getDateFormat("date_f_yy_mm_dd");
                return SimpleDateFormat.format(startTime, format);
            }
            if (
                column.name === Language.get("cal_reservation_list_column_time") &&
                reservation.begin
            ) {
                return `${this.getTime(startTime, width, false)}-${this.getTime(
                    endTime,
                    width,
                    true
                )}`;
            }
            if (column.name === Language.get("cal_reservation_list_column_length")) {
                const length = reservation.length
                    ? reservation.length
                    : reservation.end - reservation.begin;
                if (length === 0 || isNaN(length)) {
                    return "0:00";
                }
                const hours = Math.floor(length / TC.SECONDS_PER_HOUR);
                const minutes = Math.floor((length % TC.SECONDS_PER_HOUR) / TC.SECONDS_PER_MINUTE);
                // eslint-disable-next-line no-magic-numbers
                return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
            }
            if (column.name === Language.get("cal_reservation_list_column_status")) {
                if (!reservation.status) {
                    return ReservationStatus.C_CONFIRMED.getStatusName(false);
                }

                let status = reservation.status;
                if (
                    _.some(
                        status,
                        (item) =>
                            item.status === RC.STATUS.REQUESTED ||
                            item.status === RC.STATUS.REJECTED
                    )
                ) {
                    status = status.filter((item) => item.status !== RC.STATUS.INCOMPLETE);
                }
                return status
                    .map((item) => ReservationStatus.statusForId(item.status).getStatusName(false))
                    .join(", ");
            }
            if (column.name === Language.get("cal_reservation_list_column_created")) {
                let created = "";
                if (reservation.created) {
                    const createdTime = new MillenniumDateTime(reservation.created || 0);
                    created = this.getLongDate(createdTime, width);
                }
                const createdBy = reservation.createdby ? `, ${reservation.createdby}` : "";
                return created + createdBy;
            }
            if (column.name === Language.get("cal_reservation_list_column_modified")) {
                let modified = "";
                if (reservation.modified) {
                    const modifiedTime = new MillenniumDateTime(reservation.modified || 0);
                    modified = this.getLongDate(modifiedTime, width);
                }
                const modifiedBy = reservation.modifiedby ? `, ${reservation.modifiedby}` : "";
                return modified + modifiedBy;
            }
            if (column.name === Language.get("cal_reservation_list_column_cancelled")) {
                if (reservation.cancelled) {
                    const cancelledTime = new MillenniumDateTime(reservation.cancelled || 0);
                    return this.getLongDate(cancelledTime, width);
                }
                return "";
            }
            if (column.name === Language.get("cal_reservation_list_column_week")) {
                return Language.formatWeekTableCell(
                    startTime,
                    endTime,
                    this.context.customWeekNames
                );
            }
            if (column.name === Language.get("cal_reservation_list_column_week_day")) {
                const start = SimpleDateFormat.format(startTime, "EE");
                const end = SimpleDateFormat.format(endTime, "LL");
                if (start === end) {
                    return start;
                }
                return `${start} - ${end}`;
            }

            if (column.name === Language.get("nc_mass_change_collisions")) {
                const col = this.getCollisionsFor(reservation.id);
                if (!col || col.collidingReservations.length === 0) {
                    if (col && col.collidingInfoReservations.length > 0) {
                        return INFO_COLLISION;
                    }
                    return "";
                }
                return `${col.collidingReservations.length}`;
            }

            if (column.name === Language.get("cal_res_side_email_message")) {
                const index = this.props.reservations.map((res) => res.id).indexOf(reservation.id);
                return this.props.messages[index] || "";
            }

            return "";
        });

        return { labels, color, textColor, bold };
    },

    onSelect(newSelectedId) {
        if (this.props.onSelection) {
            this.props.onSelection(this.state.loadedReservations[newSelectedId]);
        }
    },

    copyReservationsToClipboard() {
        navigator.clipboard.writeText(
            `#${this.props.reservations.map((res) => res.id).join(" #")}`
        );
    },

    render() {
        const isRowLoaded = () => true;
        const style = { width: "100%" };
        const cellRenderers = {};
        cellRenderers[TYPE_COLUMN_OFFSET] = collisionCellRenderer;
        return (
            <ErrorBoundary>
                <div style={{ position: "relative" }}>
                    <div
                        style={{
                            height: this.props.height || "100px",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Table
                            cellRenderers={cellRenderers}
                            language={Language}
                            log={Log}
                            presentModal={this.context.presentModal}
                            contextMenu={ContextMenu}
                            ref="table"
                            registerSelectAll={this.registerSelectAll}
                            setClearSelection={this._setClearSelection}
                            isRowLoaded={isRowLoaded}
                            loadMoreRows={this.loadMoreRows}
                            rowCount={this.props.reservations.length}
                            allColumns={this.getAvailableColumns()}
                            columns={this.getSelectedColumns()}
                            columnWidths={this.getColumnWidths()}
                            onColumnChange={this.onTableColumnsChange}
                            onColumnWidthChange={this.onTableColumnsWidthChange}
                            getHeaderMenuItems={this.getHeaderMenuItems}
                            columnButtonClick={this.columnButtonClick}
                            getTableRow={this.getTableRow}
                            getRowMenuItems={this.getRowMenuItems}
                            width={style.width}
                            onSelect={this.onSelect}
                            highlight={
                                this.props.allowMultiSelection
                                    ? Table.HIGHLIGHT.MULTI
                                    : Table.HIGHLIGHT.SINGLE
                            }
                            onSortingChanged={this.onSortingChanged}
                            isSortable={this.props.isSortable}
                            onDragStart={this.onDragStart}
                            scrollToIndex={this.state.scrollToIndex}
                            sortOrder={this.state.sortOrder}
                            sortColumn={this.state.sortColumn}
                            activeLayer={this.props.activeLayer}
                        />
                    </div>
                    <button
                        className="dialogCopyButton"
                        style={{
                            position: "absolute",
                            right: "10px",
                            bottom: "5px",
                            fontFamily: "FontAwesome",
                            border: "solid 1px #aaa",
                            width: "25px",
                        }}
                        onClick={this.copyReservationsToClipboard}
                        title={Language.get("nc_copy_reservations_to_clipboard")}
                    >
                        &#xf0ea;
                    </button>
                </div>
            </ErrorBoundary>
        );
    },
});

export default StaticReservationList;
