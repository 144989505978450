import { TimeEdit } from "../lib/TimeEdit";
import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";
import Calendar from "./Calendar";
import ReservationListCalendar from "./ReservationListCalendar";
import CancellationListCalendar from "./CancellationListCalendar";
import WaitingListCalendar from "./WaitingListCalendar";
import OrderListCalendar from "./OrderListCalendar";
import ConflictListCalendar from "./ConflictListCalendar";
import RequestListCalendar from "./RequestListCalendar";
import StaticReservationListCalendar from "./StaticReservationListCalendar";
import ResizeableComponent from "../lib/ResizeableComponent";
import PrefsComponentCalendar from "./preferences/PrefsComponentCalendar";
import ExamComponentCalendar from "./exam/ExamComponentCalendar";
import MemberObjectCreatorCalendar from "./memberobjectcreator/MemberObjectCreatorCalendar";
import { PrefsData } from "../models/PrefsData";
import { Macros } from "../models/Macros";

class Section extends React.Component {
    static contextTypes = {
        update: PropTypes.func,
        user: PropTypes.object,
        clearExamRequest: PropTypes.func,
        fireEvent: PropTypes.func,
    };

    componentDidMount() {
        this.props.setResizeEndCallback(this.onResizeEnd);
    }

    onResizeEnd = () => {
        const newWeight = Math.max(
            Math.min(
                this.getResizePosition() /
                    (this.props.data.hasVerticalLayout
                        ? this.props.size.height
                        : this.props.size.width),
                0.95
            ),
            0.05
        );

        TimeEdit.State.update(this.props.data, {
            weight: newWeight,
        });
    };

    getResizePosition = () => {
        const eventProp = this.props.data.hasVerticalLayout ? "y" : "x";
        const start = _.getClientPos(this.props.startPosition);
        const end = _.getClientPos(this.props.lastMousePosition);
        const diff = end[eventProp] - start[eventProp];
        const size = this.props.data.hasVerticalLayout
            ? this.props.size.height
            : this.props.size.width;
        const originalPosition = size * this.props.data.weight;

        return originalPosition + diff;
    };

    closeSection = (first) => {
        const model = first ? this.props.data.first.first : this.props.data.second.first;
        if (model instanceof PrefsData) {
            this.context.fireEvent("Section", Macros.Event.AM_CLOSED);
        }
        this.context.update(this.props.data, this.props.data.close(first));
        this.props.onActiveCalendarChange(0);
    };

    split = (splitHorizontally: boolean) => {
        this.context.update(this.props.data, this.props.data.split(splitHorizontally));
    };

    toList = () => {
        this.context.update(this.props.data, this.props.data.toList(this.props.selection));
    };

    toCancellationList = () => {
        this.context.update(
            this.props.data,
            this.props.data.toCancellationList(this.props.selection)
        );
    };

    toOrderList = () => {
        this.context.update(this.props.data, this.props.data.toOrderList(this.props.selection));
    };

    toConflictList = () => {
        this.context.update(this.props.data, this.props.data.toConflictList(this.props.selection));
    };

    toWaitingList = () => {
        this.context.update(this.props.data, this.props.data.toWaitingList(this.props.selection));
    };

    toRequestList = () => {
        this.context.update(this.props.data, this.props.data.toRequestList(this.props.selection));
    };

    toCalendar = () => {
        this.context.update(this.props.data, this.props.data.toCalendar(this.props.selection));
    };

    toPrefsComponent = () => {
        this.context.update(
            this.props.data,
            this.props.data.toPrefsComponent(this.props.selection)
        );
    };

    toExamComponent = () => {
        this.context.clearExamRequest(() => {
            this.context.update(
                this.props.data,
                this.props.data.toExamComponent(this.props.selection)
            );
        });
    };

    toMemberCreator = () => {
        this.context.update(this.props.data, this.props.data.toMemberCreator(this.props.selection));
    };

    render() {
        const model = this.props.data;
        if (!model) {
            return <div />;
        }
        const id = this.props.getCalendarIndex(model.first);

        if (model.hasCalendar()) {
            return (
                <Calendar
                    {...this.props}
                    key={id}
                    data={model.first}
                    id={id}
                    showId={this.props.showCalendarIds}
                    isActive={id === this.props.activeCalendarId}
                    toList={this.toList}
                    toCancellationList={this.toCancellationList}
                    toOrderList={this.toOrderList}
                    toWaitingList={this.toWaitingList}
                    toConflictList={this.toConflictList}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                    onSplit={this.split}
                    toRequestList={this.toRequestList}
                />
            );
        }

        if (model.hasReservationList()) {
            return (
                <ReservationListCalendar
                    {...this.props}
                    data={model.first}
                    key={id}
                    id={id}
                    className="embeddedList"
                    style={this.props.size}
                    toCalendar={this.toCalendar}
                    toCancellationList={this.toCancellationList}
                    toOrderList={this.toOrderList}
                    toWaitingList={this.toWaitingList}
                    toConflictList={this.toConflictList}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                    toRequestList={this.toRequestList}
                    onSplit={this.split}
                    showId={this.props.showCalendarIds}
                    isActive={id === this.props.activeCalendarId}
                    onInfoOpen={this.props.onEntryInfoOpen}
                    onEditReservationExceptions={this.props.onEditReservationExceptions}
                />
            );
        }

        if (model.hasCancellationList()) {
            return (
                <CancellationListCalendar
                    {...this.props}
                    data={model.first}
                    key={id}
                    id={id}
                    className="embeddedList"
                    style={this.props.size}
                    toCalendar={this.toCalendar}
                    toList={this.toList}
                    toOrderList={this.toOrderList}
                    toWaitingList={this.toWaitingList}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                    toRequestList={this.toRequestList}
                    toConflictList={this.toConflictList}
                    onSplit={this.split}
                    showId={this.props.showCalendarIds}
                    isActive={id === this.props.activeCalendarId}
                    onInfoOpen={this.props.onEntryInfoOpen}
                />
            );
        }

        if (model.hasWaitingList()) {
            return (
                <WaitingListCalendar
                    {...this.props}
                    data={model.first}
                    key={id}
                    id={id}
                    className="embeddedList"
                    style={this.props.size}
                    toCalendar={this.toCalendar}
                    toList={this.toList}
                    toCancellationList={this.toCancellationList}
                    toOrderList={this.toOrderList}
                    toRequestList={this.toRequestList}
                    toConflictList={this.toConflictList}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                    onSplit={this.split}
                    showId={this.props.showCalendarIds}
                    isActive={id === this.props.activeCalendarId}
                    onInfoOpen={this.props.onEntryInfoOpen}
                    onEditReservationExceptions={this.props.onEditReservationExceptions}
                />
            );
        }

        if (model.hasOrderList()) {
            return (
                <OrderListCalendar
                    {...this.props}
                    data={model.first}
                    key={id}
                    id={id}
                    className="embeddedList"
                    style={this.props.size}
                    toCalendar={this.toCalendar}
                    toList={this.toList}
                    toCancellationList={this.toCancellationList}
                    toWaitingList={this.toWaitingList}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                    toRequestList={this.toRequestList}
                    toConflictList={this.toConflictList}
                    onSplit={this.split}
                    showId={this.props.showCalendarIds}
                    isActive={id === this.props.activeCalendarId}
                    onInfoOpen={this.props.onOrderInfoOpen}
                />
            );
        }

        if (model.hasRequestList()) {
            return (
                <RequestListCalendar
                    {...this.props}
                    data={model.first}
                    key={id}
                    id={id}
                    className="embeddedList"
                    style={this.props.size}
                    toCalendar={this.toCalendar}
                    toList={this.toList}
                    toCancellationList={this.toCancellationList}
                    toWaitingList={this.toWaitingList}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                    toOrderList={this.toOrderList}
                    toConflictList={this.toConflictList}
                    onSplit={this.split}
                    showId={this.props.showCalendarIds}
                    isActive={id === this.props.activeCalendarId}
                    onInfoOpen={this.props.onEntryInfoOpen}
                    onEditReservationExceptions={this.props.onEditReservationExceptions}
                />
            );
        }

        if (model.hasStaticReservations()) {
            return (
                <StaticReservationListCalendar
                    {...this.props}
                    data={model.first}
                    key={id}
                    id={id}
                    activeLayer={0}
                    className="embeddedList"
                    style={this.props.size}
                    toCalendar={this.toCalendar}
                    toCancellationList={this.toCancellationList}
                    toOrderList={this.toOrderList}
                    toWaitingList={this.toWaitingList}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                    toRequestList={this.toRequestList}
                    toConflictList={this.toConflictList}
                    onSplit={this.split}
                    selection={this.props.selection}
                    showId={this.props.showCalendarIds}
                    isActive={id === this.props.activeCalendarId}
                    reservationIds={model.first.reservationIds}
                    onInfoOpen={this.props.onEntryInfoOpen}
                />
            );
        }

        if (model.hasPrefsComponent()) {
            return (
                <PrefsComponentCalendar
                    {...this.props}
                    className="embeddedList"
                    data={model.first}
                    key={id}
                    id={id}
                    style={this.props.size}
                    toCalendar={this.toCalendar}
                    toList={this.toList}
                    toCancellationList={this.toCancellationList}
                    toOrderList={this.toOrderList}
                    toWaitingList={this.toWaitingList}
                    toRequestList={this.toRequestList}
                    toConflictList={this.toConflictList}
                    onSplit={this.split}
                    isActive={id === this.props.activeCalendarId}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                />
            );
        }

        if (model.hasExamComponent()) {
            return (
                <ExamComponentCalendar
                    {...this.props}
                    className="embeddedList"
                    data={model.first}
                    key={id}
                    id={id}
                    style={this.props.size}
                    toCalendar={this.toCalendar}
                    toList={this.toList}
                    toCancellationList={this.toCancellationList}
                    toOrderList={this.toOrderList}
                    toWaitingList={this.toWaitingList}
                    toRequestList={this.toRequestList}
                    toConflictList={this.toConflictList}
                    onSplit={this.split}
                    isActive={id === this.props.activeCalendarId}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                />
            );
        }

        if (model.hasConflictList()) {
            return (
                <ConflictListCalendar
                    {...this.props}
                    data={model.first}
                    key={id}
                    id={id}
                    className="embeddedList"
                    style={this.props.size}
                    toCalendar={this.toCalendar}
                    toList={this.toList}
                    toOrderList={this.toOrderList}
                    toCancellationList={this.toCancellationList}
                    toWaitingList={this.toWaitingList}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                    toRequestList={this.toRequestList}
                    onSplit={this.split}
                    showId={this.props.showCalendarIds}
                    isActive={id === this.props.activeCalendarId}
                    onInfoOpen={this.props.onEntryInfoOpen}
                />
            );
        }

        if (model.hasMemberCreator()) {
            return (
                <MemberObjectCreatorCalendar
                    {...this.props}
                    className="embeddedList"
                    data={model.first}
                    key={id}
                    id={id}
                    style={this.props.size}
                    toCalendar={this.toCalendar}
                    toList={this.toList}
                    toCancellationList={this.toCancellationList}
                    toOrderList={this.toOrderList}
                    toWaitingList={this.toWaitingList}
                    toRequestList={this.toRequestList}
                    toConflictList={this.toConflictList}
                    onSplit={this.split}
                    isActive={id === this.props.activeCalendarId}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                />
            );
        }

        const firstSize = _.clone(this.props.size);
        const secondSize = _.clone(this.props.size);
        const rulerStyle: React.CSSProperties = {};

        let rulerClass = "ruler";
        const layoutClass = ["section"];
        if (model.hasVerticalLayout) {
            firstSize.height = Math.floor(model.weight * firstSize.height);
            secondSize.height = secondSize.height - firstSize.height;
            layoutClass.push("verticalSection");
            rulerStyle.top = this.props.isResizing ? this.getResizePosition() : firstSize.height;
            rulerClass += " horizontalRuler";
        } else {
            firstSize.width = Math.floor(firstSize.width * model.weight);
            secondSize.width = secondSize.width - firstSize.width;
            layoutClass.push("horizontalSection");
            rulerStyle.left = this.props.isResizing ? this.getResizePosition() : firstSize.width;
            rulerClass += " verticalRuler";
        }

        if (this.props.isResizing) {
            layoutClass.push(`${model.hasVerticalLayout ? "vertical" : "horizontal"}Resize`);
        }

        return (
            <div className={layoutClass.join(" ")} style={this.props.size}>
                <ResizeableSection
                    {...this.props}
                    data={model.first}
                    size={firstSize}
                    onClose={this.closeSection.bind(this, true)}
                />
                <div
                    className={rulerClass}
                    style={rulerStyle}
                    onTouchStart={this.props.onResizeStart}
                    onMouseDown={this.props.onResizeStart}
                />
                <ResizeableSection
                    {...this.props}
                    data={model.second}
                    size={secondSize}
                    onClose={this.closeSection.bind(this, false)}
                />
            </div>
        );
    }
}

const ResizeableSection = ResizeableComponent.wrap(Section);
export default ResizeableSection;
