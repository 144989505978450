class APIError extends Error {
    code: number;

    constructor(message: string, code = 0) {
        super(message);
        this.name = "APIError";
        this.message = message || "The API call returned an error.";
        this.code = code;
        this.stack = new Error().stack;
    }
}

export default APIError;
