import { getUserLanguage } from "./LangUtils";

import React from "react";
import ReactDOM from "react-dom";
import API from "./TimeEditAPI";
import { MillenniumDate, MillenniumDateTime } from "@timeedit/millennium-time";
import DataStore from "./DataStore";
import Log from "./Log";
import FastClick from "fastclick";
import Locale from "../lib/Language";
import _ from "underscore";
import path from "path";
import { EAppId } from "@timeedit/types/lib/enums/appIds.enum";

import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

import * as Sentry from "@sentry/react";
import { ViewList } from "../models/ViewList";
import { Menu } from "../models/Menu";
import { App } from "../models/App";
import AppComponent from "../components/App";
import ErrorBoundary from "../components/ErrorBoundary";
import {
    AuthWrapper,
    getAccessToken,
} from "@timeedit/ui-components/lib/src/components/AuthWrapper/AuthWrapper";

import { ConfigProvider } from "antd";
import { TRefExtid } from "../types/api/shared";
import { IGetStartupDataResponse } from "../types/api/APIResponseTypes";
import { Scratchpad } from "../models/Scratchpad";
import { registerDevTools } from "./Devtools";
import { TColorDefClass } from "../types/api/APIClassTypes";

const getLdClientSideId = (env) => {
    console.log(env);
    switch (env) {
        case "development":
        case "beta":
            return "65e829cc7386bf105e2e28d5";
        case "staging":
            return "65e829836880eb10b9f4edca";
        // Production
        default:
            return "65a938f0183d141061d386f9";
    }
};

export type TTimeEditGlobals = {
    getCompletePropertyId(): number;
    presentShortcut(arg: string): unknown;
    isEmailActive?: boolean;
    reservationTextField?: TRefExtid[];
    locationTypes?: number[];
    rootType?: number;
    entryPropertyDefinitions: IGetStartupDataResponse["parameters"][0]["propertyDefs"];
    State: DataStore;
    API: typeof API;
    Log: typeof Log;
    Locale: typeof Locale;
    MillenniumDate: typeof MillenniumDate;
    MillenniumDateTime: typeof MillenniumDateTime;

    toDate: (dateNumber: number) => string;
    toDateTime: (mts) => string;
    toTime: (mts) => string;

    colorDefs?: TColorDefClass[];
    serverInfo?: IGetStartupDataResponse["parameters"][0]["versionInfo"];
    scratchpad?: Scratchpad;
};

export const TimeEdit: TTimeEditGlobals = (function () {
    const State = new DataStore({});

    let usingSSO = false;

    const AUTH_URLS = {
        development: process.env.AUTH_URL ?? "http://localhost:3000",
        beta: "https://auth-beta.timeedit.io",
        newBeta: "https://auth.timeedit.dev",
        staging: "https://auth.timeedit.io",
        test: "https://auth.test.timeedit.net",
        production: "https://auth.timeedit.net",
    };

    const getAuthURL = (env) => {
        if (!env) {
            return undefined;
        }
        if (env.serverEnv === "beta" && env.useDevDomainForBetaEnv) {
            return AUTH_URLS.newBeta;
        }
        return AUTH_URLS[env.serverEnv];
    };

    const extractSignatureFromPath = (path) => {
        const signature = path.split("/")[0];
        return `cloud_${signature}`;
    };

    const renderApp = async function (LDProvider, useSSO = false, env = undefined) {
        const onLogin = (token) => {
            // eslint-disable-next-line no-console
            console.log("Got token", token);
            const aToken = getAccessToken();
            // eslint-disable-next-line no-console
            console.log(aToken);
            API.loginWithSSOToken(aToken, (result) => {
                if (result[0] && result[0].class === "error") {
                    // eslint-disable-next-line no-console
                    console.log(result);
                    // eslint-disable-next-line no-console
                    console.log("Not authenticated");
                    TimeEdit.State.update(TimeEdit.State.get(), { token: null, logoutAuth: true });
                } else {
                    // eslint-disable-next-line no-console
                    console.log("SSO login result:", result);
                    if (result.parameters[0] === false) {
                        // eslint-disable-next-line no-console
                        console.log("Not authenticated");
                        TimeEdit.State.update(TimeEdit.State.get(), {
                            token: null,
                            logoutAuth: true,
                        });
                    } else {
                        TimeEdit.State.update(TimeEdit.State.get(), { token, logoutAuth: false });
                    }
                }
            });
        };

        const onLogout = () => {
            // eslint-disable-next-line no-console
            console.log("On logout");
            TimeEdit.State.update(TimeEdit.State.get(), { token: null });
        };

        usingSSO = useSSO;

        if (useSSO) {
            ReactDOM.render(
                <LDProvider>
                    <ConfigProvider>
                        <ErrorBoundary>
                            <AuthWrapper
                                onLoginSuccess={onLogin}
                                onLogout={onLogout}
                                authUrl={getAuthURL(env)}
                                appId={EAppId.TE_CORE}
                                customerSignature={extractSignatureFromPath(
                                    window.TIMEEDIT_APP_PATH
                                )}
                            >
                                <AppComponent
                                    {...(State.get() as App).asProps()}
                                    usingSSO={true}
                                    env={env}
                                />
                            </AuthWrapper>
                        </ErrorBoundary>
                    </ConfigProvider>
                </LDProvider>,
                document.getElementById("core-container")
            );
        } else {
            ReactDOM.render(
                <LDProvider>
                    <ConfigProvider>
                        <ErrorBoundary>
                            <AppComponent
                                {...State.get()?.asProps?.()}
                                usingSSO={false}
                                env={env}
                            />
                        </ErrorBoundary>
                    </ConfigProvider>
                </LDProvider>,
                document.getElementById("core-container")
            );
        }
        FastClick.attach(document.body);
    };

    const logoutUser = function (ssoLogoutFn) {
        if (usingSSO) {
            // eslint-disable-next-line no-console
            console.log("Logging out using SSO");
            // eslint-disable-next-line no-unused-vars
            API.logoutUser((result) => {
                if (ssoLogoutFn) {
                    ssoLogoutFn();
                }
            });
        } else {
            window.location.href = path.resolve(
                "/",
                window.TIMEEDIT_APP_PATH,
                `logout?lang=${getUserLanguage()}`
            );
        }
    };

    const run = async function (useSSO = false, env = undefined) {
        Sentry.init({
            dsn: "https://ff17d752ee011c2d3e654725fb456567@o4506875039318016.ingest.us.sentry.io/4506875378008064",
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

        const menuItems = [
            { id: "views", label: "permission_views", model: new ViewList() },
            { id: "settings", label: "nc_menu_calendar_settings" },
            { id: "layers", label: "nc_drafts_title" },
            { id: "account", label: "nc_account_settings" },
            { id: "info", label: "cal_res_side_tab_res_info" },
            { id: "pinboard", label: "nc_pinboard" },
            { id: "help", label: "menu_help" },
        ];

        const menu = new Menu(menuItems);
        menu.freeze();
        State.update(new App(menu, null));

        const LDProvider = await asyncWithLDProvider({
            clientSideID: getLdClientSideId(env.serverEnv),
            context: {
                kind: "uninitialized",
                key: "uninitialized",
            },
            options: {},
        });

        State.subscribe(renderApp.bind(this, LDProvider, useSSO, env));
        renderApp(LDProvider, useSSO, env);
    };

    const serverIsAtLeast = function (major, minor) {
        return (
            TimeEdit.serverInfo?.server.major >= major &&
            TimeEdit?.serverInfo?.server.minor >= minor
        );
    };

    const getCompletePropertyId = function () {
        return _.find(TimeEdit.entryPropertyDefinitions, (def) => def?.complete === true)?.id;
    };

    const presentShortcut = (shortcut) => shortcut.replace("mod", Locale.get("nc_key_mod_short"));

    return {
        State,
        logoutUser,
        run,
        serverIsAtLeast,
        getCompletePropertyId,
        DEBUG: false,
        presentShortcut,
    };
})() as unknown as TTimeEditGlobals;

if (process.env.NODE_ENV === "development") {
    registerDevTools(TimeEdit);
}
