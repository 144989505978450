/*
    Test 1 expected result:
Lokaltyp
    Breakout
Utrustning (Match all)
    Mörkläggning
    Vatten
*/

/*
    Test 2 expected result:
Lokaltyp
    Hörsal
Utrustning (Match any)
    Mörkläggning
    Vatten
*/

/*
    Test 3 expected result:
Lokaltyp
    Grupprum
Utrustning (Match any)
    Mörkläggning
    Vatten
    Windows
    Eluttag
*/

/*
    Test 4 expected result:
Lokaltyp
    Grupprum
Utrustning (Match all)
    Mörkläggning
    Vatten
    Windows
    Eluttag
*/

/*
    Test 1 expected result:
Lokaltyp
    Breakout
Utrustning (Match any)
    Mörkläggning
Utrustning (Match any)
    Vatten
*/

/*
    Test 2 expected result:
Lokaltyp
    Hörsal
Utrustning (Match any)
    Mörkläggning
    Vatten
*/

/*
    Test 3 expected result:
Lokaltyp
    Grupprum
Utrustning (Match any)
    Mörkläggning
    Vatten
Utrustning (Match any)
    Windows
Utrustning (Match any)
    Eluttag
*/

/*
    Test 4 expected result:
Lokaltyp
    Grupprum
Utrustning (Match all)
    Mörkläggning
    Vatten
    Windows
    Eluttag
*/

export const testSearchFiltersData = {
    1: {
        categories: [
            {
                id: "room.equipment",
                values: ["Mörkläggning"],
            },
            {
                id: "room.equipment",
                values: ["Vatten"],
            },
            {
                id: "room.type",
                values: ["Breakout"],
            },
        ],
        searchString: null,
        searchFields: null,
        type: "room",
    },

    2: {
        categories: [
            {
                id: "room.equipment",
                values: ["Mörkläggning", "Vatten"],
            },
            {
                id: "room.type",
                values: ["Hörsal"],
            },
        ],
        searchString: null,
        searchFields: null,
        type: "room",
    },

    3: {
        categories: [
            {
                id: "room.equipment",
                values: ["Mörkläggning", "Vatten"],
            },
            {
                id: "room.equipment",
                values: ["Windows"],
            },
            {
                id: "room.equipment",
                values: ["Eluttag"],
            },
            {
                id: "room.type",
                values: ["Grupprum"],
            },
        ],
        searchString: null,
        searchFields: null,
        type: "room",
    },

    4: {
        categories: [
            {
                id: "room.equipment",
                values: ["Mörkläggning"],
            },
            {
                id: "room.equipment",
                values: ["Vatten"],
            },
            {
                id: "room.equipment",
                values: ["Windows"],
            },
            {
                id: "room.equipment",
                values: ["Eluttag"],
            },
            {
                id: "room.type",
                values: ["Grupprum"],
            },
        ],
        searchString: null,
        searchFields: null,
        type: "room",
    },
};
