import React from "react";
import PropTypes from "prop-types";
import GenericList from "./GenericList";
import { MillenniumDateTime, SimpleDateFormat } from "@timeedit/millennium-time";
import _ from "underscore";
import { TimeEdit } from "../lib/TimeEdit";
import RC from "../lib/ReservationConstants";
import { TimeConstants as TC } from "../lib/TimeConstants";
import ReservationStatus from "../lib/ReservationStatus";
import Language from "../lib/Language";
import { Header } from "../models/Header";
const Size = Header.Label;
import TemplateKind from "../models/TemplateKind";
import { Selection } from "../models/Selection";
import TimeEditAPI from "../lib/TimeEditAPI";
import Experiment from "../models/Experiment";
import { Reservation } from "../models/Reservation";
import { Macros } from "../models/Macros";
import Log from "../lib/Log";
import ColumnConstants from "../lib/ColumnConstants";
const { TYPE_COLUMN_OFFSET, RESERVATION_SORT_ORDERS } = ColumnConstants;

const { OBJECT_MASS_CHANGE: OBMC } = RC;

const MAX_RESERVATIONS_MODIFY = 100;
const LIMIT_RESERVATION_DELETE = 1000;

const LABEL_SIZE_CUTOFFS = {
    MINIMUM: 10,
    XS: 30,
    S: 40,
    M: 60,
    L: 110,
};

const SETTINGS_TO_SAVE = [
    "allReservations",
    "beginTime",
    "columnWidths",
    "complete",
    "endTime",
    "includeMembers",
    "incomplete",
    "permission",
    "reservationStatus",
    "reservationVariant",
    "selectedColumns",
    "selectedInfoColumns",
    "templateGroup",
    "templateKind",
    "useTemplateGroup",
    "objectCategories",
    "reservationCategories",
];

class StaticReservationTypeList extends React.Component {
    static displayName = "StaticReservationTypeList";

    static contextTypes = {
        user: PropTypes.object,
        fireEvent: PropTypes.func,
        update: PropTypes.func,
        presentModal: PropTypes.func,
        customWeekNames: PropTypes.array,
    };

    componentDidMount() {
        this._isMounted = true;

        if (
            !TemplateKind.equals(
                this.props.data.templateKind,
                this.props.searchOptions.templateKind
            )
        ) {
            this.updateTemplateKind();
        }
    }

    componentDidUpdate(prevProps) {
        if (
            !TemplateKind.equals(
                this.props.searchOptions.templateKind,
                prevProps.searchOptions.templateKind
            )
        ) {
            this.updateTemplateKind();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    updateTemplateKind = () => {
        const newData = this.props.data.immutableSet({
            templateKind: this.props.searchOptions.templateKind,
            selection: new Selection(),
        });
        this.context.update(this.props.data, newData);
    };

    getSelection = () => {
        if (this.props.data.privateSelected) {
            return this.props.data.selection;
        }
        return this.props.selection;
    };

    getActiveFluffy = () => {
        const selection = this.getSelection();
        if (selection.fluffy) {
            return selection.fluffy;
        }
        return null;
    };

    reportProgress = (newProgress) => {
        this.setState(newProgress);
    };

    onMassChange = (reservationIds, move, ignoreFields, callback) => {
        const modeText = move
            ? `(${Language.get("dialog_modify")})`
            : `(${Language.get("cal_res_below_copy")})`;
        const name = this.props.getLayerName(`Exp (${this.context.user.userId}) ${modeText}`);
        if (!name) {
            return;
        }
        Experiment.begin(
            name,
            reservationIds,
            move,
            ignoreFields,
            this.reportProgress,
            this.context.presentModal,
            this.props.setActiveLayer,
            this.props.onLayerCreated,
            callback
        );
    };

    getReturnTypes = (columns) => {
        const selection = columns.map((column) => column.id);
        const returnTypes: number[] = [];
        selection.forEach((selectedId) => {
            let pushId: number;
            if (selectedId > TYPE_COLUMN_OFFSET) {
                pushId = selectedId - TYPE_COLUMN_OFFSET;
                if (returnTypes.indexOf(pushId) === -1) {
                    returnTypes.push(pushId);
                }
            }
            // eslint-disable-next-line no-magic-numbers
            if (selectedId === 3) {
                pushId = TimeEdit.rootType;
                if (returnTypes.indexOf(pushId) === -1) {
                    returnTypes.push(pushId);
                }
            }
        });
        return returnTypes;
    };

    getStatus = () => {
        const statuses = [].concat(this.props.searchOptions.reservationStatus);
        if (
            TemplateKind.equals(
                TemplateKind.INFO_RESERVATION,
                this.props.searchOptions.templateKind
            )
        ) {
            statuses.push(RC.STATUS.INFO);
        } else if (
            TemplateKind.equals(TemplateKind.AVAILABILITY, this.props.searchOptions.templateKind)
        ) {
            statuses.push(RC.STATUS.AVAILABILITY);
        } else {
            statuses.push(this.props.defaultStatus);
        }

        if (this.props.searchOptions.complete) {
            statuses.push(RC.STATUS.COMPLETE);
        }
        if (this.props.searchOptions.incomplete) {
            statuses.push(RC.STATUS.INCOMPLETE);
        }
        return statuses;
    };

    getTemplateGroup = () => {
        if (!this.props.searchOptions.useTemplateGroup) {
            return 0;
        }
        const fluffy = this.getActiveFluffy();
        if (fluffy) {
            return fluffy.templateGroupId || 0;
        }
        return 0;
    };

    findReservations = (
        firstIndex,
        searchObjects,
        columns,
        sortOrder,
        sortColumn,
        cb,
        // eslint-disable-next-line no-unused-vars
        longOperation = false,
        // Use default number of rows
        numberOfRows = null
    ) => {
        let reservationSortOrder = this.getSortOrder(sortOrder, sortColumn);
        let useCreated = false;
        if (
            reservationSortOrder === RESERVATION_SORT_ORDERS.CREATED_ASC ||
            reservationSortOrder === RESERVATION_SORT_ORDERS.CREATED_DESC
        ) {
            useCreated = true;
            // eslint-disable-next-line no-magic-numbers
            reservationSortOrder -= 4;
        }

        const onComplete = (result) => {
            if (!this._isMounted) {
                return;
            }

            if (result.parameters[5] === false) {
                if (result.parameters[6] !== "No reservation tags were found") {
                    Log.warning(result.parameters[6]);
                } else {
                    // eslint-disable-next-line no-console
                    console.log(result.parameters[6]);
                }
            }

            const numFound = result.parameters[2];
            const newData = result.parameters[0];

            const searchIntervalBegin = result.parameters
                ? new MillenniumDateTime(result.parameters[3].datetime)
                : null;
            const searchIntervalEnd = result.parameters
                ? new MillenniumDateTime(result.parameters[4].datetime)
                : null;
            cb(newData, numFound, searchIntervalBegin, searchIntervalEnd);
        };

        TimeEditAPI.findReservationsList(
            {
                reservationIds: this.props.reservationIds,
                sortOrder: reservationSortOrder,
                returnTypes: this.getReturnTypes(columns),
                returnFields: [TimeEdit.reservationTextField],
                useCreatedInsteadOfModified: useCreated,
                allReservations: true,
                startRow: 0,
                numberOfRows,
            },
            onComplete
        );
    };

    getSortOrder = (sortOrder, sortColumn) => {
        const ASC = 0;

        // 0 RES_SORT_ID_ASC,
        // 1 RES_SORT_ID_DESC,
        if (sortColumn === Language.get("cal_reservation_list_column_id")) {
            // ID
            return sortOrder;
        }
        // 2 RES_SORT_TIME_ASC,
        // 3 RES_SORT_TIME_DESC,
        if (sortColumn === Language.get("cal_reservation_list_column_date")) {
            // Date
            return sortOrder === ASC
                ? RESERVATION_SORT_ORDERS.TIME_ASC
                : RESERVATION_SORT_ORDERS.TIME_DESC;
        }
        if (this.props.searchOptions.reservationVariant === RC.VARIANT.CANCELLED) {
            if (sortColumn !== Language.get("cal_reservation_list_column_cancelled")) {
                // Cancelled column has ID 5
                return RESERVATION_SORT_ORDERS.TIME_DESC;
            }
            return sortOrder === ASC
                ? RESERVATION_SORT_ORDERS.MODIFIED_ASC
                : RESERVATION_SORT_ORDERS.MODIFIED_DESC;
        }
        // 4 RES_SORT_MODIFIED_ASC,
        // 5 RES_SORT_MODIFIED_DESC
        if (sortColumn === Language.get("cal_reservation_list_column_modified")) {
            // Modified
            return sortOrder === ASC
                ? RESERVATION_SORT_ORDERS.MODIFIED_ASC
                : RESERVATION_SORT_ORDERS.MODIFIED_DESC;
        }
        // Made up values for created, transfer to 4 or 5 and set extra parameter useCreatedInsteadOfModified
        if (sortColumn === Language.get("cal_reservation_list_column_created")) {
            // Created
            return sortOrder === ASC
                ? RESERVATION_SORT_ORDERS.CREATED_ASC
                : RESERVATION_SORT_ORDERS.CREATED_DESC;
        }
        return RESERVATION_SORT_ORDERS.TIME_DESC;
    };

    isSortable = (sortColumn) => {
        return (
            sortColumn === Language.get("cal_reservation_list_column_id") ||
            sortColumn === Language.get("cal_reservation_list_column_date") ||
            sortColumn === Language.get("cal_reservation_list_column_cancelled") ||
            (sortColumn === Language.get("cal_reservation_list_column_modified") &&
                this.props.searchOptions.reservationVariant === RC.VARIANT.CANCELLED) ||
            sortColumn === Language.get("cal_reservation_list_column_modified") ||
            sortColumn === Language.get("cal_reservation_list_column_created")
        );
    };

    getLabelSize = (size) => {
        if (size <= LABEL_SIZE_CUTOFFS.MINIMUM) {
            return null;
        }
        if (size < LABEL_SIZE_CUTOFFS.XS) {
            return Size.XS;
        }
        if (size < LABEL_SIZE_CUTOFFS.S) {
            return Size.S;
        }
        if (size < LABEL_SIZE_CUTOFFS.M) {
            return Size.M;
        }
        if (size < LABEL_SIZE_CUTOFFS.L) {
            return Size.L;
        }
        return Size.XL;
    };

    getTime = (time, size, isEnd) => {
        switch (this.getLabelSize(size)) {
            case Size.M:
                return SimpleDateFormat.format(
                    time,
                    isEnd
                        ? Language.getDateFormat("date_f_hh_end")
                        : Language.getDateFormat("date_f_hh")
                );
            default:
                return SimpleDateFormat.format(
                    time,
                    isEnd
                        ? Language.getDateFormat("date_f_hh_mm_end")
                        : Language.getDateFormat("date_f_hh_mm")
                );
        }
    };

    getLongDate = (date, size) => {
        switch (this.getLabelSize(size)) {
            case Size.XS:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_d"));
            case Size.S:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_d"));
            case Size.M:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_m_d"));
            case Size.L:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_ee_m_d"));
            default:
                return SimpleDateFormat.format(
                    date,
                    Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm")
                );
        }
    };

    getCollisionsFor = (reservationId) => {
        if (!this.state || !this.state.collisions) {
            return false;
        }
        return _.find(this.state.collisions, (cln) => cln.id === reservationId);
    };

    getReservationMenuItems = (selectedReservationIds, getDataAtIndex, callback, onMassReplace) => {
        const items = [];

        items.push([
            {
                label: Language.get("menu_view_info"),
                isDisabled: selectedReservationIds.length > MAX_RESERVATIONS_MODIFY,
                action: () => {
                    const user = this.context.user;
                    if (user.useInfoPopover) {
                        this.context.presentModal(
                            <div>{<p>{Language.get("nc_reservationinfo_moved")}</p>}</div>,
                            null,
                            Language.get("nc_reservationinfo_moved_title")
                        );
                        this.context.update(
                            user,
                            user.immutableSet({ useInfoPopover: !user.useInfoPopover })
                        );
                        TimeEditAPI.setPreferences(
                            "useInfoPopover",
                            [!user.useInfoPopover],
                            _.noop
                        );
                        this.props.onInfoOpen(selectedReservationIds, true);
                    } else {
                        this.props.onInfoOpen(selectedReservationIds, true);
                    }
                },
                classNames: ["icon", "showInfo"],
            },
        ]);

        if (this.props.supportsBatchOperations) {
            items.push(
                this.getBatchMenuItems(
                    selectedReservationIds,
                    getDataAtIndex,
                    callback,
                    onMassReplace
                )
            );
        }

        if (this.props.getDataMenuItems) {
            items.push(
                this.props.getDataMenuItems(
                    selectedReservationIds,
                    getDataAtIndex,
                    callback,
                    onMassReplace
                )
            );
        }
        return items;
    };

    // selectedStatus has an item for each selected reservation ID
    getBatchMenuItems = (selectedReservationIds, getDataAtIndex, callback, onMassChangeObjects) => {
        const divider = () => ({
            isSeparator: true,
        });
        let experimentModeMenu = null;
        if (this.props.activeLayer === 0) {
            experimentModeMenu = {
                key: "experimentMode",
                label: Language.get("nc_drafts_title"),
                submenu: [
                    {
                        key: "experiment.copy",
                        label: Language.get("cal_res_below_copy"),
                        action: () => {
                            this.onMassChange(selectedReservationIds, false, false, _.noop);
                        },
                    },
                    {
                        key: "experiment.copyNoFields",
                        label: Language.get("cal_reservation_action_button_copy_no_field"),
                        action: () => {
                            this.onMassChange(selectedReservationIds, false, true, _.noop);
                        },
                    },
                    {
                        key: "experiment.modify",
                        label: Language.get("dialog_modify"),
                        action: () => {
                            this.onMassChange(selectedReservationIds, true, false, _.noop);
                        },
                    },
                ],
            };
        }
        const statusItem = this.props.allStatus
            ? {
                  key: "entry.status",
                  label: Language.get("nc_mass_change_status"),
                  submenu: this.props.allStatus.map((statusId) => {
                      const status = ReservationStatus.statusForId(statusId);
                      return {
                          key: `entry.status.${status.id}`,
                          label: status.getStatusName(),
                          tooltip: _.contains(this.props.validStatus, statusId)
                              ? null
                              : Language.get("nc_mass_change_status_not_possible"),
                          isDisabled: !_.contains(this.props.validStatus, statusId),
                          action: () => this.setStatus(selectedReservationIds, status),
                      };
                  }),
              }
            : null;
        const moveItem =
            this.props.defaultStatus !== RC.STATUS.WAITING_LIST
                ? {
                      label: Language.get("nc_mass_change_move_reservations"),
                      isDisabled: selectedReservationIds.length === 0,
                      action: () => {
                          onMassChangeObjects(OBMC.MOVE);
                      },
                      classNames: ["icon", "massMoveObject"],
                  }
                : null;
        const result = [
            divider(),
            {
                label: Language.get("nc_mass_change_add_object"),
                isDisabled: selectedReservationIds.length === 0,
                action: () => {
                    onMassChangeObjects(OBMC.ADD);
                },
                classNames: ["icon", "massAddObject"],
            },
            {
                label: Language.get("nc_mass_change_remove_object"),
                isDisabled: selectedReservationIds.length === 0,
                action: () => {
                    onMassChangeObjects(OBMC.REMOVE);
                },
                classNames: ["icon", "massRemoveObject"],
            },
            {
                label: Language.get("nc_mass_change_replace_object"),
                isDisabled: selectedReservationIds.length === 0,
                action: () => {
                    onMassChangeObjects(OBMC.REPLACE);
                },
                classNames: ["icon", "massReplaceObject"],
            },
            moveItem,
            divider(),
            {
                label: Language.get("nc_cal_func_res_edit_fields"),
                isDisabled: selectedReservationIds.length > MAX_RESERVATIONS_MODIFY,
                action: () => {
                    const user = this.context.user;
                    if (user.useInfoPopover) {
                        this.context.presentModal(
                            <div>{<p>{Language.get("nc_reservationinfo_moved")}</p>}</div>,
                            null,
                            Language.get("nc_reservationinfo_moved_title")
                        );
                        this.context.update(
                            user,
                            user.immutableSet({ useInfoPopover: !user.useInfoPopover })
                        );
                        TimeEditAPI.setPreferences(
                            "useInfoPopover",
                            [!user.useInfoPopover],
                            _.noop
                        );
                        this.props.onInfoOpen(selectedReservationIds, true, true);
                    } else {
                        this.props.onInfoOpen(selectedReservationIds, true, true);
                    }
                },
                classNames: ["icon", "editFields"],
            },
            {
                label: Language.get("cal_func_res_delete"),
                action: () => {
                    TimeEditAPI.okToCancelReservations(
                        { reservationIds: selectedReservationIds },
                        (res) => {
                            const canCancelAll = _.every(
                                res.parameters[0],
                                (status) => !status.details
                            );
                            if (!canCancelAll) {
                                this.context.presentModal(
                                    <div>
                                        {
                                            <p>
                                                {Language.get(
                                                    "cal_res_below_reservations_can_not_be_cancelled"
                                                )}
                                            </p>
                                        }
                                    </div>,
                                    null,
                                    Language.get("cal_res_below_cancel_reservations")
                                );
                                return;
                            }

                            const yesButton = {
                                title: Language.get("dialog_yes"),
                                cb: () => {
                                    const calls = _.splitArray(
                                        selectedReservationIds,
                                        LIMIT_RESERVATION_DELETE
                                    ).map((batch) => (done) => {
                                        Reservation.cancel(batch, () => {
                                            done();
                                        });
                                    });
                                    _.runSync(calls, () => {
                                        this.onUpdate([]);
                                        if (this._clearSelection) {
                                            this._clearSelection();
                                        }
                                        if (this._find) {
                                            this._find();
                                        }
                                    });
                                },
                            };
                            const noButton = { title: Language.get("dialog_no") };
                            const buttons = [yesButton, noButton];
                            this.context.presentModal(
                                <div>
                                    {
                                        <p>
                                            {Language.get(
                                                "cal_res_below_do_you_wish_to_cancel_all",
                                                selectedReservationIds.length
                                            )}
                                        </p>
                                    }
                                </div>,
                                null,
                                Language.get("cal_res_below_cancel_reservations"),
                                buttons
                            );
                        }
                    );
                },
                classNames: ["icon", "remove"],
            },
            {
                key: "table_select_all",
                label: Language.get("cal_reservation_action_button_select_all"),
                action: () => {
                    callback(true);
                },
                classNames: ["icon", "selectAll"],
            },
        ];
        if (statusItem) {
            result.push(divider());
            result.push(statusItem);
        }
        if (experimentModeMenu) {
            result.push(divider());
            result.push(experimentModeMenu);
        }
        return result.filter((item) => item !== null);
    };

    _setClearSelection = (clearFunction) => {
        this._clearSelection = clearFunction;
    };

    _setFindFunction = (findFunction) => {
        this._find = findFunction;
    };

    setStatus = (selectedReservationIds, status) => {
        const setStatus = () => {
            let numSuccessful = 0;
            const calls = _.splitArray(selectedReservationIds, TimeEditAPI.STATUS_BATCH_SIZE).map(
                (batch) => (done) => {
                    TimeEditAPI.setReservationStatus(batch, status.id, (result) => {
                        numSuccessful += result[0].filter((bool) => bool === true).length;
                        // Update progress?
                        done();
                    });
                }
            );
            _.runSync(calls, () => {
                if (numSuccessful === 0) {
                    Log.warning(Language.get("nc_edit_reservation_status_change_error"));
                    return;
                }
                if (numSuccessful < selectedReservationIds.length) {
                    Log.warning(
                        Language.get(
                            "nc_edit_reservation_status_change_x_of_y_complete",
                            numSuccessful,
                            selectedReservationIds.length
                        )
                    );
                } else {
                    Log.info(Language.get("nc_edit_reservation_status_change_complete"));
                }
                this.onUpdate(selectedReservationIds);
            });
        };

        TimeEditAPI.getPreferences(
            "dismissedModalDialogs.confirm_mass_status_change",
            undefined,
            undefined,
            (value) => {
                if (!value || value === null || process.env.NODE_ENV === "development") {
                    const buttons = [
                        { title: Language.get("dialog_cancel") },
                        { title: Language.get("nc_dialog_proceed"), cb: setStatus },
                    ];
                    this.context.presentModal(
                        <p>
                            {Language.get(
                                "nc_confirm_mass_status",
                                selectedReservationIds.length,
                                status.getStatusName()
                            )}
                        </p>,
                        "confirm_mass_status_change",
                        null,
                        buttons
                    );
                } else {
                    setStatus();
                }
            }
        );
    };

    onUpdate = (selectedReservationIds) => {
        this.context.fireEvent(
            `reservationList${this.props.id}`,
            Macros.Event.RESERVATION_MADE_OR_MODIFIED,
            selectedReservationIds
        );
        this.props.refresh();
    };

    getCellLabels = (reservation, columns, columnWidths) => {
        let color;
        let textColor;
        let bold;

        const labels = columns.map((column, i) => {
            if (!reservation) {
                return "";
            }
            if (column.name === Language.get("cal_reservation_list_column_id")) {
                return reservation.id;
            }
            const width = columnWidths[i];
            if (column.name === Language.get("cal_reservation_list_column_type_all")) {
                if (!reservation.objects) {
                    return "";
                }
                const rootId = TimeEdit.rootType !== undefined ? TimeEdit.rootType : 0;
                const columnTypes = this.getReturnTypes(columns).filter((col) => col !== rootId);
                return reservation.objects
                    .filter((object) => columnTypes.indexOf(object.type.id) === -1)
                    .map((object) => {
                        if (object.fields && object.fields[0].values) {
                            return object.fields[0].values[0];
                        }
                        return "-";
                    })
                    .join(", ");
            }
            if (column.id > TYPE_COLUMN_OFFSET) {
                if (!reservation.objects) {
                    return "";
                }
                const typeId = column.id - TYPE_COLUMN_OFFSET;
                return reservation.objects
                    .filter((object) => object.type.id === typeId)
                    .map((object) => {
                        if (object.fields && object.fields[0].values) {
                            return object.fields[0].values[0];
                        }
                        return "-";
                    })
                    .join(", ");
            }
            if (column.name === Language.get("cal_reservation_list_column_field_all")) {
                if (!reservation.fields) {
                    return "";
                }
                return reservation.fields
                    .map((field) => (field.values ? field.values.join(", ") : null))
                    .filter((val) => val !== "")
                    .join(", ");
            }
            let format;
            const startTime = reservation.begin ? new MillenniumDateTime(reservation.begin) : null;
            const endTime = reservation.end ? new MillenniumDateTime(reservation.end) : null;
            const between = startTime.daysBetween(endTime);
            const isSameDay = between === 0 || (between === 1 && endTime.isMidnight());
            if (
                column.name === Language.get("cal_reservation_list_column_date") &&
                reservation.begin
            ) {
                if (!isSameDay) {
                    // Different start and end date
                    format = Language.getDateFormat("date_f_m_d");
                    const start = SimpleDateFormat.format(startTime, format);
                    const end = SimpleDateFormat.format(
                        endTime,
                        Language.getDateFormat("date_f_m_d_end")
                    );
                    if (start !== end) {
                        return `${start}-${end}`;
                    }
                    return start;
                }
                format = Language.getDateFormat("date_f_yy_mm_dd");
                return SimpleDateFormat.format(startTime, format);
            }
            if (
                column.name === Language.get("cal_reservation_list_column_time") &&
                reservation.begin
            ) {
                return `${this.getTime(startTime, width, false)}-${this.getTime(
                    endTime,
                    width,
                    true
                )}`;
            }
            if (column.name === Language.get("cal_reservation_list_column_length")) {
                const length = reservation.length
                    ? reservation.length
                    : reservation.end - reservation.begin;
                if (length === 0 || isNaN(length)) {
                    return "0:00";
                }
                const hours = Math.floor(length / TC.SECONDS_PER_HOUR);
                const minutes = Math.floor((length % TC.SECONDS_PER_HOUR) / TC.SECONDS_PER_MINUTE);
                // eslint-disable-next-line no-magic-numbers
                return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
            }
            if (column.name === Language.get("cal_reservation_list_column_status")) {
                if (!reservation.status) {
                    return ReservationStatus.C_CONFIRMED.getStatusName(false);
                }

                let status = reservation.status;
                if (
                    _.some(
                        status,
                        (item) =>
                            item.status === RC.STATUS.REQUESTED ||
                            item.status === RC.STATUS.REJECTED
                    )
                ) {
                    status = status.filter((item) => item.status !== RC.STATUS.INCOMPLETE);
                }
                return status
                    .map((item) => ReservationStatus.statusForId(item.status).getStatusName(false))
                    .join(", ");
            }
            if (column.name === Language.get("cal_reservation_list_column_created")) {
                let created = "";
                if (reservation.created) {
                    const createdTime = new MillenniumDateTime(reservation.created || 0);
                    created = this.getLongDate(createdTime, width);
                }
                const createdBy = reservation.createdby ? `, ${reservation.createdby}` : "";
                return created + createdBy;
            }
            if (column.name === Language.get("cal_reservation_list_column_modified")) {
                let modified = "";
                if (reservation.modified) {
                    const modifiedTime = new MillenniumDateTime(reservation.modified || 0);
                    modified = this.getLongDate(modifiedTime, width);
                }
                const modifiedBy = reservation.modifiedby ? `, ${reservation.modifiedby}` : "";
                return modified + modifiedBy;
            }
            if (column.name === Language.get("cal_reservation_list_column_cancelled")) {
                if (reservation.cancelled) {
                    const cancelledTime = new MillenniumDateTime(reservation.cancelled || 0);
                    return this.getLongDate(cancelledTime, width);
                }
                return "";
            }
            if (column.name === Language.get("cal_reservation_list_column_week")) {
                return Language.formatWeekTableCell(
                    startTime,
                    endTime,
                    this.context.customWeekNames
                );
            }
            if (column.name === Language.get("cal_reservation_list_column_week_day")) {
                const start = SimpleDateFormat.format(startTime, "EE");
                const end = SimpleDateFormat.format(endTime, "LL");
                if (start === end) {
                    return start;
                }
                return `${start} - ${end}`;
            }

            if (column.name === Language.get("nc_mass_change_collisions")) {
                const col = this.getCollisionsFor(reservation.id);
                if (!col || col.collidingReservations.length === 0) {
                    return "";
                }
                return `${col.collidingReservations.length}`;
            }

            return "";
        });

        return { labels, color, textColor, bold };
    };

    render() {
        return (
            <GenericList
                {...this.props}
                showSearchInterval={false}
                progress={this.state ? this.state.progress : null}
                getDataMenuItems={this.getReservationMenuItems}
                collisions={this.state ? this.state.collisions : []}
                settingsToSave={SETTINGS_TO_SAVE}
                getCellLabels={this.getCellLabels}
                isSortable={this.isSortable}
                find={this.findReservations}
                onMassChange={this.onMassChange}
                setClearSelection={this._setClearSelection}
                setFindFunction={this._setFindFunction}
            />
        );
    }
}

export default StaticReservationTypeList;
