import React from "react";

// Migration to class component with 'react-codemod' failed, explained here:
// https://github.com/reactjs/react-codemod/blob/master/LEGACY.md#explanation-of-the-new-es2015-class-transform-with-property-initializers
import createReactClass from "create-react-class";

import ListSettingsStore from "./ListSettingsStore";
import Language from "../lib/Language";
import TemplateKind from "../models/TemplateKind";
import RC from "../lib/ReservationConstants";
import _ from "underscore";

const ReservationListSettings = createReactClass({
    displayName: "ReservationListSettings",

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.defaultSettings, this.props.defaultSettings)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(this.getInitialState(this.props));
        }
        if (!_.isEqual(this.state, prevState)) {
            const settings = _.clone(this.state);
            this.props.onChange(settings);
        }
    },

    getInitialState(props = this.props) {
        return {
            // Search options
            allReservations: props.allReservations,
            includeMembers: props.includeMembers,
            templateKind: props.templateKind,
        };
    },

    onTemplateKindChange(event) {
        const stateChange = { templateKind: TemplateKind.get(parseInt(event.target.value, 10)) };
        this.setState(stateChange);
    },

    onMembersChanged(event) {
        this.setState({ includeMembers: parseInt(event.target.value, 10) });
    },

    onAllReservationsChange(event) {
        this.setState({ allReservations: !event.target.checked });
    },

    render() {
        return (
            <div>
                <h3 className="core-header">{Language.get("nc_list_search_settings_title")}</h3>

                <ListSettingsStore
                    searchSettings={this.state}
                    onChange={(search) => this.setState(search)}
                    getSavedSettings={this.props.getSavedSettings}
                    saveSettings={this.props.saveSettings}
                    removeSettings={this.props.removeSettings}
                    saveDefaultSettings={this.props.saveDefaultSettings}
                />

                <div className="settingsContainer">
                    <div className="settingsLine">
                        <label>{Language.get("dynamic_reserv_list_reservation_type")}</label>
                        <select
                            value={this.state.templateKind.number}
                            onChange={this.onTemplateKindChange}
                        >
                            <option value={TemplateKind.RESERVATION.number} key="bokning">
                                {Language.get("dynamic_reserv_list_reservation")}
                            </option>
                            <option value={TemplateKind.AVAILABILITY.number} key="tillgänglighet">
                                {Language.get("dynamic_reserv_list_availability")}
                            </option>
                            <option value={TemplateKind.INFO_RESERVATION.number} key="info">
                                {Language.get("dynamic_reserv_list_info")}
                            </option>
                        </select>
                    </div>
                    <hr />
                    <div className="settingsLine">
                        <div className="settingsLine">
                            <input
                                type="checkbox"
                                checked={!this.state.allReservations}
                                onChange={this.onAllReservationsChange}
                            />{" "}
                            {Language.get("nc_reserv_list_only_mine")}
                        </div>
                    </div>
                    <hr key="divider" />
                    <div className="settingsLine" key="settings">
                        <label>{Language.get("dynamic_reserv_list_members")}</label>
                        <select onChange={this.onMembersChanged} value={this.state.includeMembers}>
                            <option value={RC.MEMBERS.EXCLUDE} key="Exkludera">
                                {Language.get("dynamic_reserv_list_exclude")}
                            </option>
                            <option value={RC.MEMBERS.INCLUDE} key="Inkludera">
                                {Language.get("dynamic_reserv_list_include")}
                            </option>
                            <option value={RC.MEMBERS.OBSTACLES} key="Förhinder">
                                {Language.get("dynamic_reserv_list_obstacles")}
                            </option>
                            <option value={RC.MEMBERS.MEMBERS_ONLY} key="Endast medlemmar">
                                {Language.get("dynamic_reserv_list_members")}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        );
    },
});

export default ReservationListSettings;
