import React from "react";
import _ from "underscore";
import Language from "../lib/Language";
import ArrayInput from "./ArrayInput";
import { MillenniumWeek, SimpleDateFormat } from "@timeedit/millennium-time";
import API from "../lib/TimeEditAPI";
import ContextMenu from "../lib/ContextMenu";
import PropTypes from "prop-types";

const SAVE_KEY = "calendar.weeklist.weekgroups.save.weeks";

// eslint-disable-next-line prefer-const
let serializeGroups, deserializeGroups;

const displayKey = "weekListConfirmDeletionDialog";

type TWeekListProps = {
    outerHeight: number;
};

class WeekList extends React.Component<TWeekListProps> {
    state = {
        savedGroups: [],
        selectedGroup: 0,
        previousSelection: [0],
        confirmDeletion: true,
    };

    static contextTypes = {
        presentModal: PropTypes.func,
    };

    componentDidMount() {
        this.loadGroups(this.props.customWeekNames);
        API.getPreferences(`dismissedModalDialogs.${displayKey}`, undefined, undefined, (value) => {
            if (value !== undefined) {
                this.setState({ confirmDeletion: !value });
            }
        });
    }

    loadGroups = (customWeekNames) => {
        API.getPreferences(SAVE_KEY, undefined, undefined, (result) => {
            if (result) {
                const loadedGroups = deserializeGroups(result, customWeekNames);
                this.setState({ savedGroups: loadedGroups });
            }
        });
    };

    getGroups = () => {
        const allGroup = {
            name: Language.get("week_list_all"),
            values: this.props.defaultValue,
        };
        return [allGroup].concat(this.state.savedGroups);
    };

    getSelectedIndexes = () => {
        const indexes: number[] = [];
        this.getValues().forEach((item, index) => {
            if (item.selected === true) {
                indexes.push(index);
            }
        });

        if (this.props.limit === 1) {
            return indexes[0] || null;
        }
        return indexes;
    };

    getSelectedValues = () => {
        const values = this.getValues().filter((item) => item.selected);

        if (this.props.limit === 1) {
            return values[0] || null;
        }
        return values;
    };

    toggleSelection = () => {
        const currentSelection = this.getSelectedIndexes();

        if (currentSelection.length < this.getValues().length) {
            this.setState({ previousSelection: currentSelection });
            this.props.onUpdate(this.getValues().map((item) => item.value));
            return;
        }

        let values = this.getValues();
        values = this.state.previousSelection.map((index) => values[index].value);
        this.props.onUpdate(values);
    };

    saveGroup = () => {
        // eslint-disable-next-line no-alert
        const name = window.prompt(
            Language.get("nc_weeklist_prompt_group_name"),
            Language.get("nc_weeklist_prompt_default_group_name")
        );
        if (!name) {
            // prompt returns null if the user clicks cancel
            return;
        }
        const values = this.getSelectedValues();
        const groups = this.state.savedGroups.concat({ name, values });
        API.setPreferences(SAVE_KEY, [serializeGroups(groups)], _.noop);
        this.setState({ savedGroups: groups, selectedGroup: groups.length });
    };

    deleteGroup = () => {
        const doDelete = () => {
            const groups = this.state.savedGroups
                .slice(0, this.state.selectedGroup - 1)
                .concat(this.state.savedGroups.slice(this.state.selectedGroup));
            API.setPreferences(SAVE_KEY, [serializeGroups(groups)], _.noop);
            this.setState({ savedGroups: groups });
            this.setSelectedGroup(0);
        };
        if (this.state.confirmDeletion) {
            const buttons: { title: string; remember: boolean; cb?: () => void }[] = [];
            buttons.push({
                title: Language.get("dialog_cancel"),
                remember: false,
            });
            buttons.push({
                cb: () => doDelete(),
                title: Language.get("dialog_delete"),
                remember: true,
            });
            this.context.presentModal(
                <p>
                    {Language.get(
                        "nc_remove_group_dialog_text",
                        this.state.savedGroups[this.state.selectedGroup - 1].name
                    )}
                </p>,
                displayKey,
                Language.get("nc_remove_group_dialog_title"),
                buttons
            );
        } else {
            doDelete();
        }
    };

    setSelectedGroup = (index) => {
        if (_.isObject(index) && index.nativeEvent instanceof Event) {
            // eslint-disable-next-line no-param-reassign
            index = parseInt(index.target.value, 10);
        }

        if (index === this.getGroups().length) {
            this.saveGroup();
            return;
        }

        const currentWeek = MillenniumWeek.today(Language.firstDayOfWeek, Language.daysInFirstWeek);

        if (index === 0) {
            this.setState({ selectedGroup: index });
            this.props.onUpdate([currentWeek]);
            return;
        }

        let newValues = this.getGroups()
            [index].values.map((item) => item.value)
            .filter((wk) => wk.getWeek() >= currentWeek.getWeek());
        if (newValues.length === 0) {
            newValues = this.getGroups()[index].values.map((item) => item.value);
        }

        this.setState({ selectedGroup: index });
        this.props.onUpdate(newValues);
    };

    getValues = () => {
        const groups = this.getGroups();
        if (groups.length < this.state.selectedGroup + 1) {
            return [];
        }
        const values = groups[this.state.selectedGroup].values;
        const selectedValues = this.props.defaultValue.filter((val) => val.selected === true);
        return values.map((val) => {
            // eslint-disable-next-line no-param-reassign
            val.selected = selectedValues.some(
                (selected) => val.value.week() === selected.value.week()
            );
            return val;
        });
    };

    showMoreOptions = (event) => {
        ContextMenu.displayMenu(
            [
                {
                    key: "group.save",
                    label: Language.get("nc_save_selected_weeks"),
                    action: () => this.saveGroup(),
                },
                {
                    key: "group.delete",
                    label: `${Language.get("dialog_delete")} "${
                        this.getGroups()[this.state.selectedGroup].name
                    }"`,
                    isDisabled: this.state.selectedGroup === 0,
                    action: () => this.deleteGroup(),
                },
            ],
            event
        );
    };

    render() {
        const numValues = this.getSelectedIndexes().length;
        let label = Language.get("nc_button_title_x_selected", numValues);
        if (numValues === 1) {
            label = Language.get("nc_button_title_1_selected");
        }

        let selectHeight = null;
        const HEIGHT_MODIFIER = 70;
        if (this.props.outerHeight) {
            selectHeight = this.props.outerHeight - HEIGHT_MODIFIER;
        }

        return (
            <div className="weekList">
                <div className="listTopLine">
                    <select
                        key="groupSelect"
                        className="groupSelect"
                        onChange={this.setSelectedGroup}
                        value={this.state.selectedGroup}
                        disabled={this.props.isSchedulingTracks}
                    >
                        {this.getGroups().map(
                            (group, index) => (
                                <option key={index} ref={index} value={index}>
                                    {group.name}
                                </option>
                            ),
                            this
                        )}
                    </select>
                    <button key="groupSelectMore" className="more" onClick={this.showMoreOptions} />
                </div>

                <ArrayInput
                    disabled={this.props.isSchedulingTracks}
                    height={selectHeight}
                    defaultValue={this.getValues()}
                    onUpdate={this.props.onUpdate}
                    defaultSize={7}
                />
                <button
                    className="listModeToggle"
                    disabled={this.props.isSchedulingTracks}
                    onClick={this.toggleSelection}
                >
                    {label}
                </button>
            </div>
        );
    }
}

serializeGroups = function (groups) {
    return JSON.stringify(
        groups.map((group) => {
            // eslint-disable-next-line no-param-reassign
            group = _.clone(group);
            // eslint-disable-next-line no-param-reassign
            group.values = group.values.map((item) => item.value.week());

            return group;
        })
    );
};

const getCustomWeekName = (week, customWeekNames) => {
    const name = _.find(
        customWeekNames,
        (customWeek) => customWeek.dayNumber === week.date.dayNumber
    );
    if (name) {
        return name.getLongestName();
    }
    return null;
};

deserializeGroups = function (groups, customWeekNames = []) {
    if (typeof groups === "string") {
        // eslint-disable-next-line no-param-reassign
        groups = JSON.parse(groups);
    }
    return groups.map((group) => {
        // eslint-disable-next-line no-param-reassign
        group = _.clone(group);
        // eslint-disable-next-line no-param-reassign
        group.values = group.values.map((week) => {
            const value = MillenniumWeek.create(
                week,
                Language.firstDayOfWeek,
                Language.daysInFirstWeek
            );
            return {
                label:
                    getCustomWeekName(value, customWeekNames) ||
                    SimpleDateFormat.format(
                        value.getStartOfWeek(),
                        Language.getDateFormat("date_f_yyyy_ww")
                    ),
                value,
            };
        });

        return group;
    });
};

export default WeekList;
