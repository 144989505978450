import { App } from "../models/App";
import { Calendar } from "../models/Calendar";
import { Menu } from "../models/Menu";
import { Selection } from "../models/Selection";
import { User } from "../models/User";

const isSameObject = function (first, second) {
    if (first.hasOwnProperty("_modelId")) {
        return first._modelId === second._modelId;
    }
    return first === second;
};

// eslint-disable-next-line consistent-return
const findObject = function (toFind, rootObject) {
    if (isSameObject(rootObject, toFind)) {
        return rootObject;
    }
    let prop, found;
    // eslint-disable-next-line no-restricted-syntax
    for (prop in rootObject) {
        if (
            rootObject.hasOwnProperty(prop) &&
            typeof rootObject[prop] === "object" &&
            rootObject[prop] !== null
        ) {
            if (isSameObject(rootObject[prop], toFind)) {
                return rootObject[prop];
            }
            found = findObject(toFind, rootObject[prop]);
            if (found !== undefined) {
                return found;
            }
        }
    }
};
const isNotModelObject = function (object) {
    return !object.hasOwnProperty("_modelId");
};
const replaceData = function (rootCompare, rootReplace, object, replacement) {
    if (typeof object !== "object") {
        throw new Error("Data store can only update objects with new objects.");
    }
    let didReplacement = false;
    let prop;
    // eslint-disable-next-line no-restricted-syntax
    for (prop in rootCompare) {
        if (
            rootCompare.hasOwnProperty(prop) &&
            typeof rootCompare[prop] === "object" &&
            rootCompare[prop] !== null
        ) {
            if (isSameObject(rootCompare[prop], object)) {
                // eslint-disable-next-line no-param-reassign
                rootReplace[prop] = replacement;
                didReplacement = true;
            } else {
                const wasChildReplaced = replaceData(
                    rootCompare[prop],
                    rootReplace[prop],
                    object,
                    replacement
                );
                if (!wasChildReplaced) {
                    // eslint-disable-next-line no-param-reassign
                    rootReplace[prop] = rootCompare[prop];
                } else {
                    didReplacement = true;
                }
            }
        }
    }
    return didReplacement;
};

type TCallBack = (data: Partial<App> | null) => void;

// We should really consider using redux or some context for storing this state instead.
class DataStore {
    private data: Partial<App> | null = null;
    callbacks: TCallBack[] = [];
    runningCallbacks: TCallBack[] = [];
    constructor(initialData) {
        if (initialData) {
            this.data = DataStore.deepFreeze(initialData);
        }
    }
    notifySubscribers() {
        let i;
        for (i = 0; i < this.callbacks.length; i++) {
            // Make sure this callback isn't already running.
            // That could cause an infinite loop of datastore updates.
            if (this.runningCallbacks.indexOf(i) === -1) {
                const newLength = this.runningCallbacks.push(i);
                this.callbacks[i](this.get());
                this.runningCallbacks.splice(newLength - 1, 1);
            } else if (process.env.NODE_ENV === "development") {
                // Warn when a notification is blocked to prevent risk of loops. This might lead to a stale UI and should be avoided.
                // eslint-disable-next-line no-console
                console.warn(
                    "DATASTORE: Cannot notify subscribers of updates performed during the notification process."
                );
            }
        }
    }
    // Keep 'replace' fn as an arrow function, because we use this methods in a really crazy ways in other places and reference to 'this' gets messed up.
    replace = (
        obj: Partial<Selection | App | Calendar | User> | null | undefined,
        newObj: Partial<Selection | App | Calendar | User> | null | undefined
    ) => {
        if (isNotModelObject(obj) || isNotModelObject(obj)) {
            throw new Error("Object is not a model object.");
        }
        if (this.data === obj) {
            this.data = obj as App;
            this.notifySubscribers();
            return;
        }

        // If we didn't replace the root object, we have to recursively check its properties
        const copiedData = DataStore.deepClone(this.data);
        const didReplacement = replaceData(this.data, copiedData, obj, newObj);
        if (!didReplacement) {
            return;
        }
        this.data = DataStore.deepFreeze(copiedData);
        this.notifySubscribers();
    };
    update(obj: Partial<App | Menu | User> | null | undefined, props?: any) {
        const newData = DataStore.update(this.data, obj, props);
        if (newData === this.data) {
            return;
        }
        this.data = newData;
        this.notifySubscribers();
    }
    get() {
        return this.data;
    }
    logSelection() {
        // logSelection(data);
    }
    subscribe(callback: TCallBack) {
        this.callbacks.push(callback);
    }
    static deepClone(obj) {
        // Handle the 3 simple types, and null or undefined
        if (obj === null || typeof obj !== "object") {
            return obj;
        }
        let copy;

        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            let i;
            const len = obj.length;
            for (i = 0; i < len; i++) {
                copy[i] = DataStore.deepClone(obj[i]);
            }
            return copy;
        }

        // Handle Object
        if (obj instanceof Object) {
            copy = Object.create(Object.getPrototypeOf(obj));
            let attr;
            // eslint-disable-next-line no-restricted-syntax
            for (attr in obj) {
                if (obj.hasOwnProperty(attr)) {
                    copy[attr] = DataStore.deepClone(obj[attr]);
                }
            }
            return copy;
        }
        throw new Error(`Could not clone object ${obj}`);
    }
    static deepFreeze(o) {
        Object.freeze(o);
        Object.getOwnPropertyNames(o).forEach((prop) => {
            if (o[prop] !== null && typeof o[prop] === "object" && !Object.isFrozen(o[prop])) {
                DataStore.deepFreeze(o[prop]);
            }
        });
        return o;
    }
    static update(root, obj, props) {
        if (isNotModelObject(obj)) {
            throw new Error("Object is not a model object.");
        }

        // If props is undefined, we will replace the full contents of the
        // data store with the provided object.
        if (props === undefined) {
            return DataStore.deepFreeze(obj);
        }
        if (!Object.isFrozen(obj)) {
            throw new Error("Calling update with an object that is not frozen.");
        }
        const copy = DataStore.deepClone(findObject(obj, root));
        if (props instanceof Function) {
            props(copy);
        } else {
            let prop;
            // eslint-disable-next-line no-restricted-syntax
            for (prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    copy[prop] = obj[prop];
                }
            }

            // eslint-disable-next-line no-restricted-syntax
            for (prop in props) {
                if (props.hasOwnProperty(prop)) {
                    copy[prop] = props[prop];
                }
            }
        }
        let copiedData = copy;
        let didReplacement = true;
        if (root !== findObject(obj, root)) {
            // If we didn't replace the root object, we have to recursively check its properties
            copiedData = DataStore.deepClone(root);
            didReplacement = replaceData(root, copiedData, obj, copy);
        }
        if (!didReplacement) {
            return root;
        }
        return DataStore.deepFreeze(copiedData);
    }
}

export default DataStore;
