import React from "react";

export const DIVIDER = "__DIVIDER__";

class MultiSelect extends React.Component {
    onChange = (value, evt) => {
        let values = this.props.options
            .filter((option) => option.selected)
            .map((option) => option.value);

        if (evt.target.checked) {
            values = values.concat(value);
        } else {
            values = values.filter((existingValue) => existingValue !== value);
        }

        this.props.onValueChanged(values);
    };

    render() {
        return (
            <div className={this.props.className}>
                {this.props.options.map((option, index) => {
                    if (option.value === DIVIDER) {
                        return (
                            <div key={`divider${index}`}>
                                <br />
                            </div>
                        );
                    }
                    return (
                        <div key={option.value}>
                            <input
                                type="checkbox"
                                id={option.value}
                                checked={option.selected || false}
                                value={option.value || ""}
                                onChange={this.onChange.bind(this, option.value)}
                                disabled={option.disabled || false}
                            />
                            <label htmlFor={option.value}>{option.label}</label>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default MultiSelect;
