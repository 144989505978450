import React from "react";
import API from "../lib/TimeEditAPI";
import Language from "../lib/Language";
import _ from "underscore";

type TExpanderProps = {
    id: string;
    isOpenByDefault: boolean;
    label: string;
};

type TExpanderState = {
    isExpanded: boolean;
};

class Expander extends React.Component<TExpanderProps, TExpanderState> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isExpanded: props.isOpenByDefault,
        };
    }

    componentDidMount() {
        API.getPreferences(this.props.id, undefined, undefined, (val) => {
            let isExpanded;
            if (_.isNullish(val)) {
                isExpanded = this.props.isOpenByDefault;
            } else {
                isExpanded = Boolean(val);
            }
            this.setState({ isExpanded });
        });
    }

    toggleExpansion() {
        const isExpanded = !this.state.isExpanded;
        this.setState({
            isExpanded,
        });
        if (this.props.id) {
            API.setPreferences(this.props.id, [isExpanded], _.noop);
        }
    }

    render() {
        if (this.state.isExpanded) {
            return (
                <tr>
                    <th className="fieldLabel clickable" onClick={this.toggleExpansion.bind(this)}>
                        {this.props.label}
                    </th>
                    <td>
                        <div
                            className="collapseArrow clickable"
                            onClick={this.toggleExpansion.bind(this)}
                        >
                            {Language.get("nc_dialog_show_less")}
                        </div>
                        {this.props.children}
                    </td>
                </tr>
            );
        }
        return (
            <tr>
                <th className="fieldLabel clickable" onClick={this.toggleExpansion.bind(this)}>
                    {this.props.label}
                </th>
                <td>
                    <div
                        className="expandArrow clickable"
                        onClick={this.toggleExpansion.bind(this)}
                    >
                        {Language.get("nc_dialog_show_more")}
                    </div>
                </td>
            </tr>
        );
    }
}

export default Expander;
