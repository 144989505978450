import Language from "../lib/Language";
import { ValueOf } from "../types/utils";

const STATUS = {
    UNKNOWN: 0,
    RESERVATION: 10,
    AVAILABILITY: 11,
    INFO: 12,
    COMPLETE: 20,
    INCOMPLETE: 21,
    CONFIRMED: 30,
    PRELIMINARY: 31,
    PLANNED: 32,
    REQUESTED: 33,
    REJECTED: 34,
    TIME: 40,
    WAITING_LIST: 41,
} as const;

export type TStatusId = ValueOf<typeof STATUS>;

export class Status {
    id: TStatusId;
    name: string;
    description: string;

    constructor(id: TStatusId, name: string, description: string) {
        this.id = id;
        this.name = name;
        this.description = description;
    }

    getStatusName = (shortName?: string | boolean) => {
        if (this === TEReservationStatus.UNKNOWN) {
            return "-";
        }
        let result = Language.get(
            `dynamic_reserv_list_${this.name}${shortName ? "_short" : ""}` as any
        );
        if (!result) {
            result = this.name;
        }
        return result;
    };
}

const TEReservationStatus = {
    UNKNOWN: new Status(
        STATUS.UNKNOWN,
        "",
        "Status denoting an invalid, incorrect or unknown status"
    ),

    A_RESERVATION: new Status(
        STATUS.RESERVATION,
        "reservation",
        "Status for standard type reservations"
    ),
    A_AVAILABILITY: new Status(
        STATUS.AVAILABILITY,
        "availability",
        "Status for availability type reservations"
    ),
    A_INFO: new Status(STATUS.INFO, "info", "Status for info type reservations"),

    B_COMPLETE: new Status(STATUS.COMPLETE, "complete", "Status for complete reservations"),
    B_INCOMPLETE: new Status(STATUS.INCOMPLETE, "incomplete", "Status for incomplete reservations"),

    C_CONFIRMED: new Status(
        STATUS.CONFIRMED,
        "confirmed",
        "Status for accepted reservations that are not preliminary nor planned"
    ),
    C_PRELIMINARY: new Status(
        STATUS.PRELIMINARY,
        "preliminary",
        "Status for preliminary reservations"
    ),
    C_PLANNED: new Status(STATUS.PLANNED, "planned", "Status for planned reservations"),
    C_REQUESTED: new Status(STATUS.REQUESTED, "requested", "Status for reservation requests"),
    C_REJECTED: new Status(STATUS.REJECTED, "rejected", "Status for rejected reservation requests"),

    D_TIME: new Status(STATUS.TIME, "time", "Status for reservations with begin and end time"),
    E_WAITING_LIST: new Status(
        STATUS.WAITING_LIST,
        "waiting_list",
        "Status for reservations that do not contain begin or end time"
    ),
};

const statusForId = (id: TStatusId): Status => {
    let status: string;
    // eslint-disable-next-line no-restricted-syntax
    for (status in TEReservationStatus) {
        if (
            TEReservationStatus.hasOwnProperty(status) &&
            TEReservationStatus[status] instanceof Status
        ) {
            if (TEReservationStatus[status].id === id) {
                return TEReservationStatus[status];
            }
        }
    }
    return TEReservationStatus.UNKNOWN;
};

const statusForName = (name: string): Status => {
    let status: string;
    // eslint-disable-next-line no-restricted-syntax
    for (status in TEReservationStatus) {
        if (
            TEReservationStatus.hasOwnProperty(status) &&
            TEReservationStatus[status] instanceof Status
        ) {
            if (TEReservationStatus[status].name === name) {
                return TEReservationStatus[status];
            }
        }
    }
    return TEReservationStatus.UNKNOWN;
};

const TEReservationStatusWithFns = { ...TEReservationStatus, statusForId, statusForName };

export default TEReservationStatusWithFns;
