import API from "../../lib/TimeEditAPI";
import { Reservation } from "../../models/Reservation";
import { Macros } from "../../models/Macros";
import { MillenniumWeek, MillenniumDate } from "@timeedit/millennium-time";
import Log from "../../lib/Log";
import Language from "../../lib/Language";
import DataStore from "../../lib/DataStore";
import FieldInput, { FieldKindType } from "../../components/FieldInput";
const FieldKind = FieldInput.fieldKind;

import _ from "underscore";
import ObjectSettingsConstants from "../../lib/ObjectSettingsConstants";
import { testSearchFiltersData } from "./PrefsCoreAPI.testData";
import {
    doPopulateSelection,
    getFilterValue,
    isExact,
    mapReservationData,
    mapResults,
    parseDate,
    parseTime,
    selectType,
    treeToTypeList,
    validateTimezone,
} from "./PrefsCoreAPI.utils";

export const FORM_TYPES = {
    REGULAR: 1,
    AVAILABILITY: 2,
    INFO: 3, // Not yet sent by PiC, added for possible future use
    UNAVAILABILITY: 1, // Regular form
};

export const OPERATION_TYPES = {
    SELECT_OBJECT: "SELECT_OBJECT",
    SELECT_FILTER: "SELECT_FILTER",
    CREATE_RESERVATION: "CREATE_RESERVATION",
    CREATE_OBJECTS: "CREATE_OBJECTS",
    SET_WEEKS: "SET_WEEKS",
    CANCEL_AM_SCHEDULING: "CANCEL_AM_SCHEDULING",
};

export const REQUEST_TYPES = {
    NEW_OBJECT: "NEW_OBJECT",
    EDIT_OBJECT: "EDIT_OBJECT",
    MISSING_OBJECT: "MISSING_OBJECT",
};

const AM_ACTION = {
    SCHEDULE: "schedule",
    CANCEL: "cancel",
};

const SAFE_DAY_OFFSET = 4;

const coreAPI = (props, currentUser, setToolbar?: (args: any) => void) => ({
    // Return a promise from each, make sure Babel polyfills
    getExtIdProps: (values) => {
        return new Promise((resolve) => {
            API.getObjectNamesByExtid(values.objects, false, (objectResult) => {
                API.getTypesByExtid(values.types, true, (typeResult) => {
                    API.getFieldsByExtid(values.fields, (fieldResult) => {
                        resolve(mapResults(objectResult, typeResult, fieldResult));
                    });
                });
            });
        });
    }, // Values is an object with the properties fields, objects, and types. Return objects with labels
    selectObject: (indata) => {
        const type = _.find(indata, (element) => element.valueType === "TYPE_EXTID");
        const objects = _.filter(indata, (element) => element.valueType === "OBJECT_EXTID").map(
            (object) => object.extId
        );
        //const [obj, type] = objType;
        API.getObjectNamesByExtid(objects, false, (result) => {
            API.getTypesByExtid([type.extId], false, (typeResult) => {
                props.selectObject(result, typeResult[0]);
            });
        });
    }, // We need the type here as well to be able to do any good at all. Use handleAddObject in SelectionPane.jsx?
    selectType: (indata) => {
        const type = _.find(indata, (element) => element.valueType === "TYPE_EXTID");
        selectType(type.extId, (result) => {
            props.selectType(result);
        });
    }, // Guess what happens here is that the selection list is put on the right line, just as if the user had clicked it? Can I use a version of selectNextItem in SelectionPane.jsx?
    filterObjects: (criteria) => {
        props.setSearchCriteria(
            _.extend(criteria, {
                searchString: isExact(criteria.exactSearch)
                    ? criteria.searchString
                    : `%${criteria.searchString}%`,
            }),
            true,
            _.noop
        );
    },
    getReservationTypes: () => {
        return new Promise((resolve) => {
            API.getTypeTree((types) => {
                // Flatten the type tree to one level, but keep the all-type as root because PiC expects it
                const result = treeToTypeList(types, 0);
                result.shift();
                resolve({ extid: types.extid, id: types.id, name: types.name, subtypes: result });
            });
        });
    },
    getReservationFields: () => {
        return new Promise((resolve) => {
            API.getFieldDefsForReservations((fields) => {
                resolve(fields);
            });
        });
    },
    populateSelection: (reservationData) => {
        doPopulateSelection(reservationData, props);
    },
    // submissionValue is now a proper search criteria, just like in the old API call
    requestGetObjectFromFilter: (data) => {
        const value = getFilterValue(data.activityValue);
        mapReservationData(data.reservationData, false, (mappedData) => {
            const reservationData = { ...mappedData };
            delete reservationData.dateRanges;
            delete reservationData.startTime;
            delete reservationData.endTime;
            const criteria = _.extend({}, DataStore.deepFreeze(value), {
                searchString: isExact(value.exactSearch)
                    ? value.searchString
                    : `%${value.searchString}%`,
                type: data.activityValue.extId,
            });
            const finish = (params) => {
                props.requestOperation(OPERATION_TYPES.SELECT_OBJECT, params, (result) => {
                    if (!result) {
                        data.callback(null);
                    } else {
                        API.getObjectsByExtid(result, (objects) => {
                            data.callback(objects);
                        });
                    }
                });
            };
            props.setSearchCriteria(criteria, false, (resultCriteria) => {
                finish({
                    typeId: resultCriteria.type ? resultCriteria.type.id : undefined,
                    reservationData,
                });
            });
        });
    },
    requestGetFilterFromFilter: (data) => {
        const value = getFilterValue(data.activityValue);
        const criteria = {
            ...value,
            searchString: isExact(value.exactSearch)
                ? value.searchString
                : `%${value.searchString}%`,
            type: data.activityValue.extId,
        };

        const next = () => {
            props.setSearchCriteria(criteria, true, _.noop);
        };

        props.requestOperation(OPERATION_TYPES.SELECT_FILTER, { next }, (result) => {
            data.callback(result);
        });
    },
    requestReplaceObject: (data) => {
        API.getObjectNamesByExtid(
            data.objectExtId ? _.asArray(data.objectExtId) : [],
            false,
            (result) => {
                API.getTypesByExtid(_.asArray(data.typeExtId), false, (typeResult) => {
                    mapReservationData(data.reservationData, false, (mappedData) => {
                        const reservationData = { ...mappedData };
                        delete reservationData.dateRanges;
                        delete reservationData.startTime;
                        delete reservationData.endTime;
                        props.requestOperation(
                            OPERATION_TYPES.SELECT_OBJECT,
                            { objects: result, type: typeResult[0], reservationData },
                            (finalResult) => {
                                if (finalResult === null) {
                                    data.callback(null);
                                } else {
                                    API.getObjectsByExtid(finalResult, (objects) => {
                                        data.callback(objects);
                                    });
                                }
                            }
                        );
                    });
                });
            }
        );
    },
    requestHandleObjectRequest: ({ extId, fields, objectType, requestType, callback }) => {
        // Nicer styling of values from PIC - make them PIC-green, along with some indicator and explainer?
        // Option to discard value from PIC
        if (_.values(REQUEST_TYPES).indexOf(requestType) === -1) {
            Log.error(`Invalid request type ${requestType}.`);
            callback({ extId: null, label: null });
        }
        API.getTypesByExtid([objectType], false, (types) => {
            const performOperation = (objects) => {
                if (
                    requestType === REQUEST_TYPES.EDIT_OBJECT &&
                    _.any(objects, (obj) => obj.id === 0)
                ) {
                    Log.error(`No object found with external ID ${extId}.`);
                    return;
                }
                props.requestOperation(
                    requestType,
                    {
                        objectIds: objects.map((obj) => obj.id),
                        fields,
                        types: types.map((type) => type.id),
                    },
                    (createdObject) => {
                        if (createdObject === null) {
                            callback(null);
                        } else {
                            API.getObjectsByExtid(_.asArray(createdObject), (obs) => {
                                callback(obs[0]);
                            });
                        }
                    }
                );
            };
            if (extId) {
                API.getObjectNamesByExtid([extId], false, performOperation);
            } else {
                performOperation([]);
            }
        });
    },

    setToolbarContent: (toolbar) => {
        setToolbar?.(toolbar);
    },

    selectReservation: (activity) => {
        const reservationId = parseInt(activity.reservationId, 10);
        Reservation.get([reservationId], (reservations) => {
            if (reservations.length === 0 || reservations[0] === null) {
                const details = Language.get(
                    "nc_error_no_reservation_found_with_id",
                    reservationId
                );
                Log.error(details);
                return;
            }
            try {
                props.fireEvent(`prefsInCore`, Macros.Event.SELECT_RESERVATION, reservations[0]);
                props.onEntryInfoOpen([reservationId], false);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        });
    },

    validateReservations: ({ reservationIds, callback }) => {
        API.getReservations(reservationIds, (result) => {
            const invalidReservations: number[] = [];
            reservationIds.forEach((id, index) => {
                if (result[index] === null) {
                    invalidReservations.push(id);
                }
            });
            callback({ res: { invalidReservations } });
        });
    },

    setReservationMode: ({ mode, callback }) => {
        props.fireEvent(`prefsInCore`, Macros.Event.SET_EXTERNAL_TEMPLATE_GROUP, mode);
        callback({ res: "SUCCESS" }); // Future improvement if needed: listen for result of actual setting and return appropriately?
    },
    setFormType: ({ formType }) => {
        const type = FORM_TYPES[formType] || 1;
        props.fireEvent(`prefsInCore`, Macros.Event.SET_EXTERNAL_TEMPLATE_KIND, type);
    },
    getObjectsByExtid: ({ extids, callback }) => {
        API.getObjectsByExtid(extids, (result) => {
            callback(result);
        });
    },
    getCurrentUser: ({ callback }) => {
        callback(currentUser);
    },
    // Extids and templateKind can be skipped. templateKind defaults to reservations.
    // The function is NOT able to find a reservation mode outside of the given template kind.
    // Template kinds:
    // 1: Reservation
    // 2: Availability
    // 3: Info reservation
    // Extids, if present, are used as a filter on the result.
    // Without extids, all template groups of the given kind will be returned
    getReservationModes: ({ extids, templateKind, callback }) => {
        API.getTemplateGroups(templateKind || null, (result) => {
            const values = result.parameters[0];
            if (extids && extids.length > 0) {
                callback(values.filter((tG) => extids.indexOf(tG.extid) !== -1));
            } else {
                callback(values);
            }
        });
    },

    getCurrentLayer: (callback) => {
        callback(props.activeLayer);
    },

    getAvailableHeight: (callback) => {
        callback(props.size.height);
    },

    getFields: ({ typeExtIds, searchableOnly = true }) =>
        new Promise((resolve) => {
            API.getTypesByExtid(typeExtIds, false, (types) => {
                const IGNORE_KINDS = [
                    FieldKind.UNKOWN,
                    FieldKind.COMMENT,
                    FieldKind.REFERENCE,
                    FieldKind.TEXT_NO_SEARCH,
                    FieldKind.REFERENCE_NO_SEARCH,
                ];
                const typeResults = {};
                const calls = types.map((type) => (done) => {
                    API.getAllFields(type.id, (result) => {
                        API.getFieldDefs(
                            result.map((field) => field.id),
                            undefined,
                            (defs) => {
                                if (searchableOnly) {
                                    typeResults[type.extid] = defs.filter(
                                        (def) =>
                                            IGNORE_KINDS.indexOf(def.kind) === -1 &&
                                            def.searchable === true
                                    );
                                } else {
                                    typeResults[type.extid] = defs;
                                }
                                done();
                            }
                        );
                    });
                });
                _.runSync(calls, () => {
                    resolve(typeResults);
                });
            });
        }),

    getFieldIds: ({ typeExtIds, integerFieldsOnly = false, callback, includeKinds = false }) => {
        API.getTypesByExtid(typeExtIds, false, (types) => {
            const typeResults = {};
            const calls = types.map((type) => (done) => {
                API.getAllFields(type.id, (result) => {
                    API.getFieldDefs(
                        result.map((field) => field.id),
                        undefined,
                        (defs) => {
                            const mappedResult = {};
                            defs.filter((field) => {
                                if (
                                    integerFieldsOnly &&
                                    field.kind !== FieldInput.fieldKind.INTEGER
                                ) {
                                    return false;
                                }
                                return true;
                            }).forEach((field) => {
                                const { name, kind } = field;
                                const value = includeKinds ? { name, kind } : name;
                                mappedResult[field.extid] = value;
                            });
                            typeResults[type.extid] = mappedResult;
                            done();
                        }
                    );
                });
            });
            _.runSync(calls, () => {
                callback(typeResults);
            });
        });
    },

    getRelatedGroups: ({ objectIds, typeId }) =>
        new Promise((resolve) => {
            API.getTypesByExtid([typeId], true, (typeResult) => {
                const typeInt = typeResult[0].id;
                API.exportObjectsBatch(objectIds, (exportedObjects) => {
                    const result = {};
                    const calls = exportedObjects.map((exportedObject) => (done) => {
                        API.exportObjectsBatch(
                            _.flatten(exportedObject.related).map((rel) => rel.id),
                            (related) => {
                                result[exportedObject.extid] = related
                                    .filter(
                                        (rel) =>
                                            rel.active === true && rel.types.indexOf(typeInt) !== -1
                                    )
                                    .map((rel) => rel.extid);
                                done();
                            }
                        );
                    });
                    _.runSync(calls, () => {
                        resolve(result);
                    });
                });
            });
        }),

    getAllocationTypes: () => {
        return new Promise((resolve) => {
            API.getTypeTree((types) => {
                // Flatten the type tree to one level, but keep the all-type as root, mimicing what getReservationTypes does
                const result = treeToTypeList(types, 0);
                result.shift();
                resolve({ extid: types.extid, id: types.id, name: types.name, subtypes: result });
            });
        });
    },

    findObjects: (query) => {
        const isExactFieldKind = (fieldKind: FieldKindType) => {
            const EXACT_FIELD_KINDS: FieldKindType[] = [
                FieldKind.INTEGER,
                FieldKind.BOOLEAN,
                FieldKind.CATEGORY,
                FieldKind.LENGTH,
            ];
            return EXACT_FIELD_KINDS.indexOf(fieldKind) !== -1;
        };

        return new Promise((resolve) => {
            API.getTypesByExtid([query.type], false, (typeResult) => {
                const exactFields = query.fields
                    .filter((field) => isExactFieldKind(field.kind))
                    .map((field) => ({ extId: field.teExtId, values: field.values }));
                const generalFields = query.fields
                    .filter((field) => !isExactFieldKind(field.kind))
                    .map((field) => ({ extId: field.teExtId, values: field.values }));
                API.getFieldsByExtid(
                    [
                        ...exactFields.map((fld) => fld.extId),
                        ...generalFields.map((fld) => fld.extId),
                    ],
                    (fieldResult) => {
                        const exactSearchFields = exactFields
                            .map((fld) => {
                                const result = fieldResult.find((fR) => fR.extid === fld.extId);
                                result.values = fld.values;
                                return result;
                            })
                            .filter((field) => field.values.length > 0);
                        const generalSearchFields = generalFields.map((fld) =>
                            fieldResult.find((fR) => fR.extid === fld.extid)
                        );
                        const generalSearchString =
                            generalFields.length > 0 ? generalFields[0].values[0] || "" : "";
                        const apiQuery: any = {
                            type: typeResult[0].id,
                            exactSearchFields,
                            useExtid: true,
                        };
                        if (generalSearchString.length > 0 && generalSearchFields.length > 0) {
                            apiQuery.generalSearchString = generalSearchString;
                            apiQuery.generalSearchFields = generalSearchFields;
                        }
                        if (query.searchObjects) {
                            apiQuery.searchObjects = [...query.searchObjects];
                            apiQuery.relatedObjects = ObjectSettingsConstants.OTHER_OBJECTS.INCLUDE;
                        }
                        API.findObjects(apiQuery, (objects, totalNumber) => {
                            resolve({ objects, totalNumber });
                        });
                    }
                );
            });
        });
    },

    requestCreateObjects: (groupRequests = [] as any[]) => {
        return new Promise((resolve) => {
            API.exportObjectsBatch(
                groupRequests.map((gr) => gr.connectTo.extId),
                (primaryObjects) => {
                    API.getTypesByExtid(
                        groupRequests.map((gr) => gr.connectTo.typeExtId),
                        false,
                        (primaryTypes) => {
                            API.getTypesByExtid(
                                groupRequests.map((gr) => gr.typeExtId),
                                false,
                                (secondaryTypes) => {
                                    //console.log(primaryObjects, primaryTypes, secondaryTypes);
                                    const mappedRequests = groupRequests.map((gr) => gr);
                                    primaryObjects.forEach((primaryObject, index) => {
                                        mappedRequests[index].connectTo.object = primaryObject;
                                        mappedRequests[index].connectTo.object.name =
                                            primaryObject?.fields?.[0]?.values?.join(", ");
                                        mappedRequests[index].connectTo.type = primaryTypes[index];
                                        mappedRequests[index].type = secondaryTypes[index];
                                    });
                                    props.requestOperation(
                                        OPERATION_TYPES.CREATE_OBJECTS,
                                        { groupRequests: mappedRequests },
                                        (result) => {
                                            resolve(result);
                                        }
                                    );
                                }
                            );
                        }
                    );
                }
            );
        });
    },

    requestManuallyScheduleActivity: ({ reservationData, callback }) => {
        if (!validateTimezone(reservationData.timezoneInfo, currentUser.actualTimezone)) {
            callback({ action: AM_ACTION.CANCEL });
            return;
        }
        mapReservationData(DataStore.deepFreeze(reservationData), false, (data, useSpotlight) => {
            props.populateSelection(data, useSpotlight, () => {
                props.requestOperation(OPERATION_TYPES.CREATE_RESERVATION, {}, (reservationIds) => {
                    callback({
                        action: AM_ACTION.SCHEDULE,
                        payload: {
                            reservationId:
                                reservationIds && reservationIds.length > 0
                                    ? reservationIds[0]
                                    : null,
                        },
                    });
                });
            });
        });
    },

    /*

    Week-format:
    [
        [string, string], // week1
        [string, string] // week2
    ]
    Where weeks have the following format: “2022-11-13T00:00:00.000Z” - “2022-11-20T23:59:59.999Z” (edited)
    TimeEdit.prefsAPI.setWeeks([["2022-11-13T00:00:00.000Z", "2022-11-20T23:59:59.999Z"]])
    */

    // SCHED-1193: Pass in reservationMode in some AM-appropriate way
    requestManuallyScheduleTracks: (tracks, oldWeeks, oldCallback) => {
        let groupedActivities;
        let weeks;
        let onScheduleActivity;
        let timezoneInfo;
        let reservationMode = tracks.reservationMode;
        if (tracks.groupedActivities) {
            groupedActivities = tracks.groupedActivities;
            weeks = tracks.weeks || [];
            onScheduleActivity = tracks.onScheduleActivity;
            timezoneInfo = tracks.timezoneInfo || {};
        } else {
            groupedActivities = tracks;
            weeks = oldWeeks || [];
            onScheduleActivity = oldCallback;
        }
        //reservationMode = groupedActivities[Object.keys(groupedActivities)[0]][0].reservationMode;
        if (!validateTimezone(timezoneInfo, currentUser.actualTimezone)) {
            onScheduleActivity({ action: AM_ACTION.CANCEL });
            return;
        }
        const weekData = weeks.map(([start, end]) => ({
            start: parseTime(start, true),
            end: parseTime(end, true),
        }));
        const millenniumWeeks = weekData.map(
            (wD) =>
                new MillenniumWeek(
                    wD.start.addDays(SAFE_DAY_OFFSET),
                    Language.firstDayOfWeek,
                    Language.daysInFirstWeek
                )
        );
        props.requestOperation(
            OPERATION_TYPES.SET_WEEKS,
            {
                weeks: millenniumWeeks,
                tracks: groupedActivities,
                onScheduleActivity,
                reservationMode,
            },
            (result) => {
                // eslint-disable-next-line no-console
                console.log(result);
            }
        );
    },

    cancelManualScheduling: () => {
        props.requestOperation(OPERATION_TYPES.CANCEL_AM_SCHEDULING, {}, (result) => {
            // eslint-disable-next-line no-console
            console.log(result);
        });
    },

    requestTypeTree: async () =>
        new Promise((resolve, reject) => {
            API.getTypeTree((tree) => {
                if (tree.class !== "typenode") {
                    reject();
                    return;
                }

                resolve(tree);
            });
        }),

    requestRefresh: () => {
        props.fireEvent(`prefsInCore`, Macros.Event.RESERVATION_MADE_OR_MODIFIED, []);
    },

    getTimezones: () =>
        new Promise((resolve) => {
            API.getTimezones((systemTimezones) => resolve(systemTimezones));
        }),

    getCustomWeekNames: (startDate, endDate) => {
        return new Promise((resolve) => {
            if (!currentUser.useCustomWeekNames) {
                resolve([]);
                return;
            }
            API.getCustomWeekNames(
                parseDate(startDate).getDayNumber(),
                parseDate(endDate).getDayNumber(),
                (weekNames) =>
                    resolve(
                        weekNames.map((weekName) =>
                            Object.assign({}, weekName, {
                                startDate: new MillenniumDate(weekName.dayNumber).format(
                                    "yyyy-MM-dd"
                                ),
                                weekNumber: new MillenniumDate(weekName.dayNumber)
                                    .getMillenniumWeek(
                                        Language.firstDayOfWeek,
                                        Language.daysInFirstWeek
                                    )
                                    .getWeek(true),
                            })
                        )
                    )
            );
        });
    },

    testManuallyScheduleActivity: (data = 1) => {
        const reservationData = {
            fields: [],
            objects: [
                {
                    type: "room",
                    fields: [
                        {
                            fieldExtId: "room.type",
                            values: ["Lab"],
                        },
                        {
                            fieldExtId: "room.equipment",
                            values: ["Trådlöst nätverk"],
                        },
                    ],
                },
                {
                    type: "pgmevt",
                    id: "SA1006-1 (HT20)",
                },
            ],
            reservationMode: "schema_test_ap",
            formType: "REGULAR",
            startTime: [],
            endTime: [],
            dateRanges: {
                startTime: "2023-10-08T22:00:00.000Z",
                endTime: "2023-10-15T21:59:59.999Z",
            },
            duration: 120,
        };
        const reservationData2 = {
            fields: [],
            objects: [
                {
                    type: "room",
                    fields: [
                        {
                            fieldExtId: "room.type",
                            values: ["Lab", "Labb", "Möte", "Projektrum"],
                        },
                        {
                            fieldExtId: "room.equipment",
                            values: [
                                "Trådlöst nätverk",
                                "Bildskärm, 7 st",
                                "Förtittsskärm, 2 st",
                                "HDMI-ingång, 2 st",
                                "Dator, 2 st",
                            ],
                        },
                    ],
                },
                {
                    type: "pgmevt",
                    id: "SA1006-1 (HT20)",
                },
            ],
            reservationMode: "schema_test_ap",
            formType: "REGULAR",
            startTime: [],
            endTime: [],
            dateRanges: {
                startTime: "2023-10-08T22:00:00.000Z",
                endTime: "2023-10-15T21:59:59.999Z",
            },
            duration: 120,
        };
        const reservationData3 = {
            fields: [],
            objects: [
                {
                    type: "room",
                    id: "5ecfdc1b-281c-46ab-8c0e-6026de766711",
                },
                {
                    type: "pgmevt",
                    id: "SA1006-1 (HT20)",
                },
                {
                    type: "course",
                    id: "A11HIB",
                },
                {
                    type: "groups",
                    id: "group_A11INA-1 (HT22)-Alla",
                },
                {
                    type: "activity.teach_room_activity",
                    id: "_te_411562",
                },
                {
                    type: "person.teacher",
                    id: "u1mtfa1m",
                },
            ],
            reservationMode: "schema_test_ap",
            formType: "REGULAR",
            startTime: [],
            endTime: [],
            dateRanges: {
                startTime: "2023-10-08T22:00:00.000Z",
                endTime: "2023-10-15T21:59:59.999Z",
            },
            duration: 120,
        };
        const callback = (result) => {
            console.log(result);
        };
        let useData = reservationData;
        if (data === 2) {
            useData = reservationData2;
        }
        if (data === 3) {
            useData = reservationData3;
        }
        doPopulateSelection(useData, props, false).then(() => {
            props.requestOperation(OPERATION_TYPES.CREATE_RESERVATION, {}, (reservationIds) => {
                callback({
                    action: AM_ACTION.SCHEDULE,
                    payload: {
                        reservationId:
                            reservationIds && reservationIds.length > 0 ? reservationIds[0] : null,
                    },
                });
            });
        });
    },

    testSearchFilters: (testNum) => {
        if (testSearchFiltersData[testNum]) {
            props.setSearchCriteria(testSearchFiltersData[testNum]);
        }
    },

    testDateParsing: () => {
        let first = parseTime("2023-07-30T14:00:00.000Z", true);
        let second = parseTime("2023-07-30T14:00:00+00:00", true);
        console.log(first.toString(), second.toString(), first.getMts() === second.getMts());
        let third = parseTime("2024-09-15T23:00:00.000Z", true, "Europe/London");
        console.log(third.toString());
        first = parseTime("2023-08-20T13:59:59.999Z", true);
        second = parseTime("2023-08-20T13:59:59+00:00", true);
        console.log(first.toString(), second.toString(), first.getMts() === second.getMts());
    },
    testWeekParsing: () => {
        // eslint-disable-next-line no-magic-numbers
        const expectedWeeks = [
            31, 32, 33, 34, 35, 36, 38, 39, 40, 41, 42, 43, 31, 32, 33, 34, 35, 36, 38, 39, 40, 41,
            42, 43,
        ].sort();
        const testWeeks = [
            ["2023-07-30T14:00:00.000Z", "2023-08-06T13:59:59.999Z"],
            ["2023-10-22T13:00:00.000Z", "2023-10-29T12:59:59.999Z"],
            ["2023-10-15T13:00:00.000Z", "2023-10-22T12:59:59.999Z"],
            ["2023-09-17T14:00:00.000Z", "2023-09-24T13:59:59.999Z"],
            ["2023-09-03T14:00:00.000Z", "2023-09-10T13:59:59.999Z"],
            ["2023-08-06T14:00:00.000Z", "2023-08-13T13:59:59.999Z"],
            ["2023-10-01T13:00:00.000Z", "2023-10-08T12:59:59.999Z"],
            ["2023-08-20T14:00:00.000Z", "2023-08-27T13:59:59.999Z"],
            ["2023-10-08T13:00:00.000Z", "2023-10-15T12:59:59.999Z"],
            ["2023-09-24T14:00:00.000Z", "2023-10-01T12:59:59.999Z"],
            ["2023-08-27T14:00:00.000Z", "2023-09-03T13:59:59.999Z"],
            ["2023-08-13T14:00:00.000Z", "2023-08-20T13:59:59.999Z"],

            ["2023-07-30T14:00:00+00:00", "2023-08-06T13:59:59+00:00"],
            ["2023-10-22T13:00:00+00:00", "2023-10-29T12:59:59+00:00"],
            ["2023-10-15T13:00:00+00:00", "2023-10-22T12:59:59+00:00"],
            ["2023-09-17T14:00:00+00:00", "2023-09-24T13:59:59+00:00"],
            ["2023-09-03T14:00:00+00:00", "2023-09-10T13:59:59+00:00"],
            ["2023-08-06T14:00:00+00:00", "2023-08-13T13:59:59+00:00"],
            ["2023-10-01T13:00:00+00:00", "2023-10-08T12:59:59+00:00"],
            ["2023-08-20T14:00:00+00:00", "2023-08-27T13:59:59+00:00"],
            ["2023-10-08T13:00:00+00:00", "2023-10-15T12:59:59+00:00"],
            ["2023-09-24T14:00:00+00:00", "2023-10-01T12:59:59+00:00"],
            ["2023-08-27T14:00:00+00:00", "2023-09-03T13:59:59+00:00"],
            ["2023-08-13T14:00:00+00:00", "2023-08-20T13:59:59+00:00"],
        ];
        const weekData = testWeeks.map(([start, end]) => ({
            start: parseTime(start, true),
            end: parseTime(end, true),
        }));
        const millenniumWeeks = weekData.map(
            (wD) =>
                new MillenniumWeek(
                    wD.start.addDays(SAFE_DAY_OFFSET),
                    Language.firstDayOfWeek,
                    Language.daysInFirstWeek
                )
        );
        const shouldBeIncorrectWeeks = weekData.map(
            (wD) => new MillenniumWeek(wD.start, Language.firstDayOfWeek, Language.daysInFirstWeek)
        );
        const weekNumbers = millenniumWeeks.map((mW) => mW._week).sort();
        const incorrectWeekNumbers = shouldBeIncorrectWeeks.map((mW) => mW._week).sort();
        // eslint-disable-next-line no-console
        console.log(
            millenniumWeeks,
            weekNumbers,
            incorrectWeekNumbers,
            expectedWeeks,
            _.isEqual(expectedWeeks, weekNumbers)
        );
    },
});

export default coreAPI;
