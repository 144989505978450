import React from "react";
import ObjectSelect from "./ObjectSelect";
import Language from "../lib/Language";
import MultiSelect from "./MultiSelect";
import ListSettingsStore from "./ListSettingsStore";
import _ from "underscore";
import DateInput from "./DateInput";
import { ObjectSearch } from "../models/ObjectSearch";
import { MillenniumDateTime } from "@timeedit/millennium-time";

const DR_ALL = "ALL";
const DR_INTENTIONAL = "INTENTIONAL";
const DR_UNINTENTIONAL = "UNINTENTIONAL";

// Only 90 days are allowed
const DURATION_LIMIT = 91;

class ConflictListSettings extends React.Component {
    constructor(props) {
        super(props);
        this.displayName = "ConflictListSettings";
        let intentional = this.props.includeIntentionalDoubleReservations || false;
        let unintentional = this.props.includeUnintentionalDoubleReservations || false;

        if (!intentional && !unintentional) {
            intentional = true;
            unintentional = true;
        }
        let drState = DR_ALL;

        if (intentional && !unintentional) {
            drState = DR_INTENTIONAL;
        }
        if (!intentional && unintentional) {
            drState = DR_UNINTENTIONAL;
        }
        this.state = {
            // Search options
            selectedType: this.props.selectedType || 0,
            selectedObjectType: 0,
            selectedFilterObjectType: 0,
            beginTime: this.props.beginTime || 0,
            endTime: this.props.endTime || 0,
            selectedReservationMode: this.props.selectedReservationMode || 0,
            includeUnintentionalDoubleReservations:
                this.props.includeUnintentionalDoubleReservations !== undefined
                    ? this.props.includeUnintentionalDoubleReservations
                    : true,
            includeIntentionalDoubleReservations: intentional,
            includeCollisionsOutsideOfFilters: unintentional,
            doubleReservationState: drState,
            objects: this.props.objects || [],
            filterObjects: this.props.filterObjects || [],
            objectCategories: props.objectCategories || [],
            objectSearch: new ObjectSearch({}, this.props.context.user.showExtraInfo).freeze(),
            filterObjectSearch: new ObjectSearch(
                {},
                this.props.context.user.showExtraInfo
            ).freeze(),
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.defaultSettings, this.props.defaultSettings)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(this.getInitialState(this.props));
        }
        if (!_.isEqual(this.state, prevState)) {
            const settings = _.clone(this.state);
            this.props.onChange(settings);
        }
    }

    onBeginTimeChanged(beginDate) {
        this.setState({
            beginTime: MillenniumDateTime.fromDate(beginDate).getMts(),
        });
    }

    onEndTimeChanged(endDate) {
        this.setState({
            endTime: MillenniumDateTime.fromDate(endDate).getMts(),
        });
    }

    onTypeChange(event) {
        this.setState({ selectedType: parseInt(event.target.value, 10) });
    }

    onReservationModeChange(event) {
        this.setState({ selectedReservationMode: parseInt(event.target.value, 10) });
    }

    onIncludeUnintentionalDoubleReservationsToggle() {
        this.setState({
            includeUnintentionalDoubleReservations:
                !this.state.includeUnintentionalDoubleReservations,
        });
    }

    onIncludeIntentionalDoubleReservationsToggle() {
        this.setState({
            includeIntentionalDoubleReservations: !this.state.includeIntentionalDoubleReservations,
        });
    }

    onIncludeCollisionsOutsideOfFiltersToggle() {
        this.setState({
            includeCollisionsOutsideOfFilters: !this.state.includeCollisionsOutsideOfFilters,
        });
    }

    onAddObject(object) {
        // eslint-disable-next-line no-console
        console.log(object);
        this.setState(
            {
                objects: [...this.state.objects, object],
            },
            () => {
                this.setState({
                    objectSearch: this.state.objectSearch.setSearchProperty(
                        "excludeObjects",
                        this.state.objects.map((o) => o.id)
                    ),
                });
            }
        );
    }

    onRemoveObject(event) {
        const objectId = parseInt(event.target.value, 10);
        // eslint-disable-next-line no-console
        console.log(objectId);
        this.setState(
            {
                objects: this.state.objects.filter((o) => o.id !== objectId),
            },
            () => {
                this.setState({
                    objectSearch: this.state.objectSearch.setSearchProperty(
                        "excludeObjects",
                        this.state.objects.map((o) => o.id)
                    ),
                });
            }
        );
    }

    onAddFilterObject(object) {
        // eslint-disable-next-line no-console
        console.log(object);
        this.setState(
            {
                filterObjects: [...this.state.filterObjects, object],
            },
            () => {
                this.setState({
                    filterObjectSearch: this.state.filterObjectSearch.setSearchProperty(
                        "excludeObjects",
                        this.state.filterObjects.map((o) => o.id)
                    ),
                });
            }
        );
    }

    onRemoveFilterObject(event) {
        const objectId = parseInt(event.target.value, 10);
        // eslint-disable-next-line no-console
        console.log(objectId);
        this.setState(
            {
                filterObjects: this.state.filterObjects.filter((fO) => fO.id !== objectId),
            },
            () => {
                this.setState({
                    filterObjectSearch: this.state.filterObjectSearch.setSearchProperty(
                        "excludeObjects",
                        this.state.filterObjects.map((o) => o.id)
                    ),
                });
            }
        );
    }

    // typeid
    // start
    // end
    // includeUnintentionalDoubleReservations = true
    // includeIntentionalDoubleReservations = false
    // includeCollisionsOutsideOfFilters = false

    // objectIds
    // filterObjectIds
    // filterReservationModes

    onChangeObjectType(event) {
        this.setState({ selectedObjectType: parseInt(event.target.value, 10) });
    }

    onChangeFilterObjectType(event) {
        this.setState({ selectedFilterObjectType: parseInt(event.target.value, 10) });
    }

    isRunButtonActive() {
        const start = new MillenniumDateTime(this.state.beginTime);
        const end = new MillenniumDateTime(this.state.endTime);
        return (
            !this.props.conflictRunning &&
            this.state.selectedType > 0 &&
            MillenniumDateTime.lengthInDays(start, end) < DURATION_LIMIT
        );
    }

    onDoubleReservationOptionsChanged(event) {
        const value = event.target.value;
        if (value === DR_ALL) {
            this.setState({
                includeIntentionalDoubleReservations: true,
                includeUnintentionalDoubleReservations: true,
                doubleReservationState: value,
            });
        } else if (value === DR_UNINTENTIONAL) {
            this.setState({
                includeIntentionalDoubleReservations: false,
                includeUnintentionalDoubleReservations: true,
                doubleReservationState: value,
            });
        } else {
            this.setState({
                includeIntentionalDoubleReservations: true,
                includeUnintentionalDoubleReservations: false,
                doubleReservationState: value,
            });
        }
    }

    _renderTypeChooser(value, onChange) {
        return (
            <select className="replacePanelSelectList" value={value} onChange={onChange}>
                <option key={0} value={0}>
                    {Language.get("admin_choose_type")}
                </option>
                {this.props.types.map((t) => (
                    <option key={t.id} value={t.id}>
                        {t.name}
                    </option>
                ))}
            </select>
        );
    }

    _renderObjectSelection(title = Language.get("cal_res_side_reservation_objects")) {
        return (
            <div className="objectSelectList" style={{ height: "200px" }}>
                {title ? <span className="settingsLabel massChangeLabel">{title}</span> : null}
                {this._renderTypeChooser(
                    this.state.selectedObjectType,
                    this.onChangeObjectType.bind(this)
                )}
                {
                    <ObjectSelect
                        width={"100%"}
                        selectedType={_.find(
                            this.props.types,
                            (tp) => tp.id === this.state.selectedObjectType
                        )}
                        excludeObjects={this.state.objects.map((obj) => obj.id)}
                        selectedFluffyItem={null}
                        onClick={this.onAddObject.bind(this)}
                        onObjectInfo={null}
                        addReloadFunction={null}
                        ref="objectSelection"
                        onRowHover={null}
                        hoverButton={null}
                        modalKey={"allowSecondModal"}
                        highlightSelection={false}
                        reservationIds={[]}
                        useMacros={false}
                        context={this.props.context}
                        user={this.props.context.user}
                    />
                }
            </div>
        );
    }

    _renderObjectFilterSelection() {
        return (
            <div className="objectSelectList" style={{ height: "200px" }}>
                {this._renderTypeChooser(
                    this.state.selectedFilterObjectType,
                    this.onChangeFilterObjectType.bind(this)
                )}
                {
                    <ObjectSelect
                        width={"100%"}
                        selectedType={_.find(
                            this.props.types,
                            (tp) => tp.id === this.state.selectedFilterObjectType
                        )}
                        excludeObjects={this.state.filterObjects.map((obj) => obj.id)}
                        selectedFluffyItem={null}
                        onClick={this.onAddFilterObject.bind(this)}
                        onObjectInfo={null}
                        addReloadFunction={null}
                        ref="objectSelection"
                        onRowHover={null}
                        hoverButton={null}
                        modalKey={"allowSecondModal"}
                        highlightSelection={false}
                        reservationIds={[]}
                        useMacros={false}
                        context={this.props.context}
                        user={this.props.context.user}
                    />
                }
            </div>
        );
    }

    _renderObjectList(objects, onClick) {
        return (
            <select onChange={onClick} size={5}>
                {objects.map((obj) => (
                    <option key={obj.id} value={obj.id}>
                        {obj.name}
                    </option>
                ))}
            </select>
        );
    }

    onObjectCheckboxChange(fieldId, isReservationFields, event) {
        let value = event.target.value;
        if (value === "1") {
            value = ["1"];
        } else if (value === "0") {
            value = ["0"];
        } else {
            value = [];
        }
        this.onCategoriesChange(fieldId, isReservationFields, value);
    }

    onCategoriesChange(fieldId, isReservationFields, values) {
        const newValues = {};
        newValues[String(fieldId)] = values;
        if (isReservationFields) {
            const finalValues = _.omit(
                _.extend({}, this.state.reservationCategories, newValues),
                (value) => _.isNullish(value) || (_.isArray(value) && value.length === 0)
            );
            this.setState({
                reservationCategories: finalValues,
            });
        } else {
            const finalValues = _.omit(
                _.extend({}, this.state.objectCategories, newValues),
                (value) => _.isNullish(value) || (_.isArray(value) && value.length === 0)
            );
            this.setState({
                objectCategories: finalValues,
            });
        }
    }

    isCategorySelected(fieldId, option, isReservationFields) {
        if (isReservationFields) {
            return (
                this.state.reservationCategories[String(fieldId)] &&
                this.state.reservationCategories[String(fieldId)].indexOf(option) > -1
            );
        }
        return (
            this.state.objectCategories[String(fieldId)] &&
            this.state.objectCategories[String(fieldId)].indexOf(option) > -1
        );
    }

    _renderCategories(title, categories, isReservationFields = false) {
        if (!categories || categories.length === 0) {
            return null;
        }
        const options = categories.map((category) => {
            if (!category.categories) {
                return [];
            }
            return category.categories.map((opt) => ({
                value: opt,
                label: opt,
                selected: this.isCategorySelected(category.id, opt, isReservationFields),
            }));
        });
        return (
            <div className="settingsLine">
                <label>{title}</label>
                {options.map((cats, index) => (
                    <div className="categoryList" key={index}>
                        <label>{categories[index].name}</label>
                        <MultiSelect
                            className="multiSelect"
                            options={cats}
                            onValueChanged={this.onCategoriesChange.bind(
                                this,
                                categories[index].id,
                                isReservationFields
                            )}
                        />
                    </div>
                ))}
            </div>
        );
    }

    getCheckboxValue(checkboxId, isReservationFields) {
        const values = isReservationFields
            ? this.state.reservationCategories[String(checkboxId)]
            : this.state.objectCategories[String(checkboxId)];
        if (values) {
            return values[0];
        }
        return "";
    }

    _renderCheckboxes(title, checkboxes, isReservationFields = false) {
        if (!checkboxes || checkboxes.length === 0) {
            return null;
        }
        return (
            <div className="settingsLine">
                <label>{title}</label>
                {checkboxes.map((checkbox, index) => {
                    const selectedValue = this.getCheckboxValue(checkbox.id, isReservationFields);
                    return (
                        <div className="categoryList" key={index}>
                            <label>{checkbox.name} </label>
                            <select
                                onChange={this.onObjectCheckboxChange.bind(
                                    this,
                                    checkboxes[index].id,
                                    isReservationFields
                                )}
                                value={selectedValue}
                            >
                                <option value={""}>-</option>
                                <option value={1}>{Language.get("dynamic_field_yes")}</option>
                                <option value={0}>{Language.get("dynamic_field_no")}</option>
                            </select>
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        return (
            <div data-cy="list-settings">
                <h3 className="core-header">{Language.get("nc_list_search_settings_title")}</h3>

                <ListSettingsStore
                    searchSettings={this.state}
                    onChange={(search) => this.setState(search)}
                    getSavedSettings={this.props.getSavedSettings}
                    saveSettings={this.props.saveSettings}
                    removeSettings={this.props.removeSettings}
                    saveDefaultSettings={this.props.saveDefaultSettings}
                />

                <div className="settingsContainer">
                    <div className="settingsLine">
                        <label>{Language.get("dynamic_object_info_type")}</label>
                        <select
                            onChange={this.onTypeChange.bind(this)}
                            value={this.state.selectedType}
                        >
                            <option key={0} value={0}>
                                {Language.get("admin_choose_type")}
                            </option>
                            {this.props.types.map((t) => (
                                <option key={t.id} value={t.id}>
                                    {t.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <hr />

                    <div className="settingsLine">
                        <label>{Language.get("dynamic_reserv_list_reservation_situation")}</label>
                        <select
                            onChange={this.onReservationModeChange.bind(this)}
                            value={this.state.selectedReservationMode}
                        >
                            {this.props.reservationModes.map((m) => (
                                <option key={m.id} value={m.id}>
                                    {m.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="settingsLine">
                        <label>{Language.get("nc_conflict_filter_objects")}</label>
                        {this._renderObjectFilterSelection()}
                        {this._renderObjectList(
                            this.state.filterObjects,
                            this.onRemoveFilterObject.bind(this)
                        )}
                    </div>

                    <div className="settingsLine">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={this.state.includeCollisionsOutsideOfFilters}
                                            onChange={this.onIncludeCollisionsOutsideOfFiltersToggle.bind(
                                                this
                                            )}
                                        />
                                    </td>
                                    <td>{Language.get("nc_conflict_include_outside_filters")}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <hr />

                    <div className="settingsLine">
                        <label>{Language.get("nc_conflict_start")}</label>
                        <DateInput
                            defaultValue={new MillenniumDateTime(
                                this.state.beginTime
                            ).getMillenniumDate()}
                            disabled={false}
                            onUpdate={this.onBeginTimeChanged.bind(this)}
                        />
                    </div>
                    <div className="settingsLine">
                        <label>{Language.get("nc_conflict_end")}</label>
                        <DateInput
                            defaultValue={new MillenniumDateTime(
                                this.state.endTime
                            ).getMillenniumDate()}
                            disabled={false}
                            onUpdate={this.onEndTimeChanged.bind(this)}
                        />
                    </div>

                    <hr />

                    <div className="settingsLine">
                        <label>{Language.get("cal_res_side_reservation_objects")}</label>
                        {this._renderObjectSelection()}
                        {this._renderObjectList(this.state.objects, this.onRemoveObject.bind(this))}
                    </div>

                    <hr />

                    {this._renderCheckboxes(
                        Language.get("nc_lists_object_checkboxes"),
                        this.props.availableObjectCheckboxes,
                        false
                    )}
                    {this._renderCategories(
                        Language.get("nc_lists_object_categories"),
                        this.props.availableObjectCategories,
                        false
                    )}

                    <hr />

                    <div className="settingsLine">
                        <label>{Language.get("nc_conflict_double_reservations")}</label>
                        <select
                            value={this.state.doubleReservationState}
                            onChange={this.onDoubleReservationOptionsChanged.bind(this)}
                            data-cy="dbl-rsvn-menu"
                        >
                            <option value={DR_ALL} data-cy="dr-all">
                                {Language.get("dynamic_object_list_all")}
                            </option>
                            <option value={DR_UNINTENTIONAL} data-cy="dr-unintentional">
                                {Language.get("nc_conflict_double_reservation_unintentional_only")}
                            </option>
                            <option value={DR_INTENTIONAL} data-cy="dr-intentional">
                                {Language.get("nc_conflict_double_reservation_intentional_only")}
                            </option>
                        </select>
                    </div>
                </div>
                {/*                 <div className="summaryButtonContainer">
                    <button
                        onClick={this.props.runReport}
                        disabled={!this.isRunButtonActive()}
                        className="uiText teButton"
                    >
                        {Language.get("nc_conflict_run_report")}
                    </button>
                </div> */}
            </div>
        );
    }
}

export default ConflictListSettings;
