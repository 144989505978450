import React, { useEffect, useState } from "react";
import { TObject } from "../types/api/shared";
import { TFieldValue, TSplitObjectResult } from "../types/components/SplitObjectDialog";
import { Col, Form, InputNumber, Row, Slider } from "antd";
import Input from "antd/lib/input/Input";
import { Rule } from "antd/lib/form";
import Language from "../lib/Language";
import API from "../lib/TimeEditAPI";
import { TFieldDefClass, TObjectTypeFieldCatClass } from "../types/api/APIClassTypes";
import Log from "../lib/Log";

const getFieldValidationRules = (oldValue: string): Rule[] => [
    {
        message: Language.get("nc_split_object_dialog_field_validation_required"),
        required: true,
    },
    {
        message: Language.get("nc_split_object_dialog_field_validation_unique"),
        validator: async (rule, value) => {
            // New object can't use the old object's value.
            if (value === oldValue) {
                return Promise.reject();
            }

            return Promise.resolve();
        },
    },
];

type TSplitObjectDialogProps = {
    object: TObject;
    fieldDefs: TFieldDefClass[];
    initialSize: number;
    remainingCapacity: number;
    onDone: (res: TSplitObjectResult) => void;
    onCancel: () => void;
    templateId: number;
    reservationObjects: TObjectTypeFieldCatClass[];
};

const SplitObjectDialog = (props: TSplitObjectDialogProps) => {
    const [oldObjectSize, setOldObjectSize] = useState<number>(props.initialSize);
    const [newObjectSize, setNewObjectSize] = useState<number>(0);
    const [oldFieldValues, setOldFieldValues] = useState<TFieldValue[]>([]);
    const [newFieldValues, setNewFieldValues] = useState<TFieldValue[]>([]);
    const [canSubmit, setCanSubmit] = useState<boolean>(true);

    const [form] = Form.useForm();

    const updateSize = (val: number | string | null) => {
        if (val !== null) {
            const newSize = parseInt(val as string, 10);
            setNewObjectSize(newSize);
            setOldObjectSize((props.initialSize ?? 0) - newSize);
        }
    };

    const updateOldSize = (val) => {
        if (val !== null) {
            const newSize = parseInt(val as string, 10);
            setOldObjectSize(newSize);
            setNewObjectSize((props.initialSize ?? 0) - newSize);
        }
    };

    const updateFieldValue = (fieldId, value) => {
        const updatedFields = [...newFieldValues];
        if (!updatedFields.find((field) => field.id === fieldId)) {
            updatedFields.push({ id: fieldId, values: [] });
        }
        const updatedNewFieldValues = updatedFields.map((fieldValue) => {
            if (fieldValue.id === fieldId) {
                return { ...fieldValue, values: [value] };
            }
            return fieldValue;
        });
        setNewFieldValues(updatedNewFieldValues);
    };

    const getValue = (fieldId: number) => {
        const fieldValue = newFieldValues.find((fieldValue) => fieldValue.id === fieldId);
        return fieldValue ? fieldValue.values[0] : "";
    };
    const getOldValue = (fieldId: number) => {
        const fieldValue = oldFieldValues.find((fieldValue) => fieldValue.id === fieldId);
        return fieldValue ? fieldValue.values[0] : "";
    };

    const hasFieldUniqueErrorMsg = (teServerErrorString: string, fieldValue: string): boolean =>
        teServerErrorString?.includes(`the value ${fieldValue} is not unique`) ?? false;

    const setErrorOnFields = (msgWithFieldError: string): boolean => {
        const fields = form.getFieldsValue();
        const fieldsWithErrors: { fieldName: string; errorMsg: string }[] = [];

        for (const [fieldName, fieldValue] of Object.entries<string>(fields)) {
            // Find out if field is referenced in backend error.
            if (hasFieldUniqueErrorMsg(msgWithFieldError, fieldValue)) {
                fieldsWithErrors.push({
                    fieldName,
                    errorMsg: Language.get("nc_split_object_dialog_field_validation_unique"),
                });
            }
        }

        if (!fieldsWithErrors.length) return false;

        // Set field errors in form.
        form.setFields(
            fieldsWithErrors.map((f) => ({
                name: f.fieldName,
                errors: [f.errorMsg],
            }))
        );

        return true;
    };

    const onSubmit = async () => {
        API.partitionObject(
            props.object.id,
            0,
            props.templateId,
            newObjectSize,
            newFieldValues,
            (partitionObjs) => {
                const objectIds = [...partitionObjs, ...props.reservationObjects]
                    ?.map((o) => o.id)
                    .filter((id) => id !== props.object.id);

                const transformedObjects = props.reservationObjects.map((obj) => {
                    if (obj.id === props.object.id) {
                        return {
                            ...obj,
                            id: partitionObjs[0].id,
                        };
                    }
                    return obj;
                });

                props.onDone({
                    transformedObjects,
                    adjustedSize: newObjectSize,
                    objectIds: objectIds[0],
                    mandatoryFieldValues: newFieldValues,
                });
            },
            (errorResult) => {
                const didSetFieldErrors = setErrorOnFields(errorResult.message);
                if (!didSetFieldErrors) Log.warning(errorResult.message);
            }
        );
    };

    const getFieldName = (fieldId: number) => props.fieldDefs.find((f) => f.id === fieldId)?.name;

    useEffect(() => {
        const uniqueFieldIds = props.fieldDefs.filter((f) => f.unique).map((f) => f.id);
        const fieldsToSet = props.object.fields.filter((f) => uniqueFieldIds.includes(f.id));

        setNewFieldValues(fieldsToSet);

        // Set the old values for validation purposes.
        setOldFieldValues(fieldsToSet);

        // If we don't have remainingCapacity at all or if we have enough remainingCapacity
        if (!props.remainingCapacity || props.initialSize < props.remainingCapacity) {
            // Set initial split size to half.
            updateSize(props.initialSize / 2);
        } else {
            // When we don't have enough remainingCapacity for total size, it makes sense for the user to set old size to the remaining capacity.
            updateOldSize(props.remainingCapacity);
        }

        form.validateFields().then(() => {
            handleFormChange();
        });
    }, [props.object.fields, props.fieldDefs]);

    const handleFormChange = () => {
        const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
        setCanSubmit(hasErrors === false);
    };

    const handleCancel = (e: React.MouseEvent) => {
        e.preventDefault();
        props.onCancel();
    };

    return (
        <Form
            form={form}
            onFinish={() => onSubmit()}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            requiredMark={false}
            onFieldsChange={handleFormChange}
        >
            <Row gutter={20} align="middle">
                <Col span={10} offset={2}>
                    <Form.Item
                        label={Language.get("nc_split_object_dialog_field_label_existing_size")}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <InputNumber
                            min={0}
                            max={props.initialSize}
                            value={oldObjectSize}
                            onChange={updateOldSize}
                        />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item
                        label={Language.get("nc_split_object_dialog_field_label_new_size")}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <InputNumber
                            value={newObjectSize}
                            min={0}
                            max={props.initialSize}
                            onChange={updateSize}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20} align="middle">
                <Col span={20} offset={2}>
                    <Slider
                        min={0}
                        max={props.initialSize}
                        value={(props.initialSize ?? 0) - (newObjectSize ?? 0)}
                        onChange={updateOldSize}
                        included={false}
                        tooltip={{ open: false }}
                        marks={
                            props.initialSize
                                ? {
                                      0: Language.get(
                                          "nc_split_object_dialog_slider_marker_existing"
                                      ),
                                      [props.initialSize]: Language.get(
                                          "nc_split_object_dialog_slider_marker_new"
                                      ),
                                  }
                                : { 0: "" }
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col span={20} offset={2}>
                    <Form.Item
                        label={Language.get("nc_split_object_dialog_unique_fields_label")}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {newFieldValues.map((field) => (
                            <FieldValueRow
                                key={`${field.id}`}
                                id={field.id}
                                oldValue={getOldValue(field.id) ?? ""}
                                value={getValue(field.id) ?? ""}
                                name={getFieldName(field.id) ?? ""}
                                onChange={(e) => updateFieldValue(field.id, e.target.value)}
                            />
                        ))}
                    </Form.Item>
                </Col>
            </Row>
            <div className="buttons btnGroup horizontal">
                <button className="modernButton" onClick={handleCancel}>
                    {Language.get("dialog_cancel")}
                </button>
                <button
                    className="modernButton"
                    onClick={() => form.submit()}
                    disabled={!canSubmit}
                >
                    {Language.get("nc_split_object_dialog_button_split")}
                </button>
            </div>
        </Form>
    );
};

const FieldValueRow = (props: {
    id: number;
    name: string;
    value: string;
    oldValue: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
}) => (
    <Row gutter={[4, 4]}>
        <Col span={24}>
            <Form.Item
                rules={getFieldValidationRules(props.oldValue)}
                name={`fieldValueRow${props.id}`}
                initialValue={`${props.value}`}
                style={{ marginBottom: 0 }}
                validateFirst
            >
                <Input
                    addonBefore={
                        <div
                            title={props.name}
                            style={{
                                textAlign: "left",
                                width: "150px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {props.name}
                        </div>
                    }
                    size="small"
                    value={props.value}
                    onChange={props.onChange}
                    name={`fieldValueRow${props.id}`}
                />
            </Form.Item>
        </Col>
    </Row>
);

export default SplitObjectDialog;
