import PropTypes from "prop-types";
import React from "react";

// Migration to class component with 'react-codemod' failed, explained here:
// https://github.com/reactjs/react-codemod/blob/master/LEGACY.md#explanation-of-the-new-es2015-class-transform-with-property-initializers
import createReactClass from "create-react-class";

import { Table } from "@timeedit/tecore-table";
import { TimeEdit } from "../lib/TimeEdit";
import Viewer from "../lib/Viewer";
import ContextMenu from "../lib/ContextMenu";
import API from "../lib/TimeEditAPI";
import { MillenniumDateTime, SimpleDateFormat } from "@timeedit/millennium-time";
import Language from "../lib/Language";
import Popover from "./Popover";
import _ from "underscore";
import LayerComponent from "../lib/LayerComponent";
import EmailDialog from "./EmailDialog";
import { TimeConstants as TC } from "../lib/TimeConstants";
import RC from "../lib/ReservationConstants";
import Log from "../lib/Log";
import { Macros } from "../models/Macros";
import TemplateKind from "../models/TemplateKind";
import MassChangeController from "./MassChangeController";
import Mousetrap from "@timeedit/mousetrap";
import ColumnConstants from "../lib/ColumnConstants";
import FieldInput from "./FieldInput";

const {
    TYPE_COLUMN_OFFSET,
    FIELD_COLUMN_OFFSET,
    getCollisionColumn,
    collisionCellRenderer,
    checkboxCellRenderer,
} = ColumnConstants;

const MAX_RESERVATIONS_TO_MAIL = 50;
const MAX_RESERVATION_EXPORT = 2000;
const MAX_RESERVATION_BATCH = 1000;
const DEFAULT_NUMBER_OF_ROWS = 1000;

let GenericList = createReactClass({
    displayName: "GenericList",

    getDefaultProps() {
        return {
            showSearchInterval: true,
            supportsEmail: false,
            addTypeColumns: false,
            allowMultiSelection: false,
            showSelectionSummary: true,
            supportsSearch: true,
            manualSearchButton: false,
            isSearchButtonActive: false,
            isSortable: () => false,
            showLoadIndicator: false,
        };
    },

    contextTypes: {
        user: PropTypes.object,
        update: PropTypes.func,
        registerMacro: PropTypes.func,
        deregisterMacro: PropTypes.func,
        presentModal: PropTypes.func,
        dismissModal: PropTypes.func,
        fireEvent: PropTypes.func,
        getViewId: PropTypes.func,
        env: PropTypes.object,
        envIsNotProduction: PropTypes.func,
    },

    getInitialState() {
        return {
            types: [], // Only used for column names.
            columnFields: [], // Used by reservation-type lists - contains fields which may be listed as columns
            data: [],
            loadId: 0,
            selectedIds: [],
            startRow: 0,
            totalNumberOfRows: 0,
            showEmailField: false,
            emailData: null,
            isWorking: false,
            performed: 0,
            columnWidths: [],
            selectedColumns: this.props.defaultSelectedColumns,
            // eslint-disable-next-line no-magic-numbers
            selectedInfoColumns: [1, 2, 4],
            sortOrder: 3,
            sortColumn: null,
            isLoadingSelection: false,
            isSearchHelpVisible: false,
        };
    },

    onSelectedIdsChanged(newSelectedIds) {
        if (this.props.onSelectionChanged) {
            const newSelectedObjects = this.state.data.filter((reservation) =>
                _.contains(newSelectedIds, reservation.id)
            );
            this.props.onSelectionChanged(newSelectedObjects);
        }
    },

    componentDidMount() {
        this._isMounted = true;
        this._mountFinished = false;
        if (this.props.isActive) {
            this.registerKeyboardShortcuts(this.props);
        }
        if (this.props.setFindFunction) {
            this.props.setFindFunction(() => {
                this.find(0);
            });
        }

        this.registerMacros();

        const finishMount = () => {
            API.findTypes((types) => this.setState({ types }));
            if (this.props.addTypeColumns) {
                API.getFieldsForReservationLists((fields) =>
                    this.setState({ columnFields: fields })
                );
            }
            this.props.setLayerContentProvider(this.getLayerContent);

            this.loadSettings(this.props.data.listId, (newSettings) => {
                const allColumns = this.getAvailableColumns().map((column) => column.id);
                let selectedColumns = this.getInitialState().selectedColumns;
                if (newSettings.selectedColumns) {
                    selectedColumns = newSettings.selectedColumns.filter(
                        (columnId) =>
                            columnId > TYPE_COLUMN_OFFSET || allColumns.indexOf(columnId) !== -1
                    );
                }
                if (selectedColumns.length === 0) {
                    selectedColumns = this.getInitialState().selectedColumns;
                }
                if (
                    !newSettings.columnWidths ||
                    selectedColumns.length !== newSettings.columnWidths.length
                ) {
                    const width = 1 / selectedColumns.length;
                    // eslint-disable-next-line no-param-reassign
                    newSettings.columnWidths = _.fill(_.clone(selectedColumns), width);
                }
                // eslint-disable-next-line no-param-reassign
                newSettings.selectedColumns = selectedColumns;
                this.setState(newSettings, () => {
                    this._mountFinished = true;
                    this.props.onSearchSettingsChange(
                        _.omit(newSettings, ["selectedColumns", "columnWidths"])
                    );
                });
            });

            if (this.props.setRefreshFunction) {
                this.props.setRefreshFunction((refreshData) => {
                    if (refreshData && refreshData[0] === true) {
                        this.find(0);
                    } else {
                        this.find();
                    }
                });
            }

            const selection = this.getSelection();
            if (!selection.fluffy) {
                this.createFluffy(this.props);
            }
        };
        API.getPreferences(
            `listSortOrder.${this.context.getViewId()}.${this.props.type}.${this.props.id}`,
            undefined,
            undefined,
            (result) => {
                if (result) {
                    const sortData = JSON.parse(result as any);
                    this.setState(
                        {
                            sortColumn: sortData.sortBy,
                            sortOrder: sortData.sortDirection === "ASC" ? 0 : 1,
                        },
                        finishMount
                    );
                } else {
                    finishMount();
                }
            }
        );
    },

    registerMacros() {
        this.context.registerMacro(`reservationList${this.props.id}`, {
            events: [Macros.Event.LIST_RESERVATION_GROUP],
            actions: [
                {
                    key: Macros.Action.LIST_RESERVATION_GROUP,
                    action: (groupIdentifier) => {
                        if (groupIdentifier.indexOf(" ") !== -1) {
                            this.onSearchStringChange({
                                target: { value: `g:"${groupIdentifier}"` },
                            });
                        } else {
                            this.onSearchStringChange({
                                target: { value: `g:${groupIdentifier}` },
                            });
                        }
                    },
                },
            ],
        });
    },

    createFluffy(props = this.props) {
        const now = Date.now();
        this._lastCreateFluffy = now;

        const selection = this.getSelection(props);
        selection.createFluffy(null, props.activeLayer, props.data.templateKind, (newSelection) => {
            if (this._lastCreateFluffy !== now) {
                return;
            }
            this.context.update(selection, newSelection);
        });
    },

    componentWillUnmount() {
        this._isMounted = false;
        this.context.deregisterMacro(`reservationList${this.props.id}`);
    },

    registerKeyboardShortcuts() {
        Mousetrap.bindWithHelp(
            "mod+a",
            this.selectAll,
            undefined,
            Language.get("cal_reservation_action_button_select_all")
        );
    },

    getLayerContent() {
        const offset = _.nodeOffset(this._settingsButton);
        let close = this.props.hideLayer;
        let target = {
            top: offset.top,
            left: offset.left,
            width: 32,
            height: 32,
        };

        let content = this.props.getSettingsComponent(
            this.context.user.isAdmin ? this.saveDefaultSettings : undefined,
            this.saveSettingsWithName,
            this.getSavedSettings,
            this.removeSettings
        );

        if (this.props.supportsEmail && this.state.showEmailField === true) {
            close = this.onMailDialogDismissed;
            const onFinished = () => {
                this.onMailDialogDismissed();
            };
            content = (
                <EmailDialog
                    reservations={this.state.selectedIds}
                    onFinished={onFinished}
                    onCancel={this.onMailDialogDismissed}
                />
            );
            target = "center";
        }

        const classes = {
            reservationListPopover: this.props.defaultStatus === RC.STATUS.TIME,
            waitingListPopover: this.props.defaultStatus === RC.STATUS.WAITING_LIST,
        };

        return (
            <Popover
                key={_.classSet(classes)}
                style={{ width: Popover.DEFAULT_WIDTH }}
                target={target}
                onClose={close}
            >
                {content}
            </Popover>
        );
    },

    getTypeName(typeId) {
        const type = _.find(this.state.types, (tp) => tp.id === typeId);
        return type ? type.name : String(typeId);
    },

    getSelection(props = this.props) {
        if (props.data.privateSelected) {
            return props.data.selection;
        }
        return props.selection;
    },

    getActiveFluffy(props = this.props) {
        const selection = this.getSelection(props);
        if (selection.fluffy) {
            return selection.fluffy;
        }
        return null;
    },

    getFluffyObjects() {
        const fluffy = this.getActiveFluffy();
        if (fluffy === null) {
            return [];
        }

        let searchObjects = fluffy.objectItems
            .filter((object) => object.object && object.object.id !== 0)
            .map((object) => ({
                id: object.object.id,
                type: object.type.id,
                title: object.objectText,
            }));

        if (this.props.data.typeFilter && this.props.data.typeFilter.length > 0) {
            searchObjects = _.compact(
                searchObjects.map((object) => {
                    if (_.contains(this.props.data.typeFilter, object.type)) {
                        return object;
                    }
                    return null;
                })
            );
        }

        return searchObjects;
    },

    clearSearch() {
        this.props.onSearchSettingsChange({
            searchString: "",
            complete: true,
            incomplete: true,
            allReservations: true,
            objectCategories: {},
            reservationCategories: {},
            reservationStatus: [
                RC.STATUS.CONFIRMED,
                RC.STATUS.PRELIMINARY,
                RC.STATUS.PLANNED,
                RC.STATUS.REQUESTED,
                RC.STATUS.REJECTED,
            ],
        });
        this.setState({ data: [], loadId: 0, scrollToIndex: 0 });
        const fluffy = this.getActiveFluffy();
        if (fluffy && this.state.useTemplateGroup && fluffy.templateGroupId !== 0) {
            fluffy.setTemplateGroup(0, (resultFluffy) => {
                this.context.update(fluffy, resultFluffy);
            });
        }
    },

    componentDidUpdate(prevProps, prevState) {
        // Clear cache for total time selected
        if (this.state.selectedIds !== prevState.selectedIds) {
            this._selectionTimeCache = null;
        }

        if (!prevProps.isActive && this.props.isActive) {
            this.registerKeyboardShortcuts(this.props);
        }
        if (prevProps.activeLayer !== this.props.activeLayer) {
            this.clearSearch();
        }

        const selection = this.getSelection(this.props);
        if (!selection.fluffy) {
            this.createFluffy(this.props);
            return;
        }

        if (this.state.showEmailField && this.state.selectedIds.length > 0) {
            this.props.showLayer();
        }

        if (
            !_.isEqual(
                this.getSaveState(prevState, prevProps),
                this.getSaveState(this.state, this.props)
            )
        ) {
            this.saveSettings();
        }

        if (!this.props.manualSearchButton) {
            const SEARCH_TIMEOUT = 1000;
            if (this.getSelection(this.props) !== this.getSelection(prevProps)) {
                this.find(0);
            } else if (!_.isEqual(this.props.searchOptions, prevProps.searchOptions)) {
                clearTimeout(this._reservationLoadTimeout);
                this._reservationLoadTimeout = setTimeout(() => {
                    if (this._isMounted) {
                        this._reservationLoadTimeout = null;
                        this.find(0);
                    }
                }, SEARCH_TIMEOUT);
            } else if (this.props.activeLayer !== prevProps.activeLayer) {
                this.find(0, () => {
                    if (this.props.activeLayer !== 0) {
                        this.selectAll();
                    }
                });
            } else if (!_.isEqual(this.props.data.typeFilter, prevProps.data.typeFilter)) {
                this.find(0);
            }
        }
    },

    onSearchStringChange(event) {
        const value = event.target.value;
        if (value === this.props.searchOptions.searchString) {
            return;
        }

        this.props.onSearchSettingsChange({ searchString: value });
    },

    onTableColumnsWidthChange(columnWidths) {
        if (
            TemplateKind.equals(
                TemplateKind.INFO_RESERVATION,
                this.props.searchOptions.templateKind
            )
        ) {
            return;
        }
        if (columnWidths.length === 0) {
            return;
        }

        this.setState({ columnWidths });
    },

    onTableColumnsChange(columns) {
        const columnIds = columns.map((column) => column.id);
        let state = {
            selectedColumns: columnIds,
        };
        if (
            TemplateKind.equals(
                TemplateKind.INFO_RESERVATION,
                this.props.searchOptions.templateKind
            )
        ) {
            state = {
                selectedInfoColumns: columnIds,
            };
        }
        this.setState(state, () => {
            if (!this.props.manualSearchButton) {
                this.find();
            }
        });
    },

    getSelectedColumnIds() {
        const collisionColumn = getCollisionColumn();
        let selectedIds = this.state.selectedColumns;
        if (
            TemplateKind.equals(
                TemplateKind.INFO_RESERVATION,
                this.props.searchOptions.templateKind
            )
        ) {
            selectedIds = this.state.selectedInfoColumns;
        }
        if (this.props.activeLayer !== 0 && !_.contains(selectedIds, collisionColumn.id)) {
            selectedIds = [].concat([collisionColumn.id]).concat(selectedIds);
        }
        return selectedIds;
    },

    handleLoadedData(
        startRow,
        newData,
        totalNumberOfRows,
        searchStart,
        searchEnd,
        callback,
        discardData = false
    ) {
        let total = startRow === 0 ? totalNumberOfRows : this.state.totalNumberOfRows;
        if (startRow === 0 && totalNumberOfRows === -1) {
            total = newData.length;
        }
        const addToExistingData = startRow !== 0;

        let data = [];
        if (addToExistingData && !discardData) {
            data = data.concat(this.state.data);
        }
        data.length = total;

        for (let i = startRow; i < startRow + newData.length; i++) {
            data[i] = newData[i - startRow];
        }

        this.setState(
            {
                data,
                loadId: this.state.loadId + 1,
                totalNumberOfRows: total,
                startRow,
                latestSearchStart: searchStart,
                latestSearchEnd: searchEnd,
                scrollToIndex: startRow === 0 ? 0 : undefined,
            },
            () => callback(data)
        );
    },

    find(firstIndex, cb = _.noop, lastIndex = undefined, discardData = false) {
        if (!this._mountFinished) {
            return;
        }
        const startRow = _.isNullish(firstIndex) ? this.state.startRow || 0 : firstIndex;

        if (lastIndex !== undefined) {
            const rows = this.state.data.slice(firstIndex, lastIndex + 1);
            if (
                rows.length === lastIndex + 1 - firstIndex &&
                _.every(rows, (row) => row !== undefined)
            ) {
                return; // All rows already loaded.
            }
        }

        if (startRow === 0) {
            this.setState({ selectedIds: [] });
            this.onSelectedIdsChanged([]);
            if (this._clearSelection) {
                this._clearSelection();
            }
        }

        const searchTimestamp = Date.now();
        this._lastSearch = searchTimestamp;

        this.props.find(
            startRow,
            this.getFluffyObjects(),
            this.getSelectedColumns(),
            this.state.sortOrder,
            this.state.sortColumn,
            (newData, totalNumberOfRows, searchStart, searchEnd) => {
                if (this._lastSearch > searchTimestamp) {
                    return;
                }
                this.handleLoadedData(
                    startRow,
                    newData,
                    totalNumberOfRows,
                    searchStart,
                    searchEnd,
                    cb,
                    discardData
                );
            }
        );
    },

    findIds(startRow, cb = _.noop) {
        this.props.find(
            startRow,
            this.getFluffyObjects(),
            this.getSelectedColumns(),
            this.state.sortOrder,
            this.state.sortColumn,
            cb,
            true,
            DEFAULT_NUMBER_OF_ROWS
        );
    },

    getTableRow({ index }) {
        const object = typeof index === "object" ? index : this.state.data[index];
        const columns = this.getSelectedColumns();
        const fillRow = (obj) => {
            const row = {};
            if (obj) {
                const labelData = this.props.getCellLabels(obj, columns, this.getColumnWidths());
                row.color = labelData.color;
                row.bold = labelData.bold;
                row.textColor = labelData.textColor;
                row.italicize = labelData.italicize;
                columns.forEach((column, idx) => {
                    row[column.name] = labelData.labels[idx];
                });
            } else {
                columns.forEach((column) => {
                    row[column.name] = "";
                });
            }
            return row;
        };
        if (object) {
            return fillRow(object);
        }
        const row = {};
        columns.forEach((column) => {
            row[column.name] = "";
        });
        return row;
    },

    getSummary() {
        if (!this.props.showSelectionSummary) {
            return null;
        }
        const ids = this.state.selectedIds;
        if (!ids || (ids.length === 0 && !this.state.isLoadingSelection)) {
            return null;
        }

        const getSummaryText = () => {
            if (this.state.isLoadingSelection) {
                return "…";
            }

            if (this._selectionTimeCache === null) {
                const reservations = this.state.data.filter((reservation) =>
                    _.contains(ids, reservation.id)
                );

                this._selectionTimeCache = reservations.reduce(
                    (sum, reservation) =>
                        sum + (reservation.length || reservation.end - reservation.begin) || 0,
                    0
                );
            }

            if (this._selectionTimeCache === 0) {
                return "";
            }

            const hours = Math.floor(this._selectionTimeCache / TC.SECONDS_PER_HOUR);
            const minutes =
                (this._selectionTimeCache % TC.SECONDS_PER_HOUR) / TC.SECONDS_PER_MINUTE;
            // eslint-disable-next-line no-magic-numbers
            const timeSelected = `${hours}:${_.leftPad(minutes, 2, "0")}`;
            const rowsSelected =
                ids.length === 1
                    ? Language.get("nc_reserv_list_1_row")
                    : Language.get("nc_reserv_list_x_rows", ids.length);

            return `${rowsSelected} (${timeSelected})`;
        };

        return (
            <div className="summary rightSide">
                <span className="summaryLabel">{Language.get("nc_reserv_list_selected")}</span>
                <span>{getSummaryText()}</span>
            </div>
        );
    },

    runOnceSelectionCallback() {
        if (this._runOnceSelectionCallback) {
            this._runOnceSelectionCallback();
            this._runOnceSelectionCallback = null;
        }
    },

    onSelect(objectIndex, selectedIndexes, event) {
        let result = [];
        const indexArr = [];
        if (selectedIndexes.length === 0) {
            this.setState(
                {
                    selectedIds: [],
                    scrollToIndex: undefined,
                },
                () => {
                    this.onSelectedIdsChanged([]);
                    this.runOnceSelectionCallback();
                }
            );
            return;
        }
        const isModKey = event !== undefined ? _.isModKey(event) : false;
        this.setState({ isLoadingSelection: true });
        _.runAsync(
            selectedIndexes.map((index) => (done) => {
                this.getDataAtIndex(
                    index,
                    this.state.sortColumn,
                    this.state.sortOrder,
                    (selection) => {
                        if (!selection) {
                            indexArr.push(index);
                            done();
                            return;
                        }

                        result = result.concat(selection);
                        done();
                    }
                );
            }),
            () => {
                // eslint-disable-next-line no-shadow
                const findReservationIds = (result) => {
                    if (result.length > 0 && result[0].firstReservation) {
                        // If this is the conflict list
                        return _.uniq(result.map((collision) => collision.firstReservation.id));
                    }
                    return _.pluck(result, "id");
                };
                if (result.length === selectedIndexes.length) {
                    if (result.length === 1 && this.props.onSelection && !isModKey) {
                        this.props.onSelection(result[0]);
                    }

                    const resultIds = findReservationIds(result);
                    this.setState(
                        {
                            selectedIds: resultIds,
                            scrollToIndex: undefined,
                            isLoadingSelection: false,
                        },
                        () => {
                            this.onSelectedIdsChanged(resultIds);
                            this.runOnceSelectionCallback();
                        }
                    );
                } else {
                    const resultIds = findReservationIds(result);
                    const indexArrays = _.splitArray(indexArr, MAX_RESERVATION_BATCH);
                    const data = [].concat(this.state.data);
                    _.runSync(
                        indexArrays.map((array) => (done) => {
                            const startRow = array[0];
                            this.findIds(startRow, (reservations) => {
                                for (let i = startRow; i < startRow + reservations.length; i++) {
                                    data[i] = reservations[i - startRow];
                                }
                                array.forEach((idx) => {
                                    resultIds.push(reservations[idx - startRow].id);
                                });
                                done();
                            });
                        }),
                        () => {
                            this.setState(
                                {
                                    data,
                                    isLoadingSelection: false,
                                    selectedIds: resultIds,
                                    scrollToIndex: undefined,
                                },
                                () => {
                                    this.onSelectedIdsChanged(resultIds);
                                    this.runOnceSelectionCallback();
                                }
                            );
                        }
                    );
                }
            }
        );

        if (selectedIndexes.length <= 0) {
            this.setState({ selectedIds: [] });
            this.onSelectedIdsChanged([]);
            if (this._clearSelection) {
                this._clearSelection();
            }
        }
    },

    isRowLoaded(index) {
        return Boolean(this.state.data[index]);
    },

    loadMoreRows({ startIndex, stopIndex }) {
        this.find(startIndex, _.noop, stopIndex);
    },

    _rowLoadTimeout: null,
    _firstRequestedRow: 0,

    getDataAtIndex(index, sortColumn, sortOrder, cb) {
        const sortingChanged =
            this.state.sortColumn !== sortColumn || this.state.sortOrder !== sortOrder;
        if (index >= this.state.data.length) {
            cb(null);
            return;
        }

        const object = this.state.data[index];
        if (!sortingChanged && object) {
            cb(object);
            return;
        }

        const PRE_BUFFER = 20;
        const LOAD_TIMEOUT = 1000;
        const loadDataAtIndex = () => {
            const firstRequestedRow = Math.max(0, index - PRE_BUFFER);
            let timeout = this._rowLoadTimeout;
            if (!this._rowLoadTimeout) {
                this._rowLoadCallbacks = [];
                this._firstRowLoadIndex = firstRequestedRow;
            }
            this._rowLoadCallbacks.push({ callback: cb, index });
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                if (this._isMounted) {
                    this._rowLoadTimeout = null;
                    this.find(this._firstRowLoadIndex, (data) => {
                        this._rowLoadCallbacks.forEach((cachedCallback) =>
                            cachedCallback.callback(data[cachedCallback.index])
                        );
                    });
                    this._firstRequestedRow = 0;
                }
            }, LOAD_TIMEOUT);
            this._rowLoadTimeout = timeout;
        };

        if (sortingChanged) {
            this.setState(
                {
                    sortColumn,
                    sortOrder,
                    data: [],
                    loadId: 0,
                    totalNumberOfRows: 0,
                },
                loadDataAtIndex
            );
        } else {
            loadDataAtIndex();
        }
    },

    exportMenuItems(selectedReservationIds) {
        return {
            key: "exportMenu",
            isDisabled: selectedReservationIds.length === 0,
            label: Language.get("nc_my_list"),
            submenu: [
                {
                    key: "exportMenu.openInListAdd",
                    label: Language.get("nc_add_to_my_list"),
                    isDisabled: selectedReservationIds.length === 0,
                    action: () => {
                        this.props.openStaticReservationListAdd(selectedReservationIds, true);
                    },
                },
                {
                    key: "exportMenu.openInListRemove",
                    label: Language.get("nc_remove_from_my_list"),
                    isDisabled: selectedReservationIds.length === 0,
                    action: () => {
                        this.props.openStaticReservationListAdd(selectedReservationIds, false);
                    },
                },
                {
                    key: "exportMenu.openInViewer",
                    label: `${Language.get("nc_open_in_te_viewer")} ${
                        Viewer.isActive(this.context.user)
                            ? ""
                            : Language.get("nc_configure_viewer_page_in_settings")
                    }${
                        selectedReservationIds.length > MAX_RESERVATION_EXPORT
                            ? `Max ${MAX_RESERVATION_EXPORT} reservations`
                            : ""
                    }`,
                    isDisabled:
                        selectedReservationIds.length === 0 ||
                        selectedReservationIds.length > MAX_RESERVATION_EXPORT ||
                        !Viewer.isActive(this.context.user),
                    action: () => Viewer.open(`&si=${selectedReservationIds}`, this.context.user),
                },
            ],
        };
    },

    getRowMenuItems(indexes, callback, refreshCallback) {
        if (!this.props.getDataMenuItems) {
            return [];
        }
        if (this.state.isLoadingSelection === true) {
            return [
                {
                    label: Language.get("nc_loading_try_again_soon"),
                    isDisabled: true,
                    action: _.noop,
                },
            ];
        }
        const divider = () => ({
            isSeparator: true,
        });

        const currentlyLoadedSelectedData = indexes.map((index) => this.state.data[index]);
        if (
            this.props.type === "reservationList" ||
            this.props.type === "requestList" ||
            this.props.type === "waitingList" ||
            this.props.type === "orderList"
        ) {
            const list = this.props.getDataMenuItems(
                this.state.selectedIds,
                (index, cb) =>
                    this.getDataAtIndex(index, this.state.sortColumn, this.state.sortOrder, cb),
                callback,
                this.onMassChangeObjects,
                refreshCallback,
                currentlyLoadedSelectedData
            );
            if (Viewer.isEnabled(this.context.env)) {
                list.push(divider);
                list.push(this.exportMenuItems(this.state.selectedIds));
            }
            return list;
        }

        const refresh = () => {
            if (!this.props.manualSearchButton) {
                this.find(0);
            }
            callback();
        };

        const list = this.props.getDataMenuItems(
            this.state.selectedIds,
            (index, cb) =>
                this.getDataAtIndex(index, this.state.sortColumn, this.state.sortOrder, cb),
            refresh,
            this.onMassChangeObjects,
            refreshCallback,
            currentlyLoadedSelectedData
        );
        if (Viewer.isEnabled(this.context.env)) {
            list.push(divider);
            list.push(this.exportMenuItems(this.state.selectedIds));
        }
        return list;
    },

    getSaveState(state = this.state, props = this.props) {
        // Return the state which should be persisted
        return _.pick(_.extend({}, state, props.searchOptions), props.settingsToSave);
    },

    saveSettings(state = this.state, props = this.props) {
        const TIMEOUT = 1000;
        clearTimeout(this._saveSearchTimeout);
        this._saveSearchTimeout = setTimeout(() => {
            API.setPreferences(
                "listSettings",
                [props.data.listId],
                [JSON.stringify(this.getSaveState(state, props))],
                _.noop
            );
        }, TIMEOUT);
    },

    saveDefaultSettings() {
        API.setDefaultPreferences(
            "listSettings",
            [this.props.data.listId],
            [JSON.stringify(this.getSaveState())],
            () => {
                Log.info(Language.get("nc_reserv_list_default_settings_updated"));
            }
        );
    },

    getNamedSearchesKey() {
        return `list.${this.props.data.listId}.namedSearches`;
    },

    saveSettingsWithName(searchName?: () => void, callback: (res: boolean) => void = _.noop) {
        const cb = _.isFunction(searchName) ? searchName : callback;
        let name: string | null | undefined = _.isFunction(searchName) ? undefined : searchName;
        // eslint-disable-next-line no-alert
        name = name || window.prompt(Language.get("nc_reserv_list_search_name"));

        if (!name) {
            cb(false);
            return;
        }

        this.getSavedSettings((currentData) => {
            const newData = currentData
                .filter((search) => search.name !== name)
                .concat({ name, settings: this.getSaveState() });
            newData.sort((a, b) => a.name.localeCompare(b.name));
            API.setPreferences(this.getNamedSearchesKey(), [JSON.stringify(newData)], (saveOk) => {
                if (!saveOk) {
                    Log.error("nc_list_search_settings_could_not_be_saved_as", name);
                    cb(saveOk);
                }
                Log.info(Language.get("nc_list_search_settings_saved_as", name));
                cb(saveOk);
            });
        });
    },

    removeSettings(name, callback = _.noop) {
        if (!name) {
            return;
        }

        this.getSavedSettings((currentData) => {
            const newData = currentData.filter((search) => search.name !== name);
            API.setPreferences(this.getNamedSearchesKey(), [JSON.stringify(newData)], callback);
        });
    },

    getSavedSettings(callback) {
        API.getPreferences(this.getNamedSearchesKey(), undefined, undefined, (dataString) => {
            if (!dataString) {
                callback([]);
                return;
            }
            try {
                callback(JSON.parse(dataString as any));
            } catch (ignore) {
                callback([]);
            }
        });
    },

    loadSettings(listId, callback) {
        API.getPreferences("listSettings", [listId], undefined, (result) => {
            if (!result || (result as []).length === 0) {
                callback({});
                return;
            }

            const newSettings = JSON.parse(result[0]);

            callback(_.omit(newSettings, ["beginTime", "endTime", "sortColumn", "sortOrder"]));
        });
    },

    toggleSettingsPopover(event) {
        this.props.toggleLayer();
        event.stopPropagation();
    },

    onDragStart(index, event) {
        // eslint-disable-next-line no-param-reassign
        event.dataTransfer.effectAllowed = "copy";
        _.setDragData(event, "text/plain", JSON.stringify(this.state.data[index]));
        _.setDragData(event, "application/json", JSON.stringify(this.state.data[index]));
    },

    getColumnWidths() {
        return this.state.columnWidths;
    },

    emailReservations() {
        this.setState({ showEmailField: true });
    },

    copyToClipboard() {
        const ids = this.state.selectedIds;
        if (ids.length === 0) {
            return;
        }
        const reservations = this.state.data.filter((reservation) =>
            _.contains(
                ids,
                reservation.firstReservation ? reservation.firstReservation.id : reservation.id
            )
        );
        const rows = reservations.map((reservation) => this.getTableRow({ index: reservation }));
        const columns = this.getSelectedColumns();
        const text = [columns.map((col) => col.name)];
        rows.forEach((row) => {
            text.push(columns.map((col) => `"${row[col.name]}"`));
        });
        console.table(text);
        navigator.clipboard.writeText(text.map((row) => row.join("\t")).join("\n"));
        Log.info(Language.get("nc_copied_x_reservations_to_clipboard", reservations.length));
    },

    onMailDialogDismissed() {
        if (this._isMounted) {
            this.setState({ showEmailField: false });
        }

        this.props.hideLayer();
    },

    titleForChangeType(changeType) {
        if (changeType === RC.OBJECT_MASS_CHANGE.ADD) {
            return Language.get("nc_mass_change_add_object");
        } else if (changeType === RC.OBJECT_MASS_CHANGE.REMOVE) {
            return Language.get("nc_mass_change_remove_object");
        } else if (changeType === RC.OBJECT_MASS_CHANGE.REPLACE) {
            return Language.get("nc_mass_change_replace_object");
        } else if (changeType === RC.OBJECT_MASS_CHANGE.ASSIGN) {
            return Language.get("nc_mass_change_assign_object");
        } else if (changeType === RC.OBJECT_MASS_CHANGE.COPY_AVAILABILITY) {
            return Language.get("cal_res_below_copy_reservations");
        }

        return this.props.isMassChangeCopy
            ? Language.get("nc_mass_change_move_copied_reservations")
            : Language.get("nc_mass_change_move_reservations");
    },

    onMassChangeFinished(changesMade = true) {
        this.context.dismissModal();
        if (!changesMade) {
            this.setState({ isWorking: false, performed: 0 });
            return;
        }
        this.setState({ selectedIds: [], isWorking: false, performed: 0 }, () => {
            if (this._clearSelection) {
                this._clearSelection();
            }
            this.find(0, () => {
                this.reloadVisibleRows(0);
                this.context.fireEvent(
                    `list${this.props.id}`,
                    Macros.Event.RESERVATION_MADE_OR_MODIFIED,
                    []
                );
            });
        });
    },

    // ChangeType is one of ReservationConstants.OBJECT_MASS_CHANGE
    onMassChangeObjects(changeType) {
        const ids = this.state.selectedIds;
        if (ids.length === 0) {
            this._runOnceSelectionCallback = () => {
                this.onMassChangeObjects(changeType);
            };
            this.selectAll();
            return;
        }

        const displayKey = null;

        const reservations = _.sortBy(
            this.state.data.filter((reservation) => _.contains(ids, reservation.id)),
            "begin"
        );
        const currentFluffyItem = this.props.getCurrentFluffyItem();
        this.context.presentModal(
            <MassChangeController
                startTypeId={currentFluffyItem ? currentFluffyItem.type.id : undefined}
                isCopy={this.props.activeLayer !== 0 ? this.props.isMassChangeCopy : false}
                changeType={changeType}
                reservations={reservations}
                calendar={this.props.data}
                columns={this.props.columns}
                defaultSelectedColumns={this.props.defaultSelectedColumns}
                finishedCallback={this.onMassChangeFinished}
                activeLayer={this.props.activeLayer}
                setActiveLayer={this.props.setActiveLayer}
                onLayerCreated={this.props.onLayerCreated}
                selection={this.getSelection()}
                flags={this.props.flags}
            />,
            displayKey,
            this.titleForChangeType(changeType, ids.length),
            "NO_BUTTONS",
            true
        );
    },

    getHeaderMenuItems() {
        return [];
    },

    onSortingChanged(sortData) {
        if (!this.props.isSortable(sortData.sortBy)) {
            return;
        }
        this.setState(
            {
                sortColumn: sortData.sortBy,
                sortOrder: sortData.sortDirection === "ASC" ? 0 : 1,
            },
            () => {
                API.setPreferences(
                    `listSortOrder.${this.context.getViewId()}.${this.props.type}.${this.props.id}`,
                    [
                        JSON.stringify({
                            sortBy: sortData.sortBy,
                            sortDirection: sortData.sortDirection,
                        }),
                    ],
                    _.noop
                );
                this.find(0, _.noop, undefined, true);
            }
        );
    },

    showContextMenu(event) {
        const menuItems = [
            {
                key: "Clone",
                label: Language.get("cal_res_below_copy"),
                action: () => {
                    this.refs.table.clearSelection();
                    this.props.onMassChange(
                        this.state.selectedIds,
                        false,
                        false,
                        this.reloadVisibleRows(0)
                    );
                },
            },
            {
                key: "CloneNoFields",
                label: Language.get("cal_reservation_action_button_copy_no_field"),
                action: () => {
                    this.refs.table.clearSelection();
                    this.props.onMassChange(
                        this.state.selectedIds,
                        false,
                        true,
                        this.reloadVisibleRows(0)
                    );
                },
            },
            {
                key: "Change",
                label: Language.get("dialog_modify"),
                action: () => {
                    this.refs.table.clearSelection();
                    this.props.onMassChange(
                        this.state.selectedIds,
                        true,
                        false,
                        this.reloadVisibleRows(0)
                    );
                },
            },
        ];

        this._contextMenuRef = ContextMenu.displayMenu(menuItems, event, _.noop);
    },

    getSelectedColumns() {
        const columns = this.getAvailableColumns();
        return _.compact(
            this.getSelectedColumnIds().map((id) => _.find(columns, (column) => column.id === id))
        );
    },

    getAvailableColumns() {
        let columns = this.props.columns.map((column) => _.extend({ primary: true }, column));
        if (this.props.activeLayer > 0) {
            columns.push(getCollisionColumn());
        }

        const fluffy = this.getActiveFluffy();
        if (fluffy && this.props.addTypeColumns) {
            const types = _.uniq(fluffy.objectItems.map((item) => item.type.id));
            columns = columns.concat(
                types.map((type) => ({
                    id: TYPE_COLUMN_OFFSET + type,
                    name: this.getTypeName(type),
                    isType: true,
                }))
            );
        }

        if (this.props.addTypeColumns) {
            // Should technically probably be its own flag - addFieldColumns - but we want fields in the same cases as we do types
            columns = columns.concat(
                this.state.columnFields.map((field) => ({
                    id: FIELD_COLUMN_OFFSET + field.id,
                    name: this.context.user.showExtraInfo
                        ? `${field.name} (${field.extid})`
                        : field.name,
                    isField: true,
                }))
            );
        }

        // Add any selected type columns that does not exist in the fluffy
        const selectedIds = this.getSelectedColumnIds();
        const missingTypeColumns = selectedIds
            .filter((id) => id > TYPE_COLUMN_OFFSET)
            .filter((id) => !_.find(columns, (column) => column.id === id))
            .map((id) => ({
                id,
                name: this.getTypeName(id - TYPE_COLUMN_OFFSET),
            }));

        return columns.concat(missingTypeColumns);
    },

    toggleSearchHelp() {
        this.setState({ isSearchHelpVisible: !this.state.isSearchHelpVisible });
    },

    render() {
        let style = { width: this.props.width, height: "100%" };
        if (this.props.style) {
            style = this.props.style;
        }

        const selectionSummary = this.getSummary();

        const classes = _.extend(
            {
                reservationList: true,
                active: this.props.isActive,
                inactive: !this.props.isActive,
            },
            this.props.classes
        );

        const settingsButtonRef = (node) => {
            this._settingsButton = node;
            if (this.props.settingsRef) {
                this.props.settingsRef(node);
            }
        };

        const listSecondLine = {
            listSecondLine: true,
            typeFilter: this.props.data.typeFilter && this.props.data.typeFilter.length > 0,
        };

        const cellRenderers = {};
        cellRenderers[TYPE_COLUMN_OFFSET] = collisionCellRenderer;
        this.state.columnFields.forEach((field) => {
            if (field.kind === FieldInput.fieldKind.BOOLEAN) {
                cellRenderers[FIELD_COLUMN_OFFSET + field.id] = checkboxCellRenderer;
            }
        });

        const searchHelp = this.state.isSearchHelpVisible ? this.props.getSearchHelp() : null;

        const searchHelpButton = this.props.getSearchHelp ? (
            <button title="?" className="toggleSearchHelp" onClick={this.toggleSearchHelp} />
        ) : null;

        return (
            <div className={_.classSet(classes)} style={style}>
                <div className="listTopLine">
                    {this.props.topLeft}
                    <button
                        title={Language.get("nc_list_filter_title")}
                        ref={settingsButtonRef}
                        onClick={this.toggleSettingsPopover}
                        className="settings"
                        data-cy="filter-btn"
                    />
                    {this._renderSelectAllButton()}
                    {this._renderEmailButton()}
                    {this._renderClipboardButton()}
                    {this._renderSearchField(searchHelp, searchHelpButton)}
                    {this._renderManualSearchButton()}
                    {this._renderLoadIndicator()}
                </div>
                {this._renderLoaderRow()}
                {this._renderWorkingRow()}
                <div className={_.classSet(listSecondLine)}>
                    <div className="summary">
                        <span className="listTitle summaryLabel">{this.props.title}</span>
                        {this._renderSearchInterval()}
                        {this._renderTypeFilterInfo()}
                    </div>
                    {selectionSummary}
                </div>
                {this._renderPrivateSelected()}
                <Table
                    cellRenderers={cellRenderers}
                    language={Language}
                    log={Log}
                    presentModal={this.context.presentModal}
                    contextMenu={ContextMenu}
                    ref="table"
                    registerSelectAll={this.registerSelectAll}
                    registerReloadVisibleRows={this.registerReloadVisibleRows}
                    setClearSelection={this._setClearSelection}
                    isRowLoaded={this.isRowLoaded}
                    loadMoreRows={this.loadMoreRows}
                    rowCount={this.state.totalNumberOfRows}
                    allColumns={this.getAvailableColumns()}
                    columns={this.getSelectedColumns()}
                    columnWidths={this.getColumnWidths()}
                    onColumnChange={this.onTableColumnsChange}
                    onColumnWidthChange={this.onTableColumnsWidthChange}
                    getHeaderMenuItems={this.getHeaderMenuItems}
                    columnButtonClick={this.columnButtonClick}
                    getTableRow={this.getTableRow}
                    getRowMenuItems={this.getRowMenuItems}
                    width={style.width}
                    onSelect={this.onSelect}
                    highlight={
                        this.props.allowMultiSelection
                            ? Table.HIGHLIGHT.MULTI
                            : Table.HIGHLIGHT.SINGLE
                    }
                    onSortingChanged={this.onSortingChanged}
                    isSortable={this.props.isSortable}
                    onDragStart={this.onDragStart}
                    scrollToIndex={this.state.scrollToIndex}
                    sortOrder={this.state.sortOrder}
                    sortColumn={this.state.sortColumn}
                />
            </div>
        );
    },

    _setClearSelection(clearFunction) {
        this._clearSelection = clearFunction;

        if (this.props.setClearSelection) {
            this.props.setClearSelection(this._clearSelection);
        }
    },

    _renderSearchInterval() {
        if (!this.props.showSearchInterval) {
            return null;
        }

        const startTime =
            this.state.latestSearchStart ||
            new MillenniumDateTime(this.props.searchOptions.beginTime);
        const endTime =
            this.state.latestSearchEnd || new MillenniumDateTime(this.props.searchOptions.endTime);
        const format = Language.getDateFormat("date_f_yyyy_mm_dd");
        const formatEnd = Language.getDateFormat("date_f_yyyy_mm_dd_end");
        const start = startTime.getMts() !== 0 ? SimpleDateFormat.format(startTime, format) : "";
        const end = endTime.getMts() !== 0 ? SimpleDateFormat.format(endTime, formatEnd) : "";
        const timeRange = start !== end ? `${start} - ${end}` : start;

        return [
            <span className="summaryLabel" key="intervalLabel">
                {Language.get("nc_reserv_list_searching_period")}
            </span>,
            <span key="intervalTime">{timeRange}</span>,
        ];
    },

    _renderTypeFilterInfo() {
        if (!this.props.data.typeFilter || !(this.props.data.typeFilter.length > 0)) {
            return null;
        }

        const typeFilterInfo = this.props.data.typeFilter
            .map((id) => {
                const found = _.find(this.state.types, (type) => type.id === id);
                if (found) {
                    return found.name;
                }
                return id;
            })
            .join(", ");

        return [
            <span className="summaryLabel" key="intervalLabel">
                {Language.get("nc_cal_res_side_view_filter_type")}:
            </span>,
            <span key="typeFilterInfo">{typeFilterInfo}</span>,
        ];
    },

    selectAll() {
        if (this._selectAll) {
            this._selectAll();
        }
    },

    registerSelectAll(fn) {
        this._selectAll = fn;
    },

    registerReloadVisibleRows(fn) {
        this._reloadVisibleRows = fn;
    },

    reloadVisibleRows(startIndex) {
        if (this._reloadVisibleRows) {
            this._reloadVisibleRows(startIndex);
        }
    },

    _renderSelectAllButton() {
        if (!this.props.allowMultiSelection) {
            return null;
        }
        return (
            <button
                title={Language.get("cal_reservation_action_button_select_all")}
                ref="selectAllButton"
                onClick={this.selectAll}
                className="selectAll"
            />
        );
    },

    _renderEmailButton() {
        if (!this.props.supportsEmail) {
            return null;
        }

        const numReservations = this.state.selectedIds.length;
        const mailEnabled =
            TimeEdit.isEmailActive &&
            numReservations > 0 &&
            numReservations <= MAX_RESERVATIONS_TO_MAIL;
        return (
            <button
                title={Language.get("cal_func_res_email_reservation")}
                ref="emailReservationsButton"
                disabled={!mailEnabled}
                onClick={this.emailReservations}
                className="emailReservations"
            />
        );
    },

    _renderClipboardButton() {
        return (
            <button
                title={Language.get("nc_copy_to_clipboard")}
                ref="clipboardButton"
                disabled={this.state.selectedIds.length < 1 || this.state.isLoadingSelection}
                onClick={this.copyToClipboard}
                className="clipboard"
            />
        );
    },

    _renderSearchField(searchHelp, searchHelpButton) {
        if (!this.props.supportsSearch) {
            return null;
        }
        return (
            <div className="searchContainer">
                <input
                    type="text"
                    className="search"
                    value={this.props.searchOptions.searchString}
                    onChange={this.onSearchStringChange}
                />
                {searchHelp}
                {searchHelpButton}
                <button className="clearSearch" onClick={this.clearSearch} />
            </div>
        );
    },

    _renderManualSearchButton() {
        if (!this.props.manualSearchButton) {
            return null;
        }
        return (
            <button
                title={Language.get("nc_conflict_run_report")}
                ref="manualSearchButton"
                disabled={!this.props.isSearchButtonActive}
                onClick={() => {
                    this.find(0);
                }}
                className="manualSearchButton uiText teButton"
                style={{ width: "unset", color: this.props.isSearchButtonActive ? "white" : "" }}
            >
                {Language.get("nc_conflict_run_report")}
            </button>
        );
    },

    _renderLoadIndicator() {
        if (!this.props.showLoadIndicator) {
            return null;
        }
        return <div className="smallSpinner" />;
    },

    _renderPrivateSelected() {
        const selectionText = this.getFluffyObjects()
            .map((object) => object.title)
            .join(", ");
        if (!this.props.privateSelected || !selectionText.length) {
            return null;
        }

        return (
            <div className="listSecondLine">
                <div>
                    <span className="summaryLabel" key="selectionLabel">
                        {" "}
                        {Language.get("nc_search_objects")}{" "}
                    </span>
                    <span> {selectionText} </span>
                </div>
            </div>
        );
    },

    _renderLoaderRow() {
        if (_.isNullish(this.props.progress)) {
            return null;
        }
        const label = `${Language.get("nc_mass_change_loading", this.props.progress)}`;
        return (
            <div className="listSecondLine loadRow">
                <span className="summaryLabel"> {label} </span>
            </div>
        );
    },

    _renderWorkingRow() {
        if (!this.state.isWorking) {
            return null;
        }
        const label = `${Language.get("nc_mass_change_working", this.state.performed)}`;
        return (
            <div className="listSecondLine loadRow">
                <span className="summaryLabel"> {label} </span>
            </div>
        );
    },
});

export default GenericList = LayerComponent.wrap(GenericList);
