/* eslint-disable no-param-reassign */
import { Macros } from "../models/Macros";
import API from "./TimeEditAPI";
import Language from "./Language";
import {
    MillenniumDate,
    MillenniumDateTime,
    MillenniumTime,
    MillenniumWeek,
} from "@timeedit/millennium-time";
import Log from "./Log";
import { TTimeEditGlobals } from "./TimeEdit";

export const registerDevTools = (TimeEdit: TTimeEditGlobals) => {
    // eslint-disable-next-line no-console
    Log.listen((event) => console.log(JSON.stringify(event)), Log.ALL);
    TimeEdit.API = API;
    TimeEdit.Log = Log;
    TimeEdit.Locale = Language;
    TimeEdit.MillenniumDate = MillenniumDate;
    TimeEdit.MillenniumDateTime = MillenniumDateTime;
    TimeEdit.toDate = function (dateNumber: number) {
        return new MillenniumDate(dateNumber).format("yyyy-MM-dd");
    };
    TimeEdit.toDateTime = function (mts) {
        return new MillenniumDateTime(mts).format("yyyy-MM-dd HH:mm:ss");
    };
    TimeEdit.toTime = function (mts) {
        return new MillenniumTime(mts).format("HH:mm:ss");
    };
    window.TimeEdit = TimeEdit;
};

export const registerAppDevToolsAfterLogin = (app) => {
    (TimeEdit as any).showGroupManager = (requests) => {
        app.showGroupManager({ groupRequests: requests || [] }, (result) => {
            // eslint-disable-next-line no-console
            console.log(result);
        });
    };
    (TimeEdit as any).switchToWeekView = () => {
        app.switchToWeekView();
    };
    (TimeEdit as any).setWeeks = () => {
        app.setWeeks(
            MillenniumWeek.create(
                ["2307", "2302", "2309", "2306", "2303", "2304", "2308", "2301"],
                Language.firstDayOfWeek,
                Language.daysInFirstWeek
            )
        );
    };
    (TimeEdit as any).switchToWeekViewAndSetWeeks = () => {
        app.switchToWeekViewAndSetWeeks(
            MillenniumWeek.create(
                ["2307", "2302", "2309", "2306", "2303", "2304", "2308", "2301"],
                Language.firstDayOfWeek,
                Language.daysInFirstWeek
            )
        );
    };
    (TimeEdit as any).switchToWeekViewAndSetIncorrectWeeks = () => {
        app.switchToWeekViewAndSetWeeks(
            MillenniumWeek.create(
                ["2307", "2302", "2309", "2306", "2302", "2303", "2304", "2308", "2301"],
                Language.firstDayOfWeek,
                Language.daysInFirstWeek
            )
        );
    };
    (TimeEdit as any).setSchedulingTracks = () => {
        app.setSchedulingTracks({
            Petronella: [
                {
                    activityId: 5,
                    values: [{ type: "object", value: { extId: "room", value: ["r14"] } }],
                    reservationId: 40,
                },
                {
                    activityId: 6,
                    reservationId: 42,
                    values: [{ type: "object", value: { extId: "room", value: ["r14"] } }],
                },
            ],
            Morgan: [
                {
                    activityId: 7,
                    values: [{ type: "object", value: { extId: "room", value: ["r14"] } }],
                    reservationId: 50,
                },
                {
                    activityId: 8,
                    values: [{ type: "object", value: { extId: "room", value: ["r14"] } }],
                },
            ],
            Flasklock: [
                {
                    activityId: 11,
                    values: [{ type: "object", value: { extId: "room", value: ["r14"] } }],
                    reservationId: 70,
                },
                {
                    activityId: 12,
                    reservationId: 72,
                    values: [{ type: "object", value: { extId: "room", value: ["r14"] } }],
                },
            ],
            Grytlock: [
                {
                    activityId: 9,
                    values: [{ type: "object", value: { extId: "room", value: ["r14"] } }],
                    reservationId: 60,
                },
                {
                    activityId: 10,
                    values: [{ type: "object", value: { extId: "room", value: ["r14"] } }],
                },
            ],
        });
    };
    (TimeEdit as any).goToNextTrack = () => {
        app.goToNextTrack(app.state.currentTrack, app.state.mappedResult);
    };
    (TimeEdit as any).clearschedulingTracks = () => {
        app.clearSchedulingTracks();
    };
    (TimeEdit as any).testSCHED31 = () => {
        const typeList = ["room"];
        API.getTypesByExtid(typeList, false, (result) => {
            // eslint-disable-next-line no-console
            console.log("1", result);
            API.getTypesByExtid(["room"], false, (result2) => {
                // eslint-disable-next-line no-console
                console.log("2", result2);
            });
        });
        API.getTypesByExtid(typeList, false, (result) => {
            // eslint-disable-next-line no-console
            console.log("3", result);
            API.getTypesByExtid(["room", "course"], false, (result2) => {
                // eslint-disable-next-line no-console
                console.log("4", result2);
            });
        });
        API.getTypesByExtid(typeList, false, (result) => {
            // eslint-disable-next-line no-console
            console.log("5", result);
            API.getTypesByExtid(typeList, false, (result2) => {
                // eslint-disable-next-line no-console
                console.log("6", result2);
            });
        });
    };
    // _te_182, _te_82, Biffbertils bastanta bokningsgrupp
    (TimeEdit as any).testGroupSetting = (searchString = "AQVA") => {
        app.fireEvent("app", Macros.Event.LIST_RESERVATION_GROUP, searchString);
    };
    (TimeEdit as any).getRecentlyUsedState = () => {
        return TimeEdit.State.get()?.user?.recentlyUsedState;
    };
    (TimeEdit as any).setRecentlyUsed = (typeId, checked) => {
        const user = TimeEdit.State.get()?.user;
        TimeEdit.State.replace(user, user?.setRecentlyUsedChecked(typeId, checked));
    };
    (TimeEdit as any).clearRecentlyUsed = () => {
        const user = TimeEdit.State.get()?.user;
        TimeEdit.State.replace(user, user?.clearRecentlyUsedChecks());
    };
};
