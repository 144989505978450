import _ from "underscore";

type EventWithDataTransfer = Event & { dataTransfer: DataTransfer };

let _dragTypes: string[];

export default {
    nodeOffset(node: Element | null) {
        let top = 0;
        let left = 0;
        let currentNode: HTMLElement | null = node as HTMLElement;

        while (currentNode !== null) {
            top += currentNode.offsetTop - currentNode.scrollTop + currentNode.clientTop;
            left += currentNode.offsetLeft - currentNode.scrollLeft + currentNode.clientLeft;
            currentNode = currentNode.offsetParent as HTMLElement;
        }
        return { top, left };
    },

    matches(element: HTMLElement, selector: string): boolean {
        const matches =
            element.webkitMatchesSelector ||
            (element as any).mozMatchesSelector ||
            (element as any).msMatchesSelector ||
            element.matches;

        if (!matches) {
            throw new Error("The browser does not support the Element.matches() method.");
        }

        return matches.call(element, selector);
    },

    /**
     * Unified event handling functions.
     * Only tested with React's browser-unified synthetic events.
     */

    /**
     * Fetch clientX and clientY unified for touch and click events.
     * For touch, returns the coordinate of the first touch,
     * or the first changed touch (to work with touch end) if there is
     * no "normal" touch.
     */
    getClientPos(e: MouseEvent | TouchEvent): {
        x: number;
        y: number;
    } {
        const me = e as MouseEvent;
        const te = e as TouchEvent;
        if (!this.isNullish(me.clientX)) {
            return { x: me.clientX, y: me.clientY };
        } else if (te.touches && te.touches.length > 0) {
            const touch = te.touches[0];
            return { x: touch.clientX, y: touch.clientY };
        } else if (te.changedTouches && te.changedTouches.length > 0) {
            const touch = te.changedTouches[0];
            return { x: touch.clientX, y: touch.clientY };
        }

        throw new Error("Event contains no client coordinates.");
    },

    getMoveEvent(event: MouseEvent | TouchEvent): "touchmove" | "mousemove" {
        return (event as TouchEvent).touches ? "touchmove" : "mousemove";
    },

    /**
     * This - and invoking functions - should probably
     * take touchcancel into account as it is a valid
     * way for the interaction to end.
     */
    getEndEvent(event: MouseEvent | TouchEvent): "touchend" | "mouseup" {
        return (event as TouchEvent).touches ? "touchend" : "mouseup";
    },

    /**
     * "Is this event a left-click-equivalent?"
     * The internals are an implementation detail,
     * assume the question will be answered, not how.
     */
    isSingleClick(event: MouseEvent | TouchEvent): boolean {
        return (event as MouseEvent).button! === 0 || !_.isNullish((event as TouchEvent).touches);
    },

    /**
     * Return an array of arrays containing all combinations of values
     * based on the input.
     *
     * The order of the items in each array in the return value is the
     * same as the order of the input arrays.
     *
     * @param  {Array of arrays} arrays Values that should be combined
     * @type {Array of arrays} All combinations
     */
    combineArrays<T>(arrays: T[][]): T[][] {
        if (!Array.isArray(arrays) || !Array.isArray(arrays[0])) {
            throw new Error("Must specify an array of arrays as input to combine.");
        }
        const combineArraysHelper = function (arr: T[][]) {
            if (arr.length === 1) {
                return arr[0].map((item) => [item]);
            }

            const result: T[][] = [];
            const remainder = combineArraysHelper(arr.slice(1)); // Recur with the rest of array
            const length = remainder.length;
            for (let i = 0; i < length; i++) {
                const innerLength = arr[0].length;
                for (let j = 0; j < innerLength; j++) {
                    result.push(remainder[i].concat(arr[0][j]));
                }
            }

            return result;
        };

        // eslint-disable-next-line no-param-reassign
        arrays = [...arrays];
        arrays.reverse();
        return combineArraysHelper(arrays);
    },

    /**
     * Return a space-separated string of all keys in the specified object
     * whose value is truthy.
     *
     * If an array or a variable number of strings are used as the argument,
     * they will simply be joined with a space between each value.
     *
     * Based on the Facebook React cx function.
     */
    classSet(...args): string {
        const obj = args[0];
        if (typeof obj === "object") {
            return Object.keys(obj)
                .filter((key) => obj[key])
                .join(" ");
        }

        return Array.prototype.join.call(args, " ");
    },

    runAsync(functions: ((...args) => void)[], callback: () => void) {
        const tasks = functions.length;
        let counter = 0;
        const done = function () {
            counter++;
            if (counter === tasks) {
                callback();
            }
        };

        functions.forEach((fn) => {
            if (typeof fn !== "function") {
                console.log("Function is not a function", fn);
                done();
            } else {
                fn(done);
            }
        });
    },

    runSync(functions: ((...args) => void)[], callback: () => void) {
        // For when we want to be kind to a server.
        if (functions.length === 0) {
            callback();
            return;
        }
        const fn = functions.shift();
        if (typeof fn !== "function") {
            console.log("Function is not a function", fn);
            this.runSync(functions, callback);
        } else {
            fn(() => {
                this.runSync(functions, callback);
            });
        }
    },

    preventDefault(event: Event) {
        event.preventDefault();
    },

    stopPropagation(event: Event) {
        event.stopPropagation();
    },

    asArray<T>(val: T | T[]): T[] {
        if (val === null) {
            return [];
        }
        return Array.isArray(val) ? val : [val];
    },

    isInteger(val): boolean {
        // eslint-disable-next-line no-useless-escape
        return /^(\-|\+)?([0-9]+|Infinity)$/.test(val);
    },

    isNullish(val: any): boolean {
        return val === null || val === undefined;
    },

    isInputNode(nodeName: string): boolean {
        return (
            nodeName === "INPUT" ||
            nodeName === "SELECT" ||
            nodeName === "OPTION" ||
            nodeName === "TEXTAREA"
        );
    },

    splitArray<T>(array: T[], chunkSize: number): T[][] {
        const results: T[][] = [];
        let first = 0;
        while (first < array.length) {
            results.push(array.slice(first, first + chunkSize));
            first += chunkSize;
        }
        return results;
    },

    // Misery functions to support drag and drop in IE 11.
    // The problems:
    //  * We use different data types, IE 11 only allows text and URL
    //  * So, we wrap all data set in a JSON object which we set data with the type as key
    //  * This breaks isEventDragDataOfType, as reading data contents is prohibited between drag start and drop
    //  * So, we also use a global variable _dragTypes to keep tack of the types set.
    //  * All draggers and droppers need to use setDragData and getDragData
    // Wraps all drag transfer data as JSON in the 'text' type, because that's all IE 11 supports

    getDragDataObject(event: EventWithDataTransfer): any {
        const data = event.dataTransfer.getData("text");
        if (data) {
            try {
                return JSON.parse(data);
            } catch (e) {
                console.error(e);
            }
        }
        _dragTypes = [];
        return {};
    },

    isEventDragDataOfType(event: EventWithDataTransfer, types): boolean {
        if (!_dragTypes) {
            return false;
        }
        if (!Array.isArray(types)) {
            // eslint-disable-next-line no-param-reassign
            types = [types];
        }
        return types.some((type) => _dragTypes.indexOf(type) !== -1);

        // This is the check we want to use when IE can be dropped.
        // But do check that Edge actually supports the newer nicer ways.
        /* var eventTypes = event.dataTransfer.types;
        return types.some(function (type) {
            if (eventTypes.indexOf) {
                return eventTypes.indexOf(type) !== -1;
            }
            return eventTypes.contains(type);
        });*/
    },

    setDragData(event: EventWithDataTransfer, dataType: string, data: any) {
        const dataObject = this.getDragDataObject(event);
        _dragTypes.push(dataType);
        dataObject[dataType] = data;
        event.dataTransfer.setData("text", JSON.stringify(dataObject));
    },

    getDragData(event: EventWithDataTransfer, dataType: string): string {
        const wrappedData = this.getDragDataObject(event);
        event.preventDefault();
        return wrappedData[dataType];
    },

    sortByNumericColumns(arrayOfArrays: number[][]): number[][] {
        // All values in arrayOfArrays must be numeric arrays of the same length
        let length = -1;
        for (let i = 0; i < arrayOfArrays.length; i++) {
            if (length === -1) {
                length = arrayOfArrays[i].length;
            }
            if (arrayOfArrays[i].length !== length) {
                throw new Error("Sorted arrays must contain the same number of values.");
            }

            if (!_.every(arrayOfArrays[i], _.isNumber)) {
                throw new Error("Sorted arrays must only contain numeric values.");
            }
        }

        // Sort away!
        const sortedArrays: number[][] = [...arrayOfArrays];
        sortedArrays.sort((a, b) => {
            for (let i = 0; i < a.length; i++) {
                const diff = a[i] - b[i];
                if (diff !== 0) {
                    return diff;
                }
            }
            return 0;
        });
        return sortedArrays;
    },

    fill<T>(array: T[], value: T, start?: number, end?: number): T[] {
        const length = array.length;

        // eslint-disable-next-line no-param-reassign
        start = start === undefined ? 0 : start;
        if (start < 0) {
            // eslint-disable-next-line no-param-reassign
            start = -start > length ? 0 : length + start;
        }
        // eslint-disable-next-line no-param-reassign
        end = end === undefined || end > length ? length : end;
        if (end < 0) {
            // eslint-disable-next-line no-param-reassign
            end += length;
        }
        // eslint-disable-next-line no-param-reassign
        end = start > end ? 0 : end;

        while (start < end) {
            // eslint-disable-next-line no-param-reassign
            array[start++] = value;
        }

        return array;
    },

    leftPad(string: string, minLength: number, padChar: string): string {
        const length = String(string).length;
        if (length >= minLength) {
            return string;
        }

        let padding = "";
        for (let i = 0; i < minLength - length; i++) {
            padding = padding + padChar;
        }

        return padding + string;
    },

    isModKey(event: Event): boolean {
        const modKey = /Mac|iPod|iPhone|iPad/.test(navigator.platform) ? "metaKey" : "ctrlKey";
        return event[modKey];
    },

    // _.clone does not work for ES6 classes, so we check if class instance has a clone function
    safeClone<T>(obj: T): T {
        const anyobj = obj as any;
        if (anyobj?.clone && typeof anyobj.clone === "function") {
            return anyobj.clone();
        }
        return _.clone(anyobj);
    },
};
