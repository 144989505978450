const EMERGENCY = 0;
const ALERT = 1;
const CRITICAL = 2;
const ERROR = 3;
const WARNING = 4;
const NOTICE = 5;
const INFO = 6;
const DEBUG = 7;

export type TLogEvent = { time: number; level: number; message: string; context: TLogContext };

type TLogContext = {
    isImportant?: boolean;
    actions?: { title: string; action: () => void }[];
    url?: string;
    code?: number;
    copyable?: boolean;
};

const messages: TLogEvent[] = [];

const listeners: {
    listener: (event: TLogEvent) => any;
    levels: number[];
}[] = [];

const notify = (newEvent: TLogEvent) => {
    const callbacks = listeners
        .filter((item) => item.levels.indexOf(newEvent.level) !== -1)
        .map((item) => item.listener);
    callbacks.forEach((callback) => callback(newEvent));
};

const log = (message, context: TLogContext | undefined, level: number) => {
    const logEvent: TLogEvent = {
        time: Date.now(),
        level,
        message,
        context: context || {},
    };
    messages.push(logEvent);
    notify(logEvent);
};

const emergency = (message: string, context?: TLogContext) => {
    log(message, context, EMERGENCY);
};

const alert = (message: string, context?: TLogContext) => {
    log(message, context, ALERT);
};

const critical = (message: string, context?: TLogContext) => {
    log(message, context, CRITICAL);
};

const error = (message: string, context?: TLogContext) => {
    log(message, context, ERROR);
};

const warning = (message: string, context?: TLogContext) => {
    log(message, context, WARNING);
};

const notice = (message: string, context?: TLogContext) => {
    log(message, context, NOTICE);
};

const info = (message: string, context?: TLogContext) => {
    log(message, context, INFO);
};

const debug = (message: string, context?: TLogContext) => {
    log(message, context, DEBUG);
};

const addListener = (listener: (newEvent: TLogEvent) => void, levels: number[]) => {
    listeners.push({
        listener,
        levels: Array.isArray(levels) ? levels : [levels],
    });
};

export default {
    listen: addListener,
    emergency,
    alert,
    critical,
    error,
    warning,
    notice,
    info,
    debug,
    EMERGENCY,
    ALERT,
    CRITICAL,
    ERROR,
    WARNING,
    NOTICE,
    INFO,
    DEBUG,
    ALL: [DEBUG, INFO, NOTICE, WARNING, ERROR, CRITICAL, ALERT, EMERGENCY],
    ALL_LOADS_FINISHED_MSG: "allLoadsFinished",
};
