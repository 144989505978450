import { Button, notification, Space } from "antd";
import Log, { TLogEvent } from "../lib/Log";
import { useEffect } from "react";
import Paragraph from "antd/lib/typography/Paragraph";
import {
    Severity,
    SeverityIcon,
} from "@timeedit/ui-components/lib/src/components/Icons/SeverityIcons";

const IGNORE_CODE = -9065;
const AUTO_CLOSE_SECONDS = 5;

const logListenLevels =
    process.env.NODE_ENV === "development"
        ? Log.ALL
        : [Log.INFO, Log.NOTICE, Log.WARNING, Log.ERROR, Log.CRITICAL, Log.ALERT, Log.EMERGENCY];

const NotificationLogListener = () => {
    const [api, contextHolder] = notification.useNotification();

    const listener = ({ level, context, message }: TLogEvent) => {
        // Escape hatch for certain codes.
        if (context?.code === IGNORE_CODE) return;

        let severity: Severity = "error";
        let isImportant = false;

        if (level < Log.WARNING) {
            isImportant = true;
        }
        if (level === Log.WARNING) {
            severity = "warning";
        }
        if (level > Log.WARNING) {
            severity = "information";
        }
        isImportant || context.isImportant;

        const key = `notification-${Date.now()}`;

        const actions = context.actions?.length
            ? context.actions.map((a) => (
                  <Space>
                      <Button type="link" size="small" onClick={a.action}>
                          {a.title}
                      </Button>
                  </Space>
              ))
            : undefined;

        const url = context.url;
        const description = url ? <a href={url}>{message}</a> : <>{message}</>;

        const duration = isImportant ? 0 : AUTO_CLOSE_SECONDS;

        const copyableMessageWithEllipsis = (
            <Paragraph
                ellipsis={{ rows: 6, expandable: false }}
                copyable={context.copyable ? { format: "text/plain", text: message } : false}
            >
                {description}
            </Paragraph>
        );

        api.open({
            message: copyableMessageWithEllipsis,
            actions,
            key,
            duration,
            icon: <SeverityIcon severity={severity} size={24} alt={severity} />,
            style: { maxHeight: 300 },
        });
    };

    // Start listening for certail log levels and display notifications for them.
    useEffect(() => {
        Log.listen(listener, logListenLevels);
    }, []);

    // Render context, where the actual Notification component will be rendered.
    return <>{contextHolder}</>;
};

export default NotificationLogListener;
